<template>
  <div class="batch-number-wrap common-tab">
    <div class="bottom">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入来料批次码/物料编码/物料名称"
        :tags="functionHeader.tags"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :columns="fastColumns"
        :height="height"
        :columns-setting="false"
        :set-data-method="getTableData"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="status" slot-scope="{ row }">{{ row.status === 1 ? '无效' : '有效' }}</div>
        <div slot="settingVariable" slot-scope="{ row }">{{ row.settingVariable === 0 ? '否' : row.settingVariable === 1 ? '是' : '-' }}</div>
        <div slot="batchNumber" slot-scope="{ row }" class="batch-number-columns">
          <span>{{ row.batchNumber }}</span>
          <img v-if="currentBatchNumberList.indexOf(row.batchNumber) !== -1" src="../../../../assets/information/新@2x.png">
        </div>
        <div slot="action" slot-scope="{ row }">
          <el-button type="text" @click="printCode(row)">打印</el-button>
          <el-divider v-if="permission('Del')" direction="vertical" />
          <DelPopover v-if="permission('Del')" @onOk="callback => del(row, callback)" />
          <el-divider v-if="permission('Cancel')" direction="vertical" />
          <el-button v-if="permission('Cancel')" type="text" :disabled="row.status === 1" @click="cancel(row)">作废</el-button>
        </div>
      </MTable>
    </div>

    <PrintCode ref="printCode" :common-id="commonId" :sequenceNumberType="'0'"/>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import { fastColumns } from './columns'
import SearchForm from '@/components/SearchForm/SearchForm'
import DelPopover from '@/components/DelPopover/index'
import api from '@/api/information/print-label/batch-number'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import PrintCode from '@/components/PrintCode/printSequence'
import PrintCodes from '@/project-custom/kiki/print-code-info/print-code-info'

export default {
  name: 'comingsBatchNumber',
  components: { PrintCodes, PrintCode, SearchForm, FunctionHeader, DelPopover },
  mixins: [],
  data() {
    return {
      commonId: 'print',
      select: '',
      fastColumns,
      functionHeader: {
        searchVal: '',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small', disabled: true },
            style: { width: 'auto' },
            innerText: '批量打印',
            on: {
              click: this.batchPrint
            }
          }
        ]
      },
      currentBatchNumberList: [],
      printCodes: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 196
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  beforeMount() {},
  mounted() {},
  methods: {
    async getTableData(condition, callback) {
      const { page, search } = condition
      if (this.functionHeader.searchVal) {
        search.push({
          id: getUUid(),
          fieldName: 'batchNumber',
          fieldType: 'string',
          fieldValue: this.functionHeader.searchVal,
          operator: 'like'
        })
      }
      const res = await api.getComingMaterialsSequenceNumberListPage({
        page: page.currentPage,
        limit: page.pageSize,
        type: 0,
        searchKeyword:this.functionHeader.searchVal
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    selectionChange(data) {
      this.printCodes = data
      this.functionHeader.tags[0].props.disabled = !this.printCodes.length
    },
    batchPrint() {
      this.commonId = `${this.printCodes[0]}print`
      this.$refs.printCode.batchPrint(this.printCodes)
    },
    printCode(row) {
      this.commonId = `${row.batchNumber}print`
      this.$refs.printCode.print(row)
    },
    async del(row) {
      const res = await api.deleteBatchNumber({ id: row.id })
      if (res) {
        this.$message.success('删除成功')
        this.$refs.mTable.setTableData()
      }
    },
    async cancel(row) {
      this.$confirm('您确认作废吗?作废后此条码不可流转使用', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.cancelBatchNumber(row.batchNumber)
        if (res) {
          this.$message.success('作废成功')
          this.$refs.mTable.setTableData()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.m-radio-group {
  margin: 10px 10px 0 10px;
}

.batch-number-wrap {
  width: 100%;

  .top {
    ::v-deep {
      .search-form-wrap {
        form {
          .el-row {
            .el-col:last-child {
              .el-form-item__content {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    padding: 10px;
  }
}

.placeholder-div {
  height: 57px;
  padding: 20px 20px 0 20px;
  border-bottom: 1px solid #DDE3EB;
}

::v-deep input[classname=select-materials] {
  display: none;
}

::v-deep .el-input-group--prepend {
  width: 100%;
  height: 32px;
}

::v-deep .el-input-group__prepend {
  background: #FFFFFF;
  width: calc(100% - 56px);

  > div {
    width: calc(100% + 40px);
  }

  .input-prepend {
    width: 100%;
    color: #495060;
  }
}

::v-deep .el-input-group__append {
  width: 56px;
}
::v-deep {
  .el-table .new-columns {
    background: #ECFAFF;
  }
}
.batch-number-columns {
  display: inline-flex;
  align-items: center;
  img {
    height: 11px;
    padding-left: 5px;
  }
}
</style>
