
import { procedureType, status } from '@/config/options.config'
import { formatColumns } from '@/utils'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columnsDetail = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    sortable: true,
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: { index: 0, tagName: 'el-input' }
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: { index: 1, tagName: 'el-input' }
  },
  {
    prop: 'childMaterialsBomVersion',
    label: '物料版本',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      required: false,
      tagName: 'el-cascader',
      key: 'materialsClassId',
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        filterable: true
      }
    }
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      tagName: 'el-select',
      key: 'materialsTypeId',
      required: false
    }
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 5,
      tagName: 'el-select',
      key: 'materialsAttributeId',
      required: false
    }
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    form: { index: 2, tagName: 'el-input', required: false }
  },
  {
    prop: 'actualQuantity',
    label: '实际用量',
    sortable: false,
    hiddenSearch: true,
    minWidth:120
  },
  {
    prop: 'upMaterialsQuantity',
    label: '上阶物料用量',
    sortable: false,
    hiddenSearch: true,
    minWidth:120
  },
  {
    prop: 'quantityStandard',
    label: '标准用量',
    sortable: false,
    hiddenSearch: true,
    minWidth:120
  },
  {
    prop: 'quantityNumerator',
    label: '用量分子',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantityDenominator',
    label: '用量分母',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '主单位',
    sortable: false,
    hiddenSearch: true,
    minWidth:100
  },
  {
    prop: 'attritionRate',
    label: '损耗率%',
    sortable: false,
    hiddenSearch: true,
    minWidth:120
  },
  // {
  //   slotName: 'action',
  //   label: '操作',
  //   fixed: 'right',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 115
  // }
]

export const base = [
  {
    prop: 'code',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureClassId',
    label: '工序分类',
    sortable: false,
    hiddenSearch: true,
    form: {
      colSpan: 6,
      tagName: 'el-cascader',
      key: 'procedureClassId',
      required: false,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      }
    }
  },
  {
    prop: 'type',
    label: '工序类别',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      colSpan: 6,
      props: {
        disabled: ['bbelc'].includes(sessionStorage.getItem('memberCode'))
      },
      options: procedureType,
      defaultValue: 0
    }
  },
  {
    prop: 'isEnabled',
    label: '工序状态',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      colSpan: 6,
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'groupName',
    label: '所属工序段',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    label: '',
    sortable: false,
    hiddenSearch: true
  },
  {
    label: '',
    sortable: false,
    hiddenSearch: true
  },
  {
    label: '',
    sortable: false,
    hiddenSearch: true
  }
]

export const station = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工位名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false
  }
]
export const fileAndDrawings = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '名称',
    slotName: 'fileName',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false
  }
]

