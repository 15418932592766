<template>
  <div>
    <div class="top">
      <SearchFormTask
        ref="searchForm"
        :form-option="formOptions"
        @resetData="resetData"
        @searchData="searchTable"
      />
    </div>
    <el-divider class="e-divider" />
    <div class="materials-info">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="工单号/工序名称/生产批次码"
        class="functionHeader"
        :columns-key="columnsKey"
        :show-export = "!selectData.length ? true : false"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        @search="$refs.mTable.setTableData(true)"
      >
      <div slot="all" v-show="selectData.length">

        <el-button style="margin-left:10px;" v-if="permission('OperatorTaskExport')" size="small" type="primary"  @click="batchExport()">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
            / {{ total }}项
        </span>
        <el-button type="text" size="small" @click="resetSelectData">清空</el-button>


        <!-- <el-dropdown size="small" split-button @command="(command) => { allMore(command) }">
          <span class="el-dropdown-link">
            批量操作
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="batchExport" v-if="permission('OperatorTaskExport')" :disabled="!selectData.length">导出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->

      </div>
      </FunctionHeader>
      <MTable
        ref="mTable"
        :columns="operationTask"
        :set-data-method="getTableDatas"
        class="mTable"
        :height="height"
        :columns-key="columnsKey"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

        <el-button
          slot="stationEquipment"
          slot-scope="{ row }"
          type="text"
          @click="viewStationEquipment(row)"
        >查看</el-button>
        <div slot="status" slot-scope="{ row }">
          <div
            v-if="row.status || row.status == 0"
            :style="{color: ['#FA6400','#00AB29','#607FFF','#ff797a'][row.status]}"
          >
            {{ produceTaskStatus[row.status].name }}</div>
        </div>
        <el-button
          slot="materialsCode"
          slot-scope="{ row }"
          type="text"
          @click="viewMaterialsDetail(row.materialsId)"
        >{{ row.materialsCode }}</el-button>
        <div slot="produceOrderNumber" slot-scope="{ row }">
          <NewPageOpen
            v-if="isShowChild"
            :path="{ name: 'orderDetail', query: {id: row.produceOrderId}}"
            :text="row.produceOrderNumber"
          />
        </div>
        <div slot="produceWorkOrderNumber" slot-scope="{ row }">
          <img
            v-if="row.procedureClass === 1"
            src="@/assets/plans/repair-icon-have.png"
            class="icon-img"
          >
          <NewPageOpen
            v-if="isShowChild"
            :path="{ name: 'workDetail', query: {id: row.produceWorkOrderId}}"
            :text="row.produceWorkOrderNumber"
          />
        </div>
        <div slot="batchNumberList" slot-scope="{ row }">
          <span>{{ fixData(row.produceWorkOrder.batchNumberList) }}</span>
        </div>
        <div slot="currentProcedure" slot-scope="{ row }">
          <div>
            {{ row.produceWorkOrder.produceWorkOrderTaskList ? fixProduceData(row.produceWorkOrder.produceWorkOrderTaskList, 'procedureName') : '' }}
          </div>
        </div>
        <div slot="currentProcedureGroupName" slot-scope="{ row }">
          <div>
            {{ row.produceWorkOrderTaskList ? fixProduceData(row.produceWorkOrder.produceWorkOrderTaskList, 'procedureGroupName') : '' }}
          </div>
        </div>
        <div slot="procedureClass" slot-scope="{ row }">
          <div>{{ procedureClass[row.procedureClass].name }}</div>
        </div>
        <div slot="procedureType" slot-scope="{ row }">
          <div>{{ procedureType[row.procedureType] ? procedureType[row.procedureType].name : '' }}
          </div>
        </div>
        <div slot="action" slot-scope="{ row }" class="align-btn">
          <a
            v-if="row.isArrival === 0 && permission('OutSource') && row.status!==3"
            class="color-btns"
            @click="outSources(row)"
          >外协</a>
          <a v-else class="color-gray">外协</a>
          <el-divider direction="vertical" v-if="permission('OutSource')"/>
          <span v-if="row.isDeparture !== 1">
            <a
              v-if="row.isIssue !== 1 && row.isAssign !== 1 && permission('OperatorTaskDownStation') && row.status!==3"
              class="color-btns wi-btns"
              @click="stationClick(row, 1)"
            >下发至工位</a>
            <a
              v-else-if="row.isIssue !== 1 && row.isAssign !== 1 && permission('OperatorTaskDownStation')&&row.status===3"
              class="color-gray"
            >下发至工位</a>
            <a
              v-else-if="row.isIssue === 1 && row.isAssign !== 1 && permission('OperatorTaskDownStation') && row.status!==3"
              class="color-btn "
              @click="stationClick(row, 2)"
            >已下发</a>
            <a
              v-else-if="row.isIssue === 1 && row.isAssign !== 1 && permission('OperatorTaskDownStation')&& row.status===3 "
              class="color-gray"
            >已下发</a>
            <a
              v-else-if="permission('OperatorTaskDownStation')"
              class="color-gray"
            >已下发</a>
            <el-divider
              v-if="permission('OperatorTaskDownStation')"
              direction="vertical"
            />
            <template v-if="permission('OperatorTaskAssignTask')">
              <a
                v-if="row.isAssign === 1 && row.status!==3"
                class="color-btn"
                @click="assignClick(row, 2)"
              >已指派</a>
              <a
                v-else-if="row.isAssign !== 1 && row.procedureIssueMode === 1 && row.isIssue === 0||row.status===3"
                class="color-gray"
              >指派任务</a>
              <a v-else class="color-btns wi-btn" @click="assignClick(row, 1)">指派任务</a>
            </template>
          </span>
          <span v-if="row.isDeparture === 1">
            <a
              v-if="row.isIssue !== 1 && row.isAssign !== 1 && permission('OperatorTaskDownStation')"
              class="color-gray"
            >下发至工位</a>
            <a
              v-if="row.isIssue === 1 && row.isAssign !== 1 && permission('OperatorTaskDownStation')"
              class="color-gray"
            >已下发</a>
            <el-divider
              v-if="permission('OperatorTaskDownStation')"
              direction="vertical"
            />
            <template v-if="permission('OperatorTaskAssignTask')">
              <a v-if="row.isAssign !== 1" class="color-gray">指派任务</a>
              <a v-if="row.isAssign === 1" class="color-gray">已指派</a>
            </template>
          </span>
          <!-- <el-divider
              direction="vertical"
            />
          <a
            class="color-btns"
            @click="setSchedule(row)"
          >排程</a> -->
        </div>
      </MTable>
    </div>
     <!-- 外协 -->
    <MDialog v-model="outSourceVisible" title="外协申请" @onOk="saveOutSource">
       <MFormBuilder
        ref="dialogFormBuilds"
        :form-data="dialogFormDatas"
        :form-list="dialogFormLists"
      >
      </MFormBuilder>
    </MDialog>
    <!-- 排程 -->
    <!-- <MDialog v-model="scheduleVisible" title="排程" @onOk="saveSchedule(row)">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog> -->
    <!-- <MDialog v-model="scheduleVisible" title="排程" @onOk="setSchedule">
      <div class="scheduleBox">
  <el-radio v-model="radio" label="1">备选项</el-radio>
  <el-radio v-model="radio" label="2">备选项</el-radio>
</div>
    </MDialog> -->
    <!-- 下发-->
    <MDialog
      v-model="dialogVisible"
      title="下发至工位"
      :append-to-body="true"
      @onOk="dialogSubmit"
      style="height:90%"
      ref="modelStation"
      :showFooter="showDetail"
    >
      <div class="all-flexs">
        <div class="model-tit">可下发数量：{{ taskQunlity }} 个</div>
        <el-button
          v-if="!showDetail"
          type="primary"
          class="cancel-btn"
          @click="cancelAssign(0)"
        >取消下发</el-button>
      </div>
      <div v-if="showDetail" class="all-form">
        <el-select
          v-model="objectStation"
          size="small"
          multiple
          placeholder="请选择"
          style="width: 250px;"
        >
          <el-option
            v-for="item in stationList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            @click.native="selectStation(item)"
          />
        </el-select>
        <el-button size="small" type="primary" class="all-btn" @click="addStation">添加工位</el-button>
      </div>
      <MTable
        ref="sTable"
        :height="250"
        :show-page="false"
        :data="addStationList"
        :columns="columnsStation"
        :columns-setting="false"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="quantity" slot-scope="{ row }">
          <span v-if="!showDetail">{{row.quantity}}</span>
           <el-input-number v-model="row.quantity" controls-position="right" v-if="showDetail"></el-input-number>
        </div>
        <div slot="action" slot-scope="{ row }" v-if="showDetail">
           <el-button  type="text" @click="delTask(row.stationId)">删除</el-button>
        </div>
      </MTable>
    </MDialog>
     <!-- 已下发指派-->
    <MDialog
      v-model="assignVisible"
      title="指派任务"
      :append-to-body="true"
      :show-footer="false"
      @onOk="assignSubmit"
    >
      <div class="all-btns">
        <el-button
          v-if="!showAssign"
          type="primary"
          class="cancel-btn"
          @click="cancelAssign(0)"
        >取消下发</el-button>
        <el-button
          v-if="showAssign"
          type="primary"
          class="btns"
          @click="cancelAssign(2)"
        >取消下发及指派</el-button>
        <el-button
          v-if="showAssign"
          type="primary"
          class="btns"
          @click="cancelAssign(1)"
        >仅取消指派</el-button>
      </div>
      <MTable
        ref="mTables"
        :height="300"
        :show-page="false"
        :data="addStationList"
        :columns="columnsStation"
        :columns-setting="false"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="quantity" slot-scope="{ row }">
          <span>{{row.quantity}}</span>
        </div>
        <div slot="action" slot-scope="{ row,$index }">
          <el-button
            v-if="!row.userList"
            type="text"
            class="li-btn"
            @click="assignStation(row, 1, $index)"
          >指派任务</el-button>
          <el-button
            v-if="row.userList"
            type="text"
            class="color-btn li-btn"
            @click="assignStation(row, 2, $index)"
          >已指派</el-button>
        </div>
      </MTable>
    </MDialog>
    <!-- 未下发指派-->
    <MDialog v-model="assignVisibleNo" title="指派任务" @onOk="submitUser">
      <div class="all-flexss" v-if="showAssign">
         <el-button type="primary" @click="cancelAssign(1)" class="cancel-btn">取消指派</el-button>
      </div>
      <SelectUserAndUserGroup
        ref="userAndUserGroup"
        v-model="transferSelectIds"
        :is-assign="false"
        :user-options="userOptions"
        :user-group-options="userGroupOptions"
      />
    </MDialog>
    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />
    <TableDialog
      title="查看工位/设备"
      :show.sync="workVisible"
      :columns="columnsStations"
      :table-data="workList"
    />
  </div>
</template>
<script>
import SelectUserAndUserGroup from '@/components/SelectUserAndUserGroup/SelectUserAndUserGroup'
import SearchFormTask from './components/search-form-task'
import FunctionHeader from '@/components/FunctionHeader/index'
import { operationTask, columnsStation, columnsStations } from './columns'
import api from '@/api/production/operation-task'
import apis from '@/api/production/work-order'
import stApi from '@/api/information/production/station'
import supplierApi from '@/api/information/unit'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import { produceTaskStatus, procedureClass, procedureType } from '@/config/options.config'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import oApis from '@/api/production/outSource'
import { dialogFormLists } from './form-list'
import fixCommonPage from '@/mixin/fix-common-page'
import TableDialog from '@/components/TableDialog/TableDialog'
import stationApi from '@/api/information/production/station'
import sapi from '@/api/equipment/equipment'
import { status } from '@/config/options.config'

export default {
  name: 'Schedule',
  components: { TableDialog, NewPageOpen, EditMaterials, FunctionHeader, SearchFormTask, SelectUserAndUserGroup },
  mixins: [viewMaterialsDetail, fixCommonPage],
  props: {
    radio: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      api,
      total:0,
      workList: [],
      columnsStations,
      workVisible: false,
      produceTaskStatus,
      dialogFormLists,
      dialogFormDatas: {},
      operationTask,
      columnsStation,
      procedureClass,
      procedureType,
      searchDatas: [],
      selectionData: [],
      functionHeader: {
        searchVal: '',
        exportName: 'exportOperationTask',
        exportParams: {},
      },
      selectKeyword: '1',
      keyword: '',
      formOptions: {
        orderOptions: [],
        operatorOptions: [],
        produceWorkOrderList: [],
        customersOptions: [],
        stationOptions: [],
        equipmentOptions: []
      },
      dialogVisible: false,
      objectStation: [],
      stationList: [],
      produceWorkOrderTaskId: '',
      addStationList: [],
      arrayList: [],
      taskQunlity: 0,
      assignVisible: false,
      showDetail: true,
      assignVisibleNo: false,
      transferSelectIds: [],
      userOptions: [],
      userGroupOptions: [],
      isArrival: 0,
      stationId: '',
      currentIndex: -1,
      showAssign: false,
      statuses: '',
      outSourceVisible: false,
      // scheduleVisible:false,
      taskId: undefined,
      batchParam: {},
      selectData: [],
      equipmentId: '',
      searchStationId: '',
      formData: {},
      formList: [{
        key: 'stationIds',
        tagName: 'el-select',
        colSpan: 24,
        props: {
          placeholder: '请选择工位',
          multiple: false
        },
        children: [
          {
            tagName: 'el-option',
            props: {
              label: 'name',
              value: 'id'
            },
            options: status
          }
        ]
      }],
      rowItem:null
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 48 - 12
    },
    columnsKey() {
      return this.$route.name + 'OperatorTask'
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {
    this.getOperatorPages()
    this.getStationList()
    this.getEquipementList()
    this.getCustomersOptions()
  },
  methods: {
    resetSelectData() {
      this.selectData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectData = this.selectData.filter((item) => item.selectable)
    },
    async forceEnd({ id }) {
      const res = await apis.forceCloseWorkOrder(id)
      if (res) {
        this.$message.success('操作成功')
        this.$refs.mTables.setTableData(true)
      }
    },
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    batchExport() {
      let data = this.selectData.map(item => item.id).join('^')
      const ids = [
        {
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: data,
          operator: 'in'
        }
      ]
      const params = {
        searchVal: Encrypt(JSON.stringify(ids))
      }
      this.$refs.functionHeader.export(params)
    },
    viewStationEquipment(row) {
      this.workList = _.cloneDeep(row.stationList)
      this.workVisible = true
    },
    selectionChange(data) {
      this.selectData = data
    },
    outSources(row) {
      this.taskId = row.id
      this.outSourceVisible = true
    },
    // setSchedule(row){
    //   this.rowItem = row
    //   this.scheduleVisible = true

    // },
    // saveSchedule(callback){
    //   console.log(this.rowItem);
    //   const obj=[{
    //     id:null,
    //     produceWorkOrderTaskId:this.rowItem.id,  //工序任务id
    //     stationId:this.$refs.formBuild.form.stationIds,               //工位id
    //     priority:1                 //优先级
    //   }]
    //   apis.stationMapping(obj).then(res=>{
    //     console.log(res);
    //   this.scheduleVisible = false

    //   })
    // },
    // 外协申请
    async saveOutSource(callback) {
      callback()
      const { form } = this.$refs.dialogFormBuilds
      this.$refs.dialogFormBuilds.formRefs().validate(async(valid) => {
        if (valid) {
          let remark = form.remark ? form.remark : ''
          const res = await oApis.saveOutsourcingRequest(this.taskId, remark)
          if (res) {
            this.$message.success('外协申请成功')
            this.outSourceVisible = false
            this.$refs.mTable.setTableData()
          }
        }
      })
    },
    // 供应商
    async getCustomersOptions() {
      const res = await supplierApi.getSupplierList()
      if (res) {
        this.formOptions.customersOptions = res
        this.$refs.searchForm.customersList = res
      }
    },
    jumpOrderDetail(row) {
      this.$router.push({ name: 'orderDetail', query: { id: row.produceOrderId }})
    },
    fixProduceData(val, key) {
      const list = val.filter(item => item[key]).map(item => item[key])
      return list.length === 1 ? list[0] : list.join('-')
    },
    // 取消指派
    async cancelAssign(type) {
      const data = {
        workOrderTaskId: this.produceWorkOrderTaskId,
        cancelType: type
      }
      const res = await api.cancelAssignLine(data)
      if (res) {
        if (type === 0) {
          this.dialogVisible = false
        }
        if (type === 1) {
          this.assignVisibleNo = false
        }
        this.assignVisible = false
        this.$refs.mTable.setTableData()
      }
    },
    // 工位
    async getStationList() {
      const res = await stationApi.getStationList()
      if (res) {
        this.formList[0].children[0].options = res
        this.formData.stationIds = this.stationList.map(item => item.id)
        this.formOptions.stationOptions = res
        this.$refs.searchForm.stationList = res
      }
    },
    // 设备
    async getEquipementList() {
      const res = await sapi.getEquipmentList()
      if (res) {
        this.formOptions.equipmentOptions = res
        this.$refs.searchForm.equipmentList = res
      }
    },
    fixData(val) {
      if (val && val.length > 0) {
        const data = val.map(item => item.batchNumber)
        return data.join()
      } else {
        return ''
      }
    },
    // 工单列表
    // async getOrderList() {
    //   const res = await apis.getPlanpageno()
    //   if (res) {
    //     this.formOptions.produceWorkOrderList = res
    //     this.$refs.searchForm.produceWorkOrderList = res
    //   }
    // },
    // 工位
    async getStation(id, areaId) {
      const res = await stApi.getStationListByProcedureId(id, areaId)
      if (res) {
        this.stationList = res
      }
    },
    // 工序
    async getOperatorPages() {
      const res = await apis.getOperatorPage()
      if (res) {
        this.formOptions.operatorOptions = res
        this.$refs.searchForm.currentProcedures = res
      }
    },
    // 列表
    async getTableDatas(condition, callback) {
      const { page, search, order } = condition
      const newData = [{
        id: getUUid(),
        fieldName: 'sourceQuantity',
        fieldType: 'number',
        fieldValue: 0.0,
        operator: 'gt'
      },
      {
        id: getUUid(),
        fieldName: 'type',
        fieldType: 'number',
        fieldValue: 0,
        operator: 'eq'
      }]
      const status = {
        id: getUUid(),
        fieldName: 'status',
        fieldType: 'number',
        fieldValue: this.statuses,
        operator: 'in'
      }
      //  console.log('this.statuses==', this.statuses)
      let searchVal = Encrypt(JSON.stringify([...search, ...this.searchDatas, ...newData]))
      this.statuses && (searchVal = Encrypt(JSON.stringify([...search, ...this.searchDatas, ...newData, status])))
      const params = {
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        materialsSearchType: this.selectKeyword,
        materialsSearchValue: this.keyword,
        equipmentId: this.equipmentId,
        stationId: this.searchStationId,
        searchVal
      }
      // 导出
      this.functionHeader.exportParams = params
      const data = {
        page: page.currentPage,
        limit: page.pageSize,
        ...params
      }
      const res = await api.getOrderTaskpage(data)
      if (res) {
        const data = res.records.map(item => ({
          ...item,
          materialsCode: item.produceWorkOrder.materialsCode,
          materialsName: item.produceWorkOrder.materialsName,
          materialsSpecifications: item.produceWorkOrder.materialsSpecifications,
          materialsUnit: item.produceWorkOrder.materialsUnit,
          deliveryTime: item.produceWorkOrder.deliveryTime,
          processFlowName: item.produceWorkOrder.processFlowName,
          areaName: item.produceWorkOrder.areaName,
          batchNumber: item.produceWorkOrder.batchNumber
        })
        )
        callback({
          total: res.total,
          content: data
        })
        this.total = res.total
      }
      callback()
    },
    // 查询
    searchTable(data, val, keyword, statuses, stationId, equipmentId) {
      this.statuses = statuses
      this.searchDatas = data
      this.keyword = keyword
      this.selectKeyword = val
      this.equipmentId = equipmentId
      this.searchStationId = stationId
      this.$refs.mTable.setTableData(true)
    },
    // 重置
    resetData() {
      this.searchDatas = []
      this.keyword = ''
      this.selectKeyword = '1'
      this.statuses = ''
      this.$refs.mTable.setTableData(true)
    },
    jumpDetail(row) {
      this.$router.push({ path: 'work-detail', query: { id: row.produceWorkOrderId }})
    },
    // 下发至工位
    stationClick(item, type) {
      this.showDetail = type === 1
      this.produceWorkOrderTaskId = item.id
      this.taskQunlity = (item.sourceQuantity - item.reportWorkQuantity).toFixed(2)
      if (+type === 2) {
        this.addStationList = item.stationList ? item.stationList.map(item => {
          return {
            ...item,
            equipmentName: item.equipment ? item.equipment.name : ''
          }
        }) : []
      } else {
        this.addStationList = item.stationList ? item.stationList : []
      }
      this.getStation(item.procedureId, item.areaId)
      this.objectStation = []
      this.dialogVisible = true
    },
    async dialogSubmit(done) {
      done()
      if (this.addStationList.length === 0) {
        this.$message.error('请选择下发的工位')
        return false
      }
      const counts = this.addStationList.every(item => !!item.quantity)
      if (!counts) {
        this.$message.error('数量必须大于零')
        return false
      }
      const res = await api.getTaskStation(this.addStationList)
      if (res) {
        this.$message.success('下发成功')
        this.dialogVisible = false
        this.$refs.mTable.setTableData()
      }
    },
    // 保存工位
    addStation() {
      const list = []
      this.objectStation.forEach(items => {
        this.stationList.forEach(item => {
          if (item.id === items) {
            list.push(item)
          }
        })
      })
      const ArrList = list.map(item => {
        return {
          produceWorkOrderTaskId: this.produceWorkOrderTaskId,
          stationId: item.id,
          stationName: item.name,
          quantity: undefined,
          equipmentName: item.equipmentName
        }
      })
      this.addStationList = [...this.addStationList, ...ArrList]
      this.objectStation = []
      this.arrayList = []
    },
    // 删除工位
    delTask(stationId) {
      this.addStationList = this.addStationList.filter(item => item.stationId !== stationId)
    },
    // 添加工位
    selectStation(item) {
      this.arrayList.push({
        produceWorkOrderTaskId: this.produceWorkOrderTaskId,
        stationId: item.id,
        stationName: item.name,
        quantity: undefined
      })
    },
    // 操作指派任务
    async assignClick(item, type) {
      this.showAssign = type === 2
      this.produceWorkOrderTaskId = item.id
      this.isArrival = item.isArrival
      if (item.isIssue === 1) {
        this.taskQunlity = item.workOrderQuantity - item.reportWorkQuantity
        this.addStationList = item.stationList ? item.stationList.map(item => {
          return {
            ...item,
            equipmentName: item.equipment ? item.equipment.name : ''
          }
        }) : []
        this.assignVisible = true
      } else {
        this.stationId = ''
        await this.getUser(item.id)
        this.transferSelectIds = item.userList ? item.userList.map(item => item.frontKey) : []
        this.assignVisibleNo = true
      }
    },
    // 用户组
    async getUser(id, stationId) {
      const res = await api.getUsersLine({
        workOrderTaskId: id,
        stationId: stationId
      })
      if (res) {
        this.userOptions = res.userList
        this.userGroupOptions = res.userGroupList
      }
    },
    // 工序id和工位id拿用户
    async getTaskUserIds(id, stationId) {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'produceWorkOrderTaskId',
        fieldType: 'number',
        fieldValue: id,
        operator: 'eq'
      })
      search.push({
        id: getUUid(),
        fieldName: 'stationId',
        fieldType: 'number',
        fieldValue: stationId,
        operator: 'eq'
      })
      const res = await api.getTaskUserId({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.transferSelectIds = res ? res.map(item => item.frontKey) : []
      }
    },
    // 已下发工位提交
    assignSubmit(done) {
      done()
      this.assignVisible = false
    },
    async assignStation(item, type, index) {
      await this.getUser(this.produceWorkOrderTaskId, item.stationId)
      await this.getTaskUserIds(this.produceWorkOrderTaskId, item.stationId)
      this.currentIndex = index
      this.stationId = item.stationId
      this.assignVisibleNo = true
    },
    // 下发指派提交
    async submitUser(done) {
      done()
      const ids = this.$refs.userAndUserGroup.getFormatData()
      const userId = ids.user.map(item => {
        return {
          id: item,
          type: 0,
          produceWorkOrderTaskId: this.produceWorkOrderTaskId,
          stationId: this.stationId
        }
      })
      const userGruop = ids.userGroup.map(item => {
        return {
          id: item,
          type: 1,
          produceWorkOrderTaskId: this.produceWorkOrderTaskId,
          stationId: this.stationId
        }
      })
      const allId = [...userId, ...userGruop]
      if (allId.length === 0) {
        this.$message.error('指派人列表不能为空')
        return false
      }
      const res = await api.getTaskUseLine(allId)
      if (res) {
        this.$message.success('指派成功')
        this.$refs.mTable.setTableData()
        this.showAssign = true
        this.assignVisibleNo = false
        if (this.addStationList.length > 0) {
          this.addStationList[this.currentIndex].userList = this.stationId ? [] : null
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.icon-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 19px;
  height: 19px;
  display: block;
}
.all-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  .all-btn {
    height: 32px;
    margin-left: 10px;
    display: inline-block;
  }
  ::v-deep .el-input__inner {
    width: 250px;
  }
}
.model-tit {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456b;
  line-height: 20px;
  margin: 0 0 8px 0;
}
.align-btn {
  text-align: center;
  .wi-btns {
    width: 70px;
    display: inline-block;
    text-align: right;
  }
  .wi-btn {
    width: 60px;
    display: inline-block;
    text-align: left;
  }
  .color-btns {
    color: #607fff;
  }
}
.li-btn {
  margin-right: 10px;
  color: #607fff;
  text-align: center;
  padding: 0;
  font-weight: 500;
  ::v-deep .el-button {
    padding: 0 20px;
  }
}
.color-btn {
  color: #00ab29;
  ::v-deep .el-button--text span {
    color: #00ab29;
  }
}
.color-gray {
  color: #c4c4c4;
}
.functionHeader {
  margin-left: -10px;
}
.all-btns {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  height: 32px;
  .btns {
    color: #fff;
    height: 32px;
    padding: 0 10px;
    display: block;
  }
}
.cancel-btn {
  color: #fff;
  height: 32px;
  padding: 0 10px;
  display: inline-block;
  text-align: right;
  margin-bottom: 15px;
}
.all-flexs {
  display: flex;
  justify-content: space-between;
}
.all-flexss {
  display: flex;
  justify-content: flex-end;
  margin-right: 26px !important;
}
.scheduleBox{
  display: flex;
  flex-direction: column;
}
</style>
