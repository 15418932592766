var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fault-wrap" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForms", {
            ref: "searchForms",
            on: { resetData: _vm.resetData, searchData: _vm.searchTable }
          })
        ],
        1
      ),
      _c("el-divider", { staticClass: "e-divider" }),
      _c(
        "FunctionHeader",
        {
          ref: "functionHeader",
          staticStyle: { padding: "10px 0 10px 0" },
          attrs: {
            "search-title": "输入报修单号/维修单号",
            tags: _vm.functionHeader.tags,
            "export-name": _vm.functionHeader.exportName,
            "export-params": _vm.functionHeader.exportParams,
            "import-name": _vm.functionHeader.importName
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        },
        [
          _c(
            "div",
            { staticClass: "all-select", attrs: { slot: "all" }, slot: "all" },
            [
              _c(
                "div",
                { staticClass: "checkAll" },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { indeterminate: _vm.isIndeterminate },
                      on: { change: _vm.selectCheck },
                      model: {
                        value: _vm.checkeds,
                        callback: function($$v) {
                          _vm.checkeds = $$v
                        },
                        expression: "checkeds"
                      }
                    },
                    [_vm._v("全选")]
                  )
                ],
                1
              ),
              _vm._l(_vm.allStatus, function(item, index) {
                return _c(
                  "div",
                  {
                    key: item.value,
                    staticClass: "all-li",
                    class: item.checked ? "active" : "",
                    on: {
                      click: function($event) {
                        return _vm.selectTab(item, index)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.label))]
                )
              })
            ],
            2
          )
        ]
      ),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        scopedSlots: _vm._u(
          [
            {
              key: "index",
              fn: function(ref) {
                var row = ref.row
                var $index = ref.$index
                return _c("div", {}, [_vm._v(_vm._s($index + 1))])
              }
            },
            {
              key: "status",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _c(
                    "div",
                    {
                      staticClass: "faultStatus",
                      style: {
                        background: _vm.faultStatus[row.status - 1].background,
                        color: _vm.faultStatus[row.status - 1].color
                      }
                    },
                    [_vm._v(_vm._s(_vm.faultStatus[row.status - 1].name))]
                  )
                ])
              }
            },
            {
              key: "repairSparePartList",
              fn: function(ref) {
                var row = ref.row
                return row.repairSparePartList
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.viewSpareList(row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  : _vm._e()
              }
            },
            {
              key: "faultPicture",
              fn: function(ref) {
                var row = ref.row
                return _c(
                  "div",
                  {},
                  [
                    row.faultPicture
                      ? _c("el-image", {
                          staticClass: "drawing-img",
                          attrs: {
                            src: _vm.fixSinImg(row.faultPicture),
                            "preview-src-list": _vm.fixImg(row.faultPicture)
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }
            },
            {
              key: "faultDesc",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top-start",
                            width: "200",
                            trigger: "hover",
                            content: row.faultDesc
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "faultDesc",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(row.faultDesc))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              }
            },
            {
              key: "repairDesc",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "top-start",
                            width: "200",
                            trigger: "hover",
                            content: row.repairDesc
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "faultDesc",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            },
                            [_vm._v(_vm._s(row.repairDesc))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              }
            },
            {
              key: "produceOrderNumber",
              fn: function(ref) {
                var row = ref.row
                return _c(
                  "div",
                  {},
                  [
                    _c("NewPageOpen", {
                      attrs: {
                        path: { name: "orderDetail", query: { id: row.id } },
                        text: row.produceOrderNumber
                      }
                    })
                  ],
                  1
                )
              }
            },
            {
              key: "repairContinueTime",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _vm._v(
                    " " + _vm._s(_vm.fixTime(row.repairContinueTime)) + " "
                  )
                ])
              }
            }
          ],
          null,
          true
        )
      }),
      _c(
        "TableDialog",
        {
          attrs: {
            title: "查看消耗备件",
            show: _vm.spareVisible,
            columns: _vm.spareColumns,
            "table-data": _vm.spareList,
            "calc-height": 32
          },
          on: {
            "update:show": function($event) {
              _vm.spareVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "padding-bottom": "15px" },
              attrs: { gutter: 24 }
            },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v("设备编号：" + _vm._s(_vm.currentRow.equipmentCode))
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v("设备名称：" + _vm._s(_vm.currentRow.equipmentName))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }