var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scrap-wrap" }, [
    _c("h2", { staticStyle: { "margin-left": "20px" } }, [
      _vm._v("设备看板数据生成")
    ]),
    _c(
      "div",
      { staticClass: "top" },
      [
        _c(
          "el-row",
          { staticStyle: { padding: "10px 10px 10px 10px" } },
          [
            _c(
              "el-col",
              { attrs: { span: 8 } },
              [
                _c("el-date-picker", {
                  staticStyle: { width: "95%" },
                  attrs: {
                    type: "daterange",
                    align: "right",
                    "unlink-panels": "",
                    "value-format": "yyyy-MM-dd",
                    format: "yyyy-MM-dd",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期"
                  },
                  on: { change: _vm.timeChange },
                  model: {
                    value: _vm.valueTimeRange,
                    callback: function($$v) {
                      _vm.valueTimeRange = $$v
                    },
                    expression: "valueTimeRange"
                  }
                })
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 4 } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { background: "#607FFF" },
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.topSearch }
                  },
                  [_vm._v("生成数据")]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }