<template>
  <div class="schedule-wrap">
    <div class="top">
      <SearchForms
        ref="searchForms"
        :form-option="formOptions"
        :is-have="isHave"
        @resetData="resetData"
        @searchData="searchTable"
      />
    </div>
    <el-divider class="e-divider" />
    <div class="materials-info">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        :search-title="mode ? '请输入工单编号/订单编号/名称' : '请输入工单编号/项目编号/名称'"
        :tags="!selectionData.length ?  functionHeader.tags :[]"
        @search="$refs.mTable.setTableData(true)"
      >
      <div slot="all" style="display: flex;align-items: center;">
        <div v-show="selectionData.length" >
          <el-button v-if="permission('Export')" size="small" type="primary"  @click="batchExport()">批量导出</el-button>
        <el-button v-if="permission('Del')" type="primary" size="small" @click="onDel(selectionData)">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
         </div>
        <div class="all-select">
          <div class="checkAll" >
            <el-checkbox v-model="checkeds" :indeterminate="isIndeterminate" @change="selectCheck">全选</el-checkbox>
          </div>
          <div v-for="(item, index) in allStatus" :key="item.value" class="all-li" :class="item.checked ? 'active' : ''" @click="selectTab(item, index)">{{ item.label }}</div>
        </div>
      </div>
      </FunctionHeader>
      <MTable
        ref="mTable"
        :columns="getColumns"
        :set-data-method="getTableData"
        :height="height"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
<!--        <el-button-->
<!--          slot="materialsCode"-->
<!--          slot-scope="{ row }"-->
<!--          type="text"-->
<!--          @click="viewMaterialsDetail(row.materialsId)"-->
<!--        >{{ row.materialsCode }}</el-button>-->

        <NewPageOpen slot="materialsCode" slot-scope="{ row }"
                     :path="{ name: 'materialsDetail', query: { id: row.materialsId, type: 'view' }}" :text="row.materialsCode" />

        <div slot="workProcess" slot-scope="{ row }" @click="onWorkProcess(row.id,0)" style="cursor:pointer">
          <el-progress
            :percentage="row.productionTempo"
            :color="progressStatusColorMaps[row.workOrderTempo]"
          />
        </div>
        <div slot="requisitionTemp" slot-scope="{ row }" @click="onWorkProcess(row.id,1)" style="cursor:pointer">
          <el-progress
            :percentage="row.materialsRequisitionTempo"
            :color="progressStatusColorMaps[row.workOrderTempo]"
          />
        </div>
        <div slot="status" slot-scope="{ row }">
          {{ workStatusMaps[row.status] }}
        </div>
        <div slot="progressStatus" slot-scope="{ row }" :style="{color:row.workOrderTempo === 1 ? '#F5A623':row.workOrderTempo === 2 ? '#F20909':'#606266'}">
          {{ progressStatusMaps[row.workOrderTempo] }}
        </div>
        <div slot="executionDepartment" slot-scope="{ row }">
          {{ executionDepartmentMaps[row.executiveUnit] }}
        </div>
        <div slot="priority" slot-scope="{ row }" style="display: flex;align-items: center;">
          <div
            :style="{ color: priorityStatus[row.priority] ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)', background: `${priorityStatus[row.priority] ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)'}20`}"
            class="tag-priority"
          >
            {{ priorityStatus[row.priority] ? priorityStatus[row.priority].name : '普通' }}
          </div>
        </div>
        <div slot="businessUnit" slot-scope="{ row }">
          {{ divisionOptionsMaps[row.businessUnit] }}
        </div>
        <div slot="workType" slot-scope="{ row }">
          {{ workTypeMaps[row.type] }}
        </div>

        <div  v-if="permission('Detail')" slot="productionPlanNumber" slot-scope="{ row }">
          <img v-if="row.isRepair === 1" src="@/assets/plans/repair-icon-have.png" class="icon-img">
          <a class="li-btn" @click="onHandlePlan('view',row.id)">{{ row.productionPlanNumber }}</a>
        </div>
        <div v-else slot="productionPlanNumber" slot-scope="{ row }">{{ row.productionPlanNumber }}</div>
        <div slot="processFlowName" slot-scope="{ row }">
          <el-button type="text" @click="showProcess(row)">{{ row.processFlowName }}</el-button>
        </div>
        <div slot="action" slot-scope="{ row }">
          <el-button
            v-if="permission('StartWork')&&[-1].includes(row.status)"
            type="text"
            @click="startDo(row.id)"
          >开工</el-button>
          <!--<el-button-->
            <!--v-if="permission('Assembly')&&[-1,0].includes(row.status)"-->
            <!--type="text"-->
            <!--@click="onAssembly(row.id)"-->
          <!--&gt;装配完工</el-button>-->

          <el-button
            v-if="permission('Over')&&[0].includes(row.status)"
            type="text"
            @click="onOver(row.id)"
          >结案</el-button>

          <el-button
            v-if="permission('Restart')&&[1].includes(row.status)"
            type="text"
            @click="onRestart(row.id)"
            >重启</el-button>
          <el-button
          v-if="permission('Edit')&&[-1,0].includes(row.status)"
            type="text"
            @click="onHandlePlan('edit',row.id)"
          >编辑</el-button>

          <!--<el-button-->
            <!--v-if="permission('Del')&&[-1].includes(row.status)"-->
            <!--type="text"-->
            <!--@click="onDel([row.id])"-->
          <!--&gt;删除</el-button>-->

          <el-divider v-if="permission('Del') || permission('PrintSequenceNumber') || permission('AdjustStation')"  direction="vertical" />
          <el-dropdown v-if="permission('Del') || permission('PrintSequenceNumber') || permission('AdjustStation')" @command="(command) => { more(row, command) }">
                    <span class="el-dropdown-link">
                      更多<i class="el-icon-arrow-down el-icon--right" />
                    </span>
            <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
              <el-dropdown-item v-if="permission('Del')&&[-1].includes(row.status)" type="text"
                                command="del">删除</el-dropdown-item>
              <el-dropdown-item v-if="permission('PrintSequenceNumber')&&[0,1].includes(row.status)&&row.sequenceNumber" type="text"
                                command="printSequenceNumber">打印序列号</el-dropdown-item>
              <el-dropdown-item v-if="permission('AdjustStation')&&[0].includes(row.status)&&['yyhl'].includes(memberCode)" type="text"
                                command="adjustStation">工位位置调整</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!--<el-button-->
            <!--v-if="permission('PrintSequenceNumber')&&[0,1].includes(row.status)&&row.sequenceNumber"-->
            <!--type="text"-->
            <!--@click="printSequenceNumber(row)"-->
          <!--&gt;打印序列号</el-button>-->

          <!--<el-button-->
            <!--v-if="permission('AdjustStation')&&[0].includes(row.status)&&['yyhl'].includes(memberCode)"-->
            <!--type="text"-->
            <!--@click="adjustStation(row)"-->
          <!--&gt;工位位置调整</el-button>-->
        </div>
      </MTable>
      <PrintCode ref="printCode" :common-id="commonId" />
    </div>
<!--    开工-->
    <MDialog v-model="visiableStart" title="请选择产线" @onOk="addOrEditSubmit" confirmButtonText="开工">
      <MFormBuilder ref="formBuilds" :form-data="dataForAdd" :form-list="formListForStart">

      </MFormBuilder>
    </MDialog>
    <!-- 查看工艺路线 -->
    <MDialog
      v-model="processVisible"
      title="工艺路线"
      :show-footer="false"
      @onOk="processVisible = false"
    >
      <process-flow
        ref="processFlow"
        :read-only="true"
        :process-flow-detail="processFlowVersion"
        :show-version="true"
      />
    </MDialog>
    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />

    <!-- 生产进度 -->
    <MDialog
      v-model="visibleProgress"
      :title="progressType"
      :show-footer="false"
      width="870px"
      @onOk="processVisible = false"
    >
      <div class="detail-box">
        <div v-for="(item,idx) in progressDetail" :key="idx" class="item-box">
          <div v-for="i in item" :key="i.procedureLocationCode" class="item" :class="['item-wait','item-running','item-finished'][i.productionStatus]">
            <img v-if="i.productionStatus===0" src="@/assets/plans/progress-0.png" class="sign">
            <img v-if="i.productionStatus===1" src="@/assets/plans/progress-1.png" class="sign">
            <img v-if="i.productionStatus===2" src="@/assets/plans/progress-2.png" class="sign">
            <el-tooltip class="item" effect="dark" :content="i.procedureName" placement="top">
              <div>{{ i.procedureName }}</div>
            </el-tooltip>
            <div class="progress-bg" style="bottom: 5px !important;">
              <div class="progress-bar" :style="{width:i.productionTempo+'%'}" />
            </div>
            <div  class="progress-bg">
              <div class="apply-bar" :style="{width:i.materialsRequisitionTempo+'%'}" />
            </div>
          </div>
        </div>
      </div>
    </MDialog>
    <MDialog v-model="modalShow" width="800px" :append-to-body="true" :title="'工位位置调整'" @onOk="submitForm" @fullScreen="fullScreenChange">
        <div class="materials-info">
            <div>
              <span style="margin: 0 20px 0 0;">当前占用工位 <span style="color: #000">{{ selectionStationDataList.map(item => item.name).join('、') }}</span> </span>
              <el-button type="text" @click="resetStationData">清空</el-button>
            </div>
          <MTable
            ref="sTable"
            :columns="stationColumns"
            :height="sHeight"
            :data="stationList"
            :columns-setting="false"
            @selection-change="selectionStationChange"
            :show-page="false"
          >
            <div slot="lockStatus" slot-scope="{ row }">{{ row.lockStatus ? '已占用' : '未占用' }}</div>
          </MTable>
        </div>
  </MDialog>
  </div>
</template>
<script>
import SearchForms from './search-forms'
import FunctionHeader from '@/components/FunctionHeader/index'
import { columnsAll, dialogFormList, divisionOptionsMaps, workStatusMaps, progressStatusMaps, executionDepartmentMaps, progressStatusColorMaps, workTypeMaps,stationColumns} from './data'
import api from '@/api/production/prod-plan'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import dayjs from 'dayjs'
import uapi from '@/api/sets/user/user'
import projectApi from '@/api/projectManagement/project'
import PrintCode from '@/components/PrintCode/printSequence'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'

export default {
  name: 'ProdPlanList',
  components: { EditMaterials, FunctionHeader, SearchForms, ProcessFlow, PrintCode,NewPageOpen },
  mixins: [viewMaterialsDetail],
  data() {
    return {
      commonId: 'print',
      visiableStart:false,
      dataForAdd:{},
      nowOrderId:'',
      formListForStart: [
      {
        colSpan: 12,
        key: 'wouseId',
        tagName: 'el-select',
        props: {
          placeholder: '请选择产线',
          clearable:false
        },
        children: [
          {
            tagName: 'el-option',
            props: {
              label: 'name',
              value: 'id'
            },
            options: []
          }
        ],
      },
    ],
      divisionOptionsMaps,
      workStatusMaps,
      progressStatusMaps,
      executionDepartmentMaps,
      progressStatusColorMaps,
      workTypeMaps,
      api,
      planList: [],
      columnsAll,
      dialogFormList,
      dialogVisible: false,
      dialogFormData: {},
      searchData: {},
      selectionData: [],
      params: {
        processFlowName: '',
        processFlowId: undefined,
        processFlowVersionId: undefined
      },
      functionHeader: {
        searchVal: '',
        exportParams: {},
        tags: [
          {
            hidden: !this.permission('CreateProdPlan'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新建生产工单',
            on: {
              click: () => this.onHandlePlan('add')
            }
          },
          {
            hidden: !this.permission('Export'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF', marginRight: '10px' },
            innerText: '全部导出',
            on: {
              click: () => this.batchExport(true)
            }
          }
        ]
      },
      formOptions: {
        flowerOptions: [],
        areaOptions: []
      },
      isHave: false,
      processVisible: false,
      processFlowVersion: {},
      visibleProgress: false,
      progressDetail: [],
      progressType:'报工进度详情',
      selectionDataList:[],
      selectionStationData:[],
      selectionStationDataList:[],
      total:0,
      message:[],
      matchCode:[
          'close_procedure_task',
          'close_inspection_task',
          'close_finish_sit_materials_out_warehouse',
          'close_finish_in_warehouse',
          'close_auto_finish_project'
      ],
      isPageLoaded:false,
      modalShow:false,
      stationList:[],
      stationColumns,
      woId:'',
      fullScreen: false,
      isIndeterminate: false,
      checkeds: true,
      currentIdxs: [-1,0,1],
      allStatus: [
        {
          value: -1,
          label: '未开工',
          checked: true
        },
        {
          value: 0,
          label: '进行中',
          checked: true
        },
        {
          value: 1,
          label: '已结案',
          checked: true
        }
      ]
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'refresh') {
      this.$store.dispatch('tagsView/addCachedView', from)
    }
    next()
  },
  watch: {
    // checkeds(val) {
    //   if (val) {
    //     this.currentIdxs = [-1,0,1]
    //     this.isIndeterminate = false
    //     this.allStatus = this.allStatus.map(item => {
    //       return {
    //         ...item,
    //         checked: true
    //       }
    //     })
    //   } else {
    //     this.checkeds = false
    //     this.currentIdxs = []
    //     this.isIndeterminate = false
    //     this.allStatus = this.allStatus.map(item => {
    //       return {
    //         ...item,
    //         checked: false
    //       }
    //     })
    //   }
    // }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 65 - 42
    },
    sHeight() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 57 : this.$store.state.app.contentHeight - 289 - 160 - 10
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionDataList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    },
    mode() {
      return this.$store.state.app.mode
    },
    getColumns() {
      if (this.mode === 1) {
        return this.columnsAll.map(item => {
      const newLabel = item.label ? item.label.replace(/项目/g, '订单') :''
      return { ...item, label: newLabel }
    }).filter(item => item.prop !== 'planName')
      } else {
        return this.columnsAll
      }
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
  async mounted() {
    this.isPageLoaded = true
    await this.getCompleteConfigList()
    await this.processFlowList()
    await this.getUserList()
    await this.getBusinessUnitList()
    this.getAreaList()

  },
  methods: {
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkeds = checkedCount === this.allStatus.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.allStatus.length;
    },
    more(row,command) {
      const functions = {
        printSequenceNumber: () => { this.printSequenceNumber(row) },
        adjustStation: () => { this.adjustStation(row) },
        del: () => { this.onDel([row.id]) },
      }
      functions[command]()
    },
    shossssss(){
      console.log("1212",this.isIndeterminate)
      this.isIndeterminate = !this.isIndeterminate
    },
    selectTab(val, index) {
      this.allStatus[index].checked = !this.allStatus[index].checked

      const isCheck = this.allStatus.filter(item => item.checked)
      const noCheck = this.allStatus.filter(item => !item.checked)

      if(isCheck.length > 0 && isCheck.length < this.allStatus.length){
        this.isIndeterminate = true
      }else {
        this.isIndeterminate = false
      }
      if(isCheck.length > 0){
        this.checkeds = true
        this.currentIdxs = isCheck.map(item => item.value)
      }else {
        this.checkeds = false
        this.currentIdxs = []
      }
      this.$refs.mTable.setTableData(true)
    },
    selectCheck(val) {
      this.currentIdxs = val ? [-1,0,1] : [];
      this.isIndeterminate = false;
      if(val){
        this.allStatus = this.allStatus.map(item => {
          return {
            ...item,
            checked: true
          }
        })
      }else {
        this.allStatus = this.allStatus.map(item => {
          return {
            ...item,
            checked: false
          }
        })
      }
      this.$refs.mTable.setTableData(true)
    },
    adjustStation(row){
      this.woId = row.id
       this.modalShow = true
       this.getStationList(row)
    },
    async getStationList(row){
      const res = await api.getAreaStationList({id:row.id})
      if(res){
        this.stationList = res
        let a = this.stationList.filter(item => item.lockStatus === 1)
        a.forEach(item => {
            this.$refs.sTable.tableRefs().toggleRowSelection(item, true)
          })
      }
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    async submitForm(callback) {
      const res = await api.adjustStationLocation({
        id: this.woId,
        stationIds:this.selectionStationData

      })
      if(res){
        this.$message.success('调整成功')
        this.modalShow = false
        this.$refs.mTable.setTableData()
        callback(true)
      }else{
        callback()
      }
    },
    async getCompleteConfigList(){
       const res = await api.getCompleteConfigList()
       if(res){
        this.message = res.filter(item => item.value == 1 && this.matchCode.includes(item.code)).map(item => item.name)
        this.message = this.message.map((item, index) => {
            return `${index + 1}. ${item}<br>`
        }).join('')
       }
    },
    onOver(id) {
      this.$confirm(this.message + '是否确认？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      }).then(async() => {
        const res = await api.overPlan({ id })
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
      })
    },
    startDo(id){
      this.nowOrderId = id
      this.dataForAdd = {}
      this.visiableStart = true
    },
    async addOrEditSubmit(callback) {
      const { form } = this.$refs.formBuilds
      if (!form.wouseId) {
        this.$message.error('请选择产线')
        callback()
        return
      }

      const that = this
      const res = await api.startDo({
        id: that.nowOrderId,
        areaId: form.wouseId,
      })
      if (res) {
        this.$message.success('开工成功')
        callback(true)
        this.visiableStart = false
        this.$refs.mTable.tableRefs().clearSelection()
        this.$refs.mTable.setTableData()
      } else {
        callback()
      }
    },
    onAssembly(id) {
          this.$confirm('是否装配完工该生产工单？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async() => {
            const res = await api.assemblyPlan({ id })
            if (res) {
              this.$message.success('操作成功')
              this.$refs.mTable.tableRefs().clearSelection()
              this.$refs.mTable.setTableData()
            }
          })
        },

    onRestart(id) {
      this.$confirm('是否重启该生产工单？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.restart({ id })
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
      })
    },

    async onWorkProcess(id,tempoType) {
      this.progressType = tempoType ? '领料进度详情':'报工进度详情'
      const res = await api.productionTempoDetail({ id,tempoType })
      if (res) {
        this.visibleProgress = true
        this.progressDetail = res
      }
    },
    onHandlePlan(type, id) {
      this.$router.push({ name: 'prodPlanCreate', query: { type, id }})
    },
    async batchExport(isAll) {
      const params = isAll ? this.functionHeader.exportParams : { ids: this.selectionData }
      const res = await api.exportList(params)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      } else {
        this.$message.error('导出失败，内容为空')
      }
    },

    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    selectionChange(data) {
      this.selectionData = data.map(item => item.id)
      this.selectionDataList = data
    },
    selectionStationChange(data) {
      this.selectionStationData = data.map(item => item.id)
      this.selectionStationDataList = data
    },
    resetSelectData() {
      this.selectionDataList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionDataList = this.selectionDataList.filter((item) => item.selectable)
    },
    jumpOrderDetail(row) {
      this.$router.push({ name: 'orderDetail', query: { id: row.produceOrderId }})
    },
    async showProcess(row) {
      const res = await api.getProcessDetail({ workOrderId: row.id })
      if (res) {
        this.processFlowVersion = res.processFlowVersion
      }
      this.processVisible = true
    },
    // 获取产线
    async getAreaList() {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'type',
        fieldType: 'number',
        fieldValue: 2,
        operator: 'eq'
      })
      search.push({
        id: getUUid(),
        fieldName: 'isEnabled',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      })
      const res = await api.getAreaList({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.formOptions.areaOptions = res
        this.formListForStart[0].children[0].options = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.areaList = res
        }
      }
    },
    // 获取工艺详情
    async getProcessDetailById(id, versionId) {
      const res = await api.getProcessDetail({ id: id, versionId: versionId })
      if (res) {
        this.processFlowVersion = res.processFlowVersion
      }
    },
    // 下拉选择工艺
    async processFlowList() {
      const res = await api.getFlowers({
        isGetAreaList: 1,
        isGetVersionList: 1
      })
      if (res) {
        this.formOptions.flowerOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.processFlowList = res
        }
      }
    },
    // 负责人
    async getUserList() {
      const res = await uapi.getUserList()
      if (res) {
        // this.formOptions.businessList = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.userList = res
        }
      }
    },
    // 业务部门
    async getBusinessUnitList() {
      const res = await projectApi.getBusinessUnitList()
      if (res) {
        // this.formOptions.businessList = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.businessList = res
        }
      }
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, order } = condition
      const { planEndTime, planStartTime, deliveryTime } = this.searchData
      const params = {
        ...this.searchData,
        statusList:this.currentIdxs,
        sort: order,
        search: this.functionHeader.searchVal
      }
      if (planEndTime?.length) {
        params.planEndStartTime = planEndTime[0]
        params.planEndEndTime = planEndTime[1]
      }
      if (planStartTime?.length) {
        params.planStartStartTime = planStartTime[0]
        params.planStartEndTime = planStartTime[1]
      }
      if (deliveryTime?.length) {
        params.deliveryStartTime = deliveryTime[0]
        params.deliveryEndTime = deliveryTime[1]
      }
      // 导出
      this.functionHeader.exportParams = params
      const res = await api.getPlanList({
        page: page.currentPage,
        size: page.pageSize,
        ...params
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    // 查询
    searchTable(data, val) {
      this.searchData = data
      this.$refs.mTable.setTableData(true)
    },
    // 批量删除
    async onDel(ids) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.deletePlan({ ids })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
      })
    },
    printSequenceNumber(row) {
      if (row.sequenceNumber) {
        this.commonId = `${row.sequenceNumber}print`
        this.$refs.printCode.print(row)
      }
    },
    allMore(command) {
      const functions = {
        batchDel: () => this.onDel(this.selectionData),
        batchExport: this.batchExport
      }
      functions[command]()
    },
    // 重置
    resetData() {
      this.searchData = {}
      this.functionHeader.searchVal = ''
      this.$refs.mTable.setTableData(true)
    },
    // 工位重置
    resetStationData() {
      this.selectionStationDataList.forEach(item => {
        if (!item.selectable) {
          this.$refs.sTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionStationDataList = this.selectionStationDataList.filter((item) => item.selectable)
    },
  }
}
</script>

<style scoped lang="scss">
.detail-box {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  .item-box {
    margin-right: 14px;
    margin-bottom: 20px;
    .item {
      width: 121px;
      height: 38px;
      border-radius: 4px;
      font-size: 12px;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 35px;
      position: relative;
      margin-bottom: 10px;
      padding: 0 5px;
    }
    .sign{
      position: absolute;
      top: 0;
      left: 0;
      width: 17px;
      height: 17px;
    }
    .progress-bg {
      width: 100%;
      height: 4px;
      position: absolute;
      bottom: 0;
      left: 0;
      background: #a2b3e2;
      border-radius: 2px;
      .progress-bar {
        height: 4px;
        background: linear-gradient(90deg, #356c90 0%, #54aaff 100%);
        border-radius: 2px;
      }
      .apply-bar{
        height: 4px;
        background: linear-gradient(90deg, #44ec52 0%, #98e191 100%);
        border-radius: 2px;
      }
    }
    .item-finished {
      color: #4b8358;
      background: #eafbeb;
      border: 1px solid #a5dab2;
    }
    .item-running {
      color: #5B7FAF;
      background: #ebf4ff;
      border: 1px solid #a4c9ed;
    }
    .item-wait {
      color: #868686;
      border: 1px solid #c4c4c4;
      background: #eeeeee;
    }
  }
}
::v-deep {
  .dialogFormBuilds .el-row {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }
}
.schedule-wrap {
  width: 100%;
  padding: 10px;
  .all-select {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 4px;
      .checkAll {
        margin-right: 20px;
      }
      .all-li {
        width: 80px;
        height: 26px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 1px solid #DDE3EB;
        margin-right: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A4B3C6;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .active {
        color: #607FFF;
        border-color: #607FFF;
        background: #EFF2FF;
      }
    }
  .icon-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 19px;
    height: 19px;
    display: block;
  }
  ::v-deep {
    .e-divider {
      margin: 0px 0 0 0;
    }
    .el-row {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
    }
  }
  ::v-deep {
    .el-button--primary,
    .el-button-group .el-button {
      height: 32px;
    }
  }
  .li-btn {
    // margin-right: 10px;
    color: #607fff;
    font-weight: 500;
  }
  .input-select {
    width: 120px;
    display: block;
  }
  .input-with-select {
    width: 100%;
  }
  .searchs {
    padding-left: 0;
  }
  .color-btns {
    color: #c4c4c4;
  }
}
</style>
