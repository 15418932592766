<template>
    <div class="orderAdd">
      <div class="container-left">
        <div class="detail-card">
          <div class="card-tit">基本信息</div>
          <div class="card-bot">
            <el-col :span="6">
              <span class="bot-ti">单号：{{  detailData.number }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">业务类型：{{ inspectionTypeNames[detailData.businessType - 1] }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">工序：{{ detailData.procedureName }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">物料编码：{{ detailData.materialsCode }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">物料名称：{{ detailData.materialsName }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">物料规格：{{ detailData.materialsSpecifications }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">是否关键料：{{ detailData.isKeyMaterials ? '是' : '否' }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">不良品数量：{{ detailData.defectQuantity }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">批次号：{{ detailData.batchNumber }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">序列号：{{ detailData.sequenceNumber }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">供应商：{{ detailData.supplierName }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">采购单：{{ detailData.supplierName }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">收料单：{{ detailData.materialsReceivedOrderCode }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">设变件：{{ detailData.settingVariable ? '是' : '否' }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">{{ mode ? '订单：' : '项目：'}} {{ detailData.projectName }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">来源对象：{{ detailData.sourceObject }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">源单号：{{ detailData.sourceNumber }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">状态：{{ allStatus[detailData.status - 1] }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">处理时间：{{ detailData.endTime }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">创建人：{{ detailData.creator }}</span>
            </el-col>
            <el-col :span="6">
              <span class="bot-ti">创建时间：{{ detailData.createTime }}</span>
            </el-col>
         
          </div>
        </div>
        <!-- 不良品判定 -->
        <div class="detail-card" >
          <div class="card-tit">不良品判定</div>
          <div class="card-bot" style="display: block">
            <el-form ref="detail" label-position="right" label-width="90px" class="demo-form-inline">
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    label="判定类型" :required="expTaskNeedHandleMsg.mastHaveValue ? expTaskNeedHandleMsg.mastHaveValue.includes('判定类型') : false">
                    <el-select v-if="expTaskNeedHandleMsg.editFieldList && expTaskNeedHandleMsg.editFieldList.includes('判定类型')
                    && !expTaskNeedHandleMsg.showFlag"
                               v-model="resultId" placeholder="请选择判定类型" clearable>
                      <el-option
                        v-for="item in getJudgeResultList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                    <el-input v-else :disabled="true" v-model="judgmentTypeName">
                    </el-input>

                  </el-form-item>
                </el-col>
                <el-col :span="8" v-show="resultId === 3 && memberCode === 'nozoli'">
                  <el-form-item
                    label="供应商" :required="expTaskNeedHandleMsg.mastHaveValue ? expTaskNeedHandleMsg.mastHaveValue.includes('供应商') : false">
                    <el-select v-if="expTaskNeedHandleMsg.editFieldList && expTaskNeedHandleMsg.editFieldList.includes('供应商')
                    && !expTaskNeedHandleMsg.showFlag"
                               v-model="supplierId" placeholder="请选择供应商" clearable>
                      <el-option
                        v-for="item in supplierList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                    <el-input v-else :disabled="true" v-model="supplierName">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>

            </div>
        </div>
        <div class="order-footer">
          <div/>
          <div class="footer-ri">
            <el-button @click="returnList">返 回</el-button>
            <template v-if="expTaskNeedHandleMsg.id && !expTaskNeedHandleMsg.showFlag"
                      v-for="(btn,index) in expTaskNeedHandleMsg.buttonNameList">
              <el-button type="primary" @click="onChangeStatus(0)" :key="index">
                {{ btn }}
              </el-button>
            </template>
            <el-button v-if="expTaskNeedHandleMsg.id && !expTaskNeedHandleMsg.showFlag"
                       type="primary" @click="onChangeStatus(1)">通 过
            </el-button>
 
          </div>
        </div>
    </div>
      <div class="container-right">
      <div style="height: calc(100vh - 80px);width: 94%;box-sizing: border-box;margin-left: 6%;background: #FFFFFF;padding: 20px">
        <h3>流程日志</h3>
        <div style="width: 100%;max-height: calc(80vh - 80px);overflow-y: scroll">
          <el-steps direction="vertical" :active="50" space="140px">
            <el-step :title="itemP.endTime" v-for="(itemP,index) in processList" :key="itemP.taskId"
                     :icon="itemP.endTime ? 'el-icon-success' : 'el-icon-error'">
              <div slot="description">
                <div class="description"
                     :style="{paddingRight:index === processList.length - 1 ? '10%' : '0'}">
                  <div style="border: none;font-weight: bold;color: #000000">{{ itemP.taskName }}</div>
                  <div>
                    {{ itemP.assignee }}
                    <span v-if="itemP.comment"> : {{ itemP.comment }}</span>
                  </div>
                </div>
              </div>
            </el-step>
          </el-steps>
        </div>
      </div>
    </div>
    <el-dialog :title="!processType ? '驳回':'通过'" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label-width="300">
          <el-input v-model="comment" type="textarea" autocomplete="off" placeholder="请输入评论"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmFin" :loading="buttonLoading">确 定</el-button>
      </div>
    </el-dialog>
    </div>
  </template>
  <script>
  import api from '@/api/qualityManagement/defective-product-judge'
  import sapi from '@/api/call/self-abnormality'
  import expApi from '@/api/quality/expection-task'
  export default {
    name: 'DefectiveProductJudgeDetail',
    data() {
      return {
        orderList: [],
        detailData: {
          source: 0
        },
        allStatus: ['待处理','已处理','已作废'],
        inspectionTypeNames: ['自制工序自检', '自制工序专检', '委外工序检验'],
        judgmentTypeList:['让步接收', '返修', '供应商返修'],
        id: undefined,
        processList: [],
        buttonLoading:false,
        dialogFormVisible:false,
        comment:'',
        supplierList:[],
        judgeResultList:[
          {id:1,name:'让步接收'},
          {id:2,name:'返修'},
          {id:3,name:'供应商返修'}
        ],
        resultId:'',
        supplierId:'',
        processType:0,
        btnName: '',
        expTaskNeedHandleMsg: {},
        judgmentTypeName:'',
        supplierName:'',
        defectProductItemId:''
      }
    },
    computed: {
      mode() {
      return this.$store.state.app.mode
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getJudgeResultList(){
      if(this.memberCode === 'nozoli'){
        this.judgeResultList[2].name = '供应商返修'
      }else{
        this.judgeResultList[2].name = '退货'
      }
      return this.judgeResultList
    }
  },
    mounted(){
        if (this.$route.query.needInfo) {
          this.expTaskNeedHandleMsg =  JSON.parse(decodeURIComponent(this.$route.query.needInfo))
          this.getPullDownList()
        }
        this.getOrderDetail(this.$route.query.id)
        this.getHistoryTask(this.$route.query.id)
    },
    methods: {
      // 列表
      async getTableData(condition, callback) {
        const { page, search, order } = condition
        const res = await api.getDefectProductJudgmentItemListPage({
          page: page.currentPage,
          size: page.pageSize,
          defectProductJudgmentId:this.$route.query.id
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      async getPullDownList(){
           const res = await sapi.getDutySupplierList()
           if (res) {
		        this.supplierList = res
		      }
        },
      onChangeStatus(type){
        if (this.expTaskNeedHandleMsg.mastHaveValue.includes('判定类型') && !this.resultId) {
            this.$message.warning('请选择判定类型')
            return false
        }
        this.processType = type
        type ? this.btnName = '通过' : this.btnName = '驳回'
        this.dialogFormVisible = true
      },
      async confirmFin() {
      const that = this
      // if (this.btnName === '驳回') {
        if (!this.comment) {
          this.$message.warning('请输入评论')
          return false
        }
      // }
      that.buttonLoading = true
      const resUpdate = await this.updateMsg()
      if (resUpdate) {
        // 流程中的通过/驳回
        const resBtn = await expApi.btnProcess({
          taskId: that.expTaskNeedHandleMsg.taskId,
          buttonName: that.btnName,
          comment: that.comment
        })
        that.buttonLoading = false
        if (resBtn) {
          this.$message.success('操作成功')
          this.$router.push({ name: 'DefectiveProductTaskList' })
        } else {
          return false
        }
      } else {
        that.buttonLoading = false
        return false
      }
    },
      // 先更新数据
      async updateMsg() {
        if(this.expTaskNeedHandleMsg.editFieldList.includes('判定类型')){
          if(!this.defectProductItemId){
            const res = await api.addDefectProductJudgmentItem({
              judgmentId: this.$route.query.id, 
              quantity: 1,
              judgmentType: this.resultId, 
              supplierId: this.resultId === 3 ? this.supplierId :''
            })
            return res
          }else{
          const res = await api.updateDefectProductJudgmentItem({
            id: this.defectProductItemId,
            judgmentId: this.$route.query.id, 
            quantity: 1,
            judgmentType: this.resultId, 
            supplierId: this.resultId === 3 ? this.supplierId :''
          })
          return res
          }
        }else{
          return true
        }
      },
      async getOrderDetail(id) {
        const res = await api.getDefectProductJudgmentDetail({id:id})
        if (res) {
          this.detailData = res
          this.defectProductItemId = res.item ? res.item.id :''
          this.resultId = res.item ? res.item.judgmentType :''
          this.judgmentTypeName = this.resultId ?  this.getJudgeResultList[this.resultId - 1].name : ''
          this.supplierName = res.item ? res.item.supplierName :''
        }
      },
      async getHistoryTask(id) {
        const res = await api.getHistoryTask({id:id,processDefinitionKey:'defectProduct'})
        if (res) {
          this.processList = res
        }
      },
        returnList() {
          this.$router.go(-1)
        }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .orderAdd{

    display: flex;
  box-sizing: border-box;

  .container-left {
    width: 75%;
    height: 100%;
    box-sizing: border-box;
  }

  .container-right {
    width: 25%;
    height: 100%;
    box-sizing: border-box;

    .description {
      margin-top: 8px;

      div {
        line-height: 40px;
        background: #f5f7f9;
        padding-left: 10px;
        color: #67687d;
        border-bottom: 1px solid #e4e6e8;
        font-size: 14px;
      }
    }
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;

    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;

      span {
        margin-right: 45px;
        display: inline-block;
      }
    }

    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  }
  .bot-li {
    display: flex;
    justify-content: flex-start;
    .bot-ti {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 15px;
    }
  }
  .el-tags {
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .icon-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 19px;
    height: 19px;
    display: block;
  }
  .all-ipt {
    margin-top: 4px;
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .card-bot {
    display: flex;
    flex-flow: row wrap;
    .bot-ti {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      display: inline-block;
      margin-bottom: 16px;
      word-break: break-all;
    }
  }
  .tables {
  padding: 20px;
}
  </style>
  