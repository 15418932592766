export const columns = [
  {
    prop: 'index',
    label: '序号',
    fixed: 'left',
    width: 80,
    slotName: 'index',
    hiddenSearch: true
  },
  {prop: 'projectNumber', label: '项目编号', sortable: false, hiddenSearch: true},
  {prop: 'materialsCode', label: '物料编号', sortable: false, hiddenSearch: true},
  {prop: 'materialsName', label: '物料名称', sortable: false, hiddenSearch: true},
  {prop: 'quantity', label: '数量', sortable: false, hiddenSearch: true},
  {prop: 'positionNumber', label: '位号', sortable: false, hiddenSearch: true},
  {prop: 'materialsType', label: '物料类别', sortable: false, hiddenSearch: true},
  {prop: 'position', label: '放置位置', sortable: false, hiddenSearch: true},
  {prop: 'unitName', label: '计量单位', sortable: false, hiddenSearch: true},
  {prop: 'purchaseRequestReviewDate', label: '采购申请单审核日期', sortable: false, hiddenSearch: true},
  {prop: 'receivingQuantity', label: '收料数量', sortable: false, hiddenSearch: true},
  {prop: 'lackQuantity', label: '缺料数量', sortable: false, hiddenSearch: true,slotName: 'lackQuantity'},
  {prop: 'purchaseOrderReviewDate', label: '采购订单审核日期', sortable: false, hiddenSearch: true},
  {prop: 'receivingDate', label: '收料日期', sortable: false, hiddenSearch: true},
  {prop: 'notifyPurchaser', label: '通知采购员', sortable: false, hiddenSearch: true},
  {prop: 'purchaseReceiptDate', label: '采购入库日期', sortable: false, hiddenSearch: true},
  {prop: 'systemName', label: '所属系统', sortable: false, hiddenSearch: true}
]
