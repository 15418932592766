// 列表
export const columnsAll = [
    {
      type: 'selection',
      fixed: 'left',
      width: 50,
      reserveSelection: true
    },
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
        prop: 'code',
        label: '任务编号',
        hiddenSearch: true,
        sortable: false,
        width: 160
      },
    {
      prop: 'status',
      label: '状态',
      hiddenSearch: true,
      sortable: false,
      slotName: 'status',
      width: 120
    },
    {
      prop: 'inspectType',
      label: '类型',
      hiddenSearch: true,
      sortable: false,
      slotName: 'inspectType',
      width: 80
    },
    {
      prop: 'inspectionType',
      label: '业务类型',
      hiddenSearch: true,
      sortable: false,
      slotName: 'inspectionType',
      width: 80
    },
    {
      prop: 'inspectionSchemeName',
      label: '质检方案',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
        prop: 'producePlanNumber',
        label: '生产工单',
        hiddenSearch: true,
        sortable: false,
        width: 180
      },
      {
        prop: 'materialsFullName',
        label: '生产产品',
        hiddenSearch: true,
        sortable: false,
        width: 180
      },
      {
        prop: 'productionTaskCode',
        label: '生产任务',
        hiddenSearch: true,
        sortable: false,
        width: 180
      },
    {
      prop: 'procedureName',
      label: '工序',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
        prop: 'startTime',
        label: '开始时间',
        hiddenSearch: true,
        sortable: false,
        width: 180
      },
      {
        prop: 'endTime',
        label: '结束时间',
        hiddenSearch: true,
        sortable: false,
        width: 180
      },
    {
      prop: 'createUser',
      label: '创建人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'createTime',
      label: '创建时间',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      prop: 'executorName',
      label: '执行人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'businessUnitName',
      label: '业务部门',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'result',
      label: '结论判定',
      hiddenSearch: true,
      sortable: false,
      slotName: 'result',
      width: 180
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      sortable: false,
      hiddenSearch: true,
      width: 200
    }
  ]
  export const incomming = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
      prop: 'status',
      label: '质检状态',
      hiddenSearch: true,
      sortable: false,
      slotName: 'status',
      width: 120
    },
    {
      prop: 'relationNumber',
      label: '相关单号',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'inspectionType',
      label: '质检类型',
      hiddenSearch: true,
      sortable: false,
      slotName: 'inspectionType'
    },
    {
      prop: 'number',
      label: '质检单号',
      hiddenSearch: true,
      sortable: 'custom',
      width: 160,
      slotName: 'number'
    },
    {
      prop: 'inspectionSchemeName',
      label: '质检方案',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'materialCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false,
      width: 150
    },
    {
      prop: 'materialName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'materialSpecifications',
      label: '规格',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'batchNumber',
      label: '批次码',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'reports',
      label: '检验报告',
      hiddenSearch: true,
      sortable: false,
      slotName: 'reports'
    },
    {
      prop: 'creator',
      label: '制单人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'createTime',
      label: '创建时间',
      hiddenSearch: true,
      sortable: 'custom'
    },
    {
      prop: 'inspectorName',
      label: '检验人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'startTime',
      label: '质检开始时间',
      hiddenSearch: true,
      sortable: 'custom'
    },
    {
      prop: 'endTime',
      label: '质检提交时间',
      hiddenSearch: true,
      sortable: 'custom'
    },
    {
      prop: 'batchCount',
      label: '批次数量',
      hiddenSearch: true,
      sortable: false,
      width: 160
    },
    {
      prop: 'passRate',
      label: '合格率',
      hiddenSearch: true,
      sortable: false,
      slotName: 'passRate'
    },
    {
      prop: 'allCount',
      label: '检验（合格/不合格）',
      hiddenSearch: true,
      sortable: false,
      slotName: 'allCount'
    },

    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      sortable: false,
      hiddenSearch: true,
      width: 212
    }
  ]
// 详情页列表
export const columnsDetail = [
{
    prop: 'inspectionGroupName',
    label: '检验组',
    hiddenSearch: true,
    sortable: false,
    width: 150
},
{
    prop: 'inspectionItemName',
    label: '质检项',
    hiddenSearch: true,
    sortable: false
},
{
    prop: 'standardValue',
    label: '标准值',
    hiddenSearch: true,
    sortable: false
},
{
    prop: 'upperLimit',
    label: '上偏差',
    hiddenSearch: true,
    sortable: false,
    width: 100
},
{
    prop: 'lowerLimit',
    label: '下偏差',
    hiddenSearch: true,
    sortable: false
},
{
    prop: 'inspectionMethod',
    label: '检验方法',
    hiddenSearch: true,
    sortable: false,
    width: 100
},
{
    prop: 'inspectionStandard',
    label: '检验标准',
    hiddenSearch: true,
    sortable: false
},
{
    prop: 'measuredValue',
    label: '实测值',
    hiddenSearch: true,
    sortable: false,
    slotName:'measuredValue'
},
{
    prop: 'attachments',
    label: '现场图片',
    hiddenSearch: true,
    sortable: false,
    slotName: 'attachments',
    width: 100
},
{
  prop: 'defectItemName',
  label: '缺陷项',
  hiddenSearch: true,
  sortable: false,
  width: 100
},
{
  prop: 'defectReasonName',
  label: '缺陷原因',
  hiddenSearch: true,
  sortable: false,
  width: 100
},
{
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    slotName:'remark'
}
]

