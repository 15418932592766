<template>
    <div class="fault-wrap common-tab">
      <el-radio-group v-model="params.radio" class="m-radio-group" style="margin-bottom: 10px" @change="radioChange">
        <el-radio-button label="process">来料检</el-radio-button>
      </el-radio-group>
      <div class="bottom">
        <div class="top">
          <SearchForm
                  ref="searchForm"
                  :form-list-extend="searchFormList"
                  :form-data="searchFormData"
                  :searchType='1'
                  @update:formData="data=>searchFormData=data"
                  @search="searchTable"
                />
      </div>
      <el-divider class="e-divider" />
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="输入任务编号搜索"
        :tags="functionHeader.tags"
        style="padding: 10px 0 10px 0"
        @search="$refs.mTable.setTableData(true)"
      >
      </FunctionHeader>
      <MTable ref="mTable" :columns="columnsAll" :height="height" :set-data-method="getTableData">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="number" slot-scope="{ row }">
          <NewPageOpen v-if="isShowChild" :path="{ name: 'qualityInspectionOrderDetail', query: {id: row.id, isView: 'view'}}" :text="row.number" />
        </div>
        <NewPageOpen slot="materialsCode" slot-scope="{ row }"
                     :path="{ name: 'materialsDetail', query: { id: row.materialsId, type: 'view' }}" :text="row.materialsCode" />
        <div slot="inspectionType" slot-scope="{ row }">{{ inspectionTypeNames[row.type] }}</div>
        <div slot="isKeyMaterials" slot-scope="{ row }">{{ row.isKeyMaterials ? '是' : '否' }}</div>
        <div slot="isExemption" slot-scope="{ row }">{{ row.isExemption ? '是' : '否' }}</div>
        <div slot="status" slot-scope="{ row }" class="is-enabled">
          <div :style="{ background: qualityTaskStatus[row.status].background, color: qualityTaskStatus[row.status].color }" />
          <div :style="{color: qualityTaskStatus[row.status].color }">{{ qualityTaskStatus[row.status].name }}</div>
        </div>
        <div slot="allCount" slot-scope="{ row }">
          <span>{{ row.okCount }}/{{ row.ngCount }}</span>
        </div>
        <div slot="result" slot-scope="{ row }">
          <span :style="{color:row.result == 0 ? '#E02020':row.result === 1 ? '#00AB29':''}">
            {{ row.result === 1 ? '合格' : row.result === 0 ? '不合格' : '-' }}
          </span>
        </div>
        <div slot="sequenceNumber" slot-scope="{row }">
          <el-tooltip effect="dark" popper-class="sequence-tooltip" :content="row.sequenceNumber" placement="top-start">
            <span class="mr-10">
              {{ row.sequenceNumber }}
          </span>
          </el-tooltip>
        </div>
        <div slot="qualifiedSequenceNumber" slot-scope="{row }">
          <el-tooltip effect="dark" :content="row.qualifiedSequenceNumber" placement="top-end">
            <span class="mr-10">
              {{ row.qualifiedSequenceNumber }}
          </span>
          </el-tooltip>
        </div>
        <div slot="defectSequenceNumber" slot-scope="{row }">
          <el-tooltip effect="dark" :content="row.defectSequenceNumber" placement="top-end">
            <span class="mr-10">
              {{ row.defectSequenceNumber }}
          </span>
          </el-tooltip>
        </div>
        <div slot="action" slot-scope="{ row }" style="z-index: 100">
          <el-button v-if="permission('Detail')" type="text" @click="viewDetail(row.id)">详情</el-button>
           <el-button v-if="permission('Check') && row.status != 2" type="text" @click="check(row.id)">检验</el-button>
          <el-button v-if="permission('Del') && row.status != 2" type="text" @click="del(row.id)">删除</el-button>
          <!-- <el-button v-if="permission('Print')" type="text" @click="handlePreview(row,0)">打印</el-button> -->
        </div>
      </MTable>
      </div>
    </div>
  </template>
  <script>
  import FunctionHeader from '@/components/FunctionHeader'
  import { qualityTaskStatus } from '@/config/options.config'
  import api from '@/api/qualityManagement/quality-task'
  import { columnsAll } from './columns'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import DelPopover from '@/components/DelPopover/index'
  import fixCommonPage from '@/mixin/fix-common-page'
  import dayjs from 'dayjs'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { searchFormList } from './form-list'
  export default {
    name: 'QualityIncomingTaskList',
    components: { DelPopover, NewPageOpen, FunctionHeader, SearchForm },
    mixins: [fixCommonPage],
    data() {
      return {
        searchFormList,
        showInspector: false,
        visibles: false,
        params: {
          radio: 'process'
        },
        info: {},
        taskData: [],
        isIndeterminate: true,
        qualityTaskStatus,
        searchFormData: {},
        keyword: '',
        selectKeyword: '1',
        functionHeader: {
          searchVal: ''
        },
        columnsAll,
        searchDatas: [],
        currentId: undefined,
        isFaultCheck: undefined,
        currentIdxs: [0, 1],
        currentIdx: false,
        inspectionTypeNames: ['首检', '过程检', '来料检', '出货检'],
        userList: [],
        inspectionSchemeInspector: [],
        inspectionSchemeInspectorId: undefined,
        loading:false,
        printLoading:false,
        dialog: false,
        form:{
          type:''
        },
        selectIds: [],
        total:0,
        selectionData:[],
        templateList:[],
        chooseIds:[],
        searchFormatData:[],
        isPageLoaded:false
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 12 - 50 - 100
      }
    },
    watch: {
    },
    mounted() {
      this.isPageLoaded = true
    },
    activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
    methods: {
      viewDetail(id) {
        this.$router.push({ name: 'QualityIncomingTaskDetail', query: { id,type:'view' }})
      },
      check(id){
        this.$router.push({ name: 'QualityIncomingTaskDetail', query: { id, type:'check',startTime: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss') }})
      },
      radioChange(label) {
      },
      // 列表
      async getTableData(condition, callback) {
        const { page, order } = condition
        const data = Object.assign({}, ...this.searchFormatData)
        const res = await api.getQualityIncomingTaskListPage({
          page: page.currentPage,
          size: page.pageSize,
          inspectionTaskCode: this.functionHeader.searchVal,
          ...data
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      },
      async del(id) {
        this.$confirm('删除后不可恢复，是否确认？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.delQualityIncomingTask({ inspectionTaskId: id })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData()
          }
        })
      },
      // 查询
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      }
    }
  }
  </script>

    <style scoped lang="scss">
      .icons {
        font-size: 120px;
        color: #dadada;
        margin: 10px;
      }
      .setting {
        width: 15px;
       }
       .drawer-footer {
          padding: 10px;
          box-shadow: 4px -4px 12px -6px rgba(0, 0, 0, 0.1);
          text-align: center;
          button {
            padding: 10px 30px !important;
            &:first-child {
              background: #F8F8FB;
              color: #8A8CA5;
              transition: all .5s;
              &:hover {
                border-color: #dadaf6;
                background: #f0f0f8;
              }
            }
            &:last-child {
              background: $primary-color;
              color: #ffffff;
              transition: all .5s;
              &:hover {
                background: #7691fc;
              }
            }
          }
  }
     .fault-wrap{
        // padding: 10px;
        .item-wrapper {
          > div {
            display: inline-flex;
            padding: 10px 5px 0 5px;
            align-items: center;
            font-size: 14px;

            .label {
              min-width: 115px;
            }

          }
        }
        .bottom {
          width: 100%;
          height: 100%;
          padding: 10px;
        }
        .all-select {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .checkAll {
            margin-right: 20px;
          }
          .all-li {
            width: 80px;
            height: 26px;
            background: #FFFFFF;
            border-radius: 16px;
            border: 1px solid #DDE3EB;
            margin-right: 15px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #A4B3C6;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }
          .active {
            color: #607FFF;
            border-color: #607FFF;
            background: #EFF2FF;
          }
        }
        .li-btn{
          // margin-right:10px;
          color:#607FFF;
          font-weight: 500;
        }
        .color-btns{
          color:#c4c4c4;
        }
        .searchs{
          padding-left: 0;
          padding-top: 0;
        }
        .input-select{
          width: 100px;
          display: block;
          }
        .input-with-select{
          width: 100%;
        }
        .all-operator{
          height: 32px;
          margin-left: 10px;
          ::v-deep{
            .el-button-group .el-button{
              height: 32px;
            }
          }
        }
     }
     ::v-deep .customer-content div {
       width: 100%;
     }

     .tip-tit {
       height: 18px;
       line-height: 18px;
     }
     div.tooltip {
      display: inline-block;
      padding: 3px 5px;
      background: #303133;
      position: relative;
      border-radius: 3px;
      font-size: 16px;
      color: #ffffff;
      box-shadow: 2px 2px 2px 0 #c1c1c1;
      margin-left: 10px;
    }

    div.tooltip:before, div.tooltip:after {
      content: ' ';
      height: 0;
      position: absolute;
      width: 0;
      border: 3px solid transparent;
    }

    div.tooltip:before {
      border-right-color: #303133;
      position: absolute;
      left: -6px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }

    div.tooltip:after {
      border-right-color: #303133;
      position: absolute;
      left: -6px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
    .faultStatus {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      height: 22px;
      line-height: 22px;
      border-radius: 4px;
      width: 50px;
      text-align: center !important;
    }
    .drawing-img {
      width: 50px;
      height: 48px;
      display: block;
      cursor: pointer;
      color: #fff;
    }
    .faultDesc {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 180px;
    }
    ::v-deep{
      .e-divider{
        margin: 0px 0 0 0;
      }
      .demo-drawer {
        width: 400px !important;
    }
    }
    </style>
