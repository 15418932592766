<template>
  <div class="scrap-wrap">
    <div class="top">
      <el-row style="padding: 10px 10px 10px 10px;border-bottom: 1px solid #dde3eb;">

        <el-col :span="4">
          <el-select filterable clearable v-model="topSearchForm.projectNumber" @change="topSearch(11)"
                     placeholder="项目编号" style="width: 95%">
            <el-option
              v-for="item in allXM"
              :key="item.projectId"
              :label="item.projectName + '-' + item.projectNumber"
              :value="item.projectName">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-input @change="topSearch" style="width: 95%" v-model="topSearchForm.positionNumber" type="text"
                    placeholder="位号"><
          </el-input>
        </el-col>

        <el-col :span="4">
          <el-select filterable clearable v-model="topSearchForm.materialsType" @change="topSearch"
                     placeholder="物料类别" style="width: 95%">
            <el-option
              v-for="item in materialsTypeSet"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-select filterable clearable v-model="topSearchForm.position" @change="topSearch"
                     placeholder="放置位置" style="width: 95%">
            <el-option
              v-for="item in positionSet"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-select filterable clearable v-model="topSearchForm.arrivalStatus" @change="topSearch"
                     placeholder="到货情况" style="width: 95%">
            <el-option label="已到货" value="已到货"></el-option>
            <el-option label="未到货" value="未到货"></el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-button type="primary" size="small" style="background:#607FFF" @click="topSearch">查询</el-button>
          <el-button size="small" @click="resetTopSearch">重置</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        columns-key="TTT-QTJS"
        v-model="functionHeader.searchVal"
        :hiddenTitleSearch="true"
        search-title="请输入工单编号搜索"
        :tags="!exportSelectionIds.length ?  functionHeader.tags :[]"
        @search="$refs.mTable.setTableData(true)"
      >
      </FunctionHeader>
      <MTable columns-key="TTT-QTJS" ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="lackQuantity" slot-scope="{ row }">
          <div v-if="row.lackQuantity < 0" style="color: red">{{ row.lackQuantity }}</div>
          <div v-else>{{ row.lackQuantity }}</div>
        </div>
      </MTable>
    </div>

    <MDialog v-model="visiable" :title="currentType" width="1000px" top="30px" @onOk="addOrEditSubmit">
      <h3 style="margin-top: 0;margin-bottom: 10px">工单信息</h3>
      <el-row style="padding: 10px;" :gutter="20">
        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">*工单编号</label>
            <div style="flex: 1">
              <el-select filterable v-model="dataForAdd.productionPlanNumber" @change="getGD"
                         placeholder="工单编号" style="width: 98%">
                <el-option
                  v-for="item in allGD"
                  :key="item.orderId"
                  :label="item.orderCode + '-' + item.materialsCode + '-' + item.materialsName"
                  :value="item.orderCode">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">物料编号</label>
            <div style="flex: 1">
              <el-input disabled v-model="dataForAdd.materialsCode" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">物料名称</label>
            <div style="flex: 1">
              <el-input disabled v-model="dataForAdd.materialsName" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>
      </el-row>

      <h3 style="margin-top: 0;margin-bottom: 10px">筒节信息</h3>
      <el-row style="padding: 10px;" :gutter="20">
        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">*筒节号</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.shellNumber" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">材质</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.materialQuality" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">内径</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.insideDiameter" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label class="ttt-label">厚度</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.thickness" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label class="ttt-label">高度</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.height" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label class="ttt-label">下料总长</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.totalLength" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label class="ttt-label">下料总宽</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.totalWidth" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label class="ttt-label">下料长度允差</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.lengthTolerance" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label class="ttt-label">下料宽度允差</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.widthTolerance" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label style="font-size: 12px" class="ttt-label">下料对角线允差</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.diagonalTolerance" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label class="ttt-label">圆度要求</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.roundnessRequirement" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>
      </el-row>

      <h3 style="margin-top: 0;margin-bottom: 10px">焊缝信息</h3>
      <el-row style="padding: 10px;" :gutter="20">
        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label class="ttt-label">*焊缝号</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.weldNumber" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label class="ttt-label">错边量</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.misalignment" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label class="ttt-label">棱角度</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.edgeMeasurement" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt"><label class="ttt-label">外周长</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.outerPerimeter" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>
      </el-row>
    </MDialog>
  </div>
</template>

<script>
import {columns} from './columns'
import api from '@/api/toolManagement/toolLeader/index.js'
import conventionMixin from '@/mixin/conventional-page'
import batchExportFront from '@/mixin/batch-export-front'
import FunctionHeader from '@/components/FunctionHeader/index'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import DelPopover from "@/components/DelPopover/index.vue";
import {Encrypt} from "@/utils/sercet";
import {getUUid} from "@/utils";
import {getToken} from "@/utils/auth";

export default {
  name: 'WeldingProcessSpecification',
  components: {DelPopover, NewPageOpen, FunctionHeader},
  mixins: [conventionMixin, batchExportFront],
  data() {
    return {
      api,
      visiable: false,
      columns,
      dataForAdd: {},
      searchKeyword: true,
      functionHeader: {
        searchVal: '',
        tags: [
          {
            tagName: 'el-button',
            props: {type: 'primary', size: 'small'},
            style: {width: 'auto', background: '#607FFF', opacity: 0},
            innerText: '新增',
            on: {
              click: () => {
              }
            }
          }
        ],
        importName: '111',
        exportName: 'exportShift',
        exportParams: {}
      },
      currentType: '新增焊接工艺规程',
      isPageLoaded: false,
      total: 0,
      topSearchForm: {},
      allXM: [],
      allHG: [],
      allGD: [],
      allGYGC: [],
      materialsTypeSet: [],
      positionSet: [],
      finalSrc: {},
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: {token: getToken()},
        showFileList: false,
        accept: 'image/*',
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange
      },
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 285 - 10 + 35
    },
  },
  watch: {},
  async created() {
    await this.getAllXM()
  },
  activated() {
    if (!this.isPageLoaded) {
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
  },
  mounted() {
    this.isPageLoaded = true
  },
  methods: {
    async topSearch(inx) {
      if (inx == 11) {
        await this.getAllType()
      }
      this.$refs.mTable.setTableData(true)
    },
    async getAllType() {
      if (this.topSearchForm.projectNumber) {
        const res = await api.fsdKittingListCondition({
          projectNumber: this.topSearchForm.projectNumber
        })
        if (res) {
          this.materialsTypeSet = res.materialsTypeSet.filter(item => item.trim())
          this.positionSet = res.positionSet.filter(item => item.trim())
        }
      } else {
        this.materialsTypeSet = []
        this.positionSet = []
        this.topSearchForm.materialsType = ''
        this.topSearchForm.position = ''
      }
    },
    resetTopSearch() {
      this.topSearchForm = {}
      this.topSearch()
    },
    async addOrEditSubmit(callback) {
      if (this.dataForAdd.id) {
        consoleSelf.info("修改工具数据", this.dataForAdd)
        const res = await api.editWeldLayingOff({...this.dataForAdd})
        if (res) {
          this.$message.success('修改成功')
          this.visiable = false
          this.$refs.mTable.setTableData(true)
        }
      } else {
        consoleSelf.info("新增数据", this.dataForAdd)
        const res = await api.addWeldLayingOff({...this.dataForAdd})
        if (res) {
          this.$message.success('新增成功')
          this.visiable = false
          this.$refs.mTable.setTableData(true)
        }
      }
      callback(true)
    },
    addOne() {
      this.dataForAdd = {}
      this.currentType = "新增下料清单"
      this.visiable = true
    },
    onHandle(type, row) {
      if (type === 'edit') {
        this.dataForAdd = {...row};
        this.currentType = "修改下料清单"
        this.visiable = true
      } else if (type === 'del') {
        this.$confirm('确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await api.delWeldLayingOff(row.id)
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData(true)
          }
        })
      }
    },
    // 列表
    async getTableData(condition, callback) {
      const {page} = condition
      const dd = {
        page: page.currentPage,
        size: page.pageSize,
        ...this.topSearchForm
      }
      if (this.functionHeader.searchVal) {
        dd.searchKeyword = this.functionHeader.searchVal
      }
      const res = await api.fsdKittingListPage(dd)
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    async getAllxm() {
      const res = await api.getWelderProjectListPage({
        page: 1,
        limit: 2000
      })
      if (res) {
        this.allXM = res.records
        console.log("项目", this.allXM)
      }
    },
    async getAllhg() {
      const res = await api.getWelderListPage({
        page: 1,
        limit: 2000
      })
      if (res) {
        this.allHG = res.records
        console.log("焊工", this.allHG)
      }
    },
    getGD(e) {
      const a = this.allGD.find(item => item.orderCode === e)
      console.log(a)
      this.dataForAdd.materialsCode = a.materialsCode
      this.dataForAdd.materialsName = a.materialsName
    },
    //获取项目列表(自己系统的项目)
    async getAllXM() {
      const res = await api.getProjectList()
      if (res) {
        this.allXM = res.records.map((item) => {
          return {
            projectId: item.id,
            projectName: item.projectName,
            projectNumber: item.projectNumber,
          }
        })
        console.log("项目列表", this.allXM)
      }
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.$set(this.dataForAdd, 'imageUrl', res.result)
      } else {
        this.$message.error(res.errorMsg)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.ttt {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .ttt-label {
    width: 100px;
    text-align: right;
    padding-right: 10px;
    line-height: 32px;
    font-size: 14px;
  }
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;

  .bottom {
    padding: 10px;
  }

  .m-radio-group {
    margin-left: 10px;
  }
}

::v-deep {
  .e-divider {
    margin: 0px 0 0 0;
  }

  .el-form-item__content {
    height: 32px;
  }

  .avatar-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9 !important;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 38px;
        height: 38px;
        line-height: 38px;
        text-align: center;
      }
    }
  }
}
</style>
