import { formatColumns } from '@/utils'
import { status } from '@/config/options.config'

export const columns = [
  {
    prop: 'code',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    form: { index: 0, tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, tagName: 'el-input' }
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 2,
      required: false,
      tagName: 'el-cascader',
      key: 'materialsClassId',
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        filterable: true
      }
    }
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      required: false,
      tagName: 'el-select',
      key: 'materialsTypeId'
    }
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      required: true,
      tagName: 'el-select',
      key: 'materialsAttributeId'
    }
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    form: { index: 5, tagName: 'el-input', required: false }
  },
  // {
  //   prop: 'purchaseLeadTime',
  //   label: '采购提前期',
  //   sortable: false,
  //   hiddenSearch: true,
  //   form: {
  //     index: 4,
  //     tagName: 'el-input-number',
  //     attrs: {
  //       min: 0,
  //       'controlsPosition': 'right'
  //     },
  //     required: false,
  //     min: 0
  //   }
  // },
  // {
  //   prop: 'isCheck',
  //   label: '是否检验',
  //   sortable: false,
  //   hiddenSearch: true,
  //   form: {
  //     index: 6,
  //     tagName: 'el-switch',
  //     defaultValue: 0,
  //     attrs: {
  //       'active-value': 1,
  //       'inactive-value': 0
  //     },
  //     required: false
  //   }
  // },
  {
    prop: 'isEnabled',
    label: '物料状态',
    sortable: false,
    slotName: 'isEnabled',
    filters: formatColumns(status),
    form: {
      index: 7,
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'isKeyMaterials',
    label: '是否关键料',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 8,
      tagName: 'el-switch',
      defaultValue: 0,
      attrs: {
        'active-value': 1,
        'inactive-value': 0
      },
      required: false
    }
  },
  {
    prop: 'isExemption',
    label: '是否免检',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 9,
      tagName: 'el-switch',
      defaultValue: 0,
      attrs: {
        'active-value': 1,
        'inactive-value': 0
      },
      required: false
    }
  },
  {
    prop: 'iqcInspectName',
    label: '来料检方案',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 10,
      required: false,
      tagName: 'el-select',
      key: 'iqcInspectId'
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { index:11, tagName: 'el-input', required: false, props: { type: 'textarea' }}
  }
]
