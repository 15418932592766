var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quality-inspection-project-wrapper" },
    [
      _c(
        "el-tabs",
        {
          ref: "sortTab",
          attrs: { id: "sortTabs", type: "card", closable: !_vm.readOnly },
          on: { "tab-remove": _vm.removeTab },
          model: {
            value: _vm.currentTabs,
            callback: function($$v) {
              _vm.currentTabs = $$v
            },
            expression: "currentTabs"
          }
        },
        _vm._l(_vm.projectList, function(item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: {
              label: item.inspectionProjectName || "请选择",
              name: "tab" + index
            }
          })
        }),
        1
      ),
      _vm._l(_vm.projectList, function(card, cardIndex) {
        return _c(
          "el-card",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: "tab" + cardIndex === _vm.currentTabs,
                expression: "('tab' + cardIndex) === currentTabs"
              }
            ],
            key: cardIndex,
            staticClass: "card-custom"
          },
          [
            _c(
              "div",
              { staticClass: "top" },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "align-items": "center" },
                    attrs: { gutter: 24 }
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          display: "inline-flex",
                          "align-items": "center"
                        },
                        attrs: { span: 18 }
                      },
                      [
                        _c("div", { staticClass: "required-div" }, [
                          _vm._v("检验组：")
                        ]),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "250px" },
                            attrs: {
                              size: "small",
                              disabled: _vm.readOnly,
                              filterable: "",
                              placeholder: "请选择检验项"
                            },
                            on: {
                              change: function(val) {
                                _vm.inspectionProjectChange(val, cardIndex)
                              }
                            },
                            model: {
                              value: card.inspectionProjectName,
                              callback: function($$v) {
                                _vm.$set(card, "inspectionProjectName", $$v)
                              },
                              expression: "card.inspectionProjectName"
                            }
                          },
                          _vm._l(
                            _vm.formOptions.inspectionProjectList,
                            function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.name }
                              })
                            }
                          ),
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.readOnly,
                                expression: "!readOnly"
                              }
                            ],
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.addProjectGroupFront(cardIndex)
                              }
                            }
                          },
                          [_vm._v("新增检验组 ")]
                        )
                      ],
                      1
                    ),
                    !_vm.readOnly
                      ? _c(
                          "el-col",
                          {
                            staticStyle: { "text-align": "right" },
                            attrs: { span: 6 }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  height: "32px",
                                  position: "relative",
                                  top: "-2px"
                                },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.saveAs(cardIndex)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: { "margin-right": "8px" },
                                  attrs: {
                                    src: require("@/assets/statistics-board/保存@2x.png"),
                                    width: "14px"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      position: "relative",
                                      top: "-3px"
                                    }
                                  },
                                  [_vm._v("另存为")]
                                )
                              ]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  height: "32px",
                                  position: "relative",
                                  top: "-2px"
                                },
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.delCard(cardIndex)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  staticStyle: { "margin-right": "8px" },
                                  attrs: {
                                    src: require("@/assets/l_c_M_images/垃圾桶.png"),
                                    width: "14px"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      position: "relative",
                                      top: "-3px"
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    card.inspectionProjectName && !_vm.readOnly
                      ? _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.showInspectionItems(cardIndex)
                                  }
                                }
                              },
                              [_vm._v("选择检验项")]
                            ),
                            _vm.memberCode !== "fsd"
                              ? [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addInspectionItems(
                                            cardIndex
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("新增检验项")]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    card.columns.length > 1
                      ? _c(
                          "el-col",
                          { staticClass: "table-custom", attrs: { span: 24 } },
                          [
                            _c("MTable", {
                              ref: "mTable",
                              refInFor: true,
                              style: { paddingTop: _vm.readOnly ? "15px" : 0 },
                              attrs: {
                                "only-key": "uuid",
                                columns: card.columns,
                                "max-height": "600px",
                                "show-page": false,
                                data: card.inspectionItemList
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "headerSlot",
                                    fn: function(ref) {
                                      var columnIndex = ref.columnIndex
                                      return !_vm.readOnly
                                        ? _c(
                                            "div",
                                            { staticClass: "right-icon" },
                                            [
                                              _vm.memberCode !== "fsd"
                                                ? [
                                                    _c("el-button", {
                                                      staticStyle: {
                                                        padding: "0",
                                                        "font-size": "16px"
                                                      },
                                                      attrs: {
                                                        icon:
                                                          "el-icon-copy-document",
                                                        type: "text"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.modifyInspectionItem(
                                                            "copy",
                                                            columnIndex,
                                                            cardIndex
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _c("el-button", {
                                                      staticStyle: {
                                                        padding: "0",
                                                        "font-size": "16px",
                                                        margin: "0 8px"
                                                      },
                                                      attrs: {
                                                        icon: "el-icon-edit",
                                                        type: "text"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.modifyInspectionItem(
                                                            "modify",
                                                            columnIndex,
                                                            cardIndex
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                : _vm._e(),
                                              _c("img", {
                                                staticClass: "remove-img",
                                                attrs: {
                                                  src: require("@/assets/quality/sx-remove@2x.png"),
                                                  width: "16px"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removeColumns(
                                                      cardIndex,
                                                      columnIndex
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    }
                                  },
                                  {
                                    key: "customRow",
                                    fn: function(ref) {
                                      var row = ref.row
                                      var columnIndex = ref.columnIndex
                                      return _c("div", {}, [
                                        row["检验项"] === "参数类型"
                                          ? _c("div", [
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f(
                                                      "newParameterTypeName"
                                                    )(
                                                      row.data[columnIndex - 1]
                                                        .parameterType
                                                    )
                                                  )
                                                )
                                              ]),
                                              (row.data[columnIndex - 1]
                                                .possibleValue ||
                                                !_vm.readOnly) &&
                                              [2, 3].includes(
                                                row.data[columnIndex - 1]
                                                  .parameterType
                                              )
                                                ? _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#607FFF"
                                                        }
                                                      },
                                                      [_vm._v("可能值：")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#607FFF"
                                                        }
                                                      },
                                                      [
                                                        _c("el-select", {
                                                          attrs: {
                                                            size: "small",
                                                            multiple: "",
                                                            disabled:
                                                              _vm.readOnly ||
                                                              _vm.noModifyOrAdd,
                                                            "allow-create": "",
                                                            filterable: "",
                                                            "default-first-option":
                                                              "",
                                                            placeholder:
                                                              "请输入可能值，敲击回车"
                                                          },
                                                          model: {
                                                            value:
                                                              row.data[
                                                                columnIndex - 1
                                                              ].possibleValue,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row.data[
                                                                  columnIndex -
                                                                    1
                                                                ],
                                                                "possibleValue",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row.data[columnIndex - 1].possibleValue"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                : _vm._e(),
                                              ![undefined, null, ""].includes(
                                                row.data[columnIndex - 1]
                                                  .defaultValue
                                              ) ||
                                              !_vm.readOnly ||
                                              row.data[columnIndex - 1]
                                                .defaultDynamicEntry
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "padding-top": "10px",
                                                        display: "inline-flex",
                                                        "align-items": "center"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#607FFF"
                                                          }
                                                        },
                                                        [_vm._v("默认值：")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#607FFF"
                                                          }
                                                        },
                                                        [
                                                          row.data[
                                                            columnIndex - 1
                                                          ].defaultIsDynamic
                                                            ? [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      row.data[
                                                                        columnIndex -
                                                                          1
                                                                      ]
                                                                        .defaultDynamicEntry
                                                                    ) +
                                                                    " | " +
                                                                    _vm._s(
                                                                      row.data[
                                                                        columnIndex -
                                                                          1
                                                                      ]
                                                                        .defaultDynamicItem
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            : [
                                                                [2, 3].includes(
                                                                  row.data[
                                                                    columnIndex -
                                                                      1
                                                                  ]
                                                                    .parameterType
                                                                )
                                                                  ? _c(
                                                                      "el-select",
                                                                      {
                                                                        attrs: {
                                                                          size:
                                                                            "small",
                                                                          disabled:
                                                                            _vm.readOnly ||
                                                                            _vm.noModifyOrAdd,
                                                                          filterable:
                                                                            "",
                                                                          multiple:
                                                                            row
                                                                              .data[
                                                                              columnIndex -
                                                                                1
                                                                            ]
                                                                              .parameterType ===
                                                                            3,
                                                                          placeholder:
                                                                            "请输入默认值"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            row
                                                                              .data[
                                                                              columnIndex -
                                                                                1
                                                                            ]
                                                                              .defaultValue,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              row
                                                                                .data[
                                                                                columnIndex -
                                                                                  1
                                                                              ],
                                                                              "defaultValue",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "row.data[columnIndex - 1].defaultValue"
                                                                        }
                                                                      },
                                                                      _vm._l(
                                                                        row
                                                                          .data[
                                                                          columnIndex -
                                                                            1
                                                                        ]
                                                                          .possibleValue,
                                                                        function(
                                                                          item,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: index,
                                                                              attrs: {
                                                                                label: item,
                                                                                value: item
                                                                              }
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                [4].includes(
                                                                  row.data[
                                                                    columnIndex -
                                                                      1
                                                                  ]
                                                                    .parameterType
                                                                )
                                                                  ? _c(
                                                                      "el-select",
                                                                      {
                                                                        attrs: {
                                                                          size:
                                                                            "small",
                                                                          disabled:
                                                                            _vm.readOnly ||
                                                                            _vm.noModifyOrAdd,
                                                                          filterable:
                                                                            "",
                                                                          multiple:
                                                                            row
                                                                              .data[
                                                                              columnIndex -
                                                                                1
                                                                            ]
                                                                              .parameterType ===
                                                                            3,
                                                                          placeholder:
                                                                            "请输入默认值"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            row
                                                                              .data[
                                                                              columnIndex -
                                                                                1
                                                                            ]
                                                                              .defaultValue,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              row
                                                                                .data[
                                                                                columnIndex -
                                                                                  1
                                                                              ],
                                                                              "defaultValue",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "row.data[columnIndex - 1].defaultValue"
                                                                        }
                                                                      },
                                                                      _vm._l(
                                                                        _vm.isQualityValue,
                                                                        function(
                                                                          item,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "el-option",
                                                                            {
                                                                              key: index,
                                                                              attrs: {
                                                                                label: item,
                                                                                value: item
                                                                              }
                                                                            }
                                                                          )
                                                                        }
                                                                      ),
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                [1].includes(
                                                                  row.data[
                                                                    columnIndex -
                                                                      1
                                                                  ]
                                                                    .parameterType
                                                                )
                                                                  ? _c(
                                                                      "el-input",
                                                                      {
                                                                        staticStyle: {
                                                                          width:
                                                                            "100%"
                                                                        },
                                                                        attrs: {
                                                                          type:
                                                                            "textarea",
                                                                          size:
                                                                            "small",
                                                                          disabled:
                                                                            _vm.readOnly ||
                                                                            _vm.noModifyOrAdd,
                                                                          placeholder:
                                                                            "请输入默认值"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            row
                                                                              .data[
                                                                              columnIndex -
                                                                                1
                                                                            ]
                                                                              .defaultValue,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              row
                                                                                .data[
                                                                                columnIndex -
                                                                                  1
                                                                              ],
                                                                              "defaultValue",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "row.data[columnIndex - 1].defaultValue"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                [0].includes(
                                                                  row.data[
                                                                    columnIndex -
                                                                      1
                                                                  ]
                                                                    .parameterType
                                                                )
                                                                  ? _c(
                                                                      "el-input-number",
                                                                      {
                                                                        staticStyle: {
                                                                          width:
                                                                            "100%"
                                                                        },
                                                                        attrs: {
                                                                          size:
                                                                            "small",
                                                                          disabled:
                                                                            _vm.readOnly ||
                                                                            _vm.noModifyOrAdd,
                                                                          "controls-position":
                                                                            "right",
                                                                          placeholder:
                                                                            "请输入默认值"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            row
                                                                              .data[
                                                                              columnIndex -
                                                                                1
                                                                            ]
                                                                              .defaultValue,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              row
                                                                                .data[
                                                                                columnIndex -
                                                                                  1
                                                                              ],
                                                                              "defaultValue",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "row.data[columnIndex - 1].defaultValue"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                                [6].includes(
                                                                  row.data[
                                                                    columnIndex -
                                                                      1
                                                                  ]
                                                                    .parameterType
                                                                )
                                                                  ? _c(
                                                                      "el-date-picker",
                                                                      {
                                                                        staticStyle: {
                                                                          width:
                                                                            "100%"
                                                                        },
                                                                        attrs: {
                                                                          size:
                                                                            "small",
                                                                          disabled:
                                                                            _vm.readOnly ||
                                                                            _vm.noModifyOrAdd,
                                                                          "controls-position":
                                                                            "right",
                                                                          type:
                                                                            "datetime",
                                                                          "value-format":
                                                                            "yyyy-MM-dd HH:mm:ss",
                                                                          format:
                                                                            "yyyy-MM-dd HH:mm:ss",
                                                                          placeholder:
                                                                            "选择日期时间"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            row
                                                                              .data[
                                                                              columnIndex -
                                                                                1
                                                                            ]
                                                                              .defaultValue,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              row
                                                                                .data[
                                                                                columnIndex -
                                                                                  1
                                                                              ],
                                                                              "defaultValue",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "row.data[columnIndex - 1].defaultValue"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ])
                                          : _vm._e(),
                                        row["检验项"] === "检验方法"
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  row.data[columnIndex - 1]
                                                    .inspectionMethod
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        row["检验项"] === "检验标准"
                                          ? _c(
                                              "div",
                                              [
                                                row.data[columnIndex - 1]
                                                  .standardIsDynamic
                                                  ? [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.data[
                                                              columnIndex - 1
                                                            ]
                                                              .standardDynamicEntry
                                                          ) +
                                                          " | " +
                                                          _vm._s(
                                                            row.data[
                                                              columnIndex - 1
                                                            ]
                                                              .standardDynamicItem
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  : [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.data[
                                                              columnIndex - 1
                                                            ].inspectionStandard
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        row["检验项"] === "标准值"
                                          ? _c(
                                              "div",
                                              [
                                                (![undefined, null].includes(
                                                  row.data[columnIndex - 1]
                                                    .standardValue
                                                ) ||
                                                  !_vm.readOnly) &&
                                                row.data[columnIndex - 1]
                                                  .parameterType === 0
                                                  ? _c(
                                                      "el-row",
                                                      { attrs: { gutter: 24 } },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 }
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%"
                                                                },
                                                                attrs: {
                                                                  size: "small",
                                                                  disabled:
                                                                    _vm.readOnly,
                                                                  "controls-position":
                                                                    "right"
                                                                },
                                                                model: {
                                                                  value:
                                                                    card
                                                                      .inspectionItemList[0]
                                                                      .data[
                                                                      columnIndex -
                                                                        1
                                                                    ]
                                                                      .standardValue,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      card
                                                                        .inspectionItemList[0]
                                                                        .data[
                                                                        columnIndex -
                                                                          1
                                                                      ],
                                                                      "standardValue",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "card.inspectionItemList[0].data[columnIndex - 1].standardValue"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : row.data[columnIndex - 1]
                                                      .parameterType !== 0
                                                  ? _c("div", {
                                                      staticClass: "td-block"
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row["检验项"] === "是否必填"
                                          ? _c(
                                              "div",
                                              { staticClass: "td-block" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.data[columnIndex - 1]
                                                        .required
                                                        ? "必填"
                                                        : "非必填"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        row["检验项"] === "上偏差"
                                          ? _c(
                                              "div",
                                              [
                                                (![undefined, null].includes(
                                                  row.data[columnIndex - 1]
                                                    .upperLimit
                                                ) ||
                                                  !_vm.readOnly) &&
                                                row.data[columnIndex - 1]
                                                  .parameterType === 0
                                                  ? _c(
                                                      "el-row",
                                                      { attrs: { gutter: 24 } },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 }
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%"
                                                                },
                                                                attrs: {
                                                                  size: "small",
                                                                  min: 0,
                                                                  disabled:
                                                                    _vm.readOnly,
                                                                  "controls-position":
                                                                    "right"
                                                                },
                                                                model: {
                                                                  value:
                                                                    card
                                                                      .inspectionItemList[0]
                                                                      .data[
                                                                      columnIndex -
                                                                        1
                                                                    ]
                                                                      .upperLimit,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      card
                                                                        .inspectionItemList[0]
                                                                        .data[
                                                                        columnIndex -
                                                                          1
                                                                      ],
                                                                      "upperLimit",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "card.inspectionItemList[0].data[columnIndex - 1].upperLimit"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 }
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%"
                                                                },
                                                                attrs: {
                                                                  size: "small",
                                                                  disabled:
                                                                    _vm.readOnly,
                                                                  placeholder:
                                                                    "请选择"
                                                                },
                                                                model: {
                                                                  value:
                                                                    card
                                                                      .inspectionItemList[0]
                                                                      .data[
                                                                      columnIndex -
                                                                        1
                                                                    ].limitType,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      card
                                                                        .inspectionItemList[0]
                                                                        .data[
                                                                        columnIndex -
                                                                          1
                                                                      ],
                                                                      "limitType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "card.inspectionItemList[0].data[columnIndex - 1].limitType"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-option",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "数值",
                                                                      value: 1
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "el-option",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "百分比",
                                                                      value: 0
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : row.data[columnIndex - 1]
                                                      .parameterType !== 0
                                                  ? _c("div", {
                                                      staticClass: "td-block"
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row["检验项"] === "下偏差"
                                          ? _c(
                                              "div",
                                              [
                                                (![undefined, null].includes(
                                                  row.data[columnIndex - 1]
                                                    .standardValue
                                                ) ||
                                                  !_vm.readOnly) &&
                                                row.data[columnIndex - 1]
                                                  .parameterType === 0
                                                  ? _c(
                                                      "el-row",
                                                      { attrs: { gutter: 24 } },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 }
                                                          },
                                                          [
                                                            _c(
                                                              "el-input-number",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%"
                                                                },
                                                                attrs: {
                                                                  size: "small",
                                                                  min: 0,
                                                                  disabled:
                                                                    _vm.readOnly,
                                                                  "controls-position":
                                                                    "right"
                                                                },
                                                                model: {
                                                                  value:
                                                                    card
                                                                      .inspectionItemList[0]
                                                                      .data[
                                                                      columnIndex -
                                                                        1
                                                                    ]
                                                                      .lowerLimit,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      card
                                                                        .inspectionItemList[0]
                                                                        .data[
                                                                        columnIndex -
                                                                          1
                                                                      ],
                                                                      "lowerLimit",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "card.inspectionItemList[0].data[columnIndex - 1].lowerLimit"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 12 }
                                                          },
                                                          [
                                                            _c(
                                                              "el-select",
                                                              {
                                                                staticStyle: {
                                                                  width: "100%"
                                                                },
                                                                attrs: {
                                                                  size: "small",
                                                                  disabled:
                                                                    _vm.readOnly,
                                                                  placeholder:
                                                                    "请选择"
                                                                },
                                                                model: {
                                                                  value:
                                                                    card
                                                                      .inspectionItemList[0]
                                                                      .data[
                                                                      columnIndex -
                                                                        1
                                                                    ].limitType,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      card
                                                                        .inspectionItemList[0]
                                                                        .data[
                                                                        columnIndex -
                                                                          1
                                                                      ],
                                                                      "limitType",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "card.inspectionItemList[0].data[columnIndex - 1].limitType"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "el-option",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "数值",
                                                                      value: 1
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "el-option",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "百分比",
                                                                      value: 0
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : row.data[columnIndex - 1]
                                                      .parameterType !== 0
                                                  ? _c("div", {
                                                      staticClass: "td-block"
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        row["检验项"] === "附件"
                                          ? _c(
                                              "div",
                                              _vm._l(
                                                _vm.getArray(
                                                  row.data[columnIndex - 1]
                                                    .attachments
                                                ),
                                                function(at, index) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticStyle: {
                                                            color: "#607FFF"
                                                          },
                                                          attrs: {
                                                            href: at.url,
                                                            target: "_blank"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(at.name)
                                                          )
                                                        ]
                                                      ),
                                                      _c("img", {
                                                        staticStyle: {
                                                          width: "12px",
                                                          "margin-left": "5px"
                                                        },
                                                        attrs: {
                                                          src: require("@/assets/quality/路径@2x.png"),
                                                          alt: ""
                                                        }
                                                      })
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                        row["检验项"] === "备注"
                                          ? _c("div", [
                                              _vm._v(
                                                _vm._s(
                                                  row.data[columnIndex - 1]
                                                    .remark
                                                )
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      }),
      _c(
        "div",
        { staticClass: "attachment", staticStyle: { "padding-top": "20px" } },
        [
          _vm.fileList.length || !_vm.readOnly
            ? _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "padding-left": "10px",
                        "white-space": "nowrap"
                      }
                    },
                    [_vm._v("质检附件：")]
                  ),
                  !_vm.readOnly
                    ? _c(
                        "el-upload",
                        _vm._b(
                          {
                            ref: "upload",
                            attrs: {
                              "file-list": _vm.fileList,
                              disabled: _vm.readOnly
                            }
                          },
                          "el-upload",
                          _vm.uploadProps,
                          false
                        ),
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "icons",
                              staticStyle: { padding: "0" },
                              attrs: { type: "text", icon: "el-icon-upload" }
                            },
                            [_vm._v("上传文件")]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        _vm._l(_vm.fileList, function(row, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "files",
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                                "align-items": "center",
                                width: "400px"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "ellipsis",
                                  staticStyle: { "max-width": "300px" }
                                },
                                [_vm._v(_vm._s(row.name))]
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "0" },
                                      attrs: { type: "text" }
                                    },
                                    [
                                      !_vm.isImageFile(row.url)
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: row.url,
                                                target: "_blank"
                                              }
                                            },
                                            [_vm._v("查看")]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.viewPicture(row)
                                                }
                                              }
                                            },
                                            [_vm._v("查看")]
                                          ),
                                      _c("el-image", {
                                        ref: "imgViewRef",
                                        refInFor: true,
                                        staticStyle: {
                                          width: "0px",
                                          height: "0px"
                                        },
                                        attrs: {
                                          src: _vm.imgList[0],
                                          "preview-src-list": _vm.imgList,
                                          fit: "fill"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c("el-divider", {
                                    attrs: { direction: "vertical" }
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "0" },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleDownload(row)
                                        }
                                      }
                                    },
                                    [_vm._v(" 下载 ")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        0
                      )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "padding-top": "20px"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "required-div",
                  staticStyle: { "white-space": "nowrap" }
                },
                [_vm._v("检验人员：")]
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    size: "small",
                    filterable: "",
                    disabled: _vm.readOnly,
                    multiple: true,
                    placeholder: "请选择检验人员"
                  },
                  model: {
                    value: _vm.inspectionUserList,
                    callback: function($$v) {
                      _vm.inspectionUserList = $$v
                    },
                    expression: "inspectionUserList"
                  }
                },
                _vm._l(_vm.formOptions.userList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "padding-top": "20px"
              }
            },
            [
              _c("div", { staticClass: "required-div" }, [
                _vm._v("是否开启审核：")
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.readOnly },
                  on: { change: _vm.changeCheckedValue },
                  model: {
                    value: _vm.checkAndApproveInfo.isCheck,
                    callback: function($$v) {
                      _vm.$set(_vm.checkAndApproveInfo, "isCheck", $$v)
                    },
                    expression: "checkAndApproveInfo.isCheck"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          ),
          _vm.checkAndApproveInfo.isCheck
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "padding-top": "16px",
                    "padding-left": "8px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "required-div",
                      staticStyle: { "white-space": "nowrap" }
                    },
                    [_vm._v("审核人员：")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "small",
                        filterable: "",
                        disabled: _vm.readOnly,
                        multiple: true,
                        placeholder: "请选择审核人员"
                      },
                      model: {
                        value: _vm.checkAndApproveInfo.checkedIDList,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.checkAndApproveInfo,
                            "checkedIDList",
                            $$v
                          )
                        },
                        expression: "checkAndApproveInfo.checkedIDList"
                      }
                    },
                    _vm._l(_vm.formOptions.userList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "padding-top": "20px"
              }
            },
            [
              _c("div", { staticClass: "required-div" }, [
                _vm._v("是否开启审批：")
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.readOnly },
                  on: { change: _vm.changeApproverValue },
                  model: {
                    value: _vm.checkAndApproveInfo.isApproved,
                    callback: function($$v) {
                      _vm.$set(_vm.checkAndApproveInfo, "isApproved", $$v)
                    },
                    expression: "checkAndApproveInfo.isApproved"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")])
                ],
                1
              )
            ],
            1
          ),
          _vm.checkAndApproveInfo.isApproved
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "padding-top": "16px",
                    "padding-left": "8px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "required-div",
                      staticStyle: { "white-space": "nowrap" }
                    },
                    [_vm._v("审批人员：")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        size: "small",
                        filterable: "",
                        disabled: _vm.readOnly,
                        multiple: true,
                        placeholder: "请选择审批人员"
                      },
                      model: {
                        value: _vm.checkAndApproveInfo.approverIdList,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.checkAndApproveInfo,
                            "approverIdList",
                            $$v
                          )
                        },
                        expression: "checkAndApproveInfo.approverIdList"
                      }
                    },
                    _vm._l(_vm.formOptions.userList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "选择检验项" },
          on: { onOk: _vm.submitItem },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.inspectionItemColumns,
              height: _vm.height,
              data: _vm.inspectionItemList,
              "show-page": false,
              "columns-setting": false
            },
            on: { "selection-change": _vm.inspectionItemChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "inspectionEntryName",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(row.inspectionEntryName))
                  ])
                }
              },
              {
                key: "parameterType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      _vm._s(_vm._f("newParameterTypeName")(row.parameterType))
                    )
                  ])
                }
              },
              {
                key: "defaultValue",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm.getDPValue(row.defaultValue)))
                  ])
                }
              },
              {
                key: "possibleValue",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(_vm.getDPValue(row.possibleValue)))
                  ])
                }
              },
              {
                key: "isEnabled",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", { staticClass: "is-enabled" }, [
                    _c("div", {
                      style: {
                        background: row.isEnabled ? "#24CAD7" : "#FFCC78"
                      }
                    }),
                    _c("div", [_vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))])
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: {
            title:
              _vm.currentType === "add"
                ? "新增检验项"
                : _vm.currentType === "modify"
                ? "修改检验项"
                : "复制检验项"
          },
          on: { onOk: _vm.submitFormItem },
          model: {
            value: _vm.addItemVisible,
            callback: function($$v) {
              _vm.addItemVisible = $$v
            },
            expression: "addItemVisible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
            },
            [
              _c(
                "el-upload",
                _vm._b(
                  {
                    attrs: { slot: "drawing", "file-list": _vm.itemfileList },
                    slot: "drawing"
                  },
                  "el-upload",
                  _vm.uploadProps2,
                  false
                ),
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary", size: "small" } },
                    [_vm._v("上传附件")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: {
            width: "400px",
            title: _vm.addGroupType === "end" ? "另存检验组" : "新增检验组"
          },
          on: { onOk: _vm.submitGroupForm },
          model: {
            value: _vm.groupVisible,
            callback: function($$v) {
              _vm.groupVisible = $$v
            },
            expression: "groupVisible"
          }
        },
        [
          _c("el-input", {
            staticStyle: { "padding-right": "15px" },
            attrs: { placeholder: "请输入检验组名称" },
            model: {
              value: _vm.formData.inspectionProjectName,
              callback: function($$v) {
                _vm.$set(_vm.formData, "inspectionProjectName", $$v)
              },
              expression: "formData.inspectionProjectName"
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }