/** write by luomingzhi */
import { equipmentType, status } from '@/config/options.config'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'code',
    label: '设备编码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    width: 140,
    form: { index: 1, tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 2, tagName: 'el-input' }
  },
  {
    prop: 'equipmentClassName',
    label: '设备类',
    sortable: false,
    hiddenSearch: true,
    width: 150,
    form: {
      index: 3,
      key: 'equipmentClassId',
      tagName: 'div',
      customSlots: ['equipmentClassId'],
      required: true
    }
    // form: {
    //   index: 3,
    //   tagName: 'el-select', key: 'equipmentClassId', options: [], on: {
    //     change: (val) => {
    //       functionList.equipmentClassChange(val)
    //     }
    //   }
    // }
  },
  {
    prop: 'drawing',
    label: '设备图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'drawing',
    form: {
      index: 17,
      tagName: 'div',
      customSlots: ['drawing'],
      required: false
    }
  },
  {
    prop: 'stationName',
    label: '所属工位',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 6,
      required: false,
      tagName: 'el-select', key: 'stationId', options: [], on: {
        change: (val) => {
          functionList.stationChange(val)
        }
      }
    }
  },
  {
    prop: 'customerName',
    label: '客户',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 16,
      required: false,
      tagName: 'el-select',
      key: 'customerId',
      options: [],
      on: {
        change: (val) => {
          functionList.serviceChange(val)
        }
      }
    }
  },
  {
    prop: 'equipmentClassType',
    label: '设备属性',
    sortable: false,
    hiddenSearch: true,
    slotName: 'equipmentClassType',
    form: { index: 4, tagName: 'el-select', options: equipmentType, props: { placeholder: '请选择设备类', disabled: true }}
  },
  {
    prop: 'buyTime',
    label: '采购日期',
    sortable: 'custom',
    hiddenSearch: true,
    width: 180,
    form: { index: 5, tagName: 'el-date-picker', required: false }
  },
  {
    prop: 'status',
    label: '设备使用状态',
    sortable: false,
    slotName: 'status',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'isEnabled',
    label: '设备状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true,
    form: {
      index: 7,
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 50,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    prop: 'fixedCode',
    label: '固定资产编码',
    sortable: false,
    hiddenSearch: true,
    width: 150,
    form: { index: 8, tagName: 'el-input', required: false }
  },
  {
    prop: 'fixedName',
    label: '资产名称',
    sortable: false,
    hiddenSearch: true,
    form: { index: 9, tagName: 'el-input', required: false }
  },
  {
    prop: 'fixedPosition',
    label: '资产位置',
    sortable: false,
    hiddenSearch: true,
    form: { index: 10, tagName: 'el-input', required: false }
  },
  {
    prop: 'unitType',
    label: '设备型号',
    sortable: false,
    hiddenSearch: true,
    form: { index: 11, tagName: 'el-input' , required: false}
  },
  {
    prop: 'manufacturingNumber',
    label: '出厂编号',
    sortable: false,
    hiddenSearch: true,
    form: { index: 12, tagName: 'el-input' , required: false}
  },
  {
    prop: 'manufacturer',
    label: '制造厂商',
    sortable: false,
    hiddenSearch: true,
    form: { index: 13, tagName: 'el-input' , required: false}
  },
  {
    prop: 'organizationName',
    label: '设备使用部门',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 14,
      required: false,
      tagName: 'el-cascader',
      key: 'organizationId',
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      }
    }
  },
  {
    prop: 'equipmentOwnerName',
    label: '设备责任人',
    sortable: false,
    hiddenSearch: true,
    form: { index: 15, required: false, tagName: 'el-select' , key: 'equipmentOwnerId', options: []}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 175
  }
]
