import { formatColumns } from '@/utils'
import { status,abnormalClassifyCriteria, majorTypeOptions} from '@/config/options.config'
let functionList = {};
export const getFunctionList = (val) => {
  functionList = val;
};
export const CallClassify = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'name',
    label: '异常类型',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', props: { type: 'text' }}
  },
  {
    prop: 'isEnabled',
    label: '异常类型状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true,
    width: 120,
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'isEnabledMaterials',
    label: '是否开启物料',
    sortable: false,
    slotName: 'isEnabledMaterials',
    hiddenSearch: true,
    width: 120,
    form: {
      tagName: 'el-select',
      options: [
        {
          id:1,
          name:'开启'
        },
        {
          id:0,
          name:'关闭'
        }],
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 190
  }
]
export const JudgSettings = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'type',
    label: '异常分类标准',
    sortable: false,
    hiddenSearch: true,
    slotName:'type',
    form: {
      tagName: 'el-select',
      options: abnormalClassifyCriteria,
      on: {
        change: (val) => {
          functionList.typeChange(val);
        },
      },
    }
  },
  {
    prop: 'classifyName',
    label: '异常分类',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-select',options: [],key:'classifyId'
    }
  },
  {
    prop: 'majorType',
    label: '专业',
    sortable: false,
    hiddenSearch: true,
    slotName:'majorType',
    form: {
      tagName: 'el-select',
      options: majorTypeOptions
    }
  },
  {
    prop: 'users',
    label: '判定人',
    sortable: false,
    hiddenSearch: true,
    slotName: 'users'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 190
  }
]

export const CallReason = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'code',
    label: '原因编码',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', props: { type: 'text' }}
  },
  {
    prop: 'name',
    label: '原因名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', props: { type: 'text' }}
  },
  {
    prop: 'abnormalTypeId',
    label: '关联异常类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'abnormalTypeId',
    form: {
      tagName: 'el-select',
      options: []
    }
  },
  {
    prop: 'isEnabled',
    label: '异常原因状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true,
    width: 120,
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 190
  }
]

export default CallClassify
