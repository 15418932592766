<template>
  <div class="inventory-account-container">
    <div class="top">
      <SearchForm ref="searchForm" :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" >
      <SelectMultipleMaterialsNew
          slot="materialsM"
          ref="multipleMaterials"
          @selectChange="
            (key, val) => {
              handleSelect('materialsId', val && val.id)
            }
          "
        />
      </SearchForm>
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入库存记录单号"
        :tags="functionHeader.tags"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :import-name="functionHeader.importName"
        :show-export = "!exportSelectionIds.length ? true : false"
        style="margin-left: 0px"
        @search="$refs.mTable.setTableData(true)"
      >
        <el-button v-if="this.permission('InWarehouse') && !exportSelectionIds.length && !memberCodeList.includes(memberCode)" type="primary" size="small" @click="inWarehouse">入库</el-button>
        <el-button v-if="this.permission('OutWarehouse') && !exportSelectionIds.length && !memberCodeList.includes(memberCode)" type="primary" size="small" @click="outWarehouse">出库</el-button>


        <el-button v-if="this.permission('Allocation') && !exportSelectionIds.length && !memberCodeList.includes(memberCode)" type="primary" size="small" @click="allOperate(2)">调拨</el-button>
        <el-button v-if="this.permission('TransferWarehouse') && !exportSelectionIds.length && !memberCodeList.includes(memberCode)" type="primary" size="small" @click="allOperate(3)">移库</el-button>
        <el-button v-if="this.permission('WarehouseInventory') && !exportSelectionIds.length && !memberCodeList.includes(memberCode)" type="primary" size="small" @click="allOperate(4)">仓库盘点</el-button>
        <el-button v-if="this.permission('MaterialInventory') && !exportSelectionIds.length && !memberCodeList.includes(memberCode)" type="primary" size="small" @click="allOperate(5)">物料盘点</el-button>

        <el-button type="primary" size="small" v-if="exportSelectionIds.length" @click="batchExport">批量导出</el-button>
        <span v-if="exportSelectionIds.length" style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;
        line-height:32px;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
            / {{ total }}项
        </span>
        <el-button type="text" v-if="exportSelectionIds.length" size="small" @click="resetSelectData">清空</el-button>

        <div slot="all" style="display: flex; justify-content: flex-start; align-items: center">
          <!-- <el-dropdown size="small" split-button class="all-operator" @command="allOperate">
            <span class="el-dropdown-link">其他操作</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="permission('Allocation')" command="2">调拨</el-dropdown-item>
              <el-dropdown-item v-if="permission('TransferWarehouse')" command="3">移库</el-dropdown-item>
              <el-dropdown-item v-if="permission('WarehouseInventory')" command="4">仓库盘点</el-dropdown-item>
              <el-dropdown-item v-if="permission('MaterialInventory')" command="5">物料盘点</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </FunctionHeader>
      <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <el-button slot="number" slot-scope="{ row }" style="white-space: pre-wrap" type="text" @click="viewDetail(row)">{{ row.number }}</el-button>
        <div slot="type" slot-scope="{ row }" style="white-space: pre-wrap">{{  typeList[row.type] }}</div>
        <div slot="action" slot-scope="{ row }">
          <template v-if="([0].includes(+row.type) && !['撤回报工', '生产入库'].includes(row.outInWarehouseTeypeName)) || ([1].includes(+row.type) && !['生产领料', '生产投料'].includes(row.outInWarehouseTeypeName))">
            <el-button v-if="permission('Modify') && !memberCodeList.includes(memberCode) " type="text" @click="modifyRow(row)">修改</el-button>
            <el-divider v-if="permission('Modify') && !memberCodeList.includes(memberCode) " direction="vertical" />
            <el-button v-if="permission('Del')" type="text" :disabled="memberCodeList.includes(memberCode)" @click="delRow(row)">删除</el-button>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </div>
      </MTable>
    </div>
  </div>
</template>

<script>
import conventionMixin from '@/mixin/conventional-page'
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import columns from './columns'
import { searchFormList } from './form-list'
import api from '@/api/xiewei/spare-part/warehouse'
import SelectMultipleMaterialsNew from '@/components/SelectMultipleMaterialsNew/SelectMultipleMaterialsNew'
import { warehouseRecordType } from '@/config/options.sales'
import userApi from '@/api/sets/user/user'
import warehouseApi from '@/api/information/warehouse/model'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { export_json_to_excel2 } from '@/utils/plug/to-excel'
import { warehouseRecordTypeAll, yyhlWarehouseRecordTypeAll} from '@/config/options.sales'

export default {
  name: 'WarehouseRecord',
  components: { SelectMultipleMaterialsNew, SearchForm, FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'WarehouseOperationSheet'
      },
      columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [],
        exportName: 'exportWarehouseOperationSheet',
        exportParams: {}
      },
      inOrOutType: '',
      type: null,
      selectData: [],
      searchKeyword: true,
      extraParams: [],
      typeList: ['入库', '出库', '调拨', '移库', '仓库盘点', '物料盘点'],
      exportSelectionIds: [],
      isPageLoaded:false,
      memberCodeList: ['yyhl']
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.exportSelectionIds.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    },
    height() {
      return this.$store.state.app.contentHeight - 235 - 20
    }
  },
  activated(){
  if(!this.isPageLoaded){
    this.$refs.mTable.setTableData()
  }
  this.isPageLoaded = false
  },
  mounted() {
    this.isPageLoaded = true
    this.getWarehouseList()
    this.getWarehouseRecordType()
  },
  methods: {
    resetSelectData() {
      this.selectData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectData = this.selectData.filter((item) => item.selectable)
    },
    batchExport() {
      if (this.exportSelectionIds.length === 0) {
        return this.$message.error('请至少选择一项')
      }
      this.$refs.functionHeader.export({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'id',
              fieldType: 'number',
              fieldValue: this.exportSelectionIds.join('^'),
              operator: 'in'
            }
          ])
        )
      })
    },
    modifyRow(row) {
      this.$router.push({ name: 'warehouseOperate', query: { id: row.id, type: 'modify', operateType: row.type }})
    },
    // 查看详情
    viewDetail(row) {
      this.$router.push({
        name: 'warehouseOperateDetail',
        query: { id: row.id, number: row.number, type: 'view', operateType: row.type }
      })
    },
    delRow(row) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delWarehouseOperationSheet({ id: row.id, versions: row.versions })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    async getWarehouseList() {
      const res = await warehouseApi.getRepositoryModelList({
        searchVal: Encrypt(
          JSON.stringify([
            ...this.defaultSearch,
            {
              id: getUUid(),
              fieldName: 'type',
              fieldType: 'number',
              fieldValue: 0,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.searchFormList[2].children[0].options = res
      }
    },
    async getWarehouseRecordType() {
      if (this.memberCode === 'yyhl') {
        this.searchFormList[0].children[0].options = yyhlWarehouseRecordTypeAll
      } else {
        this.searchFormList[0].children[0].options = warehouseRecordTypeAll
      }

    },
    getType(row) {
      const current = this.inOrOutTypeList[row.recordType].find(item => row.type === item.id)
      return current && current.name
    },
    selectionChange(data) {
      this.selectData = data
      this.exportSelectionIds = data.map(item => item.id)
    },
    searchTable(data, type) {
      if (type === 1) {
        this.extraParams = []
        this.materialsId = null
        this.$refs.multipleMaterials.keyword = ''
      }
      this.searchFormatData = data
      console.log('ceshisou', this.searchFormatData)
      this.$refs.mTable.setTableData(true)
    },
    inWarehouse() {
      this.$router.push({ name: 'warehouseOperate', query: { type: 'add', operateType: 0 }})
    },
    outWarehouse() {
      this.$router.push({ name: 'warehouseOperate', query: { type: 'add', operateType: 1 }})
    },
    allOperate(val) {
      this.$router.push({ name: 'warehouseOperate', query: { type: 'add', operateType: val }})
    },
    handleSelect(key, val) {
      console.log(val)
      if (val || val === 0) {
        this.materialsId = val
      }else{
        this.materialsId=null
      }

      this.$refs.mTable.setTableData(true)
    },
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.inventory-account-container {
  width: 100%;

  .bottom {
    padding: 10px;
  }
}
::v-deep .customer-content div {
  width: 100%;
}
::v-deep .customer-content2 div:first-child {
  display: block !important;
}
.input-with-select {
  width: 100%;

  .input-select {
    width: 105px !important;
  }
}
::v-deep {
  .el-input-group {
    width: 100%;

    .el-input-group__prepend {
      width: 105px;
    }
  }
}
::v-deep {
  .e-divider {
    margin: 0px 0 0 0;
  }
}
</style>
