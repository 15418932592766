<template>
    <div class="inventory-wrap common-tab">
      <el-radio-group
        v-model="params.radio"
        class="m-radio-group"
        @change="radioChange"
      >
        <el-radio-button label="ReceivedMaterialsOrderItem">收料单物料行</el-radio-button>
        <!-- <el-radio-button label="Inventory">仓库汇总</el-radio-button> -->
      </el-radio-group>
      <div class="bottom">

            <div class="materials-info">
              <div v-show="searchFormList[params.radio]">
                <SearchForm
                  ref="searchForm"
                  :form-list-extend="searchFormList[params.radio]"
                  :form-data="searchFormData[params.radio]"
                  :searchType='1'
                  @update:formData="data=>searchFormData[params.radio]=data"
                  @search="searchTable"
                />
              </div>
              <div class="bottom">
                <FunctionHeader
                  ref="functionHeader"
                  v-model="functionHeader.searchVal"
                  :search-title="functionHeader.searchTitle[params.radio]"
                  :radio="params.radio"
                  :columns-key="columnsKey"
                  :export-name="functionHeader.exportName"
                  :export-params="functionHeader.exportParams"
                  :show-export="!selectTableData.length ? true : false"
                  :columns="columns[params.radio]"
                  @search="$refs.mTable.setTableData(true)"
                >
                  <el-button
                    type="primary"
                    size="small"
                    v-if="selectTableData.length && permission('ReceivedMaterialsOrderItemExport')"
                    @click="batchExportFront"
                  >
                    批量导出
                  </el-button>
                  <span v-if="selectTableData.length" style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;
                  line-height:32px;">
                    已选
                    <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                      / {{ total }}项
                  </span>
                  <el-button type="text" v-if="selectTableData.length" size="small" @click="resetSelectData">清空</el-button>

                  <div
                    slot="all"
                    style="
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                    "
                  >
                  </div>
                </FunctionHeader>
                <MTable
                  ref="mTable"
                  :columns="columns[params.radio]"
                  :height="height"
                  :set-data-method="getTableData"
                  :columns-key="columnsKey"
                  @selection-change="handleSelectionChange"
                >

                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                  <div slot="status" slot-scope="{ row }">
                    {{ receiveStatus[row.status].name }}
                  </div>
                  <div slot="checkStatus" slot-scope="{ row }">
                    {{ receiveInspectionStatus[row.checkStatus].name }}
                  </div>
                  <div slot="settingVariable" slot-scope="{ row }">
                    {{ row.settingVariable === 0 ? '否' : '是'}}
                  </div>
                  <div slot="sequenceNumber" slot-scope="{row }">
                    <el-tooltip effect="dark" popper-class="sequence-tooltip" :content="row.sequenceNumber" placement="top-start">
                      <span class="mr-10">
                        {{ row.sequenceNumber }}
                    </span>
                    </el-tooltip>
                  </div>
                <div slot="returnedSequenceNumber" slot-scope="{row }">
                  <el-tooltip effect="dark" popper-class="sequence-tooltip" :content="row.returnedSequenceNumber" placement="top-start">
                    <span class="mr-10">
                      {{ row.returnedSequenceNumber }}
                  </span>
                  </el-tooltip>
                </div>
                  <div slot="action" slot-scope="{ row }">
                  <el-button v-if="permission('EditVariable')" type="text" @click="editSettingVariable(row,0)">编辑设变件标识</el-button>
                  <el-button v-if="permission('EditSupplier')" type="text" @click="editSettingVariable(row,1)">编辑供应商标识</el-button>
                  <el-divider v-if="permission('EditSupplier')" direction="vertical" />
                  <el-dropdown @command="(command) => { more(row, command) }">
                    <span class="el-dropdown-link">
                      更多<i class="el-icon-arrow-down el-icon--right" />
                    </span>
                    <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                      <el-dropdown-item v-if="permission('Print')" command="printCode">打印批次码</el-dropdown-item>
                      <el-dropdown-item v-if="permission('Print')" command="printCodes">打印序列号</el-dropdown-item>
                      <el-dropdown-item v-if="permission('Del')&&[0,1].includes(row.checkStatus)" type="text" command="del">删除</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                </MTable>
              </div>
            </div>
      </div>
      <MDialog v-model="variableVisible" title="编辑" @onOk="submitVariableForm">
          <div style="margin-bottom: 10px;">批次码：{{ formData.batchNumber }}</div>
          <div>是否设变件：
            <el-radio-group
            v-model="formData.settingVariable"
          >
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
           </div>
    </MDialog>
    <MDialog v-model="supplierVisible" title="编辑" @onOk="submitSupplierForm">
          <div style="margin-bottom: 10px;">收料单号：{{ formData.code }}</div>
          <div style="margin-bottom: 10px;">采购单：
            <el-select v-model="formData.purchaseOrderId" placeholder="请选择" clearable @change="changepurchaseOrderId">
              <el-option
                v-for="item in purchaseOrderList"
                :key="item.id"
                :label="item.code"
                :value="item.id"
              />
            </el-select>
           </div>
           <div>供应商：
            <el-select v-model="formData.supplierId" placeholder="请选择" clearable>
              <el-option
                v-for="item in supplierList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
           </div>
    </MDialog>
    <PrintCode ref="printCode" :common-id="commonId" :sequenceNumberType="type"/>
    </div>
  </template>

  <script>
  import SearchForm from '@/components/SearchForm/SearchForm'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import {
    searchFormListDetail
  } from './form-list'
  import {
    columnsDetail
  } from './columns'
  import api from '@/api/warehouse/receive'
  import { receiveStatus, receiveInspectionStatus } from '@/config/options.config'
  import papi from '@/api/projectManagement/project'
  import supplierApi from '@/api/information/unit'
  import PrintCode from '@/components/PrintCode/printSequence'

  export default {
    name: 'receiveMaterialList',
    components: {
      FunctionHeader,
      SearchForm,
      PrintCode
    },
    data() {
      return {
        api,
        total:0,
        commonId: 'print',
        params: {
          radio: 'ReceivedMaterialsOrderItem'
        },
        supplierVisible:false,
        variableVisible:false,
        receiveStatus,
        receiveInspectionStatus,
        functionHeader: {
          searchVal: '',
          exportName: 'exportReceivedMaterialsOrderItem',
          searchTitle: {
            ReceivedMaterialsOrderItem: '请输入收料单号/采购单号/供应商',
            // Materials: '请输入物料编码/名称/规格/批次码''
          },
          exportParams: {}
        },
        functionHeader2: {
          searchVal: ''
        },
        columns: {
          ReceivedMaterialsOrderItem: columnsDetail
        },
        searchFormList: {
          ReceivedMaterialsOrderItem: searchFormListDetail
        },
        searchFormData: {
          Inventory: { isShowZero: 0 },
          ReceivedMaterialsOrderItem: {}
        },
        searchFormatData: {
          Inventory: [],
          ReceivedMaterialsOrderItem: []
        },
        selectTableData: [],
        rowId:'',
        settingVariable:-1,
        formData:{
          purchaseOrderId:'',
          supplierId:''
        },
        purchaseOrderList:[],
        supplierList:[],
        type:'0'
      }
    },
    computed: {
      getSelectionDataLength() {
        const selectionDataNoDisabled = this.selectTableData.filter((item) => !item.selectable)
        return selectionDataNoDisabled.length
      },
      height() {
        const height = {
          ReceivedMaterialsOrderItem: 44,
        }[this.params.radio]
        return this.$store.state.app.contentHeight - 235 - 95 - height
      },
      columnsKey() {
        return this.$route.name + this.params.radio
      }
    },
    watch: {
    },
    mounted() {
      this.getUserList()
      this.getSupplierList()
      this.getPurchaseOrderList()
    },
    methods: {
      more(row,command) {
      const functions = {
        printCode: () => { this.printCode(row) },
        printCodes: () => { this.printCodes(row) },
        del: () => { this.del(row) },
      }
      functions[command]()
    },
      printCode(row) {
        this.type = '0'
      this.commonId = `${row.batchNumber}print`
      this.$refs.printCode.print(row)
    },
    printCodes(row) {
      this.type = '1'
      let printCodess = row.sequenceNumber ? row.sequenceNumber.split(",") : []
      let newArray = []

      printCodess.map((item) => {
      newArray.push({
        ...row,
        batchNumber:item
      })
  })
      this.commonId = `${newArray[0]}print`
      this.$refs.printCode.batchPrint(newArray)
    },
    // 获取负责人
    async getUserList() {
      const res = await papi.getResponsible()
      if (res) {
        this.searchFormList.ReceivedMaterialsOrderItem[2].children[0].options = res
      }
    },
    // 采购单
    async getPurchaseOrderList() {
      const res = await api.getPurchaseOrderList()
      if (res) {
        this.purchaseOrderList = res
      }
    },
      // 供应商
      async getSupplierList() {
      const res = await supplierApi.getSupplierList()
      if (res) {
        this.supplierList = res
      }
    },
    changepurchaseOrderId(val){
      const targetItem = this.purchaseOrderList.find(item => item.id === val)
      if(targetItem){
        this.formData.supplierId = targetItem.supplierId
      }
    },
      resetSelectData() {
        this.selectTableData.forEach(item => {
          if (!item.selectable) {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
          }
        })
        this.selectTableData = this.selectTableData.filter((item) => item.selectable)
      },
      async getTableData(condition, callback) {
        const { page, search, order } = condition
        const data = Object.assign({}, ...this.searchFormatData[this.params.radio] )
        const { finishTimeStart } = this.searchFormData[this.params.radio]
        const searchCondition = {
          ...data,
          finishTimeStart: finishTimeStart ? finishTimeStart[0] : null,
          finishTimeEnd: finishTimeStart ? finishTimeStart[1] : null,
          searchKeyword: this.functionHeader.searchVal
        }
        this.functionHeader.exportParams = searchCondition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          limit: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      },
      editSettingVariable(row,type){
        this.formData = this._.cloneDeep(row)
        this.formData.purchaseOrderId = this.formData.purchaseOrderId ? +this.formData.purchaseOrderId : ''
        this.formData.supplierId = this.formData.supplierId ? +this.formData.supplierId : ''
        if(type){
          this.supplierVisible = true
        }else{
        this.variableVisible = true

        }
      },
      async submitVariableForm(callback) {
          const res = await api.updateMaterialsItemSettingVariable(
           {
            id:this.formData.id,
            settingVariable:this.formData.settingVariable
          }
          )
          if (res) {
            this.$message.success('操作成功')
            this.variableVisible = false
            this.$refs.mTable.setTableData()
          }
          callback(true)
    },
    async submitSupplierForm(callback) {
      if(!this.formData.supplierId){
        callback(true)
        return this.$message.error('请选择供应商')
      }
          const res = await api.updateReceivedOrderSupplier(
           {
            id:this.formData.id,
            purchaseOrderId:this.formData.purchaseOrderId,
            supplierId:this.formData.supplierId
          }
          )
          if (res) {
            this.$message.success('操作成功')
            this.supplierVisible = false
            this.$refs.mTable.setTableData()
          }
          callback(true)
    },
      radioChange(label) {
      },
      // 批量导出
      batchExportFront() {
        let dataIds
        if (this.params.radio === 'ReceivedMaterialsOrderItem') {
          dataIds = this.selectTableData.map(item => item.id)
        } else {
          dataIds = this.selectTableData.map(item => item.exportWarehouseSign)
        }
        this.$refs.functionHeader.export({
          ids: dataIds.join('^')
        })
      },
      // 查询
      searchTable(data) {
        // const searchObj = {}
        // data.forEach(item => {
        //   searchObj[item.fieldName] = item.fieldValue
        // })
        this.searchFormatData[this.params.radio] = data
        this.$refs.mTable.setTableData(true)
      },
      // 表格选择变化
      handleSelectionChangeDialog(data) {
        this.selectTableDataDialog = data
      },
      handleSelectionChange(data) {
        this.selectTableData = data
      },
      async del(row){
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.deleteMaterialsReceivedOrderMaterials(row.id)
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData(true)
          }
        })
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .inventory-wrap {
    width: 100%;
    padding-top: 10px;

    .m-radio-group {
      margin-left: 10px;
    }

    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;
    }
  }
  </style>
