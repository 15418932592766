export const columns = [
  {
    prop: 'index',
    label: '序号',
    fixed: 'left',
    width: 80,
    slotName: 'index',
    hiddenSearch: true
  },
  {prop: 'productionPlanNumber', label: '工单编号', sortable: false, hiddenSearch: true},
  {prop: 'materialsCode', label: '物料编号', sortable: false, hiddenSearch: true},
  {prop: 'materialsName', label: '物料名称', sortable: false, hiddenSearch: true},
  {prop: 'containerCategory', label: '容器类别', sortable: false, hiddenSearch: true},
  {prop: 'weldNumber', label: '焊缝号', sortable: false, hiddenSearch: true},
  {prop: 'shellNumber', label: '筒节号', sortable: false, hiddenSearch: true},
  {prop: 'materialQuality', label: '材质', sortable: false, hiddenSearch: true},
  {prop: 'insideDiameter', label: '内径', sortable: false, hiddenSearch: true},
  {prop: 'thickness', label: '厚度', sortable: false, hiddenSearch: true},
  {prop: 'diameter', label: '直径', sortable: false, hiddenSearch: true},
  {prop: 'height', label: '高度', sortable: false, hiddenSearch: true},
  {prop: 'totalLength', label: '下料总长', sortable: false, hiddenSearch: true},
  {prop: 'totalWidth', label: '下料总宽', sortable: false, hiddenSearch: true},
  {prop: 'lengthTolerance', label: '下料长度允差', sortable: false, hiddenSearch: true},
  {prop: 'widthTolerance', label: '下料宽度允差', sortable: false, hiddenSearch: true},
  {prop: 'diagonalTolerance', label: '下料对角线允差', sortable: false, hiddenSearch: true},
  {prop: 'roundnessRequirement', label: '圆度要求', sortable: false, hiddenSearch: true},
  {prop: 'misalignment', label: '错边量', sortable: false, hiddenSearch: true},
  {prop: 'edgeMeasurement', label: '棱角度', sortable: false, hiddenSearch: true},
  {prop: 'outerPerimeter', label: '外周长', sortable: false, hiddenSearch: true},
  {
    fixed: 'right',
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    slotName: 'action'
  }
]
