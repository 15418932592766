<template>
  <div class="procedure-detail-wrapper">
    <div class="procedure-detail-header flex-sbc">
      <div class="left" @click="$router.push({ name: 'QualityPlanList' })">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
      <div class="right">
        <!-- <el-button v-if="permission('qualityInspectionPlanListSynchronous', false) && !saveButton" type="primary" :loading="buttonLoading" @click="synchronousInspectionPlan">
          同步至质检单
        </el-button> -->
        <el-button v-if="permission('qualityInspectionPlanListModify', false) || saveButton" type="primary"
                   :loading="buttonLoading" @click="switchOrSubmit">
          {{ saveButton ? '保存' : '编辑' }}
        </el-button>
      </div>
    </div>

    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <MFormBuilder v-if="saveButton" ref="baseForm" :form-list="baseFormList" :form-data="baseFormData"/>
          <el-row v-else type="flex" justify="start" style="flex-flow:row wrap" class="view-list" :gutter="24">
            <!--            暂时这么处理距离问题-->
            <el-col
              v-for="(item, index) in columns.base"
              :key="item.prop"
              :span="6"
              :style="{marginBottom: index !== 4 ? '20px' : 0 }"
            >
              <span class="bot-tis">{{ item.label }}：</span>
              <span class="bot-tis">{{ getValue(item) }}</span>
            </el-col>
          </el-row>
          <div style="margin: 15px">
            <el-checkbox :disabled="!saveButton" v-model="baojianMsg.topChecked">使用报检信息</el-checkbox>
            <el-select v-if="memberCode === 'fsd' || memberCode === 'mom' || memberCode === 'ydhb'" :disabled="params.type==='modify' || params.type==='view'" style="margin-left: 30px;margin-right: 30px"
                       v-model="baojianMsg.topSelected" placeholder="请选择报检信息">
              <el-option label="焊缝" value="焊缝"></el-option>
              <el-option label="筒节" value="筒节"></el-option>
            </el-select>
            <el-button size="small" :disabled="!saveButton" type="primary" @click="addInspectionItem">新增报检项</el-button>
            <div class="baojian-container" v-if="baojianMsg.bjList.length > 0">
              <div style="min-width: 90px">
                <div class="divItem">项目</div>
                <div class="divItem divItemS">参数类型</div>
                <div class="divItem divItemS">可能值</div>
                <div class="divItem divItemS">默认值</div>
                <div class="divItem divItemS">是否必填</div>
              </div>
              <div class="content1" v-for="(bjItem,bjIndex) in baojianMsg.bjList">
                <div class="divItem" style="position:relative;">
                  {{ bjItem.inspectionEntryName }}
                  <div class="edd">
                    <el-button :disabled="!saveButton" icon="el-icon-edit" type="text" style="padding: 0;font-size: 16px;"
                               @click="modifyInspectionItem(bjItem,bjIndex)" />
                    <el-button :disabled="!saveButton" icon="el-icon-remove-outline" type="text" style="padding: 0;font-size: 16px;margin-left: 4px"
                               @click="deleteInspectionItem(bjIndex)" />
                  </div>
                </div>
                <div class="divItem divItemSW">{{bjItem.parameterType | parmeTypeName}}</div>
                <div class="divItem divItemSW">{{bjItem.possibleValue.length ? bjItem.possibleValue.join(",") : '-'}}</div>
                <div class="divItem divItemSW" v-if="bjItem.defaultIsDynamic">{{bjItem.defaultDynamicEntry}} | {{bjItem.defaultDynamicItem}}</div>
                <div class="divItem divItemSW" v-else>{{ Array.isArray(bjItem.defaultValue) ? (bjItem.defaultValue.join(",") ? bjItem.defaultValue.join(",") : '-')
                  : bjItem.defaultValue ? bjItem.defaultValue : '-'}}</div>
                <div class="divItem divItemSW">{{bjItem.required ? '必填' : '非必填'}}</div>
              </div>
            </div>
          </div>
          <quality-inspection-project
            ref="qualityInspectionProject"
            :inspection-entry-map="inspectionProjectGroup.inspectionEntryMap"
            :attachments="inspectionProjectGroup.attachments"
            :user-list="inspectionProjectGroup.userList"
            :read-only="!saveButton"
            :baseFormData="baseFormData"
          />
        </div>
        <MDialog width="1200px" v-model="baojianMsg.visiableBJ" :title="baojianMsg.currentTypeBJ" @onOk="addOrEditSubmitBJ">
<!--          <div @click="showLog">312412344234242344</div>-->
          <el-row style="padding: 10px;" :gutter="20">
            <el-col :span="12" style="margin-bottom: 20px">
              <div style="display: flex;justify-content: flex-start;align-items: center">
                <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
                  <span style="color: red">*</span>报检项名称
                </label>
                <div style="flex: 1">
                  <el-input style="width: 95%" v-model="baojianMsg.dataForAddOrModify.name" type="text"></el-input>
                </div>
              </div>
            </el-col>

            <el-col :span="12" style="margin-bottom: 20px">
              <div style="display: flex;justify-content: flex-start;align-items: center">
                <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
                  <span style="color: red">*</span>报检值类型
                </label>
                <div style="flex: 1">
                  <el-select
                    filterable @change="typeChange"
                    v-model="baojianMsg.dataForAddOrModify.type" placeholder="报检值类型" style="width: 95%">
                    <el-option label="数值" :value="0"></el-option>
                    <el-option label="文本" :value="1"></el-option>
                    <el-option label="单选" :value="2"></el-option>
                    <el-option label="多选" :value="3"></el-option>
                  </el-select>
                </div>
              </div>
            </el-col>

            <!--可能值-->
            <el-col :span="12" style="margin-bottom: 20px" v-if="baojianMsg.dataForAddOrModify.type == 2 || baojianMsg.dataForAddOrModify.type == 3">
              <div style="display: flex;justify-content: flex-start;align-items: center">
                <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
                  <span style="color: red">*</span>可能值
                </label>
                <div style="flex: 1">
                  <el-select style="width: 95%" v-model="baojianMsg.dataForAddOrModify.possibleValue" size="small" multiple
                             allow-create filterable default-first-option placeholder="请输入可能值，敲击回车" />
                </div>
              </div>
            </el-col>

            <!--默认值-->
            <el-col :span="12" style="margin-bottom: 20px" v-if="baojianMsg.dataForAddOrModify.type == 0">
              <div style="display: flex;justify-content: flex-start;align-items: center">
                <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
                  默认值
                </label>
                <div style="flex: 1">
                  <el-input-number style="width: 95%" v-model="baojianMsg.dataForAddOrModify.defaultValue"
                                   controls-position="right" :min="0" :max="9999999"></el-input-number>
                </div>
              </div>
            </el-col>

            <el-col :span="12" style="margin-bottom: 20px" v-if="baojianMsg.dataForAddOrModify.type == 1">
              <div style="display: flex;justify-content: flex-start;align-items: center">
                <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
                  默认值
                </label>
                <div style="flex: 1">
                  <el-input style="width: 95%" v-model="baojianMsg.dataForAddOrModify.defaultValue" type="text"></el-input>
                </div>
              </div>
            </el-col>

            <el-col :span="12" style="margin-bottom: 20px" v-if="baojianMsg.dataForAddOrModify.type == 2">
              <div style="display: flex;justify-content: flex-start;align-items: center">
                <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
                  默认值
                </label>
                <div style="flex: 1">
                  <el-select filterable v-model="baojianMsg.dataForAddOrModify.defaultValue" placeholder="默认值" style="width: 95%">
                    <el-option v-for="item in baojianMsg.dataForAddOrModify.possibleValue" :label="item" :value="item"></el-option>
                  </el-select>
                </div>
              </div>
            </el-col>

            <el-col :span="12" style="margin-bottom: 20px" v-if="baojianMsg.dataForAddOrModify.type == 3">
              <div style="display: flex;justify-content: flex-start;align-items: center">
                <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
                  默认值
                </label>
                <div style="flex: 1">
                  <el-select multiple filterable v-model="baojianMsg.dataForAddOrModify.defaultValue" placeholder="默认值" style="width: 95%">
                    <el-option v-for="item in baojianMsg.dataForAddOrModify.possibleValue" :label="item" :value="item"></el-option>
                  </el-select>
                </div>
              </div>
            </el-col>

            <!--是否必填-->
            <el-col :span="12" style="margin-bottom: 20px">
              <div style="display: flex;justify-content: flex-start;align-items: center">
                <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
                  <span style="color: red">*</span>是否必填
                </label>
                <div style="flex: 1">
                  <el-select
                    filterable
                    v-model="baojianMsg.dataForAddOrModify.required" placeholder="是否必填" style="width: 95%">
                    <el-option label="必填" :value="1"></el-option>
                    <el-option label="非必填" :value="0"></el-option>
                  </el-select>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="20" style="margin-top: 15px;margin-bottom: 20px" v-if="baojianMsg.dataForAddOrModify.type == 1">
            <el-col :span="2" style="font-weight: bold">默认值：</el-col>
            <el-col :span="4">
              <el-checkbox v-model="mrz.checked" @change="jybzCheckedChange($event,2)">使用动态数据源</el-checkbox>
            </el-col>
            <el-col :span="4">
              <el-select :disabled="!mrz.checked" v-model="mrz.value1" placeholder="请选择数据源类型"
                         @change="handleAllMsg2(1)">
                <el-option
                  v-for="item in mrz.options1"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4">
              <el-select :disabled="!mrz.checked || !mrz.value1" v-model="mrz.value2" placeholder="请选择数据源" @change="handleAllMsg2(2)">
                <el-option
                  v-for="item in mrz.options2"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4">
              <el-select :disabled="!mrz.checked  || !mrz.value2" v-model="mrz.value3" placeholder="请选择数据项" @change="handleAllMsg2(3)">
                <el-option
                  v-for="item in mrz.options3"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="4">
              <el-select :disabled="!mrz.checked  || !mrz.value3" v-model="mrz.value4" placeholder="请选择筛选项" @change="handleAllMsg2(4)">
                <el-option
                  v-for="item in mrz.options4"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-col>
          </el-row>
        </MDialog>
      </div>
    </div>
  </div>
</template>

<script>
import {base} from './columns'
import baseFormList from './form-list'
import api from '@/api/quality/inspection-plan'
import apitt from '@/api/quality/inspection-items'
import QualityInspectionProject from '@/views/quality/inspection-plan/components/quality-inspection-project'
import {qualityInspectionType, momQualityInspectionType, majorTypeOptions} from '@/config/options.config'

export default {
  name: 'QualityPlanDetail',
  components: {QualityInspectionProject},

  data() {
    return {
      nowModifyId:'',
      baojianMsg: {
        visiableBJ:false,
        currentTypeBJ:"新增报检项",
        topChecked: false,
        topSelected: '',
        dataForAddOrModify:{
          name:'',
          type:1,
          required:0,
          defaultValue:'',
          possibleValue:[]
        },
        bjList:[]
      },
      mrz: {
        checked: false,
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        options1: [],
        options2: [],
        options3: [],
        options4: []
      },
      allDySetMsg:[],
      params: {},
      baseFormList,
      baseFormData: {},
      saveButton: false,
      buttonLoading: false,
      columns: {
        base
      },
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '质检方案详情',
        addO: '新增质检方案',
        modifyO: '修改质检方案',
        copyO: '复制质检方案'
      },
      inspectionProjectGroup: {},
      inspectionTypeNames: ['首检', '过程检', '来料检', '出货检','探伤检测'],
      memberCode: '',
    }
  },
  created() {
    this.memberCode = sessionStorage.getItem('memberCode')
  },
  filters: {
    parmeTypeName(val) {
      if (val == 0){
        return '数值'
      } else if (val == 1){
        return '文本'
      } else if (val == 2){
        return '单选'
      } else if (val == 3){
        return '多选'
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'QualityPlanList') {
      this.$store.dispatch('tagsView/delCachedView', to)
    }
    next()
  },
  computed: {
    memberType(){
      return localStorage.getItem('memberType')
    }
  },
  mounted() {
    this.params = this.$route.query
    this.saveButton = this.params.type !== 'view'
    if (this.params.type !== 'add') {
      this.getInspectionPlanDetailById(this.params.id)
    }
    this.getQualityInspectionType()
  },
  methods: {
    showLog(){
      console.log("baojianMsg",this.baojianMsg.bjList)
    },
    async typeChange(e){
      this.baojianMsg.dataForAddOrModify.defaultValue = ''
      this.baojianMsg.dataForAddOrModify.possibleValue = []
      await this.jybzCheckedChange(false,2)
    },
    async addInspectionItem(){
      this.nowModifyId = ''
      this.baojianMsg.visiableBJ = true
      this.baojianMsg.dataForAddOrModify = {
        name:'',
        type:1,
        required:0,
        defaultValue:'',
        possibleValue:[]
      }
      await this.jybzCheckedChange(false,2)
    },
    async modifyInspectionItem(item,idx){
      this.nowModifyId = idx
      this.baojianMsg.currentTypeBJ = "修改报检项"
      this.baojianMsg.dataForAddOrModify = {
        name:item.inspectionEntryName,
        type:item.parameterType,
        required:item.required || 0,
        defaultValue:item.defaultValue,
        possibleValue:item.possibleValue
      }
      if(item.defaultIsDynamic){
        await this.jybzCheckedChange(true,2)
        this.mrz.checked = item.defaultIsDynamic ? true : false
        this.mrz.value1 = item.defaultDynamicHead
        this.handleAllMsg2(1)
        this.mrz.value2 = item.defaultDynamicEntry
        this.handleAllMsg2(2)
        this.mrz.value3 = item.defaultDynamicItem
        this.handleAllMsg2(3)
        this.mrz.value4 = item.defaultDynamicFilter
        this.handleAllMsg2(4)
      }else {
        await this.jybzCheckedChange(false,2)
      }
      this.baojianMsg.visiableBJ = true
    },
    deleteInspectionItem(index){
      this.baojianMsg.bjList.splice(index,1)
    },
    addOrEditSubmitBJ(callback){
      if(!this.baojianMsg.dataForAddOrModify.name){
        this.$message.error('请输入报检项名称')
        callback(false)
        return
      }
      //如果类型是单选或者多选，那么可能值必须有数据
      if(this.baojianMsg.dataForAddOrModify.type === 2 || this.baojianMsg.dataForAddOrModify.type === 3){
        if(!this.baojianMsg.dataForAddOrModify.possibleValue || this.baojianMsg.dataForAddOrModify.possibleValue.length === 0){
          this.$message.error('请输入可能值')
          callback(false)
          return
        }
      }
      const tem = {
        inspectionEntryName:this.baojianMsg.dataForAddOrModify.name,
        parameterType:this.baojianMsg.dataForAddOrModify.type,
        defaultValue:this.baojianMsg.dataForAddOrModify.defaultValue,
        possibleValue:this.baojianMsg.dataForAddOrModify.possibleValue,
        required:this.baojianMsg.dataForAddOrModify.required,
      }
      // //处理可能值
      // if(this.baojianMsg.dataForAddOrModify.type === 0 || this.baojianMsg.dataForAddOrModify.type === 1){
      //   tem.possibleValue = ''
      // }else {
      //   tem.possibleValue = JSON.stringify(this.baojianMsg.dataForAddOrModify.possibleValue)
      // }
      // //处理默认值
      // if(this.baojianMsg.dataForAddOrModify.type === 0 || this.baojianMsg.dataForAddOrModify.type === 1
      //   || this.baojianMsg.dataForAddOrModify.type === 2){
      //   tem.defaultValue = this.baojianMsg.dataForAddOrModify.defaultValue
      // }else {
      //   tem.defaultValue = this.baojianMsg.dataForAddOrModify.defaultValue.length > 0 ? JSON.stringify(this.baojianMsg.dataForAddOrModify.defaultValue) : ''
      // }
      if(this.mrz.checked){
        tem.defaultIsDynamic = 1
        tem.defaultDynamicHead = this.mrz.value1
        tem.defaultDynamicEntry = this.mrz.value2
        tem.defaultDynamicItem = this.mrz.value3
        tem.defaultDynamicFilter = this.mrz.value4
      }else {
        tem.defaultIsDynamic = 0
        tem.defaultDynamicHead = ""
        tem.defaultDynamicEntry = ""
        tem.defaultDynamicItem = ""
        tem.defaultDynamicFilter = ""
      }
      if(this.nowModifyId !== ''){
        this.baojianMsg.bjList[this.nowModifyId] = JSON.parse(JSON.stringify(tem))
      }else {
        this.baojianMsg.bjList.push(JSON.parse(JSON.stringify(tem)))
      }
      console.log("报检项信息list",this.baojianMsg.bjList)
      this.baojianMsg.visiableBJ = false
      callback(true)
    },
    async jybzCheckedChange(e, flag) {
      if (!e) {
        if (flag == 2) {
          this.mrz = {
            checked: false,
            value1: '',
            value2: '',
            value3: '',
            value4: '',
            options1: [],
            options2: [],
            options3: [],
            options4: []
          }
        }
      }
      if (e) {
        if(this.allDySetMsg.length === 0){
          const res = await apitt.getDynamicDataSource()
          if(res){
            this.allDySetMsg = res
            this.handleAllMsg2(1)
          }else {
            this.$message.error('暂无动态数据源配置，无法开启')
            this.mrz.checked = false
          }
        }else {
          this.handleAllMsg2(1)
        }
      }
    },
    /**
     * @desc 处理动态数据-默认值
     * @param {Number} idx 1: 数据源类型    2: 数据源    3: 数据项  4: 筛选项
     * */
    handleAllMsg2(idx) {
      if (idx == 1) {
        this.mrz.value2 = ''
        this.mrz.value3 = ''
        this.mrz.value4 = ''
      }else if(idx == 2){
        this.mrz.value3 = ''
        this.mrz.value4 = ''
      }else if(idx == 3){
        this.mrz.value4 = ''
      }
      //处理第一层
      this.allDySetMsg.forEach(item => {
        if(!this.mrz.options1.includes(item.name)){
          this.mrz.options1.push(item.name)
        }
      })
      // 处理第二层  前提第一层必须有值
      if(this.mrz.value1){
        this.mrz.options2 = []
        this.mrz.options3 = []
        this.mrz.options4 = []

        this.allDySetMsg.forEach(item => {
          if(item.name == this.mrz.value1){
            item.entryList.forEach(item2 => {
              this.mrz.options2.push(item2.name)
            })
          }
        })
      }
      // 处理第三层  前提第二层必须有值
      if(this.mrz.value2){
        this.mrz.options3 = []
        this.mrz.options4 = []
        this.allDySetMsg.forEach(item => {
          if(item.name == this.mrz.value1){
            item.entryList.forEach(item2 => {
              if(item2.name == this.mrz.value2){
                item2.entryList.forEach(item3 => {
                  this.mrz.options3.push(item3.name)
                })
              }
            })
          }
        })
      }
      // 处理第四层  前提第三层必须有值
      if(this.mrz.value3){
        this.mrz.options4 = []
        this.allDySetMsg.forEach(item => {
          if(item.name == this.mrz.value1){
            item.entryList.forEach(item2 => {
              if(item2.name == this.mrz.value2){
                item2.entryList.forEach(item3 => {
                  if(item3.name == this.mrz.value3){
                    item3.entryList.forEach(item4 => {
                      this.mrz.options4.push(item4.name)
                    })
                  }
                })
              }
            })
          }
        })
      }
    },
    async getInspectionPlanDetailById(id) {
      const res = await api.getInspectionPlanDetailById({id})
      if (res) {
        this.baseFormData = res
        this.inspectionProjectGroup = this._.cloneDeep(res)

        if(res.inspectionObject){
          this.baojianMsg.topSelected = res.inspectionObject
        }
        if(res.whetherInspectionReport){
          this.baojianMsg.topChecked = true
        }
        if(res.inspectionReportEntryList && res.inspectionReportEntryList.length > 0){
          this.baojianMsg.bjList = res.inspectionReportEntryList.map(item => {
            const aaa = {
              inspectionEntryName: item.inspectionEntryName,
              parameterType: item.parameterType,
              required: item.required,
              // defaultValue: item.defaultValue,
              // possibleValue: item.possibleValue || '',
              defaultIsDynamic: item.defaultIsDynamic,
              defaultDynamicHead: item.defaultDynamicHead,
              defaultDynamicEntry: item.defaultDynamicEntry,
              defaultDynamicItem: item.defaultDynamicItem,
              defaultDynamicFilter: item.defaultDynamicFilter
            }
            //处理可能值
            if(item.parameterType === 0 || item.parameterType === 1){
              aaa.possibleValue = ""
            }else {
              aaa.possibleValue = JSON.parse(item.possibleValue)
            }
            //处理默认值
            if(item.parameterType === 3){
              if(item.defaultValue){
                aaa.defaultValue = JSON.parse(item.defaultValue)
              }else {
                aaa.defaultValue = []
              }
            }else {
              aaa.defaultValue = item.defaultValue
            }
            return aaa
          })
        }
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      if (row.prop == 'type') {
        return this.inspectionTypeNames[result]
      }
      return result
    },
    switchOrSubmit() {
      if (this.saveButton) {
        this.submitForm()
      } else {
        this.params.type = 'modify'
        this.saveButton = true
      }
    },
    async synchronousInspectionPlan() {
      const res = await api.synchronousInspectionPlan({inspectionSchemeId: this.$route.query.id})
      if (res) {
        this.$message.success('同步成功')
      }
    },
    submitForm() {
      this.buttonLoading = true
      const projectObject = this.$refs.qualityInspectionProject.transferDataToStandard()
      if (!projectObject) {
        this.buttonLoading = false
        return false
      }
      this.$refs.baseForm.formRefs().validate(async (valid) => {
        if (valid) {
          let res
          const {form} = this.$refs.baseForm
          if (['add', 'copy'].indexOf(this.params.type) !== -1) {
            const inspectionReportEntryList = []
            inspectionReportEntryList.push(...this.baojianMsg.bjList)
            inspectionReportEntryList.forEach(item => {
              //处理可能值
              if(item.parameterType === 0 || item.parameterType === 1){
                item.possibleValue = ''
              }else {
                item.possibleValue = JSON.stringify(item.possibleValue)
              }
              // 处理默认值
              if(item.parameterType === 3){
                if(item.defaultValue.length){
                  item.defaultValue = JSON.stringify(item.defaultValue)
                }else {
                  item.defaultValue = ''
                }
              }
            })
            const reqMsg = this.createSetData({...form, ...projectObject,inspectionReportEntryList})
            if(this.baojianMsg.topSelected){
              reqMsg.inspectionObject = this.baojianMsg.topSelected
            }
            if(this.baojianMsg.topChecked){
              reqMsg.whetherInspectionReport = 1
            }else {
              reqMsg.whetherInspectionReport = 0
            }
            res = await api.addInspectionPlan(reqMsg)
          }
          if (this.params.type === 'modify') {
            const inspectionReportEntryList = []
            inspectionReportEntryList.push(...this.baojianMsg.bjList)
            inspectionReportEntryList.forEach(item => {
              //处理可能值
              if(item.parameterType === 0 || item.parameterType === 1){
                item.possibleValue = ''
              }else {
                item.possibleValue = JSON.stringify(item.possibleValue)
              }
              // 处理默认值
              if(item.parameterType === 3){
                if(item.defaultValue.length){
                  item.defaultValue = JSON.stringify(item.defaultValue)
                }else {
                  item.defaultValue = ''
                }
              }
            })
            const reqMsg = this.createResetData({...form, ...projectObject,inspectionReportEntryList})
            if(this.baojianMsg.topSelected){
              reqMsg.inspectionObject = this.baojianMsg.topSelected
            }
            if(this.baojianMsg.topChecked){
              reqMsg.whetherInspectionReport = 1
            }else {
              reqMsg.whetherInspectionReport = 0
            }
            res = await api.modifyInspectionPlan(reqMsg)
          }
          if (res) {
            this.saveButton = false
            this.$message.success(this.messages[this.params.type])
            if (this.params.type !== 'modify') {
              this.$router.push({query: {type: 'view', id: res}})
              this.params = {type: 'view', id: res}
            }
            await this.getInspectionPlanDetailById(this.params.id)
          }
          this.buttonLoading = false
        } else {
          this.buttonLoading = false
        }
      })
    },
    createSetData(raw) {
      return {
        name: raw.name,
        type: raw.type,
        isEnabled: !raw.isEnabled ? 0 : 1,
        inspectionEntryMap: raw.inspectionEntryMap,
        inspectionReportEntryList: raw.inspectionReportEntryList || [],
        inspectorList: raw.inspectorList,
        userList: raw.userList,
        attachments: raw.attachments,
        remark: raw.remark,
        isCheck: raw.isCheck,  //0关闭  1开启审核
        checkedIDList: raw.checkedIDList,   //审核人员id
        isApproved: raw.isApproved,    ///0关闭  1开启审批
        approverIdList: raw.approverIdList   //审批人员id
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    async getQualityInspectionType() {
      console.log(momQualityInspectionType);
      console.log(qualityInspectionType);
      if (this.memberType === 'mom') {
        this.baseFormList[1].children[0].options = momQualityInspectionType
        if(this.memberCode === 'mom' || this.memberCode === 'fsd' || this.memberCode === 'ydhb'){
          const m = this.baseFormList[1].children[0].options.find(item => item.name === '探伤检测')
          if(!m){
            this.baseFormList[1].children[0].options.push( {
              id: 4,
              name: '探伤检测'
            })
          }
        }
      } else {
        this.baseFormList[1].children[0].options = qualityInspectionType
      }
    }
  }
}
</script>

<style scoped lang="scss">
.baojian-container {
  width: 100%;
  overflow-x: scroll;
  max-height: 568px;
  border: 1px solid #ebeef5;
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;

  .edd{
    position: absolute;
    right: 4px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .divItem {
    color: #909399;
    border-right: 1px solid #EBEEF5;
    border-bottom: 1px solid #EBEEF5;
    padding: 4px;
    background: #F6F7F9;
    line-height: 23px;
    font-weight: bold;
    font-size: 14px;
  }

  .divItemS {
    color: #606266;
    font-weight: normal;
  }
  .divItemSW {
    color: #606266;
    font-weight: normal;
    background: white;
  }

  .content1{
    flex: auto;
    min-width: 200px;
    box-sizing: border-box;
  }

}

.procedure-detail-wrapper {
  height: 100%;

  .procedure-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .procedure-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
}
</style>
