import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/private/planOrder/'

export default {
    // 计划订单列表
    getPlanOrdersListPage(data) {
        return request.post(baseUrl + 'listPage', data)
    },
    // 详情
    getPlanOrderDetail(data) {
        return request.get(baseUrl + 'getOne', data)
    },
    // 创建计划订单
    addPlanOrders(data) {
        return request.post(baseUrl + 'create', data)
    },
    // 创建计划订单
    copyPlanOrders(data) {
        return request.post(baseUrl + 'create', data)
    },
    // // 修改计划订单
    // modifyPlanOrders(data) {
    //     return request.put(baseUrl + 'update', data)
    // },
    // 删除计划订单
    delPlanOrder(data) {
        return request.del(baseUrl + 'delete', data)
    },
    //   结案
    closeOut(data) {
        return request.put(baseUrl + `closeout?id=${data}`)
    },
    restart(data) {
      return request.put(baseUrl + `restart?id=${data}`)
    },
    // 设置站号
    setStationNumber(data){
        return request.put(baseUrl + 'setStationNumber', data)
    },
    // 修改业务部门
    modifyPlanOrders(data){
        return request.put(baseUrl + 'updateBusinessUnit', data)
    },
    // 生成计划订单编号
    getProjectNumber(data){
        return request.get(baseUrl + 'getProjectNumber', data)
    }
}
