<template>
  <div class="call-search-wrap">
    <el-upload
      ref="upload"
      drag
      multiple
      class="upload-demo"
      :file-list="fileList"
      v-bind="uploadProp"
      accept=".mp4"
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div slot="tip" class="el-upload__tip">只能上传MP4文件</div>
    </el-upload>
    <el-button size="small" type="primary" class="e-btn" @click="selectadd">合并下载</el-button>
  </div>
</template>
<script>
import api from '@/api/home'
import { getToken } from '@/utils/auth'
export default {
  name: 'UploadVideo',
  data() {
    return {
      fileList: [],
      uploadProp: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: true,
        onSuccess: this.fileUploadChange,
        onRemove: this.fileRemove,
        onError: this.fileUploadChange,
        onPreview: this.handlePreview,
        beforeUpload: this.beforeAvatarUpload
      },
      list: []
    }
  },
  methods: {
    beforeAvatarUpload(file) {
      // if (!this.fileList) {
      //   this.fileList = []
      // }
      // this.fileList.push(file)
    },
    handlePreview(file) {
      window.open(file.response.result)
    },
    fileRemove(file, fileList) {
      // console.log(file, fileList)
      // const index = this.fileList.findIndex(item => item.id === file.id)
      // this.fileList.splice(index, 1)
      this.list = fileList.length > 0 ? fileList.map(file => {
        return {
          uid: file.uid,
          id: file.uid,
          name: file.name,
          url: file.response.result
        }
      }) : []
    },
    // 上传附件
    fileUploadChange(res, file) {
      // console.log('ceshiimg', res)
      if (res.errorCode === 0) {
        this.list.push({
          uid: file.uid,
          id: file.uid,
          name: file.name,
          url: res.result
        })
        return this.$message.success('上传成功')
      }
      if (res.errorCode !== 0) {
        return this.$message.error(res.result)
      }
    },
    async selectadd() {
      //  console.log(this.list)
      if (this.list.length === 0) {
        return this.$message.error('请先上传文件')
      }

      const data = this.list.map(item => item.url.split('/')[3])
      const res = await api.mergeVideo(data)
      if (res) {
        window.location.href = res
      }
    }
  }
}
</script>

<style scoped lang="scss">
.call-search-wrap{
  padding: 20px;
  .upload-demo {
    width: 500px;
  }
  .e-btn {
    margin-top: 20px;
  }
}
</style>
