import { projectDeliveryType,checkStatus,projectStatus } from '@/config/options.config'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const columns = [
  {
    prop: 'projectNumber',
    label: '订单编号'
  },
  {
    prop: 'stationNumber',
    label: '序列号'
  },
  {
    prop: 'projectName',
    label: '订单名称'
  },
  {
    prop: 'materialsCode',
    label: '物料编码'
  },
  {
    prop: 'materialsName',
    label: '物料名称'
  },
  {
    prop: 'quantity',
    label: '数量'
  },
  {
    prop: 'issuedQuantity',
    label: '已下达数'
  },
  {
    prop: 'deliveryType',
    label: '交付类型',
    form: { options: projectDeliveryType }
  },
  {
    prop: 'deliveryDate',
    label: '交付日期'
  },
  {
    prop: 'customerOrderNumber',
    label: '客户订单号'
  },
  {
    prop: 'customerName',
    label: '客户'
  },
  {
    prop: 'customerPrincipalName',
    label: '客户负责人'
  },

  {
    prop: 'principalName',
    label: '负责人'
  },
  {
    prop: 'businessUnitName',
    label: '业务部门'
  },
  {
    prop: 'projectApprovalNumber',
    label: '立项编号'
  },

  {
    prop: 'auditStatus',
    label: '审核状态',
    form: { options: checkStatus }
  },
  {
    prop: 'projectStatus',
    label: '订单状态',
    form: { options: projectStatus }
  },
  {
    prop: 'remark',
    label: '备注'
  },
  {
    prop: 'createTime',
    label: '创建时间'
  },

  {
    prop: 'creator',
    label: '创建人'
  },
  {
    prop: 'lastUpdateTime',
    label: '更新时间'
  },
  {
    prop: 'updater',
    label: '更新人'
  }
]
