<template>
  <div class="equipment-board-container">
    <div class="top">
      <div class="fault-sort">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">用量分析<span style="font-size: 14px">({{ maintenanceData }})</span></div>
        <ve-histogram
          height="300px"
          :data="maintenanceChartData"
          :settings="barChartSettings"
          :extend="barChartExtend"
        />
      </div>
      <div class="defects">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">存量分析（分类）</div>
        <ve-pie
          height="300px"
          :settings="pieChartSettings"
          :extend="pieChartExtend1"
          :data="defectsChartData"
        />
      </div>
      <div class="defects">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">存量分析（属性）</div>
        <ve-pie
          height="300px"
          :settings="pieChartSettings"
          :extend="pieChartExtend2"
          :data="typeChartData"
        />
      </div>
    </div>
    <div class="bottom">
      <div class="inspection-task">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);margin-bottom: 10px">库存流水<span>（{{ inventoryDataTotal }}）</span></div>
        <scroll-board :config="scrollBoardConfig1" :style="{width:'100%', height: tableHeight + 'px'}">
          <div slot="type" slot-scope="{ index }">{{ getType(inventoryData[index].type, inventoryData[index].recordType) }}</div>
          <div slot="recordType" slot-scope="{ index }">{{ inventoryData[index].recordType ? '流出' : '流入' }}</div>
        </scroll-board>
      </div>
      <div class="repair-task">
        <div class="gradient-title" style="background: linear-gradient(90deg,  #7410FF 0%, rgba(96, 127, 255, 0) 100%);margin-bottom: 10px">库存预警<span>（{{ inventoryAimTotal }}）</span></div>
        <scroll-board :config="scrollBoardConfig2" :style="{width:'100%', height: tableHeight + 'px'}">
          <div slot="sumQuantity" slot-scope="{ index }">
            <i v-if="inventoryAimData[index].softSign" class="icon-upperLimit" />
            <i v-else class="icon-lowerLimit" />
            {{ inventoryAimData[index].sumQuantity }}
          </div>
        </scroll-board>
      </div>
    </div>
  </div>
</template>

<script>
import mix from '@/views/statistics-board/board-detail/components/mixin'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import api from '@/api/statistics-board/all-statistics'
import { baseUrl, colorList } from '@/views/statistics-board/board-detail/components/base-setting'
import dayjs from 'dayjs'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { warehouseRecordTypeIn, warehouseRecordTypeOut } from '@/config/options.sales'

const scrollBoardSettingDefault = {
  carousel: 'page',
  headerHeight: 32,
  hoverPause: false,
  waitTime: 10000,
  headerBGC: '#222222',
  oddRowBGC: '#222222',
  evenRowBGC: 'transparent'
}
export default {
  name: 'Inventory',
  components: { scrollBoard },
  mixins: [mix],
  data() {
    return {
      scrollBoardSettingDefault,
      digital: {
        equipmentQuantity: 0,
        maintenanceQuantity: 0,
        faultQuantity: 0
      },
      inspectionTaskData: [],
      repairTaskData: [],
      maintenanceTaskData: [],
      maintenanceChartData: {
        columns: ['物料', '数量'],
        rows: []
      },
      barChartExtend: {
        legend: {
          show: false,
          x: 'center',
          y: 'bottom',
          orient: 'horizontal'
        },
        xAxis: {
          name: '', // 坐标轴名称。
          nameLocation: 'end', // 坐标轴名称显示位置。
          axisLabel: { // 坐标轴刻度标签的相关设置。
            interval: 0,
            rotate: 0,
            formatter: function(value) {
              const val = value.split('^')
              return val[0] + '\n' + val[1]
            }
          },
          axisLine: { // 设置轴线的属性
            show: false,
            lineStyle: {
              color: '#000',
              width: 1
            }
          }

        },
        yAxis: {
          nameLocation: 'end', // 坐标轴名称显示位置。
          axisLine: { // 设置轴线的属性
            show: false,
            lineStyle: {
              color: '#000',
              width: 1
            }
          }
        }
      },
      scrollBoardConfig1: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 120, 150, 150, 150, 150, 100, 130],
        header: ['序号', '物料编号', '物料名称', '仓库', '流水方向', '库存操作', '单位', '数量'],
        data: []
      },
      scrollBoardConfig2: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 200, 200, 200, 200],
        header: ['序号', '物料编号', '物料名称', '仓库', '数量'],
        data: []
      },
      tableHeight: 100,
      inventoryDataTotal: 0,
      inventoryAimTotal: 0,
      pieChartExtend1: {
        color: colorList,
        legend: {
          top: 15,
          show: true,
          orient: 'vertical',
          right: 10,
          textStyle: {
            width: 10,
            color: '#fff',
            fontSize: 14,
            left: 20,
            overflow: 'break'
          }
        },
        series: {
          top: 0,
          avoidLabelOverlap: false,
          radius: ['40%', '70%'],
          center: ['30%', '50%'],
          label: {
            formatter: '\n\n {b} ',
            padding: [0, -25],
            alignTo: 'labelLine',
            color: '#fff'
          },
          labelLine: {
          },
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          }

        },
        tooltip: {
          trigger: 'item'
        }
      },
      pieChartExtend2: {
        color: colorList,
        legend: {
          top: 15,
          show: true,
          orient: 'vertical',
          right: 10,
          textStyle: {
            width: 10,
            color: '#fff',
            fontSize: 14,
            left: 20,
            overflow: 'break'
          }
        },
        series: {
          top: 0,
          avoidLabelOverlap: false,
          radius: ['40%', '70%'],
          center: ['30%', '50%'],
          label: {
            formatter: '\n\n {b} ',
            padding: [0, -25],
            alignTo: 'labelLine',
            color: '#fff'
          },
          labelLine: {
          },
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          }

        },
        tooltip: {
          trigger: 'item'
        }
      },
      defectsChartData: {
        columns: ['分类', '数量'],
        rows: []
      },
      typeChartData: {
        columns: ['属性', '数量'],
        rows: []
      },
      warehouseIds: '',
      createTime: [],
      maintenanceData: 0
    }
  },
  beforeMount() {

  },
  mounted() {
    this.reloadData()
  },
  methods: {
    getType(type, val) {
      let current
      if (val === 0) {
        current = warehouseRecordTypeIn.find(item => type === item.id)
      } else {
        current = warehouseRecordTypeOut.find(item => type === item.id)
      }
      return current && current.name
    },
    reloadData(warehouseIds = '', createTime = []) {
      this.warehouseIds = warehouseIds
      this.createTime = createTime || []
      if (this.createTime.length === 0) {
        this.createTime = [dayjs().subtract(2, 'month').format('YYYY-MM-DD') ,dayjs().format('YYYY-MM-DD')]
      }

      this.getDefectsChartData()
      this.getTypesChartData()
      this.getMaintenanceChartData()
      const search = []
      if (this.warehouseIds) {
        search.push({
          id: getUUid(),
          fieldName: 'warehouseId',
          fieldType: 'number',
          fieldValue: warehouseIds,
          operator: 'in'
        })
      }
      this.getTableData(baseUrl + 'getOutInSitWarehouseRecordList', {
        beginTime: this.createTime.length > 0 ? this.createTime[0] : '',
        endTime: this.createTime.length > 0 ? this.createTime[1] : '',
        warehouseIds: this.warehouseIds
      }, {
        repeat: true,
        key: 'scrollBoardConfig1',
        key2: 'inventoryData',
        columns: this.scrollBoardConfig1.header,
        columnWidth: [60, 120, 150, 150, 150, 150, 100, 130],
        rowNum: 10,
        columnsKey: ['index', 'materialsCode', 'materialsName', 'warehouseName', 'slot:recordType', 'slot:type', 'materialsUnit', 'quantity']
      })
      this.getTableData(baseUrl + 'getWarehouseSoftStockList', {
        // beginTime: this.createTime.length ? this.createTime[0] : '',
        // endTime: this.createTime.length ? this.createTime[1] : '',
        warehouseIds: this.warehouseIds
      }, {
        repeat: true,
        key: 'scrollBoardConfig2',
        key2: 'inventoryAimData',
        columns: this.scrollBoardConfig2.header,
        columnWidth: [60, 200, 200, 200, 200],
        rowNum: 10,
        columnsKey: ['index', 'materialsCode', 'materialsName', 'warehouseName', 'slot:sumQuantity']
      })
    },
    async getDefectsChartData() {
      const res = await api.getTableData(baseUrl + 'getOutInSitWarehouseRecordByClass', {
        // beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        // endTime: dayjs().format('YYYY-MM-DD'),
        warehouseIds: this.warehouseIds
      })
      if (res) {
        this.pieChartExtend1.legend.formatter = function(name) {
          for (var i = 0; i < res.length; i++) {
            var dpercent = res[i].sumQuantity
            var ratio = res[i].rate
            if (res[i].name === name) {
              return `${name} | ${ratio}%  ${dpercent} `
            }
          }
        }
        this.defectsChartData.rows = res.map(item => ({
          '分类': item.name,
          '数量': Number(item.sumQuantity)
        }))
      }
    },
    async getTypesChartData() {
      const res = await api.getTableData(baseUrl + 'getOutInSitWarehouseRecordByType', {
        // beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        // endTime: dayjs().format('YYYY-MM-DD'),
        warehouseIds: this.warehouseIds
      })
      if (res) {
        this.pieChartExtend2.legend.formatter = function(name) {
          for (var i = 0; i < res.length; i++) {
            var dpercent = res[i].sumQuantity
            var ratio = res[i].rate
            if (res[i].name === name) {
              return `${name} | ${ratio}%  ${dpercent} `
            }
          }
        }
        this.typeChartData.rows = res.map(item => ({
          '属性': item.name,
          '数量': Number(item.sumQuantity)
        }))
      }
    },
    async getMaintenanceChartData() {
      const res = await api.getTableData(baseUrl + 'getMaterialsUsageStatistics', {
        beginTime: this.createTime.length > 0 ? this.createTime[0] : dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        endTime: this.createTime.length > 0 ? this.createTime[1] : dayjs().format('YYYY-MM-DD'),
        warehouseIds: this.warehouseIds
      })
      if (res) {
        this.maintenanceChartData.rows = res.map(item => ({
          '物料': `${item.materialsCode}^${item.materialsName}`,
          '数量': Number(item.sumQuantity)
        }))
        this.maintenanceData = res.reduce((sum, item) => { return sum + Number(item.sumQuantity) }, 0)
      }
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = (window.innerHeight * 0.48) / scale
    }
  }
}
</script>

<style scoped lang="scss">
@import "./common";

.equipment-board-container {
  padding: 0 20px 20px 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  .icon-lowerLimit,
  .icon-upperLimit {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  .icon-upperLimit {
    background: url("../../../../assets/inventory/icon-arrow.png");
    background-size: contain;
  }
  .icon-lowerLimit {
    transform: scaleY(-1);
    background: url("../../../../assets/inventory/icon-arrow.png");
    background-size: contain;
  }
  .top, .center {
    display: flex;
    justify-content: space-between;

    > div {
      width: 596px;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    >div {
      width: 878px;
      .gradient-title {
        margin-bottom: 10px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .top {
    .equipment-info {
      display: flex;
      justify-content: space-between;

      .card-info {
        position: relative;
        width: 162px;

        img {
          width: 162px;
        }

        > div {
          color: #FFFFFF;
          font-size: 23px;
          text-align: center;
          padding: 20px 0;
        }

        ::v-deep {
          .dv-digital-flop {
            position: absolute;
            top: 10px;
          }
        }
      }
    }
  }
}
::v-deep .dv-scroll-board .ceil {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  >span {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.status-board {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 44px;
}
.status-board-r {
  display: inline-block;
  width: calc(100% + 10px);
  height: 100%;
  text-align: center;
  line-height: 44px;
}
</style>
