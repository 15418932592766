import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/enterpriseDocument/private/enterpriseDocumentClass/'

export default {
  // 获取企业文件目录列表（不分页）
  getEnterpriseDocumentClassList(data) {
    return request.get(baseUrl + 'getEnterpriseDocumentClassList', data)
  },
  // 获取企业文件目录列表（分页）
  getEnterpriseDocumentClassListPage(data) {
    return request.get(baseUrl + 'getEnterpriseDocumentClassListPage', data)
  },
  // 新建企业文件目录
  addEnterpriseDocumentClass(data) {
    return request.post(baseUrl + 'addEnterpriseDocumentClass', data)
  },
  // 修改企业文件目录
  modifyEnterpriseDocumentClass(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除企业文件目录
  delEnterpriseDocumentClass(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取企业文件目录-详情
  getEnterpriseDocumentClassDetail(id) {
    return request.get(baseUrl + 'getEnterpriseDocumentClassDetail', { id })
  },
  nbgGetCustomers(){
    return request.get('api/web/v1/basicData/private/customer/getCustomerListPage?page=1&limit=20000')
  }
}
