import { status } from '@/config/options.config'
import { addColumns, resetColumns,resetColumnsBbelc,addColumnsBbelc } from './columns'
import { transformColumnsToForm } from '@/utils'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const formList = [
  ...transformColumnsToForm(addColumns)
]

export const formListBbelc = [
  ...transformColumnsToForm(addColumnsBbelc)
]

export const modifyFormList = [
  ...transformColumnsToForm(resetColumns)
]

export const modifyFormListBbelc = [
  ...transformColumnsToForm(resetColumnsBbelc)
]

export const searchFormList = [
  {
    key: 'creator',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择创建人'
    },
    children: [
      {
        tagName: 'el-option',
        innerValue: (val) => {
          return `${val['jobNumber']} - ${val['name']}`
        },
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 8,
    key: 'createTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建时间',
      endPlaceholder: '创建时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: []
    }
  }
]

export const searchFormListBbelc = [
  {
    key: 'creator',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择创建人'
    },
    children: [
      {
        tagName: 'el-option',
        innerValue: (val) => {
          return `${val['jobNumber']} - ${val['name']}`
        },
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 8,
    key: 'createTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建时间',
      endPlaceholder: '创建时间',
      align: 'center',
      type: 'daterange',
      pickerOptions: []
    }
  },
  {
    key: 'customerId',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择客户'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    key: 'factoryNumber',
    tagName: 'el-input',
    colSpan: 5,
    props: {
      placeholder: '请输入出厂编号'
    },
  },
  {
    colSpan: 5,
    key: 'time',
    tagName: 'el-date-picker',
    wcFlag: 'TTT',
    props: {
      type: 'date',
      placeholder: '请选择日期',
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    }
  },
]

