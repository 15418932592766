<template>
  <div :id="commonIds" style="display: none">
    <div v-for="(item, index) in codes" :key="index" style="padding-top: 10px;display:inline-flex;justify-content:flex-start;">
      <div style="width:100px;">
        <VueQr :correct-level="3" :auto-color="false" color-dark="#000000" :text="item.batchNumber? item.batchNumber : item.sequenceNumber" :size="120" :margin="0" />
        <div style="padding-top: 14px">{{ item.batchNumber || item.sequenceNumber}}</div>
      </div>
      <!--来料批次码-->
      <div style="margin-left: 26px;text-align: left" v-if="sequenceNumberType === '0'">
        <div style="font-size: 18px;">物料编码：{{item.materialsCode}}</div>
        <div style="font-size: 18px;">物料名称：{{item.materialsName}}</div>
        <div style="font-size: 18px;">规格型号：{{item.materialsSpecifications || item.specifications}}</div>
        <div style="font-size: 18px;">供应商：{{item.supplierName}}</div>
        <div style="font-size: 18px;">收货日期：{{times}}</div>
        <div style="font-size: 18px;">数量：{{item.quantity}}</div>
        <div style="font-size: 18px;">设变件：{{item.settingVariable === 0 ? '否' : item.settingVariable === 1 ? '是' : '-'}}</div>
      </div>
      <!--来料序列号-->
      <div style="margin-left: 26px;text-align: left" v-else-if="sequenceNumberType === '1'">
        <div style="font-size: 18px;">{{item.materialsCode}}</div>
        <div style="font-size: 18px;">{{item.materialsName}}</div>
        <div style="font-size: 18px;">{{item.materialsSpecifications || item.specifications}}</div>
        <div style="font-size: 18px;">{{item.supplierName}}</div>
        <div style="font-size: 18px;">设变件({{item.settingVariable === 0 ? '否' : item.settingVariable === 1 ? '是' : '-'}})</div>
        <div style="font-size: 18px;">{{times}}</div>
      </div>
      <!--成品序列号(包含工单中的成品序列号)-->
      <div style="margin-left: 26px;text-align: left" v-else>
        <div style="font-size: 18px;">物料编码：{{item.materialsCode}}</div>
        <div style="font-size: 18px;">物料名称：{{item.materialsName}}</div>
        <div style="font-size: 18px;">规格型号：{{item.materialsSpecifications || item.specifications}}</div>
        <div style="font-size: 18px;">创建日期：{{times}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import VueQr from 'vue-qr'
export default {
  name: 'PrintCode',
  components: { VueQr },
  props: {
    commonId: {
      type: String,
      default: 'print'
    },
    sequenceNumberType: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      codes: [],
      commonIds: 'print',
      times: dayjs().format('YYYY-MM-DD')
    }
  },
  watch: {
    commonId(val) {
      this.commonIds = val
    }
  },
  methods: {
    print(code) {
      this.codes = [code]
      this.printIframe()
    },
    batchPrint(codes) {
      this.codes = codes
      this.printIframe()
    },
    printIframe() {
      setTimeout(() => {
        const printHtml = document.getElementById(`${this.commonIds}`).innerHTML
        const new_iframe = document.createElement('IFRAME')
        let doc = null
        new_iframe.setAttribute('style', 'width:0px;height:0px;position:absolute;left:-2000px;top:-2000px;')
        new_iframe.setAttribute('align', 'center')
        document.body.appendChild(new_iframe)
        doc = new_iframe.contentWindow.document
        doc.write('<div style="width:100%;height:auto;margin:0 auto;"align="center">' + printHtml + '</div>')
        // doc.body.style.zoom = '0.8'
        doc.close()
        new_iframe.contentWindow.focus()
        new_iframe.contentWindow.print()
      }, 0)
    }
  }
}
</script>

<style scoped></style>
