<template class="povers">
  <el-popover placement="right" trigger="hover" popper-class="pover-tips">
    <div
      slot="reference"
      :class="item.status === 0 || item.status === 2 ? 'plan' : 'noplan'"
      @click="onClick"
    >
      <!-- 进度条文案 -->
      <div class="runTime" :style="{background: priorityStatus[item.priority].color}" />
      <!-- 实际开始时间无-->
      <div
        v-if="!item.actualStartTime"
        class="actul-common-color"
        :style="{
          left: getPositonOffset(item.planStartTime)+'px',
          width: getWidthAbout2Times(item.planStartTime, item.planEndTime) + 'px'
        }"
      />
      <!-- 计划结束时间大于当前时间 && 实际开始时间大于计划开始时间-->
      <div
        v-if="
          item.actualStartTime &&
            compareDate(item.actualStartTime, item.planStartTime) &&
            compareDate(item.planEndTime, currentDate)
        "
        class="actul-time"
      >
        <div
          class="actul-common-color"
          :style="{
            left: getPositonOffset(item.planStartTime)+'px',
            width: getWidthAbout2Times(item.planStartTime, item.actualStartTime) - 6 +'px',
          }"
        />
        <div
          class="actul-time-color"
          :style="{
            left: getPositonOffset(item.actualStartTime)+'px',
            width: getWidthAbout2Times(item.actualStartTime, currentDate)+'px'
          }"
        />
        <div
          class="actul-common-color"
          :style="{
            left: getPositonOffset(currentDate)+'px',
            width: getWidthAbout2Times(currentDate, item.planEndTime)+'px'
          }"
        />
      </div>
      <!-- 计划结束时间小于当前时间  && 实际开始时间大于计划开始时间-->
      <div
        v-if="
          item.actualStartTime &&
            !compareDate(item.actualStartTime, item.planEndTime) &&
            compareDate(item.actualStartTime, item.planStartTime) &&
            !compareDate(item.planEndTime, currentDate)
        "
        class="actul-time"
      >
        <div
          class="actul-common-color"
          :style="{
            left: getPositonOffset(item.planStartTime)+'px',
            width: getWidthAbout2Times(item.planStartTime, item.actualStartTime) +'px'
          }"
        />
        <div
          class="actul-time-color"
          :style="{
            left: getPositonOffset(item.actualStartTime)+'px',
            width: getWidthAbout2Times(item.actualStartTime, item.planEndTime) +'px',
          }"
        />
        <!-- 超过计划结束时间 -->
        <div
          class="actul-end-color"
          :style="{
            left:getPositonOffset(item.planEndTime)+'px',
            width:getWidthAbout2Times(item.planEndTime, currentDate)- 6 +'px'}
          "
        />
      </div>
      <!-- 实际开始时间大于计划结束时间和开始时间-->
      <div
        v-if="
          item.actualStartTime &&
            compareDate(item.actualStartTime, item.planEndTime) &&
            !compareDate(item.planEndTime, currentDate) &&
            !compareDate(item.planStartTime, currentDate)
        "
        class="actul-time"
      >
        <div
          class="actul-common-color"
          :style="{
            left: getPositonOffset(item.planStartTime)+'px',
            width: getWidthAbout2Times(item.planStartTime, item.planEndTime)+'px'
          }"
        />
        <div
          class="lines"
          :style="{
            width:getWidthAbout2Times(item.planEndTime, item.actualStartTime) +'px'
          }
          "
        >
          <span class="circle" />
          <span class="line" />
          <span class="circle" />
        </div>
        <div
          class="actul-end-color"
          :style="{
            left:getPositonOffset(item.actualStartTime)+'px',
            width:getWidthAbout2Times(item.actualStartTime, currentDate) -6 +'px'}
          "
        />
      </div>
      <!-- 实际开始时间小于计划开始时间 && 计划开始时间和计划结束时间都大于当前时间-->
      <div
        v-if="
          item.actualStartTime &&
            compareDate(item.planStartTime, item.actualStartTime) &&
            compareDate(item.planStartTime, currentDate) &&
            compareDate(item.planEndTime, currentDate)
        "
        class="actul-time aa"
      >
        <div
          class="actul-time-color"
          :style="{
            left: getPositonOffset(item.actualStartTime)+'px',
            width: getWidthAbout2Times(item.actualStartTime, currentDate) - 6 +'px'
          }"
        />
        <div
          class="lines"
          :style="{
            width: getWidthAbout2Times(currentDate, item.planStartTime) +'px'
          }
          "
        >
          <span class="circle" />
          <span class="line" />
          <span class="circle" />
        </div>
        <div
          class="actul-common-color"
          :style="{
            left: getPositonOffset(item.planStartTime)+'px',
            width: getWidthAbout2Times(item.planStartTime, item.planEndTime)+'px'
          }"
        />
      </div>
      <!-- 实际开始时间小于计划开始时间 && 计划开始时间小于当前时间 && 计划结束时间大于当前时间-->
      <div
        v-if="
          item.actualStartTime &&
            compareDate(item.planStartTime, item.actualStartTime) &&
            !compareDate(item.planStartTime, currentDate) &&
            compareDate(item.planEndTime, currentDate)
        "
        class="actul-time"
      >
        <div
          class="actul-time-color"
          :style="{
            left: getPositonOffset(item.actualStartTime)+'px',
            width: getWidthAbout2Times(item.actualStartTime, currentDate) - 6 +'px'
          }"
        />
        <div
          class="actul-common-color"
          :style="{
            left: getPositonOffset(currentDate)+'px',
            width: getWidthAbout2Times(currentDate, item.planEndTime)+'px'
          }"
        />
      </div>
      <!-- 实际开始时间小于计划开始时间 && 计划结束时间和计划开始时间都小于当前时间/会覆盖计划时间-->
      <div
        v-if="
          item.actualStartTime &&
            compareDate(item.planStartTime, item.actualStartTime) &&
            !compareDate(item.planStartTime, currentDate) &&
            compareDate(currentDate, item.planEndTime)
        "
        class="actul-time cc"
      >
        <div
          class="actul-time-color"
          :style="{
            left: getPositonOffset(item.actualStartTime)+'px',
            width: getWidthAbout2Times(item.actualStartTime, item.planEndTime) +'px'
          }"
        />

        <div
          class="actul-end-color"
          :style="{
            left:getPositonOffset(item.planEndTime)+'px',
            width:getWidthAbout2Times(item.planEndTime, currentDate)- 6 +'px'}
          "
        />
      </div>
      <!-- <img src="@/assets/plans/lock.png" class="imgs" v-if="item.status === 1 || item.status === 3 || item.status === 4"/> -->
    </div>
    <!-- 标签文案 -->
    <div class="detail">
      <div class="li-right-top">
        <div class="title">{{ item.materialsName }}</div>
        <div class="tips">
          <div
            class="tip"
            :style="{
              color: '#fff',
              background: item.produceStatus === 0 ? 'rgba(224, 32, 32, 1)' : item.produceStatus === 1 ? 'rgba(0, 171, 41, 1)' : 'rgba(96, 127, 255, 1)'}"
          >
            {{ productionStatus[item.produceStatus].name }}
          </div>
          <img v-if="item.isRepair === 1" src="@/assets/plans/fanxiu-plan.png" class="tip-img">
        </div>
      </div>
      <div class="des">
        <span>{{ item.reportWorkQuantity ? item.reportWorkQuantity : 0 }}/{{ item.quantity }}</span>
      </div>
      <div class="des-title">当前工序：{{ item.produceWorkOrderTaskList ? item.produceWorkOrderTaskList[0].procedureName : '' }}</div>
      <div v-if="item.isRepair !== 1" class="des-tit">生产订单号：{{ item.produceOrderNumber }}</div>
      <div class="des-tit">物料规格：{{ item.materialsSpecifications }}</div>
      <div class="des-tit">物料编码：{{ item.materialsCode }}</div>
      <div class="des-tit">交期：{{ item.deliveryTime }}</div>
      <div class="dec">计划优先级：
        <span :style="{color: priorityStatus[item.priority].name === '普通' ? '#fff' : priorityStatus[item.priority].color}">{{ priorityStatus[item.priority].name }} </span>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { productionStatus } from '@/config/options.config'
import dayjs from 'dayjs'
import { isUndef, warn } from '@/components/ganttChart/utils/tool.js'

const NOW_PLAN = ''
const FUTHER_PLAN = ''
const PAST_PLAN = ''
export default {
  name: 'Test',
  props: {
    data: Object,
    item: Object,
    currentTime: dayjs,
    updateTimeLines: Function,
    cellHeight: Number,
    startTimeOfRenderArea: Number,
    getPositonOffset: Function,
    getWidthAbout2Times: Function
  },
  data() {
    return {
      productionStatus,
      dayjs: dayjs,
      currentDate: ''
    }
  },
  computed: {
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    statusColor() {
      const { item, currentTime } = this
      const start = dayjs(item.start)
      const end = dayjs(item.end)
      if (start.isBefore(currentTime) && end.isAfter(currentTime)) {
        return NOW_PLAN // NOW
      } else if (end.isBefore(currentTime)) {
        return PAST_PLAN // PAST
      } else {
        return FUTHER_PLAN // Future
      }
    },
    startToString() {
      return dayjs(this.item.end).format('HH:mm')
    },
    endToString() {
      return dayjs(this.item.start).format('HH:mm')
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  mounted() {
    // console.log('123=>', this.$store.state.user.priorityList)
    this.currentDate = dayjs().format('YYYY-MM-DD HH:mm:ss')
  },
  methods: {
    compareDate(d1, d2) {
      return ((new Date(d1.replace(/\-/g, '\/'))) > (new Date(d2.replace(/\-/g, '\/'))))
    },
    onClick() {
      this.updateTimeLines(this.item.start, this.item.end)
      this.$emit('modifys', { id: this.item.id, type: 2 })
    },
    getWidth(start, end) {
      if (isUndef(start) || isUndef(end)) {
        // warn(`错误，该数据项不含start值 与 end 值 ${JSON.stringify(block)}，无法计算宽度值。`)
        return 0
      }

      return this.getWidthAbout2Times(start, end)
    },
    /**
       * 计算时间块偏移
       *
       * @param {{start:string}} block
       * @returns {number}
       */
    getPosition(start) {
      if (isUndef(start)) {
        warn(
          `错误，该数据项不含start 值 ${JSON.stringify(
            start
          )}，无法计算偏移值。`
        )
        return 0
      }
      return this.getPositonOffset(start)
    }
  }
}
</script>
<style lang="scss" >
.povers {
  position: relative;
}
.pover-tips{
  width: 357px;
  padding-bottom: 5px;
  // right: 50%;
  background: rgba(0, 0, 0, 0.7)!important;
}
.pover-tips[x-placement^=right] .popper__arrow{
  /*el-popover上边三角下层的颜色---也就是视觉上的边框*/
  border-right-color: rgba(0, 0, 0, 0.7)!important;
}
.pover-tips[x-placement^=right] .popper__arrow::after{
  /*el-popover上边三角表层的颜色---也就是视觉上的角*/
  border-right-color: rgba(0, 0, 0, 0.7)!important;
}
.pover-tips[x-placement^=left] .popper__arrow{
  /*el-popover上边三角下层的颜色---也就是视觉上的边框*/
  border-left-color: rgba(0, 0, 0, 0.7)!important;
}
.pover-tips[x-placement^=left] .popper__arrow::after{
  /*el-popover上边三角表层的颜色---也就是视觉上的角*/
  border-left-color: rgba(0, 0, 0, 0.7)!important;
}
</style>
<style lang="scss" scoped>

.middle {
  flex: 1;
  text-align: center;
  padding-left: 5px;
}
.runTime {
  display: flex;
  flex-direction: column;
  width: 6px;
  height: 18px;
  background: #9597AE;
  border-radius: 4px 0px 0px 4px;
}
.lines {
  // background: #fff;
  height: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .circle {
    width:8px;
    height:8px;
    border: 1px solid #ddd;
    border-radius: 100%;
  }
  .line {
    height: 1px;
    background: #ddd;
    line-height: 18px;
    display: inline-block;
    width: 100%;
  }
}
.plan-time-color {
  background: rgba(164, 179, 198, 0.4);
  border-radius: 4px;
}
.actul-common-color {
  text-align: center;
  padding-left: 5px;
  background: rgba(164, 179, 198, 0.3);
  border-radius: 0 4px 4px 0;
  height: 18px;
}
.actul-time-color {
  text-align: center;
  padding-left: 5px;
  background: rgba(0, 171, 41, 0.4);
  border-radius: 0 4px 4px 0;
  height: 18px;
  // position: absolute;
}
.actul-time {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.actul-end-color {
  text-align: center;
  padding-left: 5px;
  background: rgba(247, 181, 0, 0.4);
  border-radius: 0 4px 4px 0;
  height: 18px;
  // position: absolute;
}
.plan {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 5px;
  color: #333333;
  font-size: 0.8rem;
  height: 18px;
  background: rgba(164, 179, 198, 0.1);
  border-radius: 4px;
  border: 1px dashed #A4B3C6;
  border-left: none;
  cursor: pointer;
  position: relative;
  // opacity: 0.8;
  &:hover {
    box-shadow: 0px 2px 10px 0px rgba(68, 68, 68, 0.2);
  }
}
.noplan {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  // border: 1px solid #f0f0f0;
  border-radius: 5px;
  color: #333333;
  font-size: 0.8rem;
  height: 18px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: 0px 2px 10px 0px rgba(68, 68, 68, 0.2);
  }
  .imgs {
    width: 9px;
    height: 12px;
    display: block;
    position: absolute;
    right: 5px;
  }
}
.detail {
  .header {
    text-align: center;
    font-size: 1rem;
  }
  .li-right-top {
    display: flex;
    justify-content: space-between;
    .title {
      width: 205px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fff;
      line-height: 22px;
    }
    .tips {
      position: relative;
    }
    .tip-img {
      position: absolute;
      top: 32px;
      width: 58px;
      height: 22px;
      display: block;
    }
    .tip {
      padding: 0 10px;
      height: 22px;
      line-height: 22px;
      border-radius: 4px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #fff;
    }
  }
  .des {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: 20px;
    margin: 5px 0;
    height: 20px;
  }
  .des-title {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: 17px;
  }
  .des-tit {
    // height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #bbb;
    line-height: 17px;
    margin: 5px 0;
  }
  .dec {
    // height: 35px;
    border-top: 1px solid #666;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
  }
}

.detail ul {
  list-style: none;
  padding: 0px;
  li {
    span {
      display: inline-block;
      width: 80px;
      color: #777;
      font-size: 0.8rem;
    }
    span:first-child {
      text-align: right;
    }

    span:last-child {
    }
  }
}

</style>
