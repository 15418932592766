<template>
  <div class="memberRouteSetting_container" :style="{height: chromeHeight + 'px'}">
    <div class="container_left">
      <img :src="src" style="width: 100%">
    </div>
    <div class="container_right">
      <div style="display: flex;justify-content: center;align-items: center;margin-top: 20px">
        <el-button :disabled="!selectedFlag" type="primary" @click="bindA(1)">绑定</el-button>
        <el-button :disabled="src == 'https://oss.mubyte.cn/mom/00001.png'" type="primary" style="margin-left: 20px" @click="bindA(0)">解绑</el-button>
      </div>
      <MTable
        ref="mTable"
        style="margin-top: 30px;"
        :columns="columnsAll"
        highlight-current-row
        :set-data-method="getTableData"
        :height="height"
        @current-change="handleCurrentChange"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

      </MTable>
    </div>

  </div>
</template>

<script>
import api from '@/api/production/prod-plan'

export default {
  name: 'MemberRouteSet2',
  data() {
    return {
      src:'https://oss.mubyte.cn/mom/00001.png',
      selectedFlag:false,
      columnsAll:[
        {
          prop: 'index',
          label: '序号',
          slotName: 'index',
          hiddenSearch: true,
          sortable: false,
          width: 50
        },
        {
          prop: 'materialsCode',
          label: '物料编码',
          hiddenSearch: true,
          sortable: false,
          width: 130
        },
        {
          prop: 'materialsName',
          label: '物料名称',
          hiddenSearch: true,
          sortable: false,
          width: 140
        },
        {
          prop: 'productionPlanNumber',
          label: '工单编号',
          hiddenSearch: true,
          sortable: false,
          width: 140
        },
      ]
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192 - 35
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    chromeHeight() {
      return document.documentElement.clientHeight - 120
    }
  },
  async created() {

  },
  beforeMount() {

  },
  methods: {
    bindA(index){
      if(index === 1){
        this.src = "https://oss.mubyte.cn/mom/00002.png"
      }else if (index === 0){
        this.src = "https://oss.mubyte.cn/mom/00001.png"
      }
    },
    handleCurrentChange(val) {
      this.selectedFlag = true
    },
    async getTableData(condition, callback) {
      const { page, order } = condition
      const res = await api.getPlanList({
        page: page.currentPage,
        size: page.pageSize,
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
  }
}
</script>

<style scoped lang="scss">
.el-table thead {
  color: #909399;
  font-weight: 500;
}

.el-table .has-gutter th {
  background-color: #f5f7fa !important;
}

.memberRouteSetting_container {
  width: 100%;
  box-sizing: border-box;
  display: flex;

  .container_left {
    width: 70%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
  }

  .container_right {
    width: 30%;
    height: 100%;
    box-sizing: border-box;
  }
}
</style>

