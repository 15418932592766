var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-wrap" },
    [
      _c(
        "div",
        { staticClass: "orders" },
        [
          _c(
            "div",
            { staticClass: "top" },
            [
              _c("SearchForm", {
                ref: "searchForm",
                staticStyle: { padding: "0" },
                attrs: {
                  "form-list-extend": _vm.taskFormList,
                  "form-data": _vm.searchFormData,
                  "default-form": _vm.defaultForm
                },
                on: {
                  "update:formData": function(data) {
                    return (_vm.searchFormData = data)
                  },
                  search: _vm.reSearch
                }
              })
            ],
            1
          ),
          _c(
            "FunctionHeader",
            {
              ref: "functionHeader",
              staticClass: "func-header",
              staticStyle: { padding: "10px 0 10px 0" },
              attrs: {
                "search-title": "搜索工序定位码/编号/名称",
                tags: _vm.functionHeader.tags
              },
              on: {
                search: function($event) {
                  return _vm.$refs.mTable.setTableData(true)
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-left": "10px",
                    display: "flex",
                    "align-items": "center"
                  },
                  attrs: { slot: "all" },
                  slot: "all"
                },
                [
                  _vm.permission("BatchExport")
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: !_vm.selectData.length,
                            size: "small",
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.batchExport()
                            }
                          }
                        },
                        [_vm._v("批量导出")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "all-select" },
                    [
                      _c(
                        "div",
                        { staticClass: "checkAll" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { indeterminate: _vm.isIndeterminate },
                              on: { change: _vm.selectCheck },
                              model: {
                                value: _vm.checkeds,
                                callback: function($$v) {
                                  _vm.checkeds = $$v
                                },
                                expression: "checkeds"
                              }
                            },
                            [_vm._v("全选")]
                          )
                        ],
                        1
                      ),
                      _vm._l(_vm.allStatus, function(item, index) {
                        return _c(
                          "div",
                          {
                            key: item.value,
                            staticClass: "all-li",
                            class: item.checked ? "active" : "",
                            on: {
                              click: function($event) {
                                return _vm.selectTab(item, index)
                              }
                            }
                          },
                          [_vm._v(_vm._s(item.label))]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ]
          ),
          _c("MTable", {
            ref: "mTable",
            staticClass: "table-left",
            attrs: {
              columns: _vm.productionTaskColumn,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            on: {
              "selection-change": function(data) {
                return (_vm.selectData = data)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "productionStatus",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      " " +
                        _vm._s(
                          ["待开始", "进行中", "已完成"][row.productionStatus]
                        ) +
                        " "
                    )
                  ])
                }
              },
              {
                key: "assignStatus",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      " " + _vm._s(["未派工", "已派工"][row.assignStatus]) + " "
                    )
                  ])
                }
              },
              {
                key: "materialsCode",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "materialsDetail",
                        query: { id: row.materialsId, type: "view" }
                      },
                      text: row.materialsCode
                    }
                  })
                }
              },
              {
                key: "procedureCode",
                fn: function(ref) {
                  var row = ref.row
                  return _c("NewPageOpen", {
                    attrs: {
                      path: {
                        name: "procedureDetail",
                        query: { id: row.procedureId, type: "view" }
                      },
                      text: row.procedureCode
                    }
                  })
                }
              },
              {
                key: "planOutputQuantity",
                fn: function(ref) {
                  var row = ref.row
                  return [_c("div", [_vm._v(_vm._s(row.planOutputQuantity))])]
                }
              },
              {
                key: "planTime",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    !row.isEdit
                      ? _c("div", [
                          _vm._v(
                            _vm._s(
                              row.planTime[0] || row.planTime[1]
                                ? row.planTime.join(" 至 ")
                                : "-"
                            )
                          )
                        ])
                      : _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            clearable: false,
                            "range-separator": "至",
                            "start-placeholder": "计划开工时间",
                            "end-placeholder": "完工时间",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            format: "yyyy-MM-dd HH:mm:ss"
                          },
                          model: {
                            value: row.planTime,
                            callback: function($$v) {
                              _vm.$set(row, "planTime", $$v)
                            },
                            expression: "row.planTime"
                          }
                        })
                  ]
                }
              },
              {
                key: "executorName",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(
                      " " +
                        _vm._s(
                          row.userList.length
                            ? row.userList
                                .map(function(item) {
                                  return item.name
                                })
                                .join(",")
                            : "-"
                        ) +
                        " "
                    )
                  ])
                }
              },
              {
                key: "remark",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    !row.isEdit
                      ? _c("div", [_vm._v(_vm._s(row.remark))])
                      : _c("el-input", {
                          model: {
                            value: row.remark,
                            callback: function($$v) {
                              _vm.$set(row, "remark", $$v)
                            },
                            expression: "row.remark"
                          }
                        })
                  ]
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "div",
                      [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !row.isEdit,
                                expression: "!row.isEdit"
                              }
                            ]
                          },
                          [
                            ([0, 1].includes(row.productionStatus) ||
                              _vm.hasEditData) &&
                            _vm.permission("Edit")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.modify(row)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v("编辑"),
                                    _c("i", { staticClass: "el-icon-edit" })
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: row.isEdit,
                                expression: "row.isEdit"
                              }
                            ]
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.cancelModify(row)
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onSave(row)
                                  }
                                }
                              },
                              [_vm._v("保存")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        row.productionStatus === 1 &&
                        _vm.permission("CompleteWork")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.completeWork(row)
                                  }
                                }
                              },
                              [_vm._v("完工")]
                            )
                          : _vm._e(),
                        +row.producePlanStatus !== 1 &&
                        row.productionStatus === 2 &&
                        _vm.permission("Restart")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.restart(row)
                                  }
                                }
                              },
                              [_vm._v("重启")]
                            )
                          : _vm._e(),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              content: "所属生产工单已结案，无法重启",
                              placement: "top"
                            }
                          },
                          [
                            +row.producePlanStatus === 1 &&
                            row.productionStatus === 2 &&
                            _vm.permission("Restart")
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "is-disabled",
                                    attrs: { type: "text" }
                                  },
                                  [_vm._v("重启")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.permission("Assign")
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: _vm.assignProductionTask(
                                    row.userGroupList.length ||
                                      row.userList.length
                                      ? "已派工"
                                      : "派工任务"
                                  ),
                                  disabled: row.productionStatus === 2
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.assignClick(
                                      row,
                                      row.userGroupList.length ||
                                        row.userList.length
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    row.userGroupList.length ||
                                      row.userList.length
                                      ? "已派工"
                                      : "派工任务"
                                  )
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.permission("DelayRecord")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.viewDelayRecord(row)
                                  }
                                }
                              },
                              [_vm._v("延期记录")]
                            )
                          : _vm._e(),
                        _vm.permission("SetFactorySequenceNumber") &&
                        row.factoryPrinting &&
                        !row.factorySequenceNumberRule
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.setFactorySequenceNumber(row)
                                  }
                                }
                              },
                              [_vm._v("设置出产序列号")]
                            )
                          : _vm._e(),
                        _vm.permission("PrintCertificate") &&
                        row.factoryPrinting &&
                        row.factorySequenceNumber
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.print(row)
                                  }
                                }
                              },
                              [_vm._v("打印")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "派工任务" },
          on: { onOk: _vm.submitUser },
          model: {
            value: _vm.assignVisibleNo,
            callback: function($$v) {
              _vm.assignVisibleNo = $$v
            },
            expression: "assignVisibleNo"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-bottom": "10px" } },
            [
              _vm.showAssign
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.cancelAssign(1)
                        }
                      }
                    },
                    [_vm._v("取消派工")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("SelectUserAndUserGroup", {
            ref: "userAndUserGroup",
            attrs: {
              "is-assign": false,
              "user-options": _vm.userOptions,
              "user-group-options": _vm.userGroupOptions
            },
            model: {
              value: _vm.transferSelectIds,
              callback: function($$v) {
                _vm.transferSelectIds = $$v
              },
              expression: "transferSelectIds"
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { "show-footer": false, title: "延期记录" },
          on: { fullScreen: _vm.fullScreenChange },
          model: {
            value: _vm.delayVisible,
            callback: function($$v) {
              _vm.delayVisible = $$v
            },
            expression: "delayVisible"
          }
        },
        [
          _c("MTable", {
            ref: "dTable",
            attrs: {
              columns: _vm.delayItemColumns,
              height: _vm.dialogHeight,
              "set-data-method": _vm.getDelayItemLog
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "设置出厂序列号" },
          on: { onOk: _vm.submitStationForm },
          model: {
            value: _vm.stationVisible,
            callback: function($$v) {
              _vm.stationVisible = $$v
            },
            expression: "stationVisible"
          }
        },
        [
          _c("el-input", {
            staticStyle: { width: "100%" },
            attrs: { placeholder: "出厂序列号" },
            model: {
              value: _vm.factorySequenceNumber,
              callback: function($$v) {
                _vm.factorySequenceNumber = $$v
              },
              expression: "factorySequenceNumber"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }