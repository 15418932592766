
export const options = [
  { name: '是', key: 1 },
  { name: '否', key: 0 }
]
// 新增生产任务表单字段
export const searchForm = [
  {
    colSpan: 4,
    key: 'producePlanNumber',
    tagName: 'el-input',
    attrs: {
      placeholder: '生产工单号'
    }
  },
  {
    colSpan: 4,
    key: 'productionTaskCode',
    tagName: 'el-input',
    attrs: {
      placeholder: '生产任务号'
    }
  },
  {
    colSpan: 4,
    key: 'procedure',
    tagName: 'el-input',
    attrs: {
      placeholder: '工序编码/名称'
    }
  },
  {
    colSpan: 4,
    key: 'isKeyMaterials',
    tagName: 'el-select',
    props: {
      placeholder: '是否为关键料'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'key'
        },
        options
      }
    ]
  },
  {
    colSpan: 4,
    key: 'receivedNumber',
    tagName: 'el-input',
    attrs: {
      placeholder: '收货单号'
    }
  },
  {
    colSpan: 4,
    key: 'warehouseName',
    tagName: 'el-input',
    attrs: {
      placeholder: '仓库'
    }
  },
  {
    colSpan: 4,
    key: 'warehouseLocationName',
    tagName: 'el-input',
    attrs: {
      placeholder: '库位'
    }
  },
  {
    colSpan: 4,
    key: 'requisitionSourceNumber',
    tagName: 'el-input',
    attrs: {
      placeholder: '请购来源单号'
    }
  },
  {
    colSpan: 4,
    key: 'purchaseOrderNumber',
    tagName: 'el-input',
    attrs: {
      placeholder: '采购单'
    }
  },
  {
    colSpan: 8,
    key: 'inspectionTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '报检时间开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 8,
    key: 'planTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '计划开工开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm',
      defaultTime: ['00:00:00', '23:59:59']
    }
  }
]

// 新增生产任务列表字段
export const columns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'productionTaskCode',
    label: '生产任务号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'producePlanNumber',
    label: '生产工单编号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    label: '工序名称',
    sortable: false,
    prop: 'procedureName',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'procedureCode',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'specifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'planStartTime',
    label: '计划开工时间',
    hiddenSearch: true,
    sortable: 'custom',
    width: 350
  },
  {
    prop: 'isKeyMaterials',
    slotName: 'isKeyMaterials',
    label: '是否为关键料',
    hiddenSearch: true,
    sortable: 'custom',
    width: 140
  },
  {
    prop: 'receivedInspectionQuantity',
    label: '已收待检量',
    hiddenSearch: true,
    sortable: false,
    width: 350
  },
  {
    prop: 'receivedNumber',
    label: '收货单号',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'receivedItem',
    label: '收货单项次',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'requisitionSourceNumber',
    label: '请购来源单号',
    hiddenSearch: true,
    sortable: 'custom',
    width: 120
  },
  {
    prop: 'purchaseOrderNumber',
    label: '采购单',
    hiddenSearch: true,
    sortable: 'custom',
    width: 120
  },
  {
    prop: 'inspectionTime',
    label: '报检时间',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'retentionTime',
    label: '滞留时长/h',
    hiddenSearch: true,
    sortable: 'custom',
    width: 120
  }
]
export const materialsColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 60
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 1
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 2
  },
  {
    prop: 'specifications',
    label: '物料规格',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'isKeyMaterials',
    label: '是否关键件',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100,
    slotName:'isKeyMaterials'
  },
  {
    prop: 'isExemption',
    label: '是否免检',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100,
    slotName:'isExemption'
  },
  {
    prop: 'isStrengthenInspection',
    label: '是否加强检',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100,
    slotName:'isStrengthenInspection'
  },
  {
    prop: 'quantity',
    label: '批次数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'sequenceNumber',
    minWidth: 200
  },
  {
    prop: 'supplierName',
    label: '供应商',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'purchaseOrderCode',
    label: '采购单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsReceivedOrderCode',
    label: '收料单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'settingVariable',
    label: '是否设变件',
    hiddenSearch: true,
    sortable: false,
    slotName:'settingVariable'
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 80
  }
]
