<template>
  <div class="schedule-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button v-if="permission('NoScheduleList')" label="NoSchedule">未排程</el-radio-button>
      <el-radio-button v-if="permission('HaveScheduleList')" label="HaveSchedule">已排程</el-radio-button>
    </el-radio-group>
   <div class="bottom">
     <div class="top">
      <SearchForms ref="searchForms" :form-option="formOptions" :is-have="isHave" @resetData="resetData" @searchData="searchTable" />
    </div>
    <el-divider class="e-divider" />
    <div class="materials-info">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :show-export = "!selectionData.length ? true : false"
        :radio="params.radio"
        search-title="请输入生产计划号/订单号"
        :style="{ marginLeft:selectionData.length ? 0 :'-10px'}"
        :columns-key="columnsKey"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" v-show="selectionData.length">
          <el-button v-if="permission(params.radio + 'DownWorkOrder')" type="primary" size="small" @click="allXia()">批量下发</el-button>
        <el-button v-if="permission(params.radio + 'Stock')" size="small" type="primary"  @click="batchStock()">批量备料</el-button>
        <el-button v-if="permission(params.radio + 'Export')" size="small" type="primary"  @click="batchExport()">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable
        v-if="permission(params.radio + 'List')"
        ref="mTable"
        :columns="columnsAll"
        :set-data-method="getTableData"
        :height="height"
        :columns-key="columnsKey"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="source" slot-scope="{ row }">
          <div>{{ planSource[row.source].name }}</div>
        </div>
        <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
        <div slot="priority" slot-scope="{ row }" style="display: flex;align-items: center;">
          <div
            :style="{ color: priorityStatus[row.priority] ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)', background: `${priorityStatus[row.priority] ? priorityStatus[row.priority].color : 'rgb(74, 79, 118)'}20`}"
            class="tag-priority"
          >
            {{ priorityStatus[row.priority] ? priorityStatus[row.priority].name : '普通' }}
          </div>
        </div>
        <div slot="status" slot-scope="{ row }">
          <div>{{ planStatus[row.status].name }}</div>
        </div>
        <div slot="materialPreparationStatus" slot-scope="{ row }" style="display: flex;align-items: center;">
          <div
            v-if="row.materialPreparationStatus || row.materialPreparationStatus === 0"
            :style="{ color: preparationStatus[row.materialPreparationStatus].color ? preparationStatus[row.materialPreparationStatus].color : 'rgb(74, 79, 118)', background: `${preparationStatus[row.materialPreparationStatus].color ? preparationStatus[row.materialPreparationStatus].color : 'rgb(74, 79, 118)'}20`}"
            class="tag-priority"
          >
            {{ preparationStatus[row.materialPreparationStatus].name ? preparationStatus[row.materialPreparationStatus].name : '待下发' }}
          </div>
        </div>
        <div slot="type" slot-scope="{ row }">
          <div>{{ productionType[row.type].name }}</div>
        </div>
        <div slot="isRepair" slot-scope="{ row }">
          <div>{{ row.isRepair === 0 ? '普通' : '返修' }}</div>
        </div>
        <div slot="producePlanNumber" slot-scope="{ row }">
          <img v-if="row.isRepair === 1" src="@/assets/plans/repair-icon-have.png" class="icon-img">
          <a class="li-btn" @click="modify(row, 1)">{{ row.producePlanNumber }}</a>
        </div>

        <div slot="produceStatus" slot-scope="{ row }">
          <div :style="{color: row.produceStatus === 0 ? '#FA6400' : row.produceStatus === 1 ? '#00AB29' : '#607fff'}"> {{ productionStatus[row.produceStatus].name }}</div>
        </div>
        <div slot="processFlowName" slot-scope="{ row }">
          <el-button type="text" @click="showProcess(row)">{{ row.processFlowName }}</el-button>
        </div>
        <div slot="produceOrderNumber" slot-scope="{ row }">
          <NewPageOpen :path="{ name: 'orderDetail', query: {id: row.produceOrderId}}" :text="row.produceOrderNumber" />
        </div>
        <div slot="action" slot-scope="{ row }">
          <template v-if="permission(params.radio + 'Stock')">
            <a v-if="row.materialPreparationStatus !== 0" class="li-btn" @click="stock(row)">备料</a>
            <a v-else class="li-btn color-btns">备料</a>
          </template>
          <el-divider v-if="permission(params.radio + 'Stock')" direction="vertical" />

          <template v-if="permission(params.radio + 'DownWorkOrder')">
            <a v-if="row.status === 0 || row.status === 2" class="li-btn" @click="fixWorkOrder(row, 1)">下发</a>
            <a v-else class="li-btn color-btns">下发</a>
          </template>
          <el-divider v-if="permission(params.radio + 'DownWorkOrder')" direction="vertical" />
          <template v-if="permission(params.radio + 'Modify')">
            <a v-if="row.status === 0 || row.status === 2 || row.status === 3" class="li-btn" @click="modify(row)">修改</a>
            <a v-else class="li-btn color-btns">修改</a>
          </template>
          <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
          <template v-if="permission(params.radio + 'Del')">
            <a v-if="row.status === 0 || row.status === 2" class="li-btn" @click="del(row)">删除</a>
            <a v-else class="li-btn color-btns">删除</a>
          </template>
          <!-- <el-divider v-if="permission(params.radio + 'Del')" direction="vertical" /> -->


          <!-- <el-dropdown v-if="row.status === 0 || row.status === 2" @command="(command) => { more(row, command)}">
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="permission(params.radio + 'Modify')" command="modify">修改</el-dropdown-item>
              <el-dropdown-item v-if="permission(params.radio + 'Del')" command="del">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
      </MTable>
    </div>
   </div>
    <!-- 修改计划-->
    <MDialog
      v-model="dialogVisible"
      :title="titles"
      :append-to-body="true"
      :show-footer="!formDisabled"
      @onOk="dialogSubmit"
    >
      <MFormBuilder
        ref="dialogFormBuild"
        :form-data="dialogFormData"
        :form-list="dialogFormList"
        :custom-rules="rules"
        :disabled="formDisabled"
        :col-span="12"
        class="dialogFormBuilds"
      >
        <div slot="process">
          <el-input v-model="params.processFlowName" class="all-ipt">
            <template slot="append">
              <el-button icon="el-icon-more" @click="selectProcessFlows" />
            </template>
          </el-input>
        </div>
      </MFormBuilder>
    </MDialog>
    <!-- 修改计划时间-->
    <MDialog
      v-model="dialogTimeVisible"
      title="修改计划时间"
      :append-to-body="true"
      width="85%"
      @onOk="dialogTimeSubmit"
    >
      <el-button size="small" type="primary" style="margin-bottom: 10px" @click="addTime">批量填充</el-button>
      <MTable
        ref="mTables"
        :columns="columnsPlanTime"
        :data="planList"
        :height="300"
        :show-page="false"
        style="margin-bottom: 10px;"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="planStartTime" slot-scope="{ row, $index }">
          <el-date-picker v-model="row.planStartTime" type="datetime" placeholder="开始日期" style="width: 100%;margin-top: 6px;" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" />
        </div>
        <div slot="planEndTime" slot-scope="{ row, $index }">
          <el-date-picker v-model="row.planEndTime" type="datetime" placeholder="结束日期" style="width: 100%;" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" />
        </div>
      </MTable>
    </MDialog>
    <!-- 批量填充-->
    <MDialog
      v-model="dialogAllTimeVisible"
      title="批量填充"
      :append-to-body="true"
      @onOk="dialogAllTimeSubmit"
    >
      <div class="item-wrapper" style="display: flex;justify-content: flex-start;align-items: center">
        <div class="label">计划时间：</div>
        <div>
          <el-date-picker
            v-model="planAllTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="计划开始时间"
            end-placeholder="计划结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            clearable
          />
        </div>
      </div>
    </MDialog>
    <!-- 查看工艺路线 -->
    <MDialog v-model="processVisible" title="工艺路线" :show-footer="false" @onOk="processVisible = false">
      <process-flow ref="processFlow" :read-only="true" :process-flow-detail="processFlowVersion" :show-version="true" />
    </MDialog>
    <!-- 选择工艺路线 -->
    <SelectProcessFlow
      ref="processFlow"
      :visible.sync="processFlowVisible"
      :select-process-and-version-ids="processIdAndVersionId"
      :proce-type="proceType"
      @submit="selectFlow"
    />
    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />
    <MDialog v-model="stockVisible" title="备料" @onOk="submitProcessForm">
      <div class="item-wrapper" style="display: flex;justify-content: flex-start;align-items: center">
        <div class="label">备料方式：</div>
        <div>
          <el-radio-group v-model="tongRadio" style="display: block;margin-bottom: 10px;margin-top: 10px;">
            <el-radio :label="0" value="0" style="display: block;margin-bottom: 10px;">工单整单备料（默认）</el-radio>
            <el-radio :label="1" value="1">按工序备料</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div v-if="+tongRadio === 1" class="item-wrapper" style="display: flex;justify-content: flex-start;align-items: center">
        <div class="label">选择工序：</div>
        <div>
          <el-select v-model="produces" placeholder="全部" filterable multiple clearable collapse-tags>
            <el-option
              v-for="item in produceList"
              :key="item.procedureId"
              :label="item.procedureName"
              :value="item.procedureId"
            />
          </el-select>
        </div>
      </div>
    </MDialog>
  </div>
</template>
<script>
import SearchForms from './search-forms'
import FunctionHeader from '@/components/FunctionHeader/index'
import { columnsPlanTime, columnsAll, getFunctionList, searchFormList, dialogFormList } from './data'
import api from '@/api/production/plans'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import { preparationStatus, planSource, noPlanStatus, havePlanStatus, planStatus, productionType, productionStatus, procedureClass } from '@/config/options.config'
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import processApi from '@/api/information/production/process'
import SelectProcessFlow from '@/components/SelectProcessFlow/SelectProcessFlow'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import { export_json_to_excel2 } from '@/utils/plug/to-excel'
import dayjs from 'dayjs'

export default {
  name: 'PlanList',
  components: { NewPageOpen, EditMaterials, FunctionHeader, SearchForms, ProcessFlow, SelectProcessFlow },
  mixins: [viewMaterialsDetail],
  data() {
    return {
      api,
      preparationStatus,
      columnsPlanTime,
      tongRadio: 0,
      produceList: [],
      produces: [],
      stockVisible: false,
      planAllTime: [],
      planList: [],
      titles: '修改计划',
      dialogAllTimeVisible: false,
      dialogTimeVisible: false,
      columnsAll,
      searchFormList,
      planSource,
      planStatus,
      noPlanStatus,
      havePlanStatus,
      productionType,
      productionStatus,
      dialogFormList,
      getFunctionList,
      procedureClass,
      dialogVisible: false,
      dialogFormData: {},
      keyword: '',
      searchDatas: [
        {
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: '2^3',
          operator: 'in'
        }
      ],
      selectionData: [],
      currentType: 1,
      params: {
        radio: 'HaveSchedule',
        processFlowName: '',
        processFlowId: undefined,
        processFlowVersionId: undefined
      },
      functionHeader: {
        searchVal: '',
        exportName: 'exportPlan',
        exportParams: {}
      },
      selectKeyword: '1',
      fixId: '',
      formDisabled: false,
      rules: {
        processFlowId: [
          { required: true, message: '请选择工艺', trigger: 'change' }
        ],
        areaId: [
          { required: true, message: '请选择产线', trigger: 'change' }
        ],
        deliveryTime: [
          { required: true, message: '请选择交期', trigger: 'change' }
        ]
      },
      formOptions: {
        flowerOptions: [],
        areaOptions: []
      },
      flag: 'HaveSchedule',
      isHave: false,
      isShow: true,
      processVisible: false,
      processFlowVersion: {},
      processFlowVisible: false,
      processIdAndVersionId: [],
      proceType: 0,
      selectionDataList: [],
      total:0
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'refresh') {
      this.$store.dispatch('tagsView/addCachedView', from)
    }
    next()
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 48 - 12
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionDataList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  async beforeMount() {
    getFunctionList({
      processFlowChange: this.processFlowChange
    })
  },
  async mounted() {
    await this.processFlowList()
    this.getAreaList()
    this.functionHeader.exportParams = {
      searchVal: Encrypt(JSON.stringify([{
        id: getUUid(),
        fieldName: 'isPlan',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      }])
      )
    }
  },
  methods: {
    resetSelectData() {
      this.selectionDataList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionDataList = this.selectionDataList.filter((item) => item.selectable)
    },
    batchStock() {
      const that = this
      if (this.selectionDataList.length > 0) {
        const isAssign = this.selectionDataList.some(item => +item.materialPreparationStatus === 0)
        if (isAssign) {
          this.$message.error('存在备料未下发的数据')
          return false
        }
        this.$confirm('批量备料只支持按工单备料', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.addBatchPlanStocking(that.selectionData)
          if (res) {
            this.$message.success('备料成功')
            this.$refs.mTable.tableRefs().clearSelection()
            this.$refs.mTable.setTableData()
          }
        })
      } else {
        this.$message.error('请选择要备料的数据')
      }
    },
    // 工序
    async getProduce() {
      const newData = [
        {
          id: getUUid(),
          fieldName: 'producePlanId',
          fieldType: 'number',
          fieldValue: this.planId,
          operator: 'eq'
        }
      ]
      const res = await api.getPlanStockingList({
        searchVal: Encrypt(JSON.stringify([...newData]))
      })
      if (res) {
        if (res.length > 0) {
          const list = res.filter(item => item.procedureId)
          const newArr = list.reduce(function(tempArr, item) {
            if (tempArr.findIndex((ele) => ele.procedureId === item.procedureId) === -1) {
              tempArr.push(item)
            }
            return tempArr
          }, [])
          this.produceList = newArr
          return false
        }
        this.produceList = []
      }
    },
    async submitProcessForm(done) {
      const res = await api.addPlanStocking({
        producePlanId: this.planId,
        materialPreparation: this.tongRadio,
        list: this.produces
      })
      if (res) {
        this.$message.success('备料成功')
        this.stockVisible = false
        done()
        return
      }
      done()
    },
    async stock(row) {
      this.planId = row.id
      this.tongRadio = 0
      this.produceList = []
      await this.getProduce()
      this.stockVisible = true
    },
    addTime() {
      this.planAllTime = []
      this.dialogAllTimeVisible = true
    },
    // 批量处理计划时间
    async dialogTimeSubmit(done) {
      const obj = this.planList.map(item => {
        return {
          id: item.id,
          planStartTime: item.planStartTime,
          planEndTime: item.planEndTime
        }
      })
      const res = await api.modifyBatchPlanTime(obj)
      if (res) {
        const ids = this.planList.map(item => item.id)
        await this.saveData(ids, done)
        done()
        return false
      }
      done()
    },
    dialogAllTimeSubmit(done) {
      done()
      if (this.planAllTime.length > 0) {
        this.planList = this.planList.map(item => {
          return {
            ...item,
            planStartTime: this.planAllTime[0],
            planEndTime: this.planAllTime[1]
          }
        })
        this.dialogAllTimeVisible = false
        return false
      }
      this.$message.info('请选择计划时间')
    },
    batchExport() {
      const ids = [
        {
          id: getUUid(),
          fieldName: 'id',
          fieldType: 'number',
          fieldValue: this.selectionData.join('^'),
          operator: 'in'
        }
      ]
      const params = {
        searchVal: Encrypt(JSON.stringify(ids))
      }
      this.$refs.functionHeader.export(params)
    },
    selectionChange(data) {
      this.selectionData = data.map(item => item.id)
      this.selectionDataList = data
    },
    jumpOrderDetail(row) {
      this.$router.push({ name: 'orderDetail', query: { id: row.produceOrderId }})
    },
    async showProcess(row) {
      await this.getProcessDetailById(row.processFlowId, row.processFlowVersionId)
      this.processVisible = true
    },
    // 获取产线
    async getAreaList() {
      const search = []
      search.push({
        id: getUUid(),
        fieldName: 'type',
        fieldType: 'number',
        fieldValue: 2,
        operator: 'eq'
      })
      search.push({
        id: getUUid(),
        fieldName: 'isEnabled',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      })
      const res = await processApi.getAreaList({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.formOptions.areaOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.areaList = res
        }
      }
    },
    // 获取工艺详情
    async getProcessDetailById(id, versionId) {
      const res = await processApi.getProcessDetail({ id: id, versionId: versionId })
      if (res) {
        this.processFlowVersion = res.processFlowVersion
      }
    },
    // 选择工艺
    async processFlowList() {
      const res = await api.getFlowers({
        isGetAreaList: 1,
        isGetVersionList: 1
      })
      if (res) {
        this.formOptions.flowerOptions = res
        if (this.$refs.searchForms !== undefined) {
          this.$refs.searchForms.processFlowList = res
        }
      }
    },
    // 工艺弹窗
    selectProcessFlows() {
      if (this.params.processFlowId) {
        this.processIdAndVersionId = [this.params.processFlowId, this.params.processFlowVersionId]
        this.$refs.processFlow.selectProcessChange(this.params.processFlowId)
      }
      this.processFlowVisible = true
    },
    selectFlow(data) {
      // console.log(data, '选中id')
      this.dialogFormList[8].children[0].options = data.processData.areaList
      Object.assign(this.params, {
        processFlowName: data.processData.name,
        processFlowId: data.processFlowVersion.processFlowId,
        processFlowVersionId: data.processFlowVersion.id
      })
      this.$refs.dialogFormBuild.setForm({
        processFlowId: data.processFlowVersion.processFlowId,
        processFlowVersionId: data.processFlowVersion.id,
        areaId: ''
      })
      this.processFlowVisible = false
    },
    // 列表
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      if (this.isShow) {
        search.push({
          id: getUUid(),
          fieldName: 'isPlan',
          fieldType: 'number',
          fieldValue: this.flag === 'NoSchedule' ? 0 : 1,
          operator: 'eq'
        })
      }
      const params = {
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        materialsSearchType: this.selectKeyword,
        materialsSearchValue: this.keyword,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchDatas]))
      }
      // 导出
      this.functionHeader.exportParams = params
      const res = await api.getPlanpage({
        page: page.currentPage,
        limit: page.pageSize,
        ...params
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    radioChange(label) {
      this.isHave = label === 'NoSchedule'
      if (label === 'NoSchedule') {
        this.$refs.searchForms.searchFormData = {
          status: [0, 1]
        }
        this.searchDatas = [
          {
            id: getUUid(),
            fieldName: 'status',
            fieldType: 'number',
            fieldValue: '0^1',
            operator: 'in'
          }
        ]
      }
      if (label === 'HaveSchedule') {
        this.$refs.searchForms.searchFormData = {
          status: [2, 3]
        }
        this.searchDatas = [
          {
            id: getUUid(),
            fieldName: 'status',
            fieldType: 'number',
            fieldValue: '2^3',
            operator: 'in'
          }
        ]
      }
      this.flag = label
      // this.selectionData = []
      this.keyword = ''
      this.selectKeyword = ''
      this.isShow = true
      this.$refs.mTable.tableRefs().clearSelection()
      this.$refs.mTable.getCheckedColumns()
      this.$refs.mTable.setTableData(true)
    },
    // 查询
    searchTable(data, val, keyword) {
      this.searchDatas = data
      this.keyword = keyword
      this.selectKeyword = val
      if (data.length > 0) {
        const datas = data.filter(item => item.fieldName === 'status')
        this.isShow = datas.length > 0 ? !datas[0].fieldValue : true
      }
      this.$refs.mTable.setTableData(true)
    },
    // 更多操作fieldValue
    more(row, command) {
      const functions = {
        del: () => { this.del(row) },
        modify: () => { this.modify(row) }
      }
      functions[command]()
    },
    async modify(row, val) {
      this.fixId = row.id
      // 详情
      const res = await api.getPlanDetail(row.id)
      if (res) {
        this.currentType = 1
        this.dialogFormData = res
        // console.log(this.formOptions.flowerOptions, 'ceshi-->', res.processFlowId)
        this.dialogFormList[8].children[0].options = this.formOptions.flowerOptions.filter(item => item.id === res.processFlowId)[0].areaList
        Object.assign(this.params, {
          processFlowName: res.processFlowName,
          processFlowId: res.processFlowId,
          processFlowVersionId: res.processFlowVersionId
        })
        this.dialogFormList[10].children[0].options = this.priorityStatus.map((item, index) => {
          return {
            ...item,
            id: index
          }
        })
        this.dialogFormList[15].children[0].options = this.procedureClass
        this.proceType = res.isRepair === 0 ? 0 : 1
        this.formDisabled = +val === 1
        this.titles = +val === 1 ? '查看' : '修改计划'
        this.dialogVisible = true
      }
    },
    // 批量删除
    async allDel() {
      if (this.selectionData.length > 0) {
        const res = await api.deletePlans(this.selectionData)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      } else {
        this.$message.error('请选择要删除的数据')
      }
    },
    async allXia() {
      if (this.selectionData.length > 0) {
        if (this.params.radio === 'NoSchedule') {
          const isAssign = this.selectionDataList.every(item => +item.status === 0)
          if (!isAssign) {
            this.$message.error('存在计划已下发的数据')
            return false
          }
          this.planList = this.selectionDataList.map(item => {
            return {
              ...item,
              planStartTime: dayjs().format('YYYY-MM-DD 00:00:00'),
              planEndTime: dayjs().format('YYYY-MM-DD 23:59:59')
            }
          })
          this.dialogTimeVisible = true
          return false
        }
        this.saveData(this.selectionData)
      } else {
        this.$message.error('请选择要下发的数据')
      }
    },
    async saveData(data) {
      const res = await api.getPage(data)
      if (res) {
        this.$message.success('下发成功')
        this.dialogTimeVisible = false
        this.$refs.mTable.tableRefs().clearSelection()
        this.$refs.mTable.setTableData()
      }
    },
    allMore(command) {
      const functions = {
        allDel: () => { this.allDel() },
        allXia: () => { this.allXia() },
        batchStock: () => { this.batchStock() },
        batchExport: () => { this.batchExport() }
      }
      functions[command]()
    },
    // batchExport() {
    //   const exportData = this._.cloneDeep(this.selectionDataList)
    //   exportData.forEach(item => {
    //     item.source = planSource[item.source].name
    //     item.priority = this.priorityStatus[item.priority].name
    //     item.status = planStatus[item.status].name
    //     item.type = productionType[item.type].name
    //     item.isRepair = item.isRepair === 0 ? '普通' : '返修'
    //     item.produceStatus = productionStatus[item.produceStatus].name
    //   })
    //   const params = {
    //     key: this.columnsAll.filter(item => item.prop && item.prop !== 'index').map(item => item.prop),
    //     header: this.columnsAll.filter(item => item.prop && item.prop !== 'index').map(item => item.label),
    //     merges: [],
    //     data: exportData,
    //     filename: '生产计划' + dayjs().format('YYYYMMDDHHmmss')
    //   }
    //   export_json_to_excel2(params)
    // },
    async del(row) {
      const res = await api.deletePlan({ id: row.id })
      if (res) {
        this.$message.success('删除成功')
        this.$refs.mTable.setTableData()
      }
    },
    // 单个下发至工单
    async fixWorkOrder(row, val) {
      if (this.params.radio === 'NoSchedule') {
        if (+val === 1) {
          const data = this._.cloneDeep(row)
          data.planStartTime = dayjs().format('YYYY-MM-DD 00:00:00')
          data.planEndTime = dayjs().format('YYYY-MM-DD 23:59:59')
          this.planList = [data]
        }
        this.dialogTimeVisible = true
        return false
      }
      const ids = []
      ids.push(row.id)
      const res = await api.getPage(ids)
      if (res) {
        this.$message.success('下发成功')
        this.$refs.mTable.setTableData()
      }
    },
    // 修改保存
    dialogSubmit(callback) {
      callback()
      const { form } = this.$refs.dialogFormBuild
      if ((form.planStartTime && form.planEndTime) && (this.compareDate(form.planStartTime, form.planEndTime))) {
        this.$message.error('开始时间不能大于或等于结束时间')
        return false
      }
      this.$refs.dialogFormBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await api.modifyPlan(this.createResetData(form))
          if (res) {
            this.$message.success('修改成功')
            this.dialogVisible = false
            this.$refs.mTable.setTableData()
          }
        }
      })
    },
    compareDate(d1, d2) {
      return ((new Date(d1.replace(/\-/g, '\/'))) >= (new Date(d2.replace(/\-/g, '\/'))))
    },
    createSetData(row) {
      return {
        areaId: row.areaId,
        priority: row.priority,
        processFlowVersionId: row.processFlowVersionId,
        processFlowId: row.processFlowId,
        deliveryTime: row.deliveryTime ? row.deliveryTime : '',
        planStartTime: row.planStartTime ? row.planStartTime : '',
        planEndTime: row.planEndTime ? row.planEndTime : '',
        remark: row.remark
      }
    },
    createResetData(row) {
      const obj = {
        id: row.id
      }
      return Object.assign(this.createSetData(row), obj)
    },
    // 重置
    resetData() {
      this.searchDatas = []
      this.selectKeyword = ''
      this.keyword = ''
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep{
  .dialogFormBuilds  .el-row {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
  }
}
.schedule-wrap {
  .bottom {
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  .icon-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 19px;
    height: 19px;
    display: block;
  }
  ::v-deep{
    .e-divider{
      margin: 0px 0 0 0;
    }
    .el-row {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
    }
  }
  ::v-deep {
    .el-button--primary, .el-button-group .el-button{
      height: 32px;
    }
  }
  .li-btn{
    // margin-right: 10px;
    color: #607FFF;
    font-weight: 500;
  }
  .input-select{
    width: 120px;
    display: block;
  }
  .input-with-select{
    width: 100%;
  }
  .searchs{
    padding-left: 0;
    padding-top: 0;
  }
  .color-btns{
    color:#c4c4c4;
  }
}

</style>
