var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "container-left" }, [
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("单号：" + _vm._s(_vm.detailData.number))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v(
                    "业务类型：" +
                      _vm._s(
                        _vm.inspectionTypeNames[_vm.detailData.businessType - 1]
                      )
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("工序：" + _vm._s(_vm.detailData.procedureName))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("物料编码：" + _vm._s(_vm.detailData.materialsCode))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("物料名称：" + _vm._s(_vm.detailData.materialsName))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v(
                    "物料规格：" +
                      _vm._s(_vm.detailData.materialsSpecifications)
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v(
                    "是否关键料：" +
                      _vm._s(_vm.detailData.isKeyMaterials ? "是" : "否")
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("不良品数量：" + _vm._s(_vm.detailData.defectQuantity))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("批次号：" + _vm._s(_vm.detailData.batchNumber))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("序列号：" + _vm._s(_vm.detailData.sequenceNumber))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("供应商：" + _vm._s(_vm.detailData.supplierName))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("采购单：" + _vm._s(_vm.detailData.supplierName))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v(
                    "收料单：" +
                      _vm._s(_vm.detailData.materialsReceivedOrderCode)
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v(
                    "设变件：" +
                      _vm._s(_vm.detailData.settingVariable ? "是" : "否")
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v(
                    _vm._s(_vm.mode ? "订单：" : "项目：") +
                      " " +
                      _vm._s(_vm.detailData.projectName)
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("来源对象：" + _vm._s(_vm.detailData.sourceObject))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("源单号：" + _vm._s(_vm.detailData.sourceNumber))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v(
                    "状态：" + _vm._s(_vm.allStatus[_vm.detailData.status - 1])
                  )
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("处理时间：" + _vm._s(_vm.detailData.endTime))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("创建人：" + _vm._s(_vm.detailData.creator))
                ])
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("span", { staticClass: "bot-ti" }, [
                  _vm._v("创建时间：" + _vm._s(_vm.detailData.createTime))
                ])
              ])
            ],
            1
          )
        ]),
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("不良品判定")]),
          _c(
            "div",
            { staticClass: "card-bot", staticStyle: { display: "block" } },
            [
              _c(
                "el-form",
                {
                  ref: "detail",
                  staticClass: "demo-form-inline",
                  attrs: { "label-position": "right", "label-width": "90px" }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "判定类型",
                                required: _vm.expTaskNeedHandleMsg.mastHaveValue
                                  ? _vm.expTaskNeedHandleMsg.mastHaveValue.includes(
                                      "判定类型"
                                    )
                                  : false
                              }
                            },
                            [
                              _vm.expTaskNeedHandleMsg.editFieldList &&
                              _vm.expTaskNeedHandleMsg.editFieldList.includes(
                                "判定类型"
                              ) &&
                              !_vm.expTaskNeedHandleMsg.showFlag
                                ? _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择判定类型",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.resultId,
                                        callback: function($$v) {
                                          _vm.resultId = $$v
                                        },
                                        expression: "resultId"
                                      }
                                    },
                                    _vm._l(_vm.getJudgeResultList, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                : _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.judgmentTypeName,
                                      callback: function($$v) {
                                        _vm.judgmentTypeName = $$v
                                      },
                                      expression: "judgmentTypeName"
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.resultId === 3 &&
                                _vm.memberCode === "nozoli",
                              expression:
                                "resultId === 3 && memberCode === 'nozoli'"
                            }
                          ],
                          attrs: { span: 8 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "供应商",
                                required: _vm.expTaskNeedHandleMsg.mastHaveValue
                                  ? _vm.expTaskNeedHandleMsg.mastHaveValue.includes(
                                      "供应商"
                                    )
                                  : false
                              }
                            },
                            [
                              _vm.expTaskNeedHandleMsg.editFieldList &&
                              _vm.expTaskNeedHandleMsg.editFieldList.includes(
                                "供应商"
                              ) &&
                              !_vm.expTaskNeedHandleMsg.showFlag
                                ? _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "请选择供应商",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.supplierId,
                                        callback: function($$v) {
                                          _vm.supplierId = $$v
                                        },
                                        expression: "supplierId"
                                      }
                                    },
                                    _vm._l(_vm.supplierList, function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }),
                                    1
                                  )
                                : _c("el-input", {
                                    attrs: { disabled: true },
                                    model: {
                                      value: _vm.supplierName,
                                      callback: function($$v) {
                                        _vm.supplierName = $$v
                                      },
                                      expression: "supplierName"
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "order-footer" }, [
          _c("div"),
          _c(
            "div",
            { staticClass: "footer-ri" },
            [
              _c("el-button", { on: { click: _vm.returnList } }, [
                _vm._v("返 回")
              ]),
              _vm._l(_vm.expTaskNeedHandleMsg.buttonNameList, function(
                btn,
                index
              ) {
                return _vm.expTaskNeedHandleMsg.id &&
                  !_vm.expTaskNeedHandleMsg.showFlag
                  ? [
                      _c(
                        "el-button",
                        {
                          key: index,
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.onChangeStatus(0)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(btn) + " ")]
                      )
                    ]
                  : _vm._e()
              }),
              _vm.expTaskNeedHandleMsg.id && !_vm.expTaskNeedHandleMsg.showFlag
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onChangeStatus(1)
                        }
                      }
                    },
                    [_vm._v("通 过 ")]
                  )
                : _vm._e()
            ],
            2
          )
        ])
      ]),
      _c("div", { staticClass: "container-right" }, [
        _c(
          "div",
          {
            staticStyle: {
              height: "calc(100vh - 80px)",
              width: "94%",
              "box-sizing": "border-box",
              "margin-left": "6%",
              background: "#FFFFFF",
              padding: "20px"
            }
          },
          [
            _c("h3", [_vm._v("流程日志")]),
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  "max-height": "calc(80vh - 80px)",
                  "overflow-y": "scroll"
                }
              },
              [
                _c(
                  "el-steps",
                  {
                    attrs: { direction: "vertical", active: 50, space: "140px" }
                  },
                  _vm._l(_vm.processList, function(itemP, index) {
                    return _c(
                      "el-step",
                      {
                        key: itemP.taskId,
                        attrs: {
                          title: itemP.endTime,
                          icon: itemP.endTime
                            ? "el-icon-success"
                            : "el-icon-error"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "description" },
                            slot: "description"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "description",
                                style: {
                                  paddingRight:
                                    index === _vm.processList.length - 1
                                      ? "10%"
                                      : "0"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      border: "none",
                                      "font-weight": "bold",
                                      color: "#000000"
                                    }
                                  },
                                  [_vm._v(_vm._s(itemP.taskName))]
                                ),
                                _c("div", [
                                  _vm._v(" " + _vm._s(itemP.assignee) + " "),
                                  itemP.comment
                                    ? _c("span", [
                                        _vm._v(" : " + _vm._s(itemP.comment))
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: !_vm.processType ? "驳回" : "通过",
            visible: _vm.dialogFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "300" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autocomplete: "off",
                      placeholder: "请输入评论"
                    },
                    model: {
                      value: _vm.comment,
                      callback: function($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.buttonLoading },
                  on: { click: _vm.confirmFin }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }