<template>
  <div class="orderAdd">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.push({ name: 'orderList' })">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>新增订单</span>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-form ref="ruleForm" label-position="right" :model="ruleForm" :rules="rules" label-width="98px" class="demo-form-inline">
          <el-row>
            <el-col :span="6">
              <el-form-item label="订单来源：" prop="source">
                <el-select v-model="ruleForm.source" placeholder="请选择" disabled>
                  <el-option
                    v-for="item in orderSource"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单号：" prop="produceOrderNumber">
                <el-input v-model="ruleForm.produceOrderNumber" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="交期：" prop="deliveryTime">
                <el-date-picker v-model="ruleForm.deliveryTime" type="date" placeholder="选择" value-format="yyyy-MM-dd" format="yyyy-MM-dd" style="width:100%" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="优先级：">
                <el-select v-model="ruleForm.priority" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in priorityStatus"
                    :key="index"
                    :label="item.name"
                    :value="index"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户：">
                <el-select v-model="ruleForm.customerId" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in userList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="备注：">
                <el-input v-model="ruleForm.remark" type="textarea" :rows="1" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <div class="bot-li">
                <div class="li-tit">附件：</div>
                <div class="li-des">
                  <el-upload
                    ref="upload"
                    class="upload-demo"
                    :file-list="fileList.slice(0, textFlg ? fileList.length : 3)"
                    v-bind="uploadProp"
                    :style="{ width: fileList.length > 3 ? '140px' : '150px' }"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <!-- <div slot="tip" class="el-upload__tip">支持多个文件同时上传</div> -->
                  </el-upload>
                  <div v-show="fileList.length > 3" class="more" @click="textFlg = !textFlg">
                    {{ textFlg ? '收起' : `展开` }}
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="18">
              <el-form-item label="自定义标签：">
                <el-tag
                  v-for="tag in customTag"
                  :key="tag"
                  class="el-tags"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(tag)"
                >
                  {{ tag }}
                </el-tag>
                <el-input
                  v-if="inputVisible"
                  ref="saveTagInput"
                  v-model="inputValue"
                  class="input-new-tag"
                  size="small"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm"
                />
                <el-button v-else class="button-new-tag" size="small" @click="showInput">+添加标签</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">订单产品</div>
      <div class="card-bot">
        <el-button size="small" type="primary" class="e-btn" @click="selectadd">选择添加物料</el-button>
        <MTable ref="mUnitTable" :height="200" :show-page="false" :data="orderList" :columns="columnsOrder" :columns-setting="false">
          <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
          <div slot="quantity" slot-scope="{ row }">
            <el-input-number
              v-model="row.quantity"
              :min="1"
              controls-position="right"
              :step="1"
              @blur="row.quantity=sliceVal(row.quantity,5)"
            />
          </div>
          <div slot="action" slot-scope="{ row }">
            <el-button type="text" class="li-btn" @click="delPlan()">删除</el-button>
          </div>
        </MTable>
      </div>

    </div>
    <div class="order-footer">
      <div class="footer-le">
        <!-- <span>创建人：</span>
        <span>创建时间：2021-03-21 14：2</span> -->
      </div>
      <div class="footer-ri">
        <el-button @click="returnList">取消</el-button>
        <el-button type="primary" @click="submitForms">确认</el-button>
      </div>
    </div>
    <div class="lines" />
    <select-materials
      ref="selectMaterials"
      :visible.sync="showMaterials"
      :single="true"
      :is-producible="true"
      :is-add="true"
      @submitForm="submitMaterials"
      @addMaterial="addMaterial"
    />
    <!-- 新增物料 -->
    <edit-materials ref="editMaterials" @onSuccess="onSuccess" />
  </div>
</template>
<script>
import { sliceVal } from '@/utils'
import { columnsOrder } from './columns'
import { orderSource } from '@/config/options.config'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
import api from '@/api/production/orders'
import keApi from '@/api/information/unit'
import { getToken } from '@/utils/auth'
import saveAs from 'file-saver'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'OrderAdd',
  components: {
    EditMaterials,
    SelectMaterials
  },
  mixins: [],
  data() {
    return {
      userList: [],
      inputVisible: false,
      inputValue: '',
      customTag: [],
      textFlg: false,
      columnsOrder,
      orderSource,
      showMaterials: false,
      orderList: [],
      selectData: [],
      searchFormData: {
        keyword: '',
        selectKeyword: ''
      },
      ruleForm: {
        produceOrderNumber: '',
        source: 0,
        deliveryTime: '',
        remark: '',
        priority: undefined,
        customerId: ''
      },
      rules: {
        source: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        produceOrderNumber: [
          { required: true, message: '请输入订单号', trigger: 'blur' }
        ]
      },
      id: '',
      selectMaterials: [],
      fileList: [],
      uploadProp: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: true,
        onSuccess: this.fileUploadChange,
        onRemove: this.fileRemove,
        onError: this.fileUploadChange,
        onPreview: this.handlePreview
      },
      isFirstShow: false
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'orderList' && this.isFirstShow) {
      this.$store.dispatch('tagsView/delCachedView', to)
    }
    next()
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 40 - 54 + 29 - 62 - 22
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    }
  },
  activated() {
    this.ruleForm.priority = this.priorityStatus.findIndex(item => item.name === '普通')
    this.id = this.$route.query.id
    this.getUser()
    if (this.id) {
      this.getDetail()
    }
  },
  methods: {
    sliceVal,
    resetData() {
      this.ruleForm = {
        produceOrderNumber: '',
        source: 0,
        deliveryTime: '',
        remark: '',
        priority: undefined,
        customerId: ''
      }
      this.orderList = []
      this.fileList = []
    },
    handleClose(tag) {
      this.customTag.splice(this.customTag.indexOf(tag), 1)
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      const inputValue = this.inputValue
      if (inputValue) {
        this.customTag.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    // 获取客户
    async getUser() {
      const res = await keApi.getCustomerList({
        searchVal: Encrypt(JSON.stringify([
          {
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }
        ]))
      })
      if (res) {
        this.userList = res
      }
    },
    onSuccess() {
      this.$refs.selectMaterials.updateTable()
    },
    addMaterial() {
      this.$refs.editMaterials.add()
    },
    handlePreview(file) {
      if (file.url.indexOf('.txt') > 0) {
        saveAs(file.url, '文件')
        return
      }
      window.open(file.url)
    },
    fileRemove(file) {
      const index = this.fileList.findIndex(item => item.id === file.id)
      this.fileList.splice(index, 1)
    },
    // 上传附件
    fileUploadChange(res, file) {
      // console.log('ceshiimg', res)
      if (res.errorCode === 0) {
        this.fileList.push({
          uid: file.uid,
          id: file.uid,
          name: file.name,
          url: res.result
        })
        return this.$message.success('上传成功')
      }
      if (res.errorCode !== 0) {
        return this.$message.error(res.result)
      }
    },
    submitMaterials(val) {
      this.orderList = val.length > 0 ? val.map(item => {
        return {
          ...item,
          quantity: 1
        }
      }) : []
      this.showMaterials = false
    },
    selectadd() {
      this.selectMaterials = this._.cloneDeep(this.orderList)
      this.showMaterials = true
    },
    submitForms() {
      if (this.orderList.length === 0) {
        this.$message.error('请选择物料')
        return
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.getData()
        } else {
          return false
        }
      })
    },
    // 新增/修改
    async getData() {
      const data = this.ruleForm
      const obj = {
        id: this.id,
        quantity: this.orderList[0].quantity,
        materialsId: this.orderList[0].id,
        attachments: this.fileList.length > 0 ? JSON.stringify(this.fileList) : '',
        customTag: this.customTag.length > 0 ? JSON.stringify(this.customTag) : ''
      }
      if (this.id) {
        const res = await api.modifyOrder(Object.assign(data, obj))
        if (res) {
          this.isFirstShow = true
          this.returnList()
        }
      } else {
        const res = await api.addOrder(Object.assign(data, obj))
        if (res) {
          this.isFirstShow = true
          this.returnList()
        }
      }
    },
    // 详情
    async getDetail() {
      this.orderList = []
      const res = await api.getOrderDetail(this.id)
      if (res) {
        // console.log(res)
        const obj = {
          quantity: res.quantity,
          id: res.materialsId,
          name: res.materialsName,
          code: res.materialsCode,
          specifications: res.materialsSpecifications,
          mainUnitName: res.materialsUnit
        }
        this.orderList.push(obj)
        Object.assign(this.ruleForm, {
          produceOrderNumber: res.produceOrderNumber,
          source: res.source,
          deliveryTime: res.deliveryTime,
          remark: res.remark,
          priority: res.priority,
          customerId: res.customerId
        })
        this.fileList = res.attachments ? JSON.parse(res.attachments) : []
        this.customTag = res.customTag ? JSON.parse(res.customTag) : []
      }
    },
    returnList() {
      this.resetData()
      this.$router.push({ path: 'order-list' })
    },
    delPlan() {
      this.orderList = []
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .el-upload-list__item.is-success.focusing .el-icon-close-tip{
    display: none !important;
  }
  ::v-deep{
    .el-tag {
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .el-form-item {
      margin-bottom: 22px;
    }
    .el-date-editor .el-range-separator,.el-date-editor .el-range__icon{
      line-height:26px;
    }
    .el-input-number{
      height:32px;
      line-height:32px;
      width:100%;
    }
    .el-input__inner{
      height:32px;
      line-height:32px;
    }
    .el-input-number.is-controls-right .el-input-number__increase, .el-input-number.is-controls-right .el-input-number__decrease{
      line-height:15px;
    }
    .el-tag .el-icon-close {
      position: absolute;
      cursor: pointer;
      font-size: 12px;
      height: 16px;
      width: 16px;
      line-height: 16px;
      vertical-align: middle;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
    }
  }
  .el-tags {
    // width: 130px;
    // text-overflow: ellipsis;
    // overflow: hidden;
    /* width: 100%; */
    padding: 0 20px 0 10px;
    position: relative;
  }
 .orderAdd{
   width:100%;
   height:100%;
  //  position:relative;
  .input-new-tag {
    width: 130px;
  }
  .button-new-tag {
    height: 32px;
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .lines{
    width:100%;
    height:20px;
    background: #F0F2F5;
  }
   ::v-deep .el-select{
     width:100%;
   }
   .e-btn{
     margin-bottom: 10px;
   }
   .order-footer{
     position: absolute;
     bottom:0;
     left:0;
     height:60px;
     width:100%;
     display:flex;
     justify-content: space-between;
     align-items: center;
     z-index: 100;
     background:#fff;
     border-top:1px solid #DDE3EB;
     .footer-le{
      padding-left:30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      span{
        margin-right:45px;
        display:inline-block;
      }
     }
     .footer-ri{
       margin-right:30px;
       display:flex;
       justify-content: center;
       align-items: center;
     }
   }
 }

  .bot-li {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    .li-tit {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 700;
      color: #606266;
      line-height: 32px;
      width: 95px;
      padding-right: 12px;
      text-align: right;
    }
    .li-des {
      position: relative;
       .more {
        // margin: 0 0 0 110px;
        cursor: pointer;
        width: 45px;
        height: 20px;
        background: #ebeef5;
        text-align: center;
        border-radius: 5px;
        margin: 10px auto;
        line-height: 20px;
        position: absolute;
        top: 32px;
        right: -60px;
      }
    }
  }

 .procedure-detail-header{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456B;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  .left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img{
    width: 8px;
    height: 12px;
    display: block;
    margin-right: 10px;
  }
}
</style>
