<template>
  <table>
    <tr>
      <td colspan="20" t="s" id="sjs-A1" v="新疆中超施工单">新疆中超施工单</td>
    </tr>
    <tr>
      <td colspan="20" t="s" id="sjs-A2" v="工位：Ф铜框绞">工位：{{ stationNm }}</td>
    </tr>
    <tr>
      <td t="s" id="sjs-A3" v="批次码" v-if="judgeField('batchNumber')">
        批次码
      </td>
      <td t="s" id="sjs-A3" v="生产工单" v-if="judgeField('produceWorkOrderNumber')">
        生产工单
      </td>
      <td t="s" id="sjs-B3" v="成品" v-if="judgeField('materialsInfo')">
        成品
      </td>
      <td t="s" id="sjs-C3" v="数量" v-if="judgeField('leftNumber')">成品相关数量</td>
      <td t="s" id="sjs-C3" v="上道工序产出数量" v-if="judgeField('sourceQuantity')">上道工序产出数量</td>

      <!-- <td t="s" id="sjs-D3" v="单位" v-if="judgeField('mainUnitName')">单位</td> -->
      <td t="s" id="sjs-E3" v="客户" v-if="judgeField('customerName')">客户</td>
      <td t="s" id="sjs-E3" v="工序名" v-if="judgeField('procedureName')">工序名</td>

      <td t="s" id="sjs-E3" v="交期" v-if="judgeField('deliveryTime')">交期</td>
      <td t="s" id="sjs-G3" v="备注" v-if="judgeField('remark')">备注</td>
      <td t="s" id="sjs-H3" v="中间品" v-if="judgeField('midMaterial')">中间品</td>
      <td t="s" id="sjs-H3" v="中间品" v-if="judgeField('rightNumber')">中间品相关数量</td>

      <template v-if="taskList.length > 0">
        <div
          v-for="(item, index) in taskList[0][0]
            .produceWorkOrderTaskFieldMappingList"
          :key="index"
        >
          <td v-if="item.type === 5 && item.isShow">
            {{ item.fieldName }}
          </td>
        </div>
      </template>
      <td colspan="2" t="s" id="sjs-I3" v="中间品数量">中间品数量</td>

    </tr>
    <template v-for="(itemList, index1) in taskList">
      <!-- <template> -->
      <div :key="index1">
        <tr v-for="(item, index2) in itemList" :key="index1 + '-' + index2">
          <td v-if="judgeField('batchNumber')">{{ item.batchNumber || "" }}</td>
          <td v-if="judgeField('produceWorkOrderNumber')">{{ item.produceWorkOrderNumber || "" }}</td>

          <td v-if="judgeField('materialsInfo')">
            {{ item.materialsName || "" }}{{ item.specifications ? "/" : ""
            }}{{ item.specifications || "" }}
          </td>

          <td v-if="judgeField('leftNumber')">
            {{ item.workOrderQuantity || "" }}/{{ item.sourceQuantity || "" }}/{{
            item.produceWorkOrder.reportWorkQuantity
          }}
          </td>
          <td v-if="judgeField('sourceQuantity')">
            {{ item.sourceQuantity || "" }}
          </td>
          <!-- <td v-if="judgeField('mainUnitName')">{{ item.mainUnitName || "" }}</td> -->
          <td v-if="judgeField('customerName')">{{ item.customerName || "" }}</td>
          <td v-if="judgeField('procedureName')">{{ item.procedureName || "" }}</td>

          <td v-if="judgeField('deliveryTime')">{{ item.produceWorkOrder.deliveryTime || "" }}</td>
          <td v-if="judgeField('remark')">{{ item.remark || "" }}</td>
          <td v-if="judgeField('midMaterial')">
            {{
              item.produceWorkOrderTaskOutputMaterials
                ? item.produceWorkOrderTaskOutputMaterials.materials.name
                : ""
            }}
          </td>
          <td v-if="judgeField('rightNumber')">
            {{
            item.produceWorkOrderTaskOutputMaterials
              ? item.produceWorkOrderTaskOutputMaterials.quantity
                ? item.produceWorkOrderTaskOutputMaterials.quantity
                : 0
              : 0
          }}/{{
            item.outputReportWorkQuantity
              ? item.outputReportWorkQuantity
              : 0
          }}
          ({{ item.reportWorkQuantity }})
          </td>
          <template v-if="itemList.length > 0">
            <div
              v-for="(item, index) in item
                .produceWorkOrderTaskFieldMappingList"
              :key="index"
            >
              <td v-if="item.type === 5 && item.isShow">
                {{ item.value }}
              </td>
            </div>
          </template>
          <td :colspan="itemList.length > 1 ? '1' : '2'">
            {{
              item.produceWorkOrderTaskOutputMaterials
                ? item.produceWorkOrderTaskOutputMaterials.quantity
                : ""
            }}
          </td>
          <td
            v-if="index2 === 0 && itemList.length > 1"
            :rowspan="itemList.length"
          >
            {{ getTotalCount(itemList) }}
          </td>
        </tr>
        <tr v-if="index1 !== taskList.length - 1" class="blank"></tr>
      </div>

      <!-- <tr></tr> -->
      <!-- </template> -->
    </template>
  </table>
</template>
<script>
export default {
  name: "zhongChaoTemplate",
  props: ["taskList", "stationNm", "fieldSet"],
  data() {
    return {};
  },
  updated() {
    //  console.log("taskList:", this.taskList);
  },
  methods: {
    getTotalCount(itemList) {
      let totalCount = 0;
      itemList.forEach((item) => {
        totalCount =
          totalCount +
          (item.produceWorkOrderTaskOutputMaterials
            ? item.produceWorkOrderTaskOutputMaterials.quantity
            : 0);
      });
      return totalCount;
    },

    judgeField(code) {
      if (this.fieldSet.has(code)) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  margin: 0 auto;
  // border: 1px solid #000000;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #000000;
  text-align: center;
  padding: 6px;
}
tr {
  page-break-inside: avoid;
}
// td:nth-child(2) {
//   color: red;
// }
// tr:nth-child(n + 3) td:nth-child(7) {
//   color: blue;
// }
// tr:nth-child(n + 4) td:nth-child(8) {
//   font-weight: 800;
// }
.blank {
  height: 10px;
  border: none;
}
</style>
