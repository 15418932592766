export default {
  callSearch: (name) => ([
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'DelSearch',
      title: '删除'
    },
    {
      name: name + 'BatchDel',
      title: '批量删除'
    }
  ]),
  callClassify: (name) => ([
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启用/停用'
    },
    {
      name: name + 'SetUser',
      title: '设置人员'
    },
    {
      name: name + 'Relation',
      title: '关联流程'
    }
  ]),
  ExceptionList: (name) => ([
    {
      name: name + 'List',
      title: '列表'
    },
    // {
    //   name: name + 'Export',
    //   title: '导出'
    // },
    {
      name: name + 'Detail',
      title: '详情'
    },
    {
      name: name + 'PushDownDefect',
      title: '下推不良品评审单'
    },
    {
      name: name + 'OnUrge',
      title: '催办'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ]),
  ExceptionSetting: (name) => ([
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Add',
      title: '新增'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Status',
      title: '启用/停用'
    },
    {
      name: name + 'SetUser',
      title: '设置人员'
    },
    {
      name: name + 'Relation',
      title: '关联流程'
    }
  ])
}
