
// 紧急程度
export const emergencyDegreeOptions = [
    { name: '紧急', id: 0 },
    { name: '一般', id: 1 },
    { name: '低', id: 2 }
  ]
  export const emergencyDegreeMap = emergencyDegreeOptions.reduce((obj, item) => {
    obj[item.id] = item.name
    return obj
  }, {})
  // 处理状态
  // export const handleStatusOptions = [
  //   { name: '待判定', id: 0 },
  //   { name: '处理中', id: 1 },
  //   { name: '待验收', id: 2 },
  //   { name: '已关闭', id: 3 }
  // ]
  export const handleStatusOptions = [
    // { name: '未开始', id: 0 },
    { name: '待判定', id: 1 },
    { name: '待处理', id: 2 },
    // { name: '-', id: 3 },
    // { name: '-', id: 4 },
    { name: '已处理', id: 5 },
    { name: '已关闭', id: 6 }
  ]
  export const handleStatusMap = handleStatusOptions.reduce((obj, item) => {
    obj[item.id] = item.name
    return obj
  }, {})
  // 判定结果
  export const judgeResultOptions = [
    { name: '无需处理，直接关闭', id: 1 },
    { name: '需要处理', id: 0 }
  ]
  export const judgeResultMap = judgeResultOptions.reduce((obj, item) => {
    obj[item.id] = item.name
    return obj
  }, {})

  // 最终结果
  export const finalResultOptions = [
    { name: '已解决', id: 0 },
    { name: '长期跟踪', id: 1 },
    { name: '其他', id: 2 }
  ]
  export const finalResultMap = finalResultOptions.reduce((obj, item) => {
    obj[item.id] = item.name
    return obj
  }, {})
  // 验收结果
  export const checkResultOptions = [
    { name: '驳回，继续处理', id: 0 },
    { name: '通过已关闭', id: 1 }
  ]
  export const checkResultMap = checkResultOptions.reduce((obj, item) => {
    obj[item.id] = item.name
    return obj
  }, {})
  // 异常呼叫列表
  export const searchFormList = [
    {
      colSpan: 6,
      key: 'emergencyDegree',
      tagName: 'el-select',
      props: {
        placeholder: '紧急程度',
        configFilter: true
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: emergencyDegreeOptions
        }
      ]
    },
    {
      colSpan: 6,
      key: 'abnormalTypeId',
      tagName: 'el-select',
      props: {
        placeholder: '异常类型',
        configFilter: true
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: []
        }
      ]
    },
    // {
    //   colSpan: 6,
    //   key: 'status',
    //   tagName: 'el-select',
    //   props: {
    //     placeholder: '状态',
    //     configFilter: true
    //   },
    //   children: [
    //     {
    //       tagName: 'el-option',
    //       props: {
    //         label: 'name',
    //         value: 'id'
    //       },
    //       options: handleStatusOptions
    //     }
    //   ]
    // },
    {
      colSpan: 6,
      key: 'initiatorId',
      tagName: 'el-select',
      props: {
        placeholder: '创建人',
        configFilter: true
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: []
        }
      ]
    },
    {
      colSpan: 6,
      key: 'businessUnit',
      tagName: 'el-select',
      props: {
        placeholder: '业务部门',
        configFilter: true
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'name',
            value: 'id'
          },
          options: []
        }
      ]
    },
    {
      colSpan: 16,
      key: 'occurrenceTime',
      tagName: 'el-date-picker',
      props: {
        rangeSeparator: '至',
        startPlaceholder: '创建时间起',
        endPlaceholder: '创建时间迄',
        align: 'center',
        type: 'daterange',
        valueFormat: 'yyyy-MM-dd HH:mm:ss',
        format: 'yyyy-MM-dd HH:mm',
        defaultTime: ['00:00:00', '23:59:59']
      }
    }
  ]
  export const searchFormMap = searchFormList.reduce((pre, cur) => ({ ...pre, [cur.key]: cur?.children }), {})
  // console.log('searchFormMap==', searchFormMap)
  export const columns = [
    {
      type: 'selection',
      width: 50,
      fixed: 'left',
      reserveSelection: true
    },
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'abnormalOrderCode',
      label: '异常单号',
      hiddenSearch: true,
      sortable: false,
      width: 120,
      slotName:'abnormalOrderCode'
    },
    {
      prop: 'materialName',
      label: '机台名称',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'emergencyDegree',
      slotName: 'emergencyDegree',
      label: '紧急程度',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'status',
      label: '状态',
      sortable: false,
      hiddenSearch: true,
      slotName: 'status',
      width: 120
    },
    {
      prop: 'abnormalTypeName',
      label: '异常类型',
      sortable: false,
      hiddenSearch: true,
      slotName: '',
      width: 120
    },
    {
      prop: 'abnormalRemark',
      label: '异常现象',
      sortable: false,
      slotName: '',
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'abnormalReasonName',
      label: '异常原因',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'reason',
      label: '原因分析',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'tempPolicy',
      label: '处理措施',
      sortable: false,
      hiddenSearch: true,
      width: 120
    },
    {
      prop: 'createUser',
      label: '创建人',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'createTime',
      label: '创建时间',
      hiddenSearch: true,
      sortable: false,
      width: 200
    },
    {
      prop: 'businessUnitName',
      label: '业务部门',
      hiddenSearch: true,
      sortable: false
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      hiddenSearch: true,
      sortable: false,
      width: 180
    }
  ]

  // 详情基本信息字段
  export const detailBaseFields = [
    {
      key: 'code',
      name: '异常单号：'
    },
    // {
    //   key: 'status',
    //   name: '处理状态'
    // },
    {
      key: 'emergencyDegree',
      name: '紧急程度：'
    },
    {
      key: 'abnormalTypeName',
      name: '异常类型：'
    },
    {
      key: 'materialsCode',
      name: '物料编码：'
    },
    {
      key: 'materialsName',
      name: '物料名称：'
    },
    {
      key: 'materialsSpecifications',
      name: '规格：'
    },
    {
      key: 'quantity',
      name: '数量：'
    },
    {
      key: 'batchNumber',
      name: '批次号：'
    },
    {
      key: 'sequenceNumber',
      name: '序列号：'
    },
    {
      key: 'supplierName',
      name: '供应商：'
    },
    {
      key: 'purchaseOrderCode',
      name: '采购单：'
    },
    {
      key: 'materialsReceivedOrderCode',
      name: '收料单：'
    },
    {
      key: 'isKeyMaterials',
      name: '关键件：'
    },
    {
      key: 'settingVariable',
      name: '设变件：'
    },
    {
      key: 'projectNumber',
      name: '项目：'
    },
    {
      key: 'defectProductJudgmentNumber',
      name: '不良品评审单：'
    }
  ]
  export const detailColumn_1 = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      width: 60
    },
    {
      prop: 'saleOrderNumber',
      label: '生产订单',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'producePlanNumber',
      label: '生产工单',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'specifications',
      label: '规格',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'workOrderCount',
      label: '工单数量',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'unitName',
      label: '单位',
      hiddenSearch: true,
      sortable: false
    }

  ]
  export const detailColumn_2 = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      width: 60
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'specifications',
      label: '规格',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'unitName',
      label: '单位',
      hiddenSearch: true,
      sortable: false
    }
  ]
  export const detailColumn_3 = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      width: 60
    },
    {
      prop: 'projectNumber',
      label: '项目单号',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'projectName',
      label: '项目名称',
      hiddenSearch: true,
      sortable: false,
      slotName:"projectName"
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'businessUnitName',
      label: '业务部门',
      hiddenSearch: true,
      sortable: false
    }

  ]

