import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/inspection/private/inspectionEntry/'
const baseUrl2 = 'api/web/v1/inspection/private/inspectionItemLibrary/'

export default {
  // 获取检验条目不分页
  getInspectionItemsList(data) {
    return request.get(baseUrl + 'getInspectionEntryList', data)
  },
  // 获取检验条目-分页
  getInspectionItemsListPage(data) {
    return request.get(baseUrl + 'getInspectionEntryListPage', data)
  },
  // 新增检验条目
  addInspectionItems(data) {
    return request.post(baseUrl + 'addInspectionEntry', data)
  },
  // 新增检验条目
  copyInspectionItems(data) {
    return request.post(baseUrl + 'addInspectionEntry', data)
  },
  // 修改检验条目
  modifyInspectionItems(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除检验条目
  delInspectionItems(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  batchDelInspectionItems(ids) {
    return request.del(baseUrl + 'deleteBatch', { ids })
  },
  addHotInspectionItems(data) {
    return request.post(baseUrl2 + 'addInspectionItemLibrary', data)
  },
  modifyHotInspectionItems(data) {
    return request.put(baseUrl2 + 'updateOne', data)
  },
  delHotInspectionItems(id) {
    return request.del(baseUrl2 + 'deleteOne', { id })
  },
  getHotInspectionItems(data) {
    return request.get(baseUrl2 + 'getInspectionItemLibraryList', data)
  },
  // 选择单位
  getUnitList(params){
    return request.get('api/web/v1/basicData/materials/private/materialsUnit/getUnitPullDownList', params)
  },
  //获取动态数据源
  getDynamicDataSource(){
    return request.get('api/web/v1/inspection/private/inspectionEntry/getDynamicsSource?memberCode=fsd')
  },
}
