export const columns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    minWidth: 80,
    hiddenSearch: true
  },
  {
    prop: 'welderName',
    label: '姓名',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'welderNumber',
    label: '焊工编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'enableDate',
    label: '启用日期',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'welderProjectName',
    label: '合格项目',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'issueDate',
    label: '发证日期',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'expirationDate',
    label: '到期日期',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    slotName: 'action'
  }
]

export const columns2 = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'number',
    label: '项目编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'name',
    label: '项目名称',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    width: 160,
    slotName: 'action',
    fixed: 'right'
  }
]

export const columns3 = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '焊工姓名',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'number',
    label: '焊工编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'enableDate',
    label: '启用日期',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    width: 160,
    slotName: 'action',
    fixed: 'right'
  }
]
