// 列表
export const columnsAll = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 60
    },
    {
        prop: 'code',
        label: '来料检单号',
        hiddenSearch: true,
        sortable: false,
        width: 160
      },
      {
        prop: 'status',
        label: '状态',
        hiddenSearch: true,
        sortable: false,
        slotName: 'status',
        width: 120
      },
      {
        prop: 'inspectionSchemeName',
        label: '检验方案',
        hiddenSearch: true,
        sortable: false,
        width: 180
      },
      {
        prop: 'executeUserName',
        label: '计划检验人',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'materialsCode',
        label: '物料编码',
        hiddenSearch: true,
        sortable: false,
        width: 150,
        slotName: 'materialsCode'
      },
      {
        prop: 'materialsName',
        label: '物料名称',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'materialsSpecifications',
        label: '物料规格',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'isKeyMaterials',
        label: '是否关键件',
        hiddenSearch: true,
        sortable: false,
        slotName:'isKeyMaterials',
        width: 100
      },
      {
        prop: 'isExemption',
        label: '是否免检',
        hiddenSearch: true,
        sortable: false,
        slotName:'isExemption',
        width: 100
      },
      {
        prop: 'qualifiedBatchNumber',
        label: '检验批次',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'batchQuantity',
        label: '批次数量',
        hiddenSearch: true,
        sortable: false,
        width: 100
      },
      {
        prop: 'inspectionQuantity',
        label: '检验数量',
        hiddenSearch: true,
        sortable: false,
        width: 100
      },
      {
        prop: 'qualifiedQuantity',
        label: '合格数量',
        hiddenSearch: true,
        sortable: false,
        width: 100
      },
      {
        prop: 'qualifiedSequenceNumber',
        label: '合格序列号',
        hiddenSearch: true,
        sortable: false,
        slotName: 'qualifiedSequenceNumber'
      },
      {
        prop: 'defectQuantity',
        label: '不良数量',
        hiddenSearch: true,
        sortable: false,
        width: 100
      },
      {
        prop: 'defectSequenceNumber',
        label: '不良序列号',
        hiddenSearch: true,
        sortable: false,
        slotName: 'defectSequenceNumber'
      },
      {
        prop: 'defectBatchNumber',
        label: '不良批次',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'sequenceNumber',
        label: '序列号',
        hiddenSearch: true,
        sortable: false,
        slotName: 'sequenceNumber'
      },
      {
        prop: 'supplierName',
        label: '供应商',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'purchaseOrderCode',
        label: '采购单号',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'materialsReceivedOrderCode',
        label: '收料单号',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'remark',
        label: '备注',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'inspectionUserName',
        label: '检验人',
        hiddenSearch: true,
        sortable: false
      },
      {
        prop: 'startTime',
        label: '开始检验时间',
        hiddenSearch: true,
        sortable: false,
        width: 180
      },
      {
        prop: 'endTime',
        label: '完成检验时间',
        hiddenSearch: true,
        sortable: false,
        width: 180
      },
    {
      prop: 'createUser',
      label: '创建人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'createTime',
      label: '创建时间',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      sortable: false,
      hiddenSearch: true,
      width: 140
    }
  ]
// 详情页列表
export const columnsDetail = [
{
    prop: 'inspectionItemName',
    label: '质检项',
    hiddenSearch: true,
    sortable: false
},
{
    prop: 'standardValue',
    label: '标准值',
    hiddenSearch: true,
    sortable: false
},
{
    prop: 'upperLimit',
    label: '上偏差',
    hiddenSearch: true,
    sortable: false,
    width: 100
},
{
    prop: 'lowerLimit',
    label: '下偏差',
    hiddenSearch: true,
    sortable: false
},
{
    prop: 'inspectionMethod',
    label: '检验方法',
    hiddenSearch: true,
    sortable: false,
    width: 100
},
{
    prop: 'inspectionStandard',
    label: '检验标准',
    hiddenSearch: true,
    sortable: false
},
{
    prop: 'measuredValue',
    label: '实测值',
    hiddenSearch: true,
    sortable: false,
    slotName:'measuredValue'
},
{
    prop: 'attachments',
    label: '现场图片',
    hiddenSearch: true,
    sortable: false,
    slotName: 'attachments',
    width: 100
},
{
  prop: 'defectItemName',
  label: '缺陷项',
  hiddenSearch: true,
  sortable: false,
  width: 100
},
{
  prop: 'defectReasonName',
  label: '缺陷原因',
  hiddenSearch: true,
  sortable: false,
  width: 100
},
{
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    slotName: 'remark'
}
]
