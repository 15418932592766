<template>
    <div class="inventory-wrap common-tab">
      <div class="bottom">

            <div class="materials-info">
              <div v-show="searchFormList">
                <SearchForm
                  ref="searchForm"
                  :form-list-extend="searchFormList"
                  :form-data="searchFormData"
                  :searchType='1'
                  :default-form="defaultForm"
                  @search="searchTable"
                />
              </div>
              <div class="bottom">
                <FunctionHeader
                  ref="functionHeader"
                  v-model="functionHeader.searchVal"
                  :search-title="functionHeader.searchTitle"
                  :export-name="functionHeader.exportName"
                  :export-params="functionHeader.exportParams"
                  :columns="columns"
                  @search="$refs.mTable.setTableData(true)"
                >
                <el-button type="primary" size="small" v-if="exportSelectionIds.length && permission('BatchInWarehouse')" @click="batchInWarehouse">批量入库</el-button>
                  <span v-if="exportSelectionIds.length" style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;
                  line-height:32px;">
                    已选
                    <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                      / {{ total }}项
                  </span>
                  <el-button type="text" v-if="exportSelectionIds.length" size="small" @click="resetSelectData">清空</el-button>
                </FunctionHeader>
                <MTable
                  ref="mTable"
                  :columns="columns"
                  :height="height"
                  :set-data-method="getTableData"
                  @selection-change="selectionChange"
                >

                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="isKeyMaterials" slot-scope="{ row }">{{ row.isKeyMaterials ? '是' : '否' }}</div>
                <div slot="isExemption" slot-scope="{ row }">{{ row.isExemption ? '是' : '否' }}</div>
                <div slot="isStrengthenInspection" slot-scope="{ row }">{{ row.isStrengthenInspection ? '是' : '否' }}</div>
                <div slot="settingVariable" slot-scope="{ row }">{{ row.settingVariable ? '是' : '否' }}</div>
                  <div slot="status" slot-scope="{ row }">
                    {{ receiveStatus[row.status].name }}
                  </div>
                  <div slot="sequenceNumber" slot-scope="{row }">
                    <el-tooltip effect="dark" popper-class="sequence-tooltip" :content="row.sequenceNumber" placement="top-start">
                      <span class="mr-10">
                        {{ row.sequenceNumber }}
                    </span>
                    </el-tooltip>
                  </div>
                  <div slot="action" slot-scope="{ row }">
                    <el-button v-if="permission('Cancel')&&[1].includes(row.status)" type="text" @click="cancel(row)">撤销</el-button>
                  </div>
                </MTable>
              </div>
            </div>
      </div>

      <MDialog v-model="visible" :title="'选择仓库'" @onOk="addOrEditSubmit" confirmButtonText="入库">
      <MFormBuilder ref="formBuilds" :form-data="dataForAdd" :form-list="formList">
        <div slot="warehouseLocationName" style="display: flex;align-items: center;justify-content: flex-start;">
          <template>
            <span style="color: #f00;width: 10px;">*</span>
            <el-cascader
              style="width: 100%;"
              v-model="kwId"
              :options="warehouseLocationList"
              :props="{value: 'id', label: 'name'}"
            />
          </template>
        </div>
      </MFormBuilder>
    </MDialog>
    </div>
  </template>

  <script>
  import SearchForm from '@/components/SearchForm/SearchForm'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import sitApi from '@/api/information/warehouse/model'
  import {
    searchFormList
  } from './form-list'
  import {
    columns
  } from './columns'
  import api from '@/api/warehouse/warehouse-assign'
  import warehouseApi from '@/api/information/warehouse/model'
  import { setTreeData } from '@/utils'

  export default {
    name: 'purchaseWarehouseTaskList',
    components: {
      FunctionHeader,
      SearchForm
    },
    data() {
      return {
        api,
        total:0,
        params: {
          radio: 'ReceivedMaterialsOrderItem'
        },
        receiveStatus:[
            {
              id:0,
              name:'待开始'
            },
            {
              id:1,
              name:'已完成'
            }
        ],
        functionHeader: {
          searchVal: '',
          exportName: '',
          searchTitle:  '请输入收料单号/采购单号/供应商',
          exportParams: {}
        },
        functionHeader2: {
          searchVal: ''
        },
        columns,
        searchFormList,
        searchFormData: {
        },
        searchFormatData: [],
        defaultForm: { status: 0 },
        exportSelectionIds:[],
        selectData: [],
        visible:false,
        kwId:'',
        warehouseLocationList: [],
        dataForAdd: {},
        isLocation: false,
        formList: [
        {
          label: '仓库',
          colSpan: 12,
          key: 'wouseId',
          tagName: 'el-select',
          props: {
            placeholder: '请选择仓库',
            clearable:false,
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: []
            }
          ],
          on: {
            change: async (val) => {
              const isLocation = this.allWarehouseList.filter(item => item.id === val)[0].isLocation
              this.kwId = ''
              if (+isLocation === 1) {
                this.isLocation = true
                await this.getAllNodesByWarehouseId(val)
                this.formList[1].hidden = false
              }else {
                this.isLocation = false
                this.warehouseLocationList = []
                this.formList[1].hidden = true
              }
            }
          }
        },
        {
          label: '库位',
          colSpan: 12,
          hidden: true,
          key: 'warehouseLocationId',
          tagName: 'div',
          children: [
            {
              customSlots: ['warehouseLocationName']
            }
          ]
        }
      ],
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 44
      },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.exportSelectionIds.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
    },
    watch: {
    },
    created() {
      this.getWarehouseList()
    },
    mounted() {

    },
    methods: {
          // 获取线边仓
    async getWarehouseList() {
      const res = await sitApi.getRepositoryModelList()
      this.allWarehouseList = res
      if (res) {
        this.formList[0].children[0].options = res.filter(item => item.type === 0)
      }
    },
    async getAllNodesByWarehouseId(id) {
      const res = await warehouseApi.getAllNodesByWarehouseId(id)
      if (res) {
        this.warehouseLocationList = res.length > 1 ? setTreeData(res) : []
      }
    },
      batchInWarehouse(){
        this.kwId = ''
        this.warehouseLocationList = []
        this.formList[1].hidden = true
        this.visible = true
        this.dataForAdd = {}
      },
      selectionChange(data){
        this.selectData = data
        this.exportSelectionIds = data.map(item => item.batchNumber)
      },
      resetSelectData() {
      this.selectData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectData = this.selectData.filter((item) => item.selectable)
    },
    async addOrEditSubmit(callback) {
      const { form } = this.$refs.formBuilds
      if (!form.wouseId) {
        this.$message.error('请选择仓库')
        callback()
        return
      }else {
        if(this.isLocation){
          if (!this.kwId) {
            this.$message.error('请选择库位')
            callback()
            return
          }
        }
      }
      const that = this
      const res = await api.batchInWarehouses({
        batchNumbers: this.exportSelectionIds,
        warehouseId: form.wouseId,
        warehouseLocationId:that.kwId ? that.kwId[that.kwId.length - 1] : ''
      })
      if (res) {
        this.$message.success('入库成功')
        callback(true)
        this.visible = false
        this.$refs.mTable.tableRefs().clearSelection()
        this.$refs.mTable.setTableData()
      } else {
        callback()
      }
    },
    // 撤销
    async cancel(row) {
      this.$confirm('您确定要撤销吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.cancelMaterialsInWarehouses({id : row.id})
        if (res) {
          this.$message.success('操作成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
      async getTableData(condition, callback) {
        const { page, search, order } = condition
        const data = Object.assign({}, ...this.searchFormatData )
        const searchCondition = {
          ...data,
          searchKeyword: this.functionHeader.searchVal
        }
        this.functionHeader.exportParams = searchCondition
        const res = await api.getWaitingInWarehouseMaterialsListPage({
          page: page.currentPage,
          limit: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      },
      // 查询
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .inventory-wrap {
    width: 100%;
    padding-top: 10px;

    .m-radio-group {
      margin-left: 10px;
    }

    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;
    }
  }
  </style>
