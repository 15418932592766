import {status} from '@/config/options.config'
import {formatColumns} from '@/utils'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'name',
    label: '文件名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name'
  },
  {
    prop: 'customerName',
    label: '客户',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'factoryNumber',
    label: '出厂编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'time',
    label: '日期',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 150
  }
]

export const resetColumns = [
  {
    prop: 'name',
    label: '文件名称',
    sortable: true,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      colSpan: 24
    }
  },
  {
    prop: 'enterpriseDocumentClassId',
    label: '目录',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      index: 1, tagName: 'el-select', key: 'enterpriseDocumentClassId', colSpan: 24, props: {
        props: {
          label: 'name',
          value: 'id'
        },
        options: [],
        filterable: true
      }
    }
  }
]

export const resetColumnsBbelc = [
  {
    prop: 'name',
    label: '文件名称',
    sortable: true,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      colSpan: 24
    }
  },
  {
    prop: 'enterpriseDocumentClassId',
    label: '目录',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      index: 1, tagName: 'el-select', key: 'enterpriseDocumentClassId', colSpan: 24, props: {
        props: {
          label: 'name',
          value: 'id'
        },
        options: [],
        filterable: true
      }
    }
  },
  {
    prop: 'customerId',
    label: '客户',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      index: 3,
      tagName: 'el-select',
      required: false,
      key: 'customerId',
      colSpan: 24,
      props: {
        props: {
          label: 'name',
          value: 'id'
        },
        options: [],
        filterable: true
      }
    }
  },
  {
    prop: 'factoryNumber',
    label: '出厂编号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      index: 4,
      tagName: 'el-input',
      required: false,
      colSpan: 24,
      props: {
        type: 'text',
        filterable: true
      }
    }
  },
  {
    prop: 'time',
    label: '日期',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 5,
      key: 'time',
      tagName: 'el-date-picker',
      required: false,
      colSpan: 24,
      props: {
        type: 'date',
        valueFormat: 'yyyy-MM-dd',
        format: 'yyyy-MM-dd'
      }
    }
  }
]

export const addColumns = [
  {
    prop: 'enterpriseDocumentClassId',
    label: '目录',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      index: 1, tagName: 'el-select', key: 'enterpriseDocumentClassId', colSpan: 24, props: {
        props: {
          label: 'name',
          value: 'id'
        },
        options: [],
        filterable: true
      }
    }
  },
  {
    prop: 'drawing',
    label: '附件',
    sortable: false,
    hiddenSearch: true,
    slotName: 'drawing',
    form: {
      index: 2,
      colSpan: 24,
      tagName: 'div',
      customSlots: ['drawing']
    }
  }
]

export const addColumnsBbelc = [
  {
    prop: 'enterpriseDocumentClassId',
    label: '目录',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      index: 1, tagName: 'el-select', key: 'enterpriseDocumentClassId', colSpan: 24, props: {
        props: {
          label: 'name',
          value: 'id'
        },
        options: [],
        filterable: true
      }
    }
  },
  {
    prop: 'drawing',
    label: '附件',
    sortable: false,
    hiddenSearch: true,
    slotName: 'drawing',
    form: {
      index: 2,
      colSpan: 24,
      tagName: 'div',
      customSlots: ['drawing']
    }
  },
  {
    prop: 'customerId',
    label: '客户',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      index: 3,
      tagName: 'el-select',
      required: false,
      key: 'customerId',
      colSpan: 24,
      props: {
        props: {
          label: 'name',
          value: 'id'
        },
        options: [],
        filterable: true
      }
    }
  },
  {
    prop: 'factoryNumber',
    label: '出厂编号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      index: 4,
      tagName: 'el-input',
      required: false,
      colSpan: 24,
      props: {
        type: 'text',
        filterable: true
      }
    }
  },
  {
    prop: 'time',
    label: '日期',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 5,
      key: 'time',
      tagName: 'el-date-picker',
      required: false,
      colSpan: 24,
      props: {
        type: 'date',
        valueFormat: 'yyyy-MM-dd',
        format: 'yyyy-MM-dd'
      }
    }
  }
]
