
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'spotCheckNumber',
    label: '点检单号',
    sortable: 'custom',
    hiddenSearch: true,
    slotName: 'spotCheckNumber',
    minWidth: 160
  },
  {
    prop: 'spotCheckType',
    label: '点检类别',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentCode',
    label: '设备编号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'equipmentName',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'result',
    label: '点检结论',
    sortable: false,
    hiddenSearch: true,
    slotName: 'result'
  },
  {
    prop: 'equipmentClassName',
    label: '设备类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'checkBeginTime',
    label: '实际开始',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'checkEndTime',
    label: '实际结束',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'checkContinueTime',
    label: '消耗时间（min）',
    sortable: false,
    hiddenSearch: true,
    slotName: 'checkSpendTime',
    minWidth: 160
  },
  {
    prop: 'checker',
    label: '点检人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'organizationName',
    label: '业务部门',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status'
  },
  {
    prop: 'pointCheckItem',
    label: '点检项',
    sortable: false,
    hiddenSearch: true,
    slotName: 'pointCheckItem'
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  }
]
