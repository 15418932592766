import equipmentButtons from './page-buttons/equipment.buttons'
import facilityButtons from './page-buttons/xiewei/facility.buttons'
import informationButtons from './page-buttons/information.buttons'

export default {
  path: 'equipment',
  name: 'equipment',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '设备管理', icon: require('@/assets/l_c_S_images/生产设备.svg'), activeMenu: 'equipment' },
  redirect: '/equipment/all-equipment/account/list',
  children: [
    {
      path: 'all-equipment',
      name: 'allEquipment',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '设备管理', activeMenu: 'all-equipment' },
      redirect: '/equipment/all-equipment/account/list',
      children: [
        {
          path: 'account',
          name: 'equipmentAccount',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '设备台账', activeMenu: 'account', canLink: true },
          redirect: '/equipment/all-equipment/account/list',
          children: [
            {
              path: 'list',
              name: 'equipmentAccountList',
              component: () => import('@/views/equipment/account/account.vue'),
              meta: { roleTitle: '设备列表', title: '', activeMenu: 'account', buttons: equipmentButtons.equipmentAccountList('equipmentAccountList') },
              hidden: true
            },
            {
              path: 'base-print',
              name: 'basePrint',
              component: () => import('@/views/setting/base-print/base-print'),
              meta: { title: '打印设备码', activeMenu: 'account' },
              hidden: true
            },
            {
              path: 'detail/:id(\\d+)',
              name: 'equipmentAccountDetail',
              component: () => import('@/views/equipment/account/new-detail/detail.vue'),
              meta: { title: '设备详情', activeMenu: 'account', nb: true, buttons: equipmentButtons.equipmentAccountDetail('equipmentAccountDetail') },
              hidden: true
            }
          ]
        }
        // {
        //   path: 'maintenance',
        //   name: 'EquipmentMaintenance',
        //   component: () => import('@/views/equipment/maintenance/index'),
        //   meta: { title: '设备维保', icon: 'maintenance', activeMenu: 'maintenance', buttons: equipmentButtons.EquipmentMaintenance('EquipmentMaintenance') }
        // },
        // {
        //   path: 'detail/:id(\\d+)',
        //   name: 'MaintenanceDetail',
        //   component: () => import('@/views/equipment/maintenance/detail/detail.vue'),
        //   meta: { title: '维保处理', icon: 'equipment', noCache: true, nbc: true, activeMenu: 'maintenance' },
        //   hidden: true
        // }
      ]
    },
    {
      path: 'all-assign',
      name: 'allAssign',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '任务管理', activeMenu: 'all-assign' },
      redirect: '/equipment/all-assign/equipment-fault',
      children: [
        {
          path: 'equipment-fault',
          name: 'EquipmentFault',
          component: () => import('@/views/equipment/fault/index'),
          meta: { title: '报修单', icon: 'equipment', activeMenu: 'equipment-fault', buttons: equipmentButtons.EquipmentFault('EquipmentFault') }
        },
        {
          path: 'equipment-repair',
          name: 'EquipmentRepair',
          component: () => import('@/views/equipment/repair/index'),
          meta: { title: '维修单', icon: 'equipment', activeMenu: 'equipment-repair', buttons: equipmentButtons.EquipmentRepair('EquipmentRepair') }
        },
        {
          path: 'inspection-bills',
          name: 'inspectionBills',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '巡检单', icon: 'inspection-bills', canLink: true, activeMenu: 'inspection-bills' },
          redirect: '/equipment/all-assign/inspection-bills/list',
          children: [
            {
              hidden: true,
              path: 'list',
              name: 'inspectionBillsList',
              component: () => import('@/views/equipment/inspection-bills/inspection-bills.vue'),
              meta: { roleTitle: '巡检单列表', title: '', icon: 'equipment', activeMenu: 'inspection-bills', buttons: equipmentButtons.inspectionBillsList('inspectionBillsList') }
            },
            {
              hidden: true,
              path: 'detail',
              name: 'inspectionBillsDetail',
              component: () => import('@/views/equipment/inspection-bills/detail/detail.vue'),
              meta: { title: '详情', icon: 'equipment', activeMenu: 'inspection-bills', nb: true }
            }
          ]
        },
        {
          path: 'point-bills',
          name: 'pointBills',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '点检单', icon: 'point-bills', canLink: true, activeMenu: 'point-bills' },
          redirect: '/equipment/all-assign/point-bills/list',
          children: [
            {
              hidden: true,
              path: 'list',
              name: 'pointBillsList',
              component: () => import('@/views/equipment/point-bills/point-bills.vue'),
              meta: { roleTitle: '点检单列表', title: '', icon: 'equipment', activeMenu: 'point-bills', buttons: equipmentButtons.pointBillsList('pointBillsList') }
            },
            {
              hidden: true,
              path: 'detail',
              name: 'pointBillsDetail',
              component: () => import('@/views/equipment/point-bills/detail/detail.vue'),
              meta: { title: '详情', icon: 'equipment', activeMenu: 'point-bills', nb: true }
            }
          ]
        },
        {
          path: 'maintenance-bills',
          name: 'maintenanceBills',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '保养单', icon: 'maintenance-bills', canLink: true, activeMenu: 'maintenance-bills' },
          redirect: '/equipment/all-assign/maintenance-bills/list',
          children: [
            {
              hidden: true,
              path: 'list',
              name: 'maintenanceBillsList',
              component: () => import('@/views/equipment/maintenance-bills/maintenance-bills.vue'),
              meta: { roleTitle: '保养单列表', title: '', icon: 'equipment', activeMenu: 'maintenance-bills', buttons: equipmentButtons.maintenanceBillsList('maintenanceBillsList') }
            },
            {
              hidden: true,
              path: 'detail',
              name: 'maintenanceBillsDetail',
              component: () => import('@/views/equipment/maintenance-bills/detail/detail.vue'),
              meta: { title: '详情', icon: 'equipment', activeMenu: 'maintenance-bills', nb: true }
            }
          ]
        }
        // {
        //   path: 'maintenance',
        //   name: 'EquipmentMaintenance',
        //   component: () => import('@/views/equipment/maintenance/index'),
        //   meta: { title: '设备维保', icon: 'maintenance', activeMenu: 'maintenance', buttons: equipmentButtons.EquipmentMaintenance('EquipmentMaintenance') }
        // },
        // {
        //   path: 'detail/:id(\\d+)',
        //   name: 'MaintenanceDetail',
        //   component: () => import('@/views/equipment/maintenance/detail/detail.vue'),
        //   meta: { title: '维保处理', icon: 'equipment', noCache: true, nbc: true, activeMenu: 'maintenance' },
        //   hidden: true
        // }
      ]
    },
    // {
    //   path: 'plan-management',
    //   name: 'planManagement',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '计划管理', icon: 'equipment', activeMenu: 'plan-management' },
    //   redirect: '/equipment/plan-management/inspection-plan',
    //   children: [
    //     {
    //       path: 'inspection-plan',
    //       name: 'inspectionPlan',
    //       component: () => import('@/layout/blank.vue'),
    //       meta: { title: '巡检计划', icon: 'inspection-plan', canLink: true, activeMenu: 'inspection-plan' },
    //       redirect: '/equipment/plan-management/inspection-plan/list',
    //       children: [
    //         {
    //           hidden: true,
    //           path: 'list',
    //           name: 'inspectionPlanList',
    //           component: () => import('@/views/equipment/plan-management/inspection/spot.vue'),
    //           meta: { roleTitle: '巡检计划', title: '', icon: 'equipment', activeMenu: 'inspection-plan', buttons: equipmentButtons.inspectionPlan('inspectionPlanList') }
    //         },
    //         {
    //           hidden: true,
    //           path: 'detail',
    //           name: 'inspectionPlanDetail',
    //           component: () => import('@/views/equipment/plan-management/inspection/detail/detail.vue'),
    //           meta: { title: '详情', icon: 'equipment', noCache: true, activeMenu: 'inspection-plan', nb: true, buttons: equipmentButtons.inspectionPlanDetail('inspectionPlanDetail') }
    //         }
    //       ]
    //     },
    //     {
    //       path: 'maintenance-plan',
    //       name: 'maintenancePlan',
    //       component: () => import('@/layout/blank.vue'),
    //       meta: { title: '保养计划', icon: 'maintenance-plan', canLink: true, activeMenu: 'maintenance-plan' },
    //       redirect: '/equipment/plan-management/maintenance-plan/list',
    //       children: [
    //         {
    //           hidden: true,
    //           path: 'list',
    //           name: 'maintenancePlanList',
    //           component: () => import('@/views/equipment/plan-management/maintenance/maintenance.vue'),
    //           meta: { roleTitle: '保养计划列表', title: '', icon: 'equipment', activeMenu: 'maintenance-plan', buttons: equipmentButtons.maintenancePlan('maintenancePlanList') }
    //         },
    //         {
    //           hidden: true,
    //           path: 'detail',
    //           name: 'maintenancePlanDetail',
    //           component: () => import('@/views/equipment/plan-management/maintenance/detail/detail.vue'),
    //           meta: { title: '详情', icon: 'equipment', noCache: true, activeMenu: 'maintenance-plan', nb: true, buttons: equipmentButtons.maintenancePlanDetail('maintenancePlanDetail') }
    //         }
    //       ]
    //     }
    //   ]
    // },
    {
      path: 'spare-part-infos',
      name: 'sparePartInfos',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '备件管理', activeMenu: 'spare-part-infos' },
      redirect: '/equipment/spare-part-infos/part-account',
      children: [
        {
          path: 'part-account',
          name: 'PartAccount',
          component: () => import('@/views/equipment/part-account/index'),
          meta: { title: '备件台账', icon: 'equipment', activeMenu: 'part-account', buttons: equipmentButtons.PartAccount('PartAccount') }
        },
        {
          path: 'part-apply',
          name: 'PartApply',
          component: () => import('@/views/equipment/part-apply/index'),
          meta: { title: '备件申请', icon: 'equipment', activeMenu: 'part-apply', buttons: equipmentButtons.PartApply('PartApply') }
        },
        {
          path: 'part-nums',
          name: 'PartNums',
          component: () => import('@/views/equipment/part-nums/index'),
          meta: { title: '备件库存', icon: 'equipment', activeMenu: 'part-nums', buttons: equipmentButtons.PartNums('PartNums') }
        },
        {
          path: 'part-bills',
          name: 'PartBills',
          component: () => import('@/views/equipment/part-bills/index'),
          meta: { title: '库存流水', icon: 'equipment', activeMenu: 'part-bills', buttons: equipmentButtons.PartBills('PartBills') }
        }
      ]
    },
    //设备建模
    {
      path: 'equipments-info',
      name: 'EquipmentsInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '基础数据', activeMenu: 'equipments-info' },
      redirect: '/equipment/equipments-info/ledger/list',
      children: [
        // {
        //   path: 'ledger',
        //   name: 'Ledger',
        //   component: () => import('@/layout/blank.vue'),
        //   meta: { title: '设备台账', activeMenu: 'ledger', canLink: true },
        //   redirect: '/equipment/equipments-info/ledger/list',
        //   children: [
        //     {
        //       path: 'list',
        //       name: 'equipmentAccountList',
        //       component: () => import('@/views/equipment/account/account.vue'),
        //       meta: { roleTitle: '设备列表', title: '', activeMenu: 'ledger', buttons: informationButtons.equipmentAccountList('equipmentAccountList') },
        //       hidden: true
        //     },
        //     {
        //       path: 'base-print',
        //       name: 'basePrint',
        //       component: () => import('@/views/setting/base-print/base-print'),
        //       meta: { title: '打印设备码', activeMenu: 'ledger' },
        //       hidden: true
        //     },
        //     {
        //       path: 'detail/:id(\\d+)',
        //       name: 'equipmentAccountDetail',
        //       component: () => import('@/views/equipment/account/new-detail/detail.vue'),
        //       meta: { title: '设备详情', activeMenu: 'ledger', nb: true, buttons: informationButtons.equipmentAccountDetail('equipmentAccountDetail') },
        //       hidden: true
        //     }
        //   ]
        // },
        {
          path: 'inspection-plan',
          name: 'inspectionPlan',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '巡检计划', icon: 'inspection-plan', canLink: true, activeMenu: 'inspection-plan' },
          redirect: '/equipment/equipments-info/inspection-plan/list',
          children: [
            {
              hidden: true,
              path: 'list',
              name: 'inspectionPlanList',
              component: () => import('@/views/equipment/plan-management/inspection/inspection.vue'),
              meta: { roleTitle: '巡检计划', title: '', icon: 'equipment', activeMenu: 'inspection-plan',
                buttons: informationButtons.inspectionPlan('inspectionPlanList') }
            },
            {
              hidden: true,
              path: 'detail',
              name: 'inspectionPlanDetail',
              component: () => import('@/views/equipment/plan-management/inspection/detail/detail.vue'),
              meta: { title: '详情', icon: 'equipment', noCache: true, activeMenu: 'inspection-plan', nb: true,
                buttons: informationButtons.inspectionPlanDetail('inspectionPlanDetail') }
            }
          ]
        },
        {
          path: 'spot-plan',
          name: 'spotPlan',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '点检计划', icon: 'spot-plan', canLink: true, activeMenu: 'spot-plan' },
          redirect: '/equipment/equipments-info/spot-plan/list',
          children: [
            {
              hidden: true,
              path: 'list',
              name: 'spotPlanList',
              component: () => import('@/views/equipment/plan-management/spot/spot.vue'),
              meta: { roleTitle: '点检计划', title: '', icon: 'equipment', activeMenu: 'spot-plan',
                buttons: informationButtons.spotPlan('spotPlanList') }
            },
            {
              hidden: true,
              path: 'detail',
              name: 'spotPlanDetail',
              component: () => import('@/views/equipment/plan-management/spot/detail/detail.vue'),
              meta: { title: '详情', icon: 'equipment', noCache: true, activeMenu: 'spot-plan', nb: true,
                buttons: informationButtons.spotPlanDetail('spotPlanDetail') }
            }
          ]
        },
        {
          path: 'maintenance-plan',
          name: 'maintenancePlan',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '保养计划', icon: 'maintenance-plan', canLink: true, activeMenu: 'maintenance-plan' },
          redirect: '/equipment/equipments-info/maintenance-plan/list',
          children: [
            {
              hidden: true,
              path: 'list',
              name: 'maintenancePlanList',
              component: () => import('@/views/equipment/plan-management/maintenance/maintenance.vue'),
              meta: { roleTitle: '保养计划列表', title: '', icon: 'equipment', activeMenu: 'maintenance-plan',
                buttons: informationButtons.maintenancePlan('maintenancePlanList') }
            },
            {
              hidden: true,
              path: 'detail',
              name: 'maintenancePlanDetail',
              component: () => import('@/views/equipment/plan-management/maintenance/detail/detail.vue'),
              meta: { title: '详情', icon: 'equipment', noCache: true, activeMenu: 'maintenance-plan', nb: true,
                buttons: informationButtons.maintenancePlanDetail('maintenancePlanDetail') }
            }
          ]
        },
        {
          path: 'maintenance-setting',
          name: 'maintenanceSetting',
          component: () => import('@/views/equipment/maintenance-new/maintenance.vue'),
          meta: { noCache: false, title: '维保配置', icon: 'equipment', activeMenu: 'maintenance-setting',
            buttons: informationButtons.maintenanceSetting('maintenanceSetting') }
        },
        {
          path: 'project-setting',
          name: 'projectSetting',
          component: () => import('@/views/equipment/project-setting/project-setting.vue'),
          meta: { noCache: false, title: '点巡检配置', icon: 'equipment', activeMenu: 'project-setting',
            buttons: informationButtons.projectSetting('projectSetting') }
        },
        {
          path: 'equipment-params',
          name: 'equipmentParams',
          component: () => import('@/views/equipment/params/params.vue'),
          meta: { noCache: true, title: '设备参数', icon: 'equipment', activeMenu: 'equipment-params',
            buttons: informationButtons.equipmentParams('equipmentParams') }
        }
      ]
    },
    // {
    //   path: 'equipment-setting',
    //   name: 'equipmentSetting',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '配置管理', activeMenu: 'equipment-setting' },
    //   redirect: '/equipment/equipment-setting/maintenance-setting',
    //   children: [
    //     {
    //       path: 'maintenance-setting',
    //       name: 'maintenanceSetting',
    //       component: () => import('@/views/equipment/maintenance-new/maintenance.vue'),
    //       meta: { noCache: false, title: '维保配置', icon: 'equipment', activeMenu: 'maintenance-setting', buttons: equipmentButtons.maintenanceSetting('maintenanceSetting') }
    //     },
    //     {
    //       path: 'project-setting',
    //       name: 'projectSetting',
    //       component: () => import('@/views/equipment/project-setting/project-setting.vue'),
    //       meta: { noCache: false, title: '点巡检配置', icon: 'equipment', activeMenu: 'project-setting', buttons: equipmentButtons.projectSetting('projectSetting') }
    //     },
    //     {
    //       path: 'equipment-params',
    //       name: 'equipmentParams',
    //       component: () => import('@/views/equipment/params/params.vue'),
    //       meta: { noCache: true, title: '设备参数', icon: 'equipment', activeMenu: 'equipment-params', buttons: equipmentButtons.equipmentParams('equipmentParams') }
    //     }
    //   ]
    // },
    // {
    //   path: 'auxiliary-material-management',
    //   name: 'auxiliaryMaterialManagement',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '辅材管理', activeMenu: 'auxiliary-material-management' },
    //   redirect: '/equipment/auxiliary-material-management/facility/list',
    //   children: [
    //     {
    //       path: 'auxiliary-materials',
    //       name: 'auxiliaryMaterials',
    //       component: () => import('@/layout/blank.vue'),
    //       meta: { title: '辅材履历', activeMenu: 'auxiliary-materials', canLink: true },
    //       redirect: '/equipment/auxiliary-material-management/auxiliary-materials/list',
    //       children: [
    //         {
    //           path: 'list',
    //           name: 'auxiliaryMaterialsList',
    //           component: () => import('@/views/facility/auxiliary-materials/list.vue'),
    //           meta: { roleTitle: '辅材列表', title: '', noCache: true, activeMenu: 'auxiliary-materials', buttons: facilityButtons.auxiliaryMaterialsList('auxiliaryMaterialsList') },
    //           hidden: true
    //         },
    //         {
    //           path: 'detail/:id(\\d+)',
    //           name: 'auxiliaryMaterialsDetail',
    //           component: () => import('@/views/facility/auxiliary-materials/detail.vue'),
    //           meta: { title: '辅材详情', activeMenu: 'auxiliary-materials', noCache: true, nb: true, buttons: facilityButtons.auxiliaryMaterialsDetail('auxiliaryMaterialsDetail') },
    //           hidden: true
    //         }
    //       ]
    //     },
    //   ]
    // }
  ]
}
