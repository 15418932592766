<template>
  <div class="orders">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange" style="display:none;">
      <el-radio-button v-if="permission('MaterialsList')" label="Materials">物料</el-radio-button>
      <el-radio-button v-if="permission('PackageList')" label="Package">包装</el-radio-button>
      <el-radio-button v-if="permission('TrayList')" label="Tray">托盘</el-radio-button>
    </el-radio-group>
    <div class="bottom">
      <div v-if="flag === 'Materials'" class="top">
      <SearchForm
        ref="searchForms"
        :form-list-extend="searchFormList"
        class="searchs"
        @search="searchTable"
      >
        <div slot="width">
          <el-input v-model="widthMin" placeholder="宽度下限" style="width:43%" clearable />
          <span style="margin: 0 10px;">-</span>
          <el-input v-model="widthMax" placeholder="宽度上限" style="width:43%" clearable />
        </div>
        <div slot="thickness">
          <el-input v-model="thicknessMin" placeholder="厚度下限" style="width:43%" clearable />
          <span style="margin: 0 10px;">-</span>
          <el-input v-model="thicknessMax" placeholder="厚度上限" style="width:43%" clearable />
        </div>
      </SearchForm>
    </div>
    <div v-else class="top">
      <SearchForm
        ref="searchForms"
        :form-list-extend="searchFormLists"
        class="searchs"
        @search="searchTable"
      />
    </div>
    <FunctionHeader
      ref="functionHeader"
      v-model="functionHeader.searchVal"
      :search-title="searchTitle"
      :radio="params.radio"
      :columns-key="columnsKey"
      :export-name="functionHeader.exportName"
      :show-export="!selects.length ? true : false"
      :style="{ marginLeft : selects.length ? 0 : '-10px'}"
      @search="$refs.mTable.setTableData(true)"
    >
      <div slot="all" v-show="selects.length">
        <el-button v-if="(memberCode === 'bbelc' || memberCode === 'bbwz') && permission('MaterialsList')" type="primary" :disabled="!selects.length" size="small" @click="batchEnterWarehouse()">批量入库</el-button>
        <el-button v-if="permission(params.radio + 'Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
      </div>
    </FunctionHeader>
    <MTable
      v-if="permission(params.radio + 'List')"
      ref="mTable"
      :columns="columns[params.radio]"
      :height="height"
      :set-data-method="getTableData"
      :columns-key="columnsKey"
      @selection-change="selectionChange"
    >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
    </MTable>
    </div>
    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />
  </div>
</template>
<script>
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import { searchFormListBbelc, searchFormList, searchFormLists } from './form-list'
import api from '@/api/site-warehouse/warehouse'
import maApi from '@/api/information/materials-attribute'
import woApi from '@/api/production/work-order'
import keApi from '@/api/information/unit'
import sitApi from '@/api/information/production/sit-warehouse'
import columns from './columns'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import Vue from 'vue'

export default {
  name: 'Warehouses',
  components: { EditMaterials, FunctionHeader, SearchForm },
  mixins: [viewMaterialsDetail],
  data() {
    return {
      widthMin: '',
      widthMax: '',
      thicknessMin: '',
      thicknessMax: '',
      api,
      columns: this._.cloneDeep(columns),
      searchFormListBbelc,
      searchFormList,
      searchFormLists,
      functionHeader: {
        searchVal: '',
        exportName: 'exportSiteWarehouse'
      },
      searchFormatData: [],
      workId: '',
      sitId: '',
      typeId: '',
      params: {
        radio: 'Materials'
      },
      searchTitle: '批次码/工单号/物料编码/名称/规格',
      flag: 'Materials',
      selects: [],
      customerCode: '',
      total:0
    }
  },
  computed: {
    height() {
      if (this.memberCode === 'bbelc' || this.memberCode === 'bbwz') {
        return this.$store.state.app.contentHeight - 235 - 63 - 45
      }
      return this.$store.state.app.contentHeight - 235 - 63
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selects.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  created(){
    this.params.radio = this.$route.meta.nameFlage ? this.$route.meta.nameFlage : 'Materials'
  },
  beforeMount() {
    if (this.memberCode === 'bbelc' || this.memberCode === 'bbwz') {
      for (const key in this.columns) {
        this.columns[key].unshift({
          type: 'selection',
          fixed: 'left',
          width: 80,
          selectable: this.checkSelectable
        })
      }
    } else {
      for (const key in this.columns) {
        this.columns[key].unshift({
          type: 'selection',
          width: 50,
          fixed: 'left',
          reserveSelection: true
        })
      }
    }
  },
  mounted() {
    if (this.memberCode === 'bbelc' || this.memberCode === 'bbwz') {
      this.getUser()
      this.searchFormList = this.searchFormListBbelc
    }
    this.getMaterialsTypeList()
    // this.getWorkOrder()
    this.getWareList()
  },
  methods: {
    resetSelectData() {
      this.selects.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selects = this.selects.filter((item) => item.selectable)
    },
    checkSelectable(row) {
      return !!row.workOrderId
    },
    allMore(command) {
      const functions = {
        batchExport: () => { this.batchExportFront() }
      }
      functions[command]()
    },
    batchExportFront() {
      if (this.params.radio === 'Materials') {
        const dataId = this.selects.map(item => item.sitWarehouseId)
        const data1 = this.selects.map(item => item.materialsId)
        const data2 = this.selects.map(item => item.batchNumber)
        const data = dataId.map((item, index) => {
          return `${item}${data1[index]}${data2[index]}`
        })
        this.$refs.functionHeader.export({
          exportWarehouseSign: data.join('^')
        })
      }
      if (this.params.radio === 'Package') {
        const dataIds = this.selects.map(item => item.sitWarehouseId)
        const data3 = this.selects.map(item => item.containerCode)
        const data4 = this.selects.map(item => item.batchNumber)
        const datas = dataIds.map((item, index) => {
          return `${item}${data3[index]}${data4[index]}`
        })
        this.$refs.functionHeader.export({
          exportWarehouseSign: datas.join('^')
        })
      }
      if (this.params.radio === 'Tray') {
        const dataIdss = this.selects.map(item => item.palletCode)
        const data5 = this.selects.map(item => item.containerCode)
        const data6 = this.selects.map(item => item.batchNumber)
        const datass = dataIdss.map((item, index) => {
          return `${item}${data5[index]}${data6[index]}`
        })
        this.$refs.functionHeader.export({
          exportWarehouseSign: datass.join('^')
        })
      }
    },
    radioChange(label) {
      Object.assign(this.functionHeader, {
        exportName: label === 'Package' ? 'exportStockContainer' : label === 'Tray' ? 'exportStockPallet' : 'exportSiteWarehouse'
      })
      this.flag = label
      this.searchFormatData = []
      this.selectKeyword = ''
      const titles = new Map([
        ['Materials', '批次码/工单号/物料编码/名称/规格'],
        ['Package', '包装码/批次码'],
        ['Tray', '托盘编码/包装码/批次码']
      ])
      this.searchTitle = titles.get(this.params.radio)
      this.selects = []
      this.$refs.searchForms.reset()
      this.getWareList()
      // this.$refs.mTable.setTableData(true)
      this.$refs.mTable.tableRefs().clearSelection()
    },
    selectionChange(val) {
      this.selects = val
    },
    // 获取客户
    async getUser() {
      const res = await keApi.getCustomerList()
      if (res) {
        this.searchFormList[2].children[0].options = res
      }
    },
    // 获取物料类型
    async getMaterialsTypeList() {
      const res = await maApi.getMaterialsTypeList()
      if (res) {
        this.searchFormList[1].children[0].options = res
      }
    },
    // 获取工单
    async getWorkOrder() {
      const res = await woApi.getPlanpageno()
      if (res) {
        this.searchFormList[1].children[0].options = res
      }
    },
    // 获取线边库
    async getWareList() {
      const res = await sitApi.getSiteWarehouseList()
      if (res) {
        if (this.flag === 'Materials') {
          this.searchFormList[0].children[0].options = res
        } else {
          this.searchFormLists[0].children[0].options = res
        }
      }
    },
    // 批量入库  北变
    async batchEnterWarehouse() {
      // const res = await api.batchEnterWarehouse(this.selects.map(item => item.workOrderId))
      const res = await api.batchEnterWarehouse(this.selects.map(item => {
        return {
          sitWarehouseId:item.sitWarehouseId,
          batchNumber:item.batchNumber,
          workOrderId:item.workOrderId,
          materialsId:item.materialsId
        }
      }))
      if (res) {
        this.$message.success('入库成功')
      }
    },
    // 物料列表
    getTableData(condition, callback) {
      if (this.flag === 'Package') {
        this.getPackData(condition, callback)
      } else if (this.flag === 'Tray') {
        this.getTrayData(condition, callback)
      } else {
        this.getMaterialData(condition, callback)
      }
    },
    // 物料列表
    async getMaterialData(condition, callback) {
      const { page, order } = condition
      const res = await api.getPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        sitWarehouseId: this.sitId,
        materialType: this.typeId,
        workOrderId: this.workId,
        customerCode: this.customerCode,
        searchKeyword: this.functionHeader.searchVal,
        widthMin: this.widthMin,
        widthMax: this.widthMax,
        thicknessMin: this.thicknessMin,
        thicknessMax: this.thicknessMax
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      // callback()
    },
    // 托盘列表
    async getTrayData(condition, callback) {
      const { page, order } = condition
      const res = await api.getTrayPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        sitWarehouseId: this.sitId,
        searchKeyword: this.functionHeader.searchVal
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    // 包装列表
    async getPackData(condition, callback) {
      const { page, order } = condition
      const res = await api.getPackPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        sitWarehouseId: this.sitId,
        searchKeyword: this.functionHeader.searchVal
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    searchTable(data) {
      if (data.length > 0) {
        const workId = data.filter(item => item.fieldName === 'workOrderId')
        const sitId = data.filter(item => item.fieldName === 'sitWarehouseId')
        const typeId = data.filter(item => item.fieldName === 'materialsType')
        const customerCode = data.filter(item => item.fieldName === 'customerCode')
        this.workId = workId.length > 0 ? workId[0].fieldValue : ''
        this.sitId = sitId.length > 0 ? sitId[0].fieldValue : ''
        this.typeId = typeId.length > 0 ? typeId[0].fieldValue : ''
        this.customerCode = customerCode.length > 0 ? customerCode[0].fieldValue : ''
      } else {
        this.workId = ''
        this.sitId = ''
        this.typeId = ''
        this.customerCode = ''
      }
      while (Vue.Cancel.length > 0) {
        Vue.Cancel.shift()('cancel')
      }
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep button {
    height: 32px;
  }
 .orders{
    .bottom {
      width: 100%;
      height: 100%;
      padding: 0 10px;
    }
    .li-btn{
      margin-right: 10px;
      color:#607FFF;
    }
    .searchs{
      padding-left: 0;
    }
    .input-select{
      width: 120px;
      display: block;
      }
    .input-with-select{
      width: 100%;
   }
 }
</style>
