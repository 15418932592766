var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("探伤检测任务详情")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("任务编号：" + _vm._s(_vm.detailData.code))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "业务类型：" +
                    _vm._s(_vm.inspectionTypeNames[_vm.detailData.type])
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "质检方案：" + _vm._s(_vm.detailData.inspectionSchemeName)
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "质检状态：" +
                    _vm._s(
                      _vm.detailData.status || _vm.detailData.status == 0
                        ? _vm.qualityTaskStatus[_vm.detailData.status].name
                        : ""
                    )
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("生产工单：" + _vm._s(_vm.detailData.producePlanNumber))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("生产产品：" + _vm._s(_vm.detailData.materialsFullName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("生产任务：" + _vm._s(_vm.detailData.productionTaskCode))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("工序：" + _vm._s(_vm.detailData.procedureName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("创建人：" + _vm._s(_vm.detailData.createUser))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("创建时间：" + _vm._s(_vm.detailData.createTime))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("任务开始时间：" + _vm._s(_vm.detailData.startTime))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("任务结束时间：" + _vm._s(_vm.detailData.endTime))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("执行人：" + _vm._s(_vm.detailData.executorName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("业务部门：" + _vm._s(_vm.detailData.businessUnitName))
              ])
            ]),
            _vm.type === "view"
              ? _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v("结论判定： "),
                    _c(
                      "span",
                      {
                        style: {
                          color:
                            _vm.detailData.result == 0
                              ? "#E02020"
                              : _vm.detailData.result == 1
                              ? "#00AB29"
                              : ""
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.detailData.result == 0
                                ? "不合格"
                                : _vm.detailData.result == 1
                                ? "合格"
                                : ""
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _vm.detailData.result == 0 && _vm.type === "view"
              ? _c("el-col", { attrs: { span: 6 } }, [
                  _c("span", { staticClass: "bot-ti" }, [
                    _vm._v("处理意见：" + _vm._s(_vm.detailData.suggestion))
                  ])
                ])
              : _vm._e(),
            _vm.type === "view"
              ? _c("el-col", { attrs: { span: 6 } }, [
                  _c(
                    "span",
                    { staticClass: "bot-ti" },
                    [
                      _vm._v("附件： "),
                      _vm._l(_vm.detailData.attachment, function(item) {
                        return _c(
                          "el-link",
                          {
                            key: item.id,
                            staticStyle: { "padding-left": "10px" },
                            attrs: { href: item.url, target: "_blank" }
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      })
                    ],
                    2
                  )
                ])
              : _vm._e()
          ],
          1
        )
      ]),
      _vm.whetherInspectionReportFlag
        ? _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticClass: "card-tit" }, [_vm._v("报检信息")]),
            _c(
              "div",
              { staticClass: "card-bot" },
              [
                (_vm.memberCode === "fsd" ||
                  _vm.memberCode === "mom" ||
                  _vm.memberCode === "ydhb") &&
                _vm.inspectionObject
                  ? _c(
                      "el-col",
                      {
                        staticStyle: { "line-height": "36px" },
                        attrs: { span: 3 }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "bot-ti",
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "15px"
                            }
                          },
                          [_vm._v("报检对象：" + _vm._s(_vm.inspectionObject))]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.reportItemViewList.length > 0
                  ? _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-pagination", {
                          attrs: {
                            "page-size": 1,
                            layout: "prev, pager, next",
                            total: _vm.reportItemViewList.length
                          },
                          on: { "current-change": _vm.handleCurrentChange }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  padding: "20px",
                  "padding-top": "0"
                }
              },
              [
                _vm.reportItemViewList.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "baojian-container",
                        staticStyle: { width: "100%", "overflow-x": "scroll" }
                      },
                      [
                        _vm._m(0),
                        _vm._l(
                          _vm.reportItemViewList[_vm.currentPageIndex - 1].msg,
                          function(bjItem) {
                            return _c("div", { staticClass: "content1" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "divItem",
                                  staticStyle: { position: "relative" }
                                },
                                [_vm._v(_vm._s(bjItem.inspectionEntryName))]
                              ),
                              _c("div", { staticClass: "divItem divItemSW" }, [
                                _vm._v(
                                  _vm._s(
                                    bjItem.measuredValue
                                      ? bjItem.measuredValue.includes("[")
                                        ? JSON.parse(bjItem.measuredValue).join(
                                            ","
                                          )
                                        : bjItem.measuredValue
                                      : "-"
                                  ) + " "
                                )
                              ])
                            ])
                          }
                        )
                      ],
                      2
                    )
                  : _vm._e()
              ]
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "detail-card" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "detail-tabs",
              attrs: { type: "border-card" },
              model: {
                value: _vm.activeTabName,
                callback: function($$v) {
                  _vm.activeTabName = $$v
                },
                expression: "activeTabName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "按检验对象", name: "按检验对象" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        padding: "20px",
                        "padding-top": "0"
                      }
                    },
                    [
                      _vm.allViewList.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "baojian-container",
                              staticStyle: { width: "80%" }
                            },
                            [
                              _c("div", { staticStyle: { width: "120px" } }, [
                                _c("div", { staticClass: "divItem" }, [
                                  _vm._v("焊缝号")
                                ]),
                                _c("div", { staticClass: "divItem divItemS" }, [
                                  _vm._v("探伤合格状态")
                                ])
                              ]),
                              _vm._l(_vm.allViewList, function(bjItem) {
                                return _c("div", { staticClass: "content1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "divItem",
                                      staticStyle: { position: "relative" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(bjItem.inspectionObjectNumber)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "divItem divItemSW" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          bjItem.result ? "合格" : "不合格"
                                        ) + " "
                                      )
                                    ]
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.type !== "view"
        ? _c("div", { staticClass: "detail-card" }, [
            _c("div", { staticStyle: { display: "flex" } }, [
              _c(
                "div",
                {
                  staticClass: "card-bot",
                  staticStyle: {
                    "flex-wrap": "nowrap",
                    "align-items": "center"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "bot-ti",
                      staticStyle: { "margin-bottom": "0px" }
                    },
                    [_vm._v("合格状态：")]
                  ),
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.detailData.result,
                        callback: function($$v) {
                          _vm.$set(_vm.detailData, "result", $$v)
                        },
                        expression: "detailData.result"
                      }
                    },
                    _vm._l(_vm.resultList, function(val) {
                      return _c("el-option", {
                        key: val.name,
                        attrs: { label: val.name, value: val.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.detailData.result === 0,
                      expression: "detailData.result === 0"
                    }
                  ],
                  staticClass: "card-bot",
                  staticStyle: {
                    "flex-wrap": "nowrap",
                    "align-items": "center"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "bot-ti",
                      staticStyle: { "margin-bottom": "0px" }
                    },
                    [_vm._v("处理意见：")]
                  ),
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.detailData.suggestion,
                          callback: function($$v) {
                            _vm.$set(_vm.detailData, "suggestion", $$v)
                          },
                          expression: "detailData.suggestion"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]),
            _c(
              "div",
              {
                staticClass: "card-bot",
                staticStyle: { "flex-wrap": "nowrap", "align-items": "center" }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "bot-ti",
                    staticStyle: { "margin-bottom": "0px" }
                  },
                  [_vm._v("附件：")]
                ),
                _c(
                  "div",
                  { staticClass: "li-des" },
                  [
                    _c(
                      "el-upload",
                      _vm._b(
                        {
                          ref: "upload",
                          staticClass: "upload-demo",
                          style: {
                            width:
                              _vm.detailData.attachment.length > 3
                                ? "140px"
                                : "150px"
                          },
                          attrs: {
                            "file-list": _vm.detailData.attachment.slice(
                              0,
                              _vm.textFlg ? _vm.detailData.attachment.length : 3
                            )
                          }
                        },
                        "el-upload",
                        _vm.taskUploadProps,
                        false
                      ),
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "primary" } },
                          [_vm._v("点击上传")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.detailData.attachment.length > 3,
                            expression: "detailData.attachment.length > 3"
                          }
                        ],
                        staticClass: "more",
                        on: {
                          click: function($event) {
                            _vm.textFlg = !_vm.textFlg
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.textFlg ? "收起" : "展开") + " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v(_vm._s(_vm.type !== "view" ? "取 消" : "返 回"))
            ]),
            _vm.type !== "view"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.save(0)
                      }
                    }
                  },
                  [_vm._v("暂 存")]
                )
              : _vm._e(),
            _vm.type !== "view"
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.save(1)
                      }
                    }
                  },
                  [_vm._v("完成检验")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c(
        "MDialog",
        {
          attrs: {
            title: "现场图片",
            width: "600px",
            height: "500px",
            "show-footer": false
          },
          on: {
            onOk: function($event) {
              _vm.pictureVisible = false
            }
          },
          model: {
            value: _vm.pictureVisible,
            callback: function($$v) {
              _vm.pictureVisible = $$v
            },
            expression: "pictureVisible"
          }
        },
        _vm._l(_vm.formatArr(_vm.imgList), function(item) {
          return _c("el-image", {
            key: item,
            staticClass: "table-row-img",
            staticStyle: { "padding-left": "10px" },
            attrs: { src: item, "preview-src-list": _vm.formatArr(_vm.imgList) }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "min-width": "90px" } }, [
      _c("div", { staticClass: "divItem" }, [_vm._v("项目")]),
      _c("div", { staticClass: "divItem divItemS" }, [_vm._v("报检值")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }