import request from '@/utils/request'
import requestForAsync from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/authority/private/user/'
export default {
  login(postData) {
    return requestForAsync.post('api/web/v1/authority/public/user/login', postData)
  },
  phoneLogin(postData) {
    return requestForAsync.post('api/web/v1/authority/public/user/loginByPhone', postData)
  },
  logout() {
    return request.get('api/web/v1/authority/private/user/logout')
  },
  getLoginInfo() {
    return request.post('api/web/v1/authority/private/user/getLoginInfo', {})
  },
  modifyPassword(data) {
    return requestForAsync.put('/api/web/v1/authority/private/user/updatePassword', data)
  },
  modifyEquipment(showMyEquipmentOnly) {
    return requestForAsync.put(baseUrl + `updateShowMyEquipmentOnly?showMyEquipmentOnly=${showMyEquipmentOnly}`)
  },
  delEquipment(id) {
    return requestForAsync.del(baseUrl + `removeEquipmentFromMyList?equipmentId=${id}`)
  },
  getEquipment() {
    return requestForAsync.get(baseUrl + 'getMyEquipmentList')
  },
  updateMyEquipmentList(data) {
    return requestForAsync.put(baseUrl + 'updateMyEquipmentList', data)
  },
  getEquipmentStatus() {
    return requestForAsync.get(baseUrl + 'getShowMyEquipmentOnly')
  },
  // 用户设备列表
  getUserEquipmentLists(userId) {
    return requestForAsync.get(baseUrl + `getEquipmentListByUserId?userId=${userId}`)
  },
  // 取得用户的设备id列表
  getUserEquipmentId(userId) {
    return requestForAsync.get(baseUrl + `getEquipmentIdListByUserId?userId=${userId}`)
  },
  // 修改用户的设备
  modifyUserEquipment(userId, data) {
    return requestForAsync.put(baseUrl + `updateEquipmentList?userId=${userId}`, data)
  },
  // 解绑
  delUserEquipment(userId, id) {
    return requestForAsync.del(baseUrl + `removeEquipmentFromUser?equipmentId=${id}&userId=${userId}`)
  },
  // 获取用户
  getUsers(data) {
    return requestForAsync.get(`api/web/v1/production/private/productionTask/getProductionTaskUserList`, data)
  },
  //获取版本信息
  getVersionMsg(){
    return requestForAsync.get(`/api/web/v1/basicConfig/public/basicConfig/getBasicConfigDetailByCode?code=version`)
  },
  getTokenForOther(data){
    return requestForAsync.post(`/api/web/v1/thirdParty/public/authority/getToken`,data)
  }

}
