import { projectDeliveryType } from '@/config/options.config'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const projectStatusOptions = [
  { name: '待开始', id: 0 },
  { name: '进行中', id: 2 },
  { name: '已结案', id: 4 }
]
export const projectStatusMap = projectStatusOptions.reduce((obj, item) => {
  obj[item.id] = item.name
  return obj
}, {})

// 项目列表
export const columns = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'projectNumber',
      label: '订单编号',
      sortable: false,
      hiddenSearch: true,
      slotName:'projectNumber',
      minWidth: 120,
      form: { index: 0, tagName: 'el-input', required: true,props: { disabled: false } }
    },
    {
      prop: 'stationNumber',
      label: '序列号',
      sortable: false,
      hiddenSearch: true,
      slotName:'stationNumber',
      minWidth: 120
    },
    {
      prop: 'projectName',
      label: '订单名称',
      sortable: false,
      hiddenSearch: true,
      form: { index: 1, tagName: 'el-input', required: true, props: { disabled: false } }
    },
    {
        prop: 'materialsCode',
        label: '物料编码',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120,
        directives: [
            {
              name: 'selectScrollEnd',
              value: () => {
                console.log('滚动触底了，可以请求下一页了')
              }
            }
          ],
    form: {
      index:2,
      tagName: 'el-select',
      key: 'materialsId',
      options: [],
      optionsProps: {
        label: 'fullLabel',
        value: 'id'
      },
      props: {
        disabled: false,
        remote: true,
        remoteMethod: (val) => {
          console.log('远程搜', val)
        }
      },
      on: { change: (val) => { functionList.searchMaterialChange(val) } },
    }
      },
      {
        prop: 'materialsName',
        label: '物料名称',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'quantity',
        label: '数量',
        sortable: false,
        hiddenSearch: true,
        form: { index: 3, tagName: 'el-input-number',props: { controlsPosition: 'right', min: 1 , disabled: false }, required: true, defaultValue: 1 }
      },
      {
        prop: 'issuedQuantity',
        label: '已下达数',
        sortable: false,
        hiddenSearch: true,
        form: { index: 4, hidden:false, tagName: 'el-input-number',props: { controlsPosition: 'right', min: 0, disabled: false }, required: false, defaultValue: 0 }
      },
      {
        prop: 'issuedStatus',
        label: '下达状态',
        sortable: false,
        slotName: 'issuedStatus',
        hiddenSearch: true
      },
    {
      prop: 'customerOrderNumber',
      label: '客户订单号',
      sortable: false,
      hiddenSearch: true,
      form: { index: 5, tagName: 'el-input', required: false, props: { disabled: false } }
    },
    {
      prop: 'customerName',
      label: '客户',
      sortable: false,
      hiddenSearch: true,
      directives: [
        {
          name: 'selectScrollEnd',
          value: () => {
            console.log('滚动触底了，可以请求下一页了')
          }
        }
      ],
      form: {
        index: 6,
        required: false,
        tagName: 'el-select',
        key: 'customerId',
        options: [],
        colSpan: 12,
        optionsProps: {
          label: 'name',
          value: 'id'
        },
        props: {
          disabled: false,
          remote: true,
          remoteMethod: (val) => {
            console.log('远程搜', val)
          }
        },
      on: { change: (val) => { functionList.searchCustomerChange(val) } }
    }
    },
    {
      prop: 'customerPrincipalName',
      label: '客户负责人',
      hiddenSearch: true,
      sortable: false
    },
    {
        prop: 'deliveryType',
        label: '交付类型',
        hiddenSearch: true,
        sortable: false,
        minWidth: 100,
        slotName:'deliveryType',
        form: {
          props: { disabled: true },
          index: 7,
          tagName: 'el-select',
           options: projectDeliveryType,
           required: true,
           defaultValue: 0,
           on: { change: (val) => { functionList.searchChange(val) } }
  }
      },
    {
        prop: 'deliveryDate',
        label: '交付日期',
        hiddenSearch: true,
        sortable: false,
        slotName:'deliveryDate',
        form: {
          hidden: false,
          props: { disabled: false },
          index: 8,
          tagName: 'div',
          required: true,
          customSlots: ['deliveryDate']
        }
      },
      {
        prop: 'principalName',
        label: '负责人',
        hiddenSearch: true,
        sortable: false,
        slotName:'principalName',
        form: {index: 9, key: 'principalId', tagName: 'el-select',
        optionsProps: {
          label: 'userFullName',
          value: 'id'
        },options: [], required: false,
        props: {
          multiple: true,
          disabled: false
         }}
      },
      {
        prop: 'businessUnitName',
        label: '业务部门',
        hiddenSearch: true,
        sortable: false,
        form: {
          index: 10, 
          required: false,
          tagName: 'el-cascader',
          key: 'businessUnit',
          props: {
            disabled: false,
            props: {
              label: 'name',
              value: 'id',
              checkStrictly: true
            },
            options: [],
            collapseTags: true,
            filterable: true
          }
        }
      },
      {
        prop: 'projectApprovalNumber',
        label: '立项编号',
        sortable: false,
        hiddenSearch: true,
        slotName:'projectApprovalNumber',
        minWidth: 120,
        form: { index: 11, tagName: 'el-input', required: false,props: { disabled: false } }
      },
      {
        prop: 'auditStatus',
        label: '审核状态',
        hiddenSearch: true,
        sortable: false,
        minWidth: 100,
        slotName:'auditStatus'
      },
    {
      prop: 'projectStatus',
      label: '订单状态',
      hiddenSearch: true,
      sortable: false,
       minWidth: 100,
       fixed: 'left',
       slotName:'projectStatus'
    },

    {
      prop: 'remark',
      label: '备注',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100,
      form: { index: 12, tagName: 'el-input', required: false }
    },
    {
        prop: 'createTime',
        label: '创建时间',
        hiddenSearch: true,
        sortable: false
      },
    {
        prop: 'creator',
        label: '创建人',
        hiddenSearch: true,
        sortable: false
      },

      {
        prop: 'lastUpdateTime',
        label: '更新时间',
        hiddenSearch: true,
        sortable: false
      },
    {
        prop: 'updater',
        label: '更新人',
        hiddenSearch: true,
        sortable: false
      },
    {
      slotName: 'action',
      label: '操作',
      sortable: false,
      hiddenSearch: true,
      width: 250,
      fixed: 'right'
    }
  ]
