<template>
  <div class="inventory-wrap common-tab">
<!--    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">-->
<!--      <el-radio-button label="WarehouseAssign">备料任务</el-radio-button>-->
<!--      <el-radio-button label="ApplyAssign">领料任务</el-radio-button>-->
<!--      <el-radio-button label="ReturnAssign">退料任务</el-radio-button>-->
<!--    </el-radio-group>-->
    <div v-show="params.radio === 'WarehouseAssign'">
    <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormListAll"
        :form-data="searchFormData.WarehouseAssign"
        style="padding-left: 0"
        @search="searchTable"
      />
    </div>
    <div class="materials-info">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        :search-title="functionHeader.searchTitle[params.radio]"
        :radio="params.radio"
        :columns-key="columnsKey"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" v-show="selectTableData.length" style="display: flex;justify-content: flex-start;align-items: center">
        <el-button v-if="permission('Finish')" size="small" type="primary"  @click="allMore('batchFinish')">批量完成</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable
        ref="mTable"
        :columns="columns[params.radio]"
        :height="height"
        :set-data-method="getTableData"
        :columns-key="columnsKey"
        @selection-change="handleSelectionChange"
      >
        <div slot="materialPreparationNumber" slot-scope="{ row }">
          <a @click="jumpDetail(row)">{{ row.materialPreparationNumber }}</a>
        </div>
        <div slot="materialPreparation" slot-scope="{ row }">
          <span>{{ +row.materialPreparation === 1 ? '按工序备料' : +row.materialPreparation === 0 ? '按工单备料' : '' }}</span>
        </div>
        <div slot="materialPreparationStatus" slot-scope="{ row }" style="display: flex;align-items: center;">
          <div
            v-if="row.materialPreparationStatus || row.materialPreparationStatus === 0"
            :style="{ color: preparationStatus[row.materialPreparationStatus].color ? preparationStatus[row.materialPreparationStatus].color : 'rgb(74, 79, 118)', background: `${preparationStatus[row.materialPreparationStatus].color ? preparationStatus[row.materialPreparationStatus].color : 'rgb(74, 79, 118)'}20`}"
            class="tag-priority"
          >
            {{ preparationStatus[row.materialPreparationStatus].name ? preparationStatus[row.materialPreparationStatus].name : '待下发' }}
          </div>
        </div>
        <div slot="producePlanPriorityId" slot-scope="{ row }" style="display: flex;align-items: center;">
          <div
            :style="{ color: row.producePlanPriorityId || row.producePlanPriorityId === 0 ? priorityStatus[row.producePlanPriorityId].color : 'rgb(74, 79, 118)', background: `${row.producePlanPriorityId || row.producePlanPriorityId === 0 ? priorityStatus[row.producePlanPriorityId].color : 'rgb(74, 79, 118)'}20`}"
            class="tag-priority"
          >
            {{ row.producePlanPriorityId || row.producePlanPriorityId === 0 ? priorityStatus[row.producePlanPriorityId].name : '' }}
          </div>
        </div>


      </MTable>
    </div>
  </div>
  <div v-show="params.radio != 'WarehouseAssign'">
    <div class="top">
      <SearchForm
        ref="requisitionSearchForm"
        :form-list-extend="searchFormListRequisition"
        :form-data="searchFormData.ApplyAssign"
        style="padding-left: 0"
        :searchType='1'
        @search="searchRequisitionTaskTable"
      />
    </div>
    <div class="materials-info">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        :search-title="functionHeader.searchTitle[params.radio]"
        :radio="params.radio"
        :columns-key="columnsKey"
        @search="$refs.rTable.setTableData(true)"
      >
        <div slot="all" class="all-select">
          <div class="checkAll" >
            <el-checkbox v-model="checkeds" :indeterminate="isIndeterminate" @change="selectCheck">全选</el-checkbox>
          </div>
          <div v-for="(item, index) in allStatus" :key="item.value" class="all-li" :class="item.checked ? 'active' : ''" @click="selectTab(item, index)">{{ item.label }}</div>
        </div>
      </FunctionHeader>
      <MTable
        ref="rTable"
        :columns="getColumns"
        :height="height"
        :set-data-method="getRequisitionTaskTableData"
        :columns-key="columnsKey"
      >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <NewPageOpen slot="code" slot-scope="{ row }" :path="getDetailPath(row, 'view')" :text="row.taskCode" />
          <div slot="receiptType" slot-scope="{ row }">{{row.receiptType | receiptType }}</div>
          <div slot="directionType" slot-scope="{ row }">{{row.directionType === 0 ? '工单领料' : row.directionType === 1 ? '工单退料': '工单挪料'}}}}</div>
          <div slot="receiptStatus" slot-scope="{ row }">{{row.receiptStatus | receiptStatus }}</div>
          <div slot="type" slot-scope="{ row }" class="is-enabled">
            <span>{{ procedureClass[row.type].name }}</span>
          </div>
          <!--<div slot="handoverStatus" slot-scope="{ row }">{{row.handoverStatus === 1 ? '已完成' : '未完成' }}</div>-->
          <!--<div slot="action" slot-scope="{ row }">-->
            <!--<el-button v-if="permission('FinishHandover')&&row.receiptStatus === 2" :disabled="row.handoverStatus === 1" type="text"  @click="finishHandover(row)">-->
              <!--完成交接-->
            <!--</el-button>-->
          <!--</div>-->
      </MTable>
    </div>
  </div>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader/index'
import { searchFormListAll,searchFormListRequisition } from './form-list'
import { columnsAll,columnsApply,columnsReturn } from './columns'
import { preparationStatus,docType,materialsRequisitionStatus,procedureClass } from '@/config/options.config'
import api from '@/api/warehouse/warehouse-assign'
import processApi from '@/api/information/production/process'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'

export default {
  name: 'WarehouseAssignList',
  components: { NewPageOpen,FunctionHeader, SearchForm },
  filters: {
        receiptType(val) {
            if (val || val=== 0) return docType.find(item => item.id === val).name
        },
        receiptStatus(val) {
            return materialsRequisitionStatus.find(item => item.id === val).name
        }
    },
  data() {
    return {
      searchFormListAll,
      searchFormListRequisition,
      procedureClass,
      selectTableData: [],
      preparationStatus,
      params: {
        radio: 'WarehouseAssign'
      },
      functionHeader: {
        searchVal: '',
        searchTitle: {
          WarehouseAssign: '备料单号/工单号/订单号',
          ApplyAssign: '请输入领料单名称/编号',
          ReturnAssign: '请输入退料单名称/编号',
        }
      },
      columns: {
        WarehouseAssign: columnsAll,
        ApplyAssign: columnsApply,
        ReturnAssign: columnsReturn,
      },
      searchFormList: {
        WarehouseAssign: searchFormListAll,
        ApplyAssign:searchFormListRequisition,
        ReturnAssign:searchFormListRequisition,
      },
      searchFormData: {
        WarehouseAssign: {},
        ApplyAssign:{},
        ReturnAssign:{}
      },
      searchWarehouseAssignList: [],
      searchApplyAssignList:[],
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      isPageLoaded:false,
      total:0,
      isIndeterminate: true,
      checkeds: true,
      currentIdxs: [0],
      allStatus: [
        {
          value: 0,
          label: '未开始',
          checked: true
        },
        {
          value: 1,
          label: '进行中',
          checked: false
        },
        {
          value: 2,
          label: '已完成',
          checked: false
        },
        {
          value: 3,
          label: '已撤销',
          checked: false
        }
      ]
    }
  },
  created(){
    this.params.radio = this.$route.meta.nameFlage ? this.$route.meta.nameFlage : 'WarehouseAssign'
    this.radioChange(this.params.radio)
  },
  watch: {
    // checkeds(val) {
    //   if (val) {
    //     this.currentIdxs = []
    //     this.isIndeterminate = false
    //     this.allStatus = this.allStatus.map(item => {
    //       return {
    //         ...item,
    //         checked: false
    //       }
    //     })
    //   } else {
    //     this.isIndeterminate = true
    //   }
    // }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 95 - 20
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectTableData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    },

    mode() {
      return this.$store.state.app.mode
    },
      getColumns(){
        if (this.mode === 1) {
        return this.columns[this.params.radio].map(item => {
      const newLabel = item.label ? item.label.replace(/项目/g, '订单') :''
      return { ...item, label: newLabel }
    })
      } else {
        return this.columns[this.params.radio]
      }
    }
  },
  activated(){
    if(!this.isPageLoaded){
      if(this.params.radio === 'WarehouseAssign'){
        this.$refs.mTable.setTableData()
      }else{
        this.$refs.rTable.setTableData()
      }
    }
    this.isPageLoaded = false
    },
  mounted() {
    this.isPageLoaded = true
    this.getAreaList()
    this.searchFormList.ApplyAssign[3].attrs.placeholder = this.mode ? '订单编号' : '项目编号'
    this.searchFormList.ApplyAssign[4].attrs.placeholder = this.mode ? '订单名称' : '项目名称'
    this.searchFormList.ReturnAssign[3].attrs.placeholder = this.mode ? '订单编号' : '项目编号'
    this.searchFormList.ReturnAssign[4].attrs.placeholder = this.mode ? '订单名称' : '项目名称'
    this.searchFormList.WarehouseAssign[3].children[0].options = this.priorityStatus.map((item, index) => {
      return {
        ...item,
        id: index
      }
    })
  },
  methods: {
    selectTab(val, index) {
      this.allStatus[index].checked = !this.allStatus[index].checked

      const isCheck = this.allStatus.filter(item => item.checked)
      const noCheck = this.allStatus.filter(item => !item.checked)

      if(isCheck.length > 0 && isCheck.length < this.allStatus.length){
        this.isIndeterminate = true
      }else {
        this.isIndeterminate = false
      }
      if(isCheck.length > 0){
        this.checkeds = true
        this.currentIdxs = isCheck.map(item => item.value)
      }else {
        this.checkeds = false
        this.currentIdxs = []
      }
      this.$refs.rTable.setTableData(true)
    },
    selectCheck(val) {
      this.currentIdxs = val ? [0,1,2,3] : [];
      this.isIndeterminate = false;
      if(val){
        this.allStatus = this.allStatus.map(item => {
          return {
            ...item,
            checked: true
          }
        })
      }else {
        this.allStatus = this.allStatus.map(item => {
          return {
            ...item,
            checked: false
          }
        })
      }
      this.$refs.rTable.setTableData(true)
    },
    getDetailPath(row, command) {
        if(row.directionType){
          return { name: 'ReturnAssignDetail', query: { type:'view',id: row.id}}
        }else{
           return { name: 'ApplyAssignDetail', query: { type:'view',id: row.id}}
        }
      },
    jumps(row) {
      if (row.produceWorkOrderId) {
        this.$router.push({ name: 'workDetail', query: {id: row.produceWorkOrderId}})
      }
    },
    jumpDetail(row) {
      this.$router.push({ name: 'warehouseAssignDetail', query: {id: row.id}})
    },
    // 获取产线
    async getAreaList() {
      const search = [
        {
          id: getUUid(),
          fieldName: 'type',
          fieldType: 'number',
          fieldValue: 2,
          operator: 'eq'
        },
        {
          id: getUUid(),
          fieldName: 'isEnabled',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }
      ]
      const res = await processApi.getAreaList({
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        this.searchFormList.WarehouseAssign[2].children[0].options = res
      }
    },
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const searchCondition = {
        sort: order,
        searchKeyword: this.functionHeader.searchVal,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchWarehouseAssignList]))
      }
      const res = await api.getWarehouseAssignListPage({
        page: page.currentPage,
        limit: page.pageSize,
        ...searchCondition
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    async getRequisitionTaskTableData(condition, callback) {
      const { page, search, order } = condition
      const data = Object.assign({}, ...this.searchApplyAssignList)
      const searchCondition = {
        directionType: this.params.radio === 'ApplyAssign' ? 0 :1,
        search: this.functionHeader.searchVal,
        receiptStatus:this.currentIdxs.join(','),
        ...data
      }
      const res = await api.getApplyAssignListPage({
        page: page.currentPage,
        size: page.pageSize,
        ...searchCondition
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    radioChange(label) {
      this.selectTableData = []
      this.searchFormData[this.params.radio] = {}
      this.selectTableData = []
      this.searchWarehouseAssignList = []
      this.searchApplyAssignList = []
      if(label === 'WarehouseAssign'){
        this.$refs.searchForm.reset()
        this.$refs.mTable.refreshTable()
      }else{
        this.$refs.requisitionSearchForm.reset()
        this.$refs.rTable.refreshTable()
      }
    },
    allMore(command) {
      const functions = {
        batchFinish: () => { this.batchFinish() }
      }
      functions[command]()
    },
    async batchFinish() {
      this.$confirm('确认批量完成备料任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const ids = this.selectTableData.map(item => item.id)
        const res = await api.batchWarehouseAssign(ids)
        if (res) {
          this.$message.success('批量完成成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
      })
    },
    // 完成交接
    async finishHandover(row) {
      this.$confirm('确认完成交接吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.finishHandover(row.id)
        if (res) {
          this.$message.success('操作成功')
          this.$refs.rTable.setTableData()
        }
      })
    },
    // 查询
    searchTable(data) {
      this.searchWarehouseAssignList = data
      this.$refs.mTable.setTableData(true)
    },
      // 领退料查询
      searchRequisitionTaskTable(data) {
        this.searchApplyAssignList = data
      this.$refs.rTable.setTableData(true)
    },
    // 表格选择变化
    handleSelectionChangeDialog(data) {
      this.selectTableDataDialog = data
    },
    handleSelectionChange(data) {
      this.selectTableData = data
    },
    resetSelectData() {
      this.selectTableData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectTableData = this.selectTableData.filter((item) => item.selectable)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-date-editor .el-range__close-icon {
    line-height: 26px;
  }
}
.inventory-wrap {
  width: 100%;
  padding: 10px;
  .all-select {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 4px;
      .checkAll {
        margin-right: 20px;
      }
      .all-li {
        width: 80px;
        height: 26px;
        background: #FFFFFF;
        border-radius: 16px;
        border: 1px solid #DDE3EB;
        margin-right: 10px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #A4B3C6;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .active {
        color: #607FFF;
        border-color: #607FFF;
        background: #EFF2FF;
      }
    }
  .bottom {
    width: 100%;
    height: 100%;
    padding-top: 10px;

    .materials-info {
      padding: 10px;
    }
  }

  a {
    color: rgb(96, 127, 255);
  }
}

div.tooltip {
  display: inline-block;
  padding: 3px 5px;
  background: #DF6A6A;
  position: relative;
  border-radius: 3px;
  font-size: 12px;
  color: #ffffff;
  box-shadow: 2px 2px 2px 0 #c1c1c1;
  margin-left: 10px;
}

div.tooltip:before, div.tooltip:after {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  border: 3px solid transparent;
}

div.tooltip:before {
  border-right-color: #DF6A6A;
  position: absolute;
  left: -6px;
  top: 10px;
  z-index: 2;
}

div.tooltip:after {
  border-right-color: #DF6A6A;
  position: absolute;
  left: -6px;
  top: 10px;
  z-index: 1;
}

</style>
