import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/field/facility/tool/private/tool/'

export default {
  // 工具列表
  getToolLedgerListPage(data) {
    return request.get(baseUrl + 'getToolListPage', data)
  },
  // 获取工具详情
  getToolLedgerDetail(data) {
    return request.get(`${baseUrl}getToolDetail`, data)
  },
  // 工具新增
  add(data) {
    return request.post(baseUrl + 'addTool', data)
  },
  // 工具编辑
  edit(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 工具删除
  del(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  /**
   * 焊工资格
   * */
  getWelderQualificationListPage(data) {
    return request.get('api/web/v1/basicData/weld/private/welderQualification/getWelderQualificationListPage', data)
  },
  addWelderQualification(data){
    return request.post('api/web/v1/basicData/weld/private/welderQualification/addWelderQualification', data)
  },
  editWelderQualification(data){
    return request.put('api/web/v1/basicData/weld/private/welderQualification/updateOne', data)
  },
  delWelderQualification(id){
    return request.del('api/web/v1/basicData/weld/private/welderQualification/deleteOne?id=' + id)
  },
  getWorkOrder(data){
    return request.post('api/web/v1/production/private/productionPlan/list',data)
  },
  getProjectList(){
    return request.get('api/web/v1/production/private/project/listPage?page=1&size=1000&search=&status=')
  },
  /**
   * 合格项目  welderProject
   * */
  getWelderProjectListPage(data){
    return request.get('api/web/v1/basicData/weld/private/welderProject/getWelderProjectListPage',data)
  },
  addWelderProject(data){
    return request.post('api/web/v1/basicData/weld/private/welderProject/addWelderProject',data)
  },
  delWelderProject(id){
    return request.del('api/web/v1/basicData/weld/private/welderProject/deleteOne?id='+id)
  },
  /**
   * 焊工编号  welder
   * */
  getWelderListPage(data){
    return request.get('api/web/v1/basicData/weld/private/welder/getWelderListPage',data)
  },
  addWelder(data){
    return request.post('api/web/v1/basicData/weld/private/welder/addWelder',data)
  },
  delWelder(id){
    return request.del('api/web/v1/basicData/weld/private/welder/deleteOne?id='+id)
  },
  /**
   * 焊接工艺规程
   * */
  getWeldProcessRegulationListPage(data){
    return request.get('api/web/v1/basicData/weld/private/weldProcessRegulation/getWeldProcessRegulationListPage',data)
  },
  addWeldProcessRegulation(data){
    return request.post('api/web/v1/basicData/weld/private/weldProcessRegulation/addWeldProcessRegulation',data)
  },
  editWeldProcessRegulation(data){
    return request.put('api/web/v1/basicData/weld/private/weldProcessRegulation/updateOne',data)
  },
  delWeldProcessRegulation(id){
    return request.del('api/web/v1/basicData/weld/private/weldProcessRegulation/deleteOne?id='+id)
  },
  /**
   * 焊接工艺清单
   * */
  getWeldProcessListPage(data){
    return request.get('api/web/v1/basicData/weld/private/weldProcess/getWeldProcessListPage',data)
  },
  addWeldProcess(data){
    return request.post('api/web/v1/basicData/weld/private/weldProcess/addWeldProcess',data)
  },
  editWeldProcess(data){
    return request.put('api/web/v1/basicData/weld/private/weldProcess/updateOne',data)
  },
  delWeldProcess(id){
    return request.del('api/web/v1/basicData/weld/private/weldProcess/deleteOne?id='+id)
  },
  /**
   * 下料清单
   */
  getWeldLayingOffListPage(data){
    return request.get('api/web/v1/basicData/weld/private/weldLayingOff/getWeldLayingOffListPage',data)
  },
  addWeldLayingOff(data){
    return request.post('api/web/v1/basicData/weld/private/weldLayingOff/addWeldLayingOff',data)
  },
  editWeldLayingOff(data){
    return request.put('api/web/v1/basicData/weld/private/weldLayingOff/updateOne',data)
  },
  delWeldLayingOff(id){
    return request.del('api/web/v1/basicData/weld/private/weldLayingOff/deleteOne?id='+id)
  },
  /**
   * 根据项目查询物料类别、放置位置
   * */
  fsdKittingListCondition(data){
    return request.get('api/web/v1/basicData/materials/private/materialsCompleteCalculate/fsdKittingListCondition',data)
  },
  /**
   * 齐套计算
   * */
  fsdKittingListPage(data){
    return request.get('api/web/v1/basicData/materials/private/materialsCompleteCalculate/fsdKittingListPage',data)
  }
}
