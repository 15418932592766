var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "none" }, attrs: { id: _vm.commonIds } },
    _vm._l(_vm.codes, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticStyle: {
            "padding-top": "10px",
            display: "inline-flex",
            "justify-content": "flex-start"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { width: "100px" } },
            [
              _c("VueQr", {
                attrs: {
                  "correct-level": 3,
                  "auto-color": false,
                  "color-dark": "#000000",
                  text: item.batchNumber
                    ? item.batchNumber
                    : item.sequenceNumber,
                  size: 120,
                  margin: 0
                }
              }),
              _c("div", { staticStyle: { "padding-top": "14px" } }, [
                _vm._v(_vm._s(item.batchNumber || item.sequenceNumber))
              ])
            ],
            1
          ),
          _vm.sequenceNumberType === "0"
            ? _c(
                "div",
                {
                  staticStyle: { "margin-left": "26px", "text-align": "left" }
                },
                [
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("物料编码：" + _vm._s(item.materialsCode))
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("物料名称：" + _vm._s(item.materialsName))
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(
                      "规格型号：" +
                        _vm._s(
                          item.materialsSpecifications || item.specifications
                        )
                    )
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("供应商：" + _vm._s(item.supplierName))
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("收货日期：" + _vm._s(_vm.times))
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("数量：" + _vm._s(item.quantity))
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(
                      "设变件：" +
                        _vm._s(
                          item.settingVariable === 0
                            ? "否"
                            : item.settingVariable === 1
                            ? "是"
                            : "-"
                        )
                    )
                  ])
                ]
              )
            : _vm.sequenceNumberType === "1"
            ? _c(
                "div",
                {
                  staticStyle: { "margin-left": "26px", "text-align": "left" }
                },
                [
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(item.materialsCode))
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(item.materialsName))
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(
                      _vm._s(
                        item.materialsSpecifications || item.specifications
                      )
                    )
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(item.supplierName))
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(
                      "设变件(" +
                        _vm._s(
                          item.settingVariable === 0
                            ? "否"
                            : item.settingVariable === 1
                            ? "是"
                            : "-"
                        ) +
                        ")"
                    )
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(_vm._s(_vm.times))
                  ])
                ]
              )
            : _c(
                "div",
                {
                  staticStyle: { "margin-left": "26px", "text-align": "left" }
                },
                [
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("物料编码：" + _vm._s(item.materialsCode))
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("物料名称：" + _vm._s(item.materialsName))
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v(
                      "规格型号：" +
                        _vm._s(
                          item.materialsSpecifications || item.specifications
                        )
                    )
                  ]),
                  _c("div", { staticStyle: { "font-size": "18px" } }, [
                    _vm._v("创建日期：" + _vm._s(_vm.times))
                  ])
                ]
              )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }