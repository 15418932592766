import { formListColumns } from './columns'
import { transformColumnsToForm } from '@/utils'

const formList = transformColumnsToForm(formListColumns)
formList.push({
  label: '附件',
  key: 'attachments',
  tagName: 'div',
  required: false,
  children: [{
    customSlots: ['drawing']
  }]
})

formList.push({
  label: '是否开启动态数据源',
  key: 'isOrNoDy',
  tagName: 'div',
  required: false,
  children: [{
    customSlots: ['isOrNoDy']
  }]
})

formList.push({
  label: '动态数据源配置',
  key: 'DySetting',
  tagName: 'div',
  hidden:true,
  required: false,
  colSpan:24,
  children: [{
    customSlots: ['DySetting']
  }]
})

export default formList
