<template>
  <div v-loading="loading" class="v-charts-container" :style="{width: tabInfo.width || '100%'}">
    <chart-search-condition ref="searchCondition" :ssflag="false" :tab-info="tabInfo" @conditionChange="getPreDataList" />
    <div v-if="tabInfo.chartType === 'barAndLine'">
      <div id="bar-line" />
      <MTable v-if="tabInfo.type === 'chartsAndTable'" ref="mTable" :max-height="200" :data="tableData" :show-page="false" :columns="tableColumns">
        <div slot="workingDays" slot-scope="{ row }">
          <span v-if="row.id !== currentId">
            {{ row.workingDays }}
            <i class="el-icon-edit" @click="modifyWorkingDay(row)" />
          </span>
          <span v-else>
            <el-input-number v-model="newWorkingDays" class="iNum" :precision="0" size="mini" controls-position="right" :min="1" />
            <i class="el-icon-close" @click="returns(row)" />
            <i class="el-icon-check" @click="confirm(row)" />
          </span>
        </div>
      </MTable>
    </div>
    <div v-else-if="chartData.columns.length && !isDevicePro" ref="charts">
      <div v-if="tabInfo.chartDataKey === 'hours'" class="dailyNums">UR: {{ urData }}， 总工时：{{ allHours }}</div>
      <ve-line v-if="tabInfo.chartType === 'line'" :data="chartData" :settings="lineChartSettings" :extend="lineChartExtend" />
      <ve-histogram v-if="tabInfo.chartType === 'bar'" :data="chartData" :settings="barChartSettings" :extend="barChartExtend" />
      <ve-bar v-if="tabInfo.chartType === 'horizontalBar'" :data="chartData" :settings="barChartSettings" :extend="barChartExtend" />
      <ve-pie v-if="tabInfo.chartType === 'pie'" :data="chartData" :settings="pieChartSettings" :extend="pieChartExtend" />
      <MTable v-if="tabInfo.type === 'chartsAndTable'" ref="mTable" :max-height="200" :data="tableData" :show-page="false" :columns="tableColumns" />
    </div>
    <div v-else-if="chartData.columns.length && isDevicePro" ref="charts" class="pie">
      <div class="pie-item">
        <ve-pie v-if="tabInfo.chartType === 'pie'" :data="chartDataOne" :settings="pieChartSettings" :extend="pieChartExtendOne" />
      </div>
      <div class="pie-item">
        <ve-pie v-if="tabInfo.chartType === 'pie'" :data="chartDataTwo" :settings="pieChartSettings" :extend="pieChartExtendTwo" />
      </div>
      <MTable v-if="tabInfo.type === 'chartsAndTable'" ref="mTable" :height="166" :data="tableData" :show-page="false" :columns="tableColumns">
        <div slot="quanlityInfoType" slot-scope="{ row }">{{ quanlityInfoType[row.sign].name }}</div>
      </MTable>
    </div>
    <MBlank v-else />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import api from '@/api/statistics-board/all-statistics'
import 'echarts/lib/component/legendScroll'
import 'echarts/lib/component/dataZoom'
import { demandServiceTypes, deviceStatus, quanlityInfoType, demandDistribStatus, warrantyStatus, tatStatus } from '@/config/options.sales'
import ChartSearchCondition from '@/views/statistics-board/board-manage/components/charts/chart-search-condition'
const colorList = [
  '#60E8FF',
  '#38E300',
  '#F7B500',
  '#FA6400',
  '#C8CF0B',
  '#13B1C4',
  '#009112',
  '#BCBB95',
  '#FF84A1',
  '#808080',
  '#C89595',
  '#44D7B6',
  '#FFE1A5',
  '#90BD61',
  '#95B2C8',
  '#AB6FA7',
]
export default {
  name: 'Charts',
  components: { ChartSearchCondition },
  props: {
    tabInfo: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      urData: '',
      allHours: '',
      quanlityInfoType,
      lineChartSettings: {},
      barChartSettings: {
      },
      pieChartSettings: {},
      lineChartExtend: {
        color: colorList,
        legend: {
          type: 'scroll'
        },
        grid: { width: '94%' },
        xAxis: {
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#9597AE'
            }
          }
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#9597AE'
            }
          }
        },
        tooltip: {
          trigger: 'axis'
          // appendToBody: true
        }
      },
      barChartExtend: {
        color: colorList,
        legend: {
          show: true,
          type: 'scroll'
        },
        xAxis: {
          splitLine: {
            show: true,
            interval: (index, value) => index === 0,
            lineStyle: {
              type: 'dashed',
              color: '#9597AE'
            }
          },
          color: '#fff',
          axisLabel:{
            color: '#fff'
          }
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#9597AE'
            }
          },
          axisLabel:{
            color: '#fff'
          }
        },
        tooltip: {
          confine: true
          // appendToBody: true
        }
      },
      pieChartExtend: {
        color: colorList,
        legend: {
          show: true,
          type: 'scroll',
          orient: 'vertical',
          left: 10,
          textStyle: {
            width: 10,
            overflow: 'break',
            color: '#fff'
          }
        },
        series: {
          avoidLabelOverlap: true,
          radius: [0, '70%'],
          center: ['50%', '50%'],
          label: {
            width: 50,
            overflow: 'break'
          }
        },
        tooltip: {
          trigger: 'item'
          // appendToBody: true
        }
      },
      pieChartExtendOne: {},
      pieChartExtendTwo: {},
      chartData: {
        columns: [],
        rows: []
      },
      preDataList: [],
      loading: false,
      tableData: [],
      tableColumns: [],
      allUr: [],
      chartDataOne: {
        columns: [],
        rows: []
      },
      chartDataTwo: {
        columns: [],
        rows: []
      },
      currentId: '',
      newWorkingDays: ''
    }
  },
  computed: {
    isDevicePro() {
      return this.tabInfo.name === '设备质保状态'
    }
  },
  watch: {
    allUr(val) {
      if (val.length > 0) {
        const data = val.filter(item => item.name === 'UR')
        this.urData = data[0].ratio + '%'
        this.allHours = data[0].hours + 'h'
      }
    }
  },
  mounted() {
    this.getPreDataList()
    if (this.tabInfo.columnsType !== 'vertical') {
      this.tableColumns = this.tabInfo.columns
    }
    if (this.tabInfo.lineKey) {
      this.barChartExtend['series.1.type'] = 'line'
      this.barChartExtend['series.1.yAxisIndex'] = 1
    }
  },
  methods: {
    async getPreDataList(condition) {
      let preData
      this.loading = true
      if (!condition && this.tabInfo.dataCondition) {
        condition = this.tabInfo.dataCondition
      }
      this.searchCondition = condition
      const newCondition = condition || {}
      const { preDataUrl, preDataCondition = {}, preDataKey } = this.tabInfo
      if (preDataUrl && (!condition || (preDataKey && !condition[preDataKey]))) {
        preData = await api.getTableData(preDataUrl, preDataCondition)
        if (preData) {
          const data = Array.isArray(preData) ? preData : preData.records
          newCondition[preDataKey] = data.map(item => item.id).join('^')
          this.preDataList = data
        }
      }
      if (!preData || newCondition[preDataKey]) {
        await this.getLineChartsData(newCondition)
      } else {
        this.loading = false
      }
    },
    async getLineChartsData(condition) {
      const { dataUrl, dataCondition } = this.tabInfo
      const res = await api.getTableData(dataUrl, {
        ...dataCondition,
        ...condition
      })
      if (res && res[0]) {
        if (this.tabInfo.type === 'chartsAndTable') {
          this.setTableData(res)
        }
        if (this.tabInfo.chartType === 'pie') {
          this.pieSetData(res)
        } else {
          this.unPieSetData(res)
        }
      } else {
        this.chartData.columns = []
        this.chartData.rows = []
      }
      this.loading = false
    },
    pieSetData(res) {
      const { chartLegendKey, chartDataKey, chartOverlap, optionKey } = this.tabInfo
      this.allUr = chartDataKey === 'hours' ? res : []
      res = chartDataKey === 'hours' ? res.filter(item => item.name !== 'UR') : res
      if (chartOverlap) {
        this.pieChartExtend.legend.formatter = (name) => {
          for (var i = 0; i < res.length; i++) {
            var dpercent = res[i].hours
            var ratio = res[i].ratio
            if (res[i].name === name) {
              return `${name} | ${ratio}%  ${dpercent}h `
            }

            var mName = res[i].materialsName
            var mCode = res[i].materialsCode
            var mQuantity = res[i].quantity
            if (res[i].materialsCode === name) {
              return `${mName} | ${mCode}  ${mQuantity}个 `
            }
          }
        }
      }
      this.pieChartExtend.series.avoidLabelOverlap = false
      this.pieChartExtend.series.radius = ['40%', '70%']
      this.pieChartExtend.series.itemStyle = {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      }

      if (this.isDevicePro) {
        this.pieChartExtend.series.label = true
        this.pieChartExtend.series.center = ['50%', '37%']
        this.pieChartExtend.legend.left = 'center'
        this.pieChartExtend.legend.bottom = '0'

        this.pieChartExtendOne = this._.cloneDeep(this.pieChartExtend)
        this.pieChartExtendTwo = this._.cloneDeep(this.pieChartExtend)

        this.pieChartExtendOne.legend.formatter = (name) => {
          for (var i = 0; i < res.length; i++) {
            const n = this.getChartLegendKey(res[i], chartLegendKey, optionKey)
            if (n === name) {
              return `${name} | ${res[i].ratio}%  ${res[i].count}台 `
            }
          }
        }

        this.pieChartExtendTwo.legend.formatter = (name) => {
          for (var i = 0; i < res.length; i++) {
            const n = this.getChartLegendKey(res[i], chartLegendKey, optionKey)
            if (n === name && res[i].isRight) {
              return `${name} | ${res[i].ratio}%  ${res[i].count}台 `
            }
          }
        }

        this.chartDataOne = res.filter(item => !item.isRight)
        this.chartDataOne.columns = ['name', 'value']
        this.chartDataOne.rows = this.chartDataOne.map(item => ({
          name: this.getChartLegendKey(item, chartLegendKey, optionKey),
          value: item.totalList ? item.totalList.reduce((a, b) => a + +b[chartDataKey], 0) : item[chartDataKey]
        }))

        this.chartDataTwo = res.filter(item => item.isRight)
        this.chartDataTwo.columns = ['name', 'value']
        this.chartDataTwo.rows = this.chartDataTwo.map(item => ({
          name: this.getChartLegendKey(item, chartLegendKey, optionKey),
          value: item.totalList ? item.totalList.reduce((a, b) => a + +b[chartDataKey], 0) : item[chartDataKey]
        }))

        this.tableData = res.filter(item => item.isRight)
      }

      this.chartData.columns = ['name', 'value']
      this.chartData.rows = res.map(item => ({
        name: this.getChartLegendKey(item, chartLegendKey, optionKey),
        value: item.totalList ? item.totalList.reduce((a, b) => a + +b[chartDataKey], 0) : item[chartDataKey]
      }))
      if(this.tabInfo.name === '存量分析'){
        this.pieChartExtend.legend.show = false
      }
      this.loading = false
    },

    getChartLegendKey(data, key, optionKey) {
      let res = this._.get(data, key)
      if (optionKey === 'deviceStatus') {
        res = deviceStatus[res].name
      } else if (optionKey === 'quanlityInfoType') {
        res = quanlityInfoType[res].name
      } else if (optionKey === 'warrantyStatus') {
        res = warrantyStatus[res].name
      } else if (optionKey === 'demandDistribStatus') {
        res = demandDistribStatus[res].name
      } else if (optionKey === 'tatStatus') {
        res = tatStatus[res].name
      } else if (key === 'sign') {
        res = demandServiceTypes[res].name
      }
      return res
    },

    unPieSetData(res) {
      const { chartLegendKey, chartXKey, chartDataKey, chartListKey, lineKey, optionKey, lineTitle, chartColumns, chartStack, stackColumns, dataZoom, chartType } = this.tabInfo
      if (chartType === 'barAndLine') {
        this.initBarAndLine(res)
        return
      }

      if (dataZoom) {
        this.barChartExtend.dataZoom = {
          show: false,
          height: 22,
          start: 0,
          end: 100
        }
        this.barChartExtend.xAxis = {
          axisLabel: {
            color: '#fff',
            rotate: 30,
            formatter: function(value) {
              return value.length > 7 ? value.slice(0, 7) + '...' : value // 根据长度决定是否省略
            }
          }
        }
      }

      if (res[0].totalList) {
        this.chartData.columns = ['月份', ...res.map(item => this.getChartLegendKey(item, chartLegendKey, optionKey))]
        const months = res[0].totalList.map(item => ({ 月份: item[chartXKey] }))
        months.forEach((month, index) => {
          res.forEach(item => {
            Object.assign(month, {
              [this._.get(item, chartLegendKey)]: item.totalList[index][chartDataKey]
            })
          })
        })
        this.chartData.rows = months
      } else if (chartListKey && res[0].monthList) {
        this.chartData.columns = ['月份', ...res.map(item => this.getChartLegendKey(item, chartLegendKey, optionKey))]
        const months = res[0].monthList.map(item => ({ 月份: item[chartXKey] }))
        months.forEach((month, index) => {
          res.forEach(item => {
            const key = this.getChartLegendKey(item, chartLegendKey, optionKey)
            if (item[chartListKey]) {
              Object.assign(month, {
                [key]: item[chartListKey][index][chartDataKey]
              })
            }
          })
        })
        this.chartData.rows = months
      } else if (res[0].beanList) {
        this.chartData.columns = ['月份', ...res[0].beanList.map(item => this.getChartLegendKey(item, chartLegendKey, optionKey))]
        const months = res.map(item => ({ 月份: item[chartXKey] }))
        months.forEach((month, index) => {
          res[index].beanList.forEach(item => {
            const key = this.getChartLegendKey(item, chartLegendKey, optionKey)
            Object.assign(month, {
              [key]: item[chartDataKey]
            })
          })
        })
        this.chartData.rows = months
      } else {
        this.chartData.columns = this._.cloneDeep(chartColumns)
        this.barChartExtend.legend.show = false
        if (lineKey) {
          this.barChartExtend.legend.show = true
          this.chartData.columns.push(lineTitle)
        }
        if (chartStack) {
          this.barChartExtend.legend.show = true
          this.chartData.columns = this._.cloneDeep(stackColumns.map(item => item.name))
          this.barChartSettings.stack = chartStack
          this.chartData.rows = res.map(item => {
            const obj = {}
            stackColumns.forEach(co => {
              obj[co.name] = item[co.key]
            })
            return obj
          })
        } else {
          this.chartData.rows = res.map(item => {
            item[chartLegendKey] = this.getChartLegendKey(item, chartLegendKey, optionKey)
            const obj = { [chartColumns[0]]: item[chartLegendKey], [chartColumns[1]]: +item[chartDataKey] }
            if (lineKey) {
              obj[lineTitle] = item[lineKey]
            }
            return obj
          })
        }
      }
    },

    initBarAndLine(res) {
      const option = {
        color: colorList,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line'
          }
        },
        grid: {
          right: '20%'
        },
        legend: {
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: res.map((t) => t.time),
            axisLabel:{
              color: '#fff'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            position: 'left',
            alignTicks: true,
            axisLabel:{
              color: '#fff'
            },
            axisLine: {
              show: false
            }
          },
          {
            position: 'right',
            alignTicks: true,
            offset: 80,
            axisLine: {
              show: false
            },
            axisLabel: {
              formatter: '{value} %',
              color: '#fff'
            }
          },
          {
            type: 'value',
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              formatter: '{value} %',
              color: '#fff'
            }
          }
        ],
        series: [
          {
            name: '产出工时',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: res.map((t) => t.outputHours)
          },
          {
            name: '辅助工时',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: res.map((t) => t.assistHours)
          },
          {
            name: '协调工时',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: res.map((t) => t.coordinateHours)
          },
          {
            name: '休假',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: res.map((t) => t.holidayHours)
          },
          {
            name: 'UR',
            type: 'line',
            yAxisIndex: 2,
            data: res.map((t) => t.urRate)
          },
          {
            name: '产出比',
            type: 'line',
            yAxisIndex: 2,
            data: res.map((t) => t.outputRate)
          }
        ]
      }
      const chartDom = document.getElementById('bar-line')
      const myChart = echarts.init(chartDom)
      option && myChart.setOption(option)
    },

    setTableData(res) {
      if (this.tabInfo.columnsType === 'vertical') {
        if (res[0].totalList) {
          this.hasTotalList(res)
        } else {
          this.noTotalList(res)
        }
      } else {
        this.tableData = res || []
      }
    },
    hasTotalList(res) {
      const { chartXKey, chartLegendKey, chartDataKey } = this.tabInfo
      const tableData = []
      this.tableColumns = this.tabInfo.columns.filter(item => !item.vertical)
      const verticalColumns = res.map(item => item[chartLegendKey])
      res[0].totalList.forEach(item => {
        this.tableColumns.push({
          prop: item[chartXKey],
          label: item[chartXKey],
          sortable: true,
          hiddenSearch: true
        })
      })
      verticalColumns.forEach((vc, vcIndex) => {
        const obj = {}
        this.tableColumns.forEach((tc, index) => {
          if (index === 0) {
            obj[tc.prop] = vc
          } else {
            obj[tc.prop] = +res[vcIndex].totalList.find(item => item[chartXKey] === tc.prop)[chartDataKey]
          }
        })
        tableData.push(obj)
      })
      this.tableData = tableData
    },
    noTotalList(res) {
      const tableData = []
      this.tableColumns = this.tabInfo.columns.filter(item => !item.vertical)
      const verticalColumns = this.tabInfo.columns.filter(item => item.vertical)
      res.forEach(item => {
        this.tableColumns.push({
          prop: item.name,
          label: item.name,
          sortable: true,
          hiddenSearch: true
        })
      })
      verticalColumns.forEach(vc => {
        const obj = {}
        this.tableColumns.forEach((tc, index) => {
          if (index === 0) {
            obj[tc.prop] = vc.label
          } else {
            obj[tc.prop] = res[index - 1][vc.prop]
          }
        })
        tableData.push(obj)
      })
      this.tableData = tableData
    },
    searchConditionRef() {
      return this.$refs.searchCondition
    },
    exportToPdf() {
      this.getPdf(this.$refs.charts, this.tabInfo.name)
    },
    modifyWorkingDay(row) {
      this.currentId = row.id
      this.newWorkingDays = row.workingDays
    },
    returns() {
      this.currentId = ''
    },
    async confirm(row) {
      const res = await api.modifyWorkingDays({
        id: row.id,
        workingDays: +this.newWorkingDays,
        workingHours: +this.newWorkingDays * 8
      })
      if (res) {
        this.currentId = ''
        const condition = this.tabInfo.dataCondition
        await this.getLineChartsData(condition)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#bar-line {
  height: 400px;
}
.el-icon-edit{
  margin-left: 15px;
}

.el-input-number {
  &.iNum{
    height: 28px;
    line-height: 28px;
  }
}
.el-icon-close{
  font-size: 18px;
  color: #FF0000;
  margin: 0 10px;
}

.el-icon-check {
  font-size: 18px;
  color: #07ef39
}

.el-input-number--mini{
  width: 100px;
}
.v-charts-container {
  position: relative;

  ::v-deep .chart-search-condition{
    .search-setting{
      top: -40px!important;
      right: 10px;
    }
  }
}
.dailyNums {
  font-size: 15px;
  color: #606266;
  margin-bottom: 5px;
}
.pie{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .pie-item {
    width: 40%;
  }
}
</style>
