// xiewei
// 字段类型 0:文本 1:数值  2:单选 3:多选
export const paramsType = [
  {
    id: 0,
    name: '文本',
    template: {
      tagName: 'el-input',
      props: {},
      children: []
    }
  },
  {
    id: 1,
    name: '数值',
    template: {
      tagName: 'el-input-number',
      rules: [],
      props: {
        controlsPosition: 'right'
      },
      children: []
    }
  },
  {
    id: 2,
    name: '单选',
    template: {
      tagName: 'el-select',
      props: {},
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'label',
            value: 'value'
          }
        }
      ]
    }
  },
  {
    id: 3,
    name: '多选',
    template: {
      tagName: 'el-select',
      props: {
        multiple: true
      },
      children: [
        {
          tagName: 'el-option',
          props: {
            label: 'label',
            value: 'value'
          }
        }
      ]
    }
  }
]
export const paramsTypeList = [
  {
    colSpan: 8,
    tagName: 'el-input',
    props: {}
  },
  {
    colSpan: 8,
    tagName: 'el-input-number',
    props: {
      controlsPosition: 'right',
      placeholder: '',
      min: undefined,
      max: undefined
    }
  },
  {
    colSpan: 8,
    tagName: 'el-select',
    props: {
      clearable: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 8,
    tagName: 'el-select',
    props: {
      multiple: true,
      clearable: true,
      collapseTags: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  }
]
export const paramsTypeListSearch = [
  {
    colSpan: 6,
    tagName: 'el-input',
    props: {
      clearable: true
    }
  },
  {
    colSpan: 6,
    tagName: 'el-input-number',
    props: {
      controlsPosition: 'right',
      placeholder: '',
      min: undefined,
      max: undefined
    }
  },
  {
    colSpan: 6,
    tagName: 'el-select',
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    tagName: 'el-select',
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  }
]
// 库存操作类型
export const warehouseRecordTypeAll = [
  {
    id: 0,
    name: '入库'
  },
  {
    id: 1,
    name: '出库'
  },
  {
    id: 2,
    name: '调拨'
  },
  {
    id: 3,
    name: '移库'
  },
  {
    id: 4,
    name: '仓库盘点'
  },
  {
    id: 5,
    name: '物料盘点'
  }
]
export const warehouseRecordType = [
  {
    id: 2,
    name: '调拨'
  },
  {
    id: 3,
    name: '移库'
  },
  {
    id: 4,
    name: '仓库盘点'
  },
  {
    id: 5,
    name: '物料盘点'
  }
]
// 质保信息-服务类型
export const quanlityInfoServeType = [
  {
    id: 0,
    name: '维修'
  },
  {
    id: 1,
    name: '保养'
  },
  {
    id: 2,
    name: '点检'
  }
]
// 质保信息-类型
export const quanlityInfoType = [
  {
    id: 0,
    name: '工厂质保'
  },
  {
    id: 1,
    name: '本地质保'
  },
  {
    id: 2,
    name: '服务'
  },
  {
    id: 3,
    name: '无质保/合同'
  }
]
// 售后设备信息-状态
export const afterSaleEquStatus = [
  {
    id: 0,
    name: '待上机'
  },
  {
    id: 1,
    name: '正常运行'
  },
  {
    id: 2,
    name: '待返厂'
  },
  {
    id: 3,
    name: '返厂中'
  },
  {
    id: 4,
    name: '待测试'
  },
  {
    id: 5,
    name: '待运出'
  }
]
// 质保状态
export const warrantyStatus = [
  {
    id: 0,
    name: '本月开机'
  },
  {
    id: 1,
    name: '本月验收'
  },
  {
    id: 2,
    name: '本月过工厂质保'
  },
  {
    id: 3,
    name: '本月过本地质保'
  }
]
// 质保信息-类型
export const deviceStatus = [
  {
    id: 0,
    name: '待开机'
  },
  {
    id: 1,
    name: '已开机'
  },
  {
    id: 2,
    name: '未验收'
  },
  {
    id: 3,
    name: '已验收'
  }
]
export const tatStatus = [
  {
    id: 0,
    name: '派工TAT'
  },
  {
    id: 1,
    name: '执行TAT'
  },
  {
    id: 2,
    name: '完工TAT'
  },
  {
    id: 3,
    name: '结案TAT'
  },
  {
    id: 4,
    name: '总TAT'
  }
]
export const demandDistribStatus = [
  {
    id: 0,
    name: '待派工'
  },
  {
    id: 1,
    name: '待执行'
  },
  {
    id: 2,
    name: '待完成'
  },
  {
    id: 3,
    name: '待审核'
  },
  {
    id: 4,
    name: '当月新增'
  },
  {
    id: 5,
    name: '当月完结'
  }
]
// 质保信息-费用
export const quanlityInfoFeeType = [
  {
    id: 0,
    name: '本地'
  },
  {
    id: 1,
    name: '工厂'
  },
  {
    id: 2,
    name: '客户'
  }
]
// 保养计划-级别
export const maintenanceLevel = [
  {
    id: 1,
    name: '一级'
  },
  {
    id: 2,
    name: '二级'
  },
  {
    id: 3,
    name: '三级'
  }
]
// 保养计划-周期类别
export const maintenanceCycleType = [
  {
    id: 0,
    name: '固定周期'
  },
  {
    id: 1,
    name: '固定间隔'
  }
]
// 点检计划-周期类别
export const spotCheckCycleType = [
  {
    id: 0,
    name: '固定周期'
  }
  // {
  //   id: 1,
  //   name: '固定间隔'
  // }
]
// 需求中心-服务内容-不包含保养计划
export const demandServiceType = (()=>{
  if(localStorage.getItem("memberCode") === 'bbelc'){
    return [
      {
        id: 0,
        name: '维修'
      }
    ]
  }else {
    return [
      {
        id: 0,
        name: '维修'
      },
      {
        id: 1,
        name: '保养'
      },
      {
        id: 2,
        name: '装机'
      },
      {
        id: 3,
        name: '改造'
      },
      {
        id: 6,
        name: '点检'
      },
      {
        id: 7,
        name: '更换'
      }
    ]
  }
})()
// 需求中心-服务内容-包含保养计划、点检计划
export const demandServiceTypes = [
  {
    id: 0,
    name: '维修'
  },
  {
    id: 1,
    name: '保养'
  },
  {
    id: 2,
    name: '装机'
  },
  {
    id: 3,
    name: '改造'
  },
  {
    id: 4,
    name: '点检'
  },
  {
    id: 5,
    name: '保养'
  },
  {
    id: 6,
    name: '点检'
  },
  {
    id: 7,
    name: '更换'
  }
]
// 维修、保养
export const demandStatus = [
  {
    id: 0,
    name: '待派工'
  },
  {
    id: 1,
    name: '待审批'
  },
  {
    id: 2,
    name: '待填报出差计划'
  },
  {
    id: 3,
    name: '待修改出差计划'
  },
  {
    id: 4,
    name: '待执行'
  },
  {
    id: 5,
    name: '执行中'
  },
  {
    id: 6,
    name: '待关闭'
  },
  {
    id: 10,
    name: '已完成'
  },
  {
    id: 8,
    name: '重开中'
  }
]
// 改造
export const reformStatus = [
  {
    id: 0,
    name: '待上传技术方案'
  },
  {
    id: 1,
    name: '待处理'
  },
  {
    id: 2,
    name: '上传技术方案中'
  },
  {
    id: 3,
    name: '待填写本地开发申请表'
  },
  {
    id: 4,
    name: '待销售部审批'
  },
  {
    id: 5,
    name: '待研发部审批'
  },
  {
    id: 6,
    name: '待执行'
  },
  {
    id: 7,
    name: '执行中'
  },
  {
    id: 8,
    name: '待审批'
  },
  {
    id: 9,
    name: '待验收'
  },
  {
    id: 10,
    name: '已完成'
  }
]
// 需求中心-服务类型
export const demandServiceContentType = [
  {
    id: 0,
    name: '质保'
  },
  {
    id: 1,
    name: '收费'
  },
  {
    id: 2,
    name: '服务合同'
  },
  {
    id: 3,
    name: '销售赠送'
  }
]
// 消息通知-触发动作
export const triggerActionType = [
  {
    id: 0,
    name: '新增待办'
  },
  {
    id: 1,
    name: '库存预警'
  }
]
// 消息通知-通知渠道
export const notifyChannelType = [
  {
    id: 0,
    name: '站内消息'
  },
  {
    id: 1,
    name: '邮箱'
  },
  {
    id: 2,
    name: '短信'
  },
  {
    id: 3,
    name: '公众号'
  }
]

// 库存操作类型-流入
export const warehouseRecordTypeIn = [
  {
    id: 0,
    name: '采购入库'
  },
  {
    id: 1,
    name: '生产入库'
  },
  {
    id: 2,
    name: '包装入库'
  },
  {
    id: 3,
    name: '托盘入库'
  },
  {
    id: 4,
    name: '其他入库'
  },
  {
    id: 5,
    name: '撤回报工'
  },
  {
    id: 6,
    name: '入库'
  },
  {
    id: 7,
    name: '盘点'
  },
  {
    id: 8,
    name: '调拨'
  }
]

export const warehouseRecordTypeOut = [
  {
    id: 0,
    name: '生产领料'
  },
  {
    id: 1,
    name: '销售出库'
  },
  {
    id: 2,
    name: '其他出库'
  },
  {
    id: 3,
    name: '生产投料'
  },
  {
    id: 6,
    name: '出库'
  },
  {
    id: 7,
    name: '盘点'
  },
  {
    id: 8,
    name: '调拨'
  }
]
// 工作类型
export const jobTypes = [
  // {
  //   id: 0,
  //   name: '点检'
  // },
  // {
  //   id: 1,
  //   name: '保养'
  // },
  // {
  //   id: 2,
  //   name: '排故'
  // },
  // {
  //   id: 3,
  //   name: '安装、调试'
  // },
  {
    id: 4,
    name: '报告'
  },
  {
    id: 5,
    name: '5S'
  },
  {
    id: 6,
    name: '会议'
  },
  {
    id: 7,
    name: '培训'
  },
  {
    id: 8,
    name: '路途'
  },
  {
    id: 9,
    name: '等待'
  },
  {
    id: 10,
    name: '休假'
  },
  {
    id: 11,
    name: '加班'
  }
]
// 日期类型
export const dateTypes = [
  {
    id: 0,
    name: '工作日'
  },
  {
    id: 1,
    name: '双休日'
  },
  {
    id: 2,
    name: '节假日'
  }
]
// 改造类型
export const reformType = [
  {
    id: 1,
    name: '会勘'
  },
  {
    id: 2,
    name: '硬件改造'
  },
  {
    id: 3,
    name: '软件升级'
  },
  {
    id: 4,
    name: '测试验证'
  }
]
// 装机进度
export const installationProgress = [
  {
    id: 0,
    name: '未操作'
  },
  {
    id: 1,
    name: '本次完成了'
  },
  {
    id: 2,
    name: '本次操作了但未完成'
  }
]

// 易易互联-库存操作类型
export const yyhlWarehouseRecordTypeAll = [
  {
    id: 0,
    name: '入库'
  },
  {
    id: 1,
    name: '出库'
  }
]
