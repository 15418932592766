var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MElTransfer", {
    attrs: {
      "filter-placeholder": "请输入用户",
      filterable: "",
      titles: ["源列表", "目的列表"],
      data: _vm.data,
      props: { key: "key", label: "name" },
      "filter-method": _vm.filterMethod,
      "source-data-methods": _vm.sourceDataMethods,
      "set-label-r-method": _vm.setLabelRMethod
    },
    model: {
      value: _vm.selectIds,
      callback: function($$v) {
        _vm.selectIds = $$v
      },
      expression: "selectIds"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }