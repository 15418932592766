<template>
  <div class="account-detail-wrapper">
    <div class="account-detail-header flex-sbc">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">设备详情</span>
      </div>
    </div>

    <div class="account-detail-body">
      <div class="detail-card">
        <div class="card-tit">设备基本信息</div>
        <div class="card-bot" style="padding: 15px">
          <div style="display: inline-flex;justify-content: space-between">
            <div style="width: 200px">
              <el-image
                style="border-radius: 4px"
                fit="contain"
                :src="equipmentDetail.drawing || empty"
                :preview-src-list="[equipmentDetail.drawing || empty]"
              />
            </div>
            <el-row class="view-list" style="padding: 10px 15px" :gutter="24">
              <!--            暂时这么处理距离问题-->
              <el-col
                v-for="(item, index) in columns"
                :key="item.prop"
                :span="6"
                :style="{marginBottom: index !== 4 ? '20px' : 0 }"
              >
                <span class="bot-tis">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
              </el-col>
            </el-row>
          </div>

          <div style="padding: 20px">
            <el-tabs v-model="params.radio" @tab-click="tabClick">
              <el-tab-pane v-if="!['yyhl'].includes(memberCode)" label="设备监控" name="EquipmentMonitoring"/>
              <el-tab-pane label="故障记录" name="FaultRadio"/>
              <el-tab-pane label="维修记录" name="RepairRadio"/>
              <el-tab-pane label="巡检记录" name="InspectionRadio"/>
              <el-tab-pane label="点检记录" name="PointRadio"/>
              <el-tab-pane label="保养记录" name="MaintenanceRadio"/>
              <el-tab-pane v-if="!['yyhl'].includes(memberCode)" label="关联售后设备" name="auxiliaryMaterialsRadio">
                <MTable v-if="params.radio === 'auxiliaryMaterialsRadio'" ref="mTable" :height="height"
                        :columns="auxiliaryMaintainColumns" :data="auxiliaryList" :showPage="false"/>
              </el-tab-pane>
              <el-tab-pane v-if="!['yyhl'].includes(memberCode)" label="售后设备记录" name="auxiliaryRecordRadio">
                <MTable v-if="params.radio === 'auxiliaryRecordRadio'" ref="mTable" :height="height"
                        :columns="auxiliaryRecordColumns" :data="auxiliaryRecordList" :showPage="false">
                  <div slot="operationType" slot-scope="{ row }">{{ operationType[row.operationType].name }}</div>
                </MTable>
              </el-tab-pane>
            </el-tabs>
            <EquipmentMonitoring v-if="params.radio === 'EquipmentMonitoring'" :equip-data="equipmentName" :equip-code="equipmentCode" />
            <template v-else-if="['auxiliaryMaterialsRadio', 'auxiliaryRecordRadio'].includes(params.radio)"></template>
            <template v-else>
              <div class="top">
                <SearchForm ref="searchForm" :form-list-extend="searchFormList[params.radio]" @search="searchTable"/>
              </div>
              <div class="bottom">
                <FunctionHeader
                  v-model="functionHeader.searchVal"
                  :search-title="searchTitle[params.radio]"
                  @search="$refs.mTable.setTableData(true)"
                >
                  <div v-if="['FaultRadio', 'RepairRadio'].includes(params.radio)" slot="all" class="all-select">
                    <div class="checkAll">
                      <el-checkbox v-model="checkeds" :disabled="checkeds" :indeterminate="isIndeterminate"
                                   @change="selectCheck">全选
                      </el-checkbox>
                    </div>
                    <div v-for="(item, index) in allStatus" :key="item.value" class="all-li"
                         :class="item.checked ? 'active' : ''" @click="selectTab(item, index)">{{ item.label }}
                    </div>
                  </div>
                </FunctionHeader>
                <MTable ref="mTable" :columns-key="columnsKey" :columns="tableColumns[params.radio]" :height="height"
                        :set-data-method="getTableData">
                  <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
                  <div slot="faultStatus" slot-scope="{ row }">
                    <div class="faultStatus"
                         :style="{ background: faultStatus[row.status-1].background, color: faultStatus[row.status-1].color }">
                      {{ faultStatus[row.status - 1].name }}
                    </div>
                  </div>
                  <div slot="continueTime" slot-scope="{ row }">
                    <div v-if="row.repairContinueTime">{{ fixTime(row.repairContinueTime) }}</div>
                  </div>
                  <div slot="inspectionStatus" slot-scope="{ row }">
                    <div v-if="status.inspectionStatus[row.status]" class="faultStatus"
                         :style="{ background: status.inspectionStatus[row.status].background, color: status.inspectionStatus[row.status].color }">
                      {{ status.inspectionStatus[row.status].name }}
                    </div>
                  </div>
                  <div slot="pointStatus" slot-scope="{ row }">
                    <div v-if="status.pointStatus[row.status]" class="faultStatus"
                         :style="{ background: status.pointStatus[row.status].background, color: status.pointStatus[row.status].color }">
                      {{ status.pointStatus[row.status].name }}
                    </div>
                  </div>
                  <div slot="maintenanceStatus" slot-scope="{ row }">
                    <div v-if="status.maintenanceStatus[row.status]" class="faultStatus"
                         :style="{ background: status.maintenanceStatus[row.status].background, color: status.maintenanceStatus[row.status].color }">
                      {{ status.maintenanceStatus[row.status].name }}
                    </div>
                  </div>
                  <el-button slot="checkItem" slot-scope="{ row }" type="text" @click="viewCheckItem(row)">查看
                  </el-button>
                  <div slot="errorCount" slot-scope="{ row }">
                    <div style="color: #E02020">{{ getErrorCount(row) }}</div>
                  </div>
                  <div slot="unMaintainCount" slot-scope="{ row }">
                    <div style="color: #E02020">{{ getUnMaintainCount(row) }}</div>
                  </div>
                  <el-button v-if="row.sparePartList && row.sparePartList.length" slot="spareList" slot-scope="{ row }"
                             type="text" @click="viewSpareList(row)">查看
                  </el-button>
                  <div slot="picture" slot-scope="{ row, column }">
                    <el-image
                      v-if="row[column.property]"
                      :src="fixSinImg(row[column.property])"
                      class="drawing-img"
                      :preview-src-list="fixImg(row[column.property])"
                    />
                  </div>
                </MTable>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <MDialog v-model="checkItemVisible" :title="tableDialogTitle[params.radio]" :show-footer="false"
             @close="visible = false">
      <MTable :columns="tableDialogColumns[params.radio]" :height="tableDialogHeight" :data="checkItemList"
              :show-page="false" :columns-setting="false">
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="result" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.result === 1 ? '#24CAD7' : row.result === 0 ? '#FFCC78' : '' }"/>
          <div>{{ row.result === 1 ? '正常' : row.result === 0 ? '异常' : '' }}</div>
        </div>
        <div slot="maintenanceResult" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.result === 1 ? '#24CAD7' : '#FFCC78' }"/>
          <div>{{ row.result === 1 ? '已保养' : '未保养' }}</div>
        </div>
        <div slot="action" slot-scope="{ row, $index }">
          <el-button type="text" @click="viewCheckDetail(row, $index)">详情</el-button>
        </div>
      </MTable>
    </MDialog>

    <MDialog v-model="resultVisible" :title="tableDialogResultTitle[params.radio]" :show-footer="false">
      <div class="result-wrapper">
        <div>
          <span>{{ params.radio !== 'MaintenanceRadio' ? '检查项：' : '保养项：' }}</span>
          <span>{{ currentData.name }}</span>
        </div>
        <div>
          <span>{{ params.radio !== 'MaintenanceRadio' ? '检查标准：' : '保养标准：' }}</span>
          <span>{{ currentData.standard }}</span>
        </div>
        <div v-if="currentData.sketchMap">
          <span>操作图：</span>
          <div slot="sketchMap">
            <el-image
              v-for="item in formatArr(currentData.sketchMap)"
              :key="item.id"
              style="padding-left: 10px"
              :src="item.url"
              class="table-row-img"
              :preview-src-list="[item.url]"
            />
          </div>
        </div>
        <div v-if="params.radio !== 'MaintenanceRadio'">
          <template v-if="currentData.result === 1 || currentData.result === 0">
            <span v-if="params.radio === 'PointRadio'">点检结果：</span>
            <span v-if="params.radio === 'InspectionRadio'">巡检结果：</span>
            <span>
              <div class="is-enabled">
                <div
                  :style="{background: currentData.result === 1 ? '#24CAD7' : currentData.result === 0 ? '#FFCC78' : '' }"/>
                <div>{{ currentData.result === 1 ? '正常' : currentData.result === 0 ? '异常' : '' }}</div>
              </div>
            </span>
          </template>
        </div>
        <div v-else>
          <span>状态：</span>
          <span>
            <div class="is-enabled">
              <div :style="{background: currentData.result === 1 ? '#24CAD7' : '#FFCC78' }"/>
              <div>{{ currentData.result === 1 ? '已保养' : '未保养' }}</div>
            </div>
          </span>
        </div>
        <div v-if="currentData.remark">
          <span>备注：</span>
          <span>{{ currentData.remark }}</span>
        </div>
        <div v-if="currentData.sceneMap">
          <span>图片：</span>
          <div slot="sketchMap">
            <el-image
              v-for="item in formatArr(currentData.sceneMap)"
              :key="item.id"
              style="padding-left: 10px"
              :src="item.url"
              class="table-row-img"
              :preview-src-list="[item.url]"
            />
          </div>
        </div>
      </div>
    </MDialog>

    <TableDialog title="查看消耗备件" :show.sync="spareVisible" :columns="spareColumns" :table-data="spareList"
                 :calc-height="32">
      <el-row :gutter="24" style="padding-bottom: 15px">
        <el-col :span="8">设备编号：{{ currentRow.equipmentCode }}</el-col>
        <el-col :span="8">设备名称：{{ currentRow.equipmentName }}</el-col>
        <el-col :span="8">保养人：{{ currentRow.maintainerName }}</el-col>
      </el-row>
    </TableDialog>
  </div>
</template>

<script>
const empty = 'https://oss.mubyte.cn/static/empty.png'
import api from '@/api/equipment/equipment'
import inApi from '@/api/equipment/inspection-bills'
import poApi from '@/api/equipment/point-bills'
import maApi from '@/api/equipment/maintenance-bills'
import edApi from '@/api/equipment/equipment-detail'
import usApi from '@/api/sets/user/user'
import { columns, faultColumns, inspectionColumns, maintenanceColumns, pointColumns, repairColumns } from './columns'
import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import { faultSearchFormList, inspectionFormList, maintenanceFormList, pointFormList } from './form-list'
import { faultStatus, inspectionStatus, maintenanceStatus, pointStatus } from '@/config/options.config'
import { maintenanceItemView } from '@/views/equipment/maintenance-bills/detail/columns'
import { pointCheckItemView } from '@/views/equipment/point-bills/detail/columns'
import { pointCheckItemView as inspectionItemView } from '@/views/equipment/inspection-bills/detail/columns'
import { spareColumns } from '@/views/equipment/maintenance-bills/columns'
import TableDialog from '@/components/TableDialog/TableDialog'
import { getUUid } from '@/utils'
import eapi from '@/api/equipment/maintenance'
import { operationType } from '@/config/options.config'
import {
  auxiliaryMaintainColumns,
  auxiliaryRecordColumns
} from './columns'
import EquipmentMonitoring from '@/views/equipment/account/components/equipment-monitoring'

export default {
  name: 'EquipmentAccountDetails',
  components: { EquipmentMonitoring, TableDialog, FunctionHeader, SearchForm },
  mixins: [conventionMixin],
  data() {
    return {
      auxiliaryMaintainColumns,
      auxiliaryRecordColumns,
      faultColumns,
      faultStatus,
      operationType,
      spareColumns,
      api: edApi,
      params: {
        radio: 'EquipmentMonitoring'
      },
      status: {
        inspectionStatus,
        pointStatus,
        maintenanceStatus
      },
      columns,
      tableColumns: {
        FaultRadio: faultColumns,
        RepairRadio: repairColumns,
        InspectionRadio: inspectionColumns,
        PointRadio: pointColumns,
        MaintenanceRadio: maintenanceColumns
      },
      empty,
      equipmentDetail: {},
      functionHeader: {
        searchVal: ''
      },
      searchTitle: {
        FaultRadio: '输入报修单号/维修单号',
        RepairRadio: '输入报修单号/维修单号',
        InspectionRadio: '请输入巡检单号',
        PointRadio: '请输入点检单号',
        MaintenanceRadio: '请输入保养单号'
      },
      searchFormList: {
        FaultRadio: faultSearchFormList,
        RepairRadio: faultSearchFormList,
        InspectionRadio: inspectionFormList,
        PointRadio: pointFormList,
        MaintenanceRadio: maintenanceFormList
      },
      searchFormatData: [],
      checkItemList: [],
      checkItemVisible: false,
      tableDialogTitle: {
        InspectionRadio: '查看巡检项',
        PointRadio: '查看点检项',
        MaintenanceRadio: '查看保养项'
      },
      tableDialogResultTitle: {
        InspectionRadio: '巡检结果详情',
        PointRadio: '点检结果详情',
        MaintenanceRadio: '保养详情'
      },
      tableDialogColumns: {
        InspectionRadio: inspectionItemView,
        PointRadio: pointCheckItemView,
        MaintenanceRadio: maintenanceItemView
      },
      resultVisible: false,
      currentData: {},
      currentRow: {},
      spareList: [],
      spareVisible: false,
      searchFieldName: '',
      searchKeyword: true,
      extraParams: [],
      allStatusCopy: [
        {
          value: 1,
          label: '待审核',
          checked: false
        },
        {
          value: 2,
          label: '待接单',
          checked: false
        },
        {
          value: 3,
          label: '待维修',
          checked: false
        },
        {
          value: 4,
          label: '维修中',
          checked: false
        },
        {
          value: 5,
          label: '待验证',
          checked: false
        },
        {
          value: 7,
          label: '已驳回',
          checked: false
        },
        {
          value: 6,
          label: '已完成',
          checked: false
        }
      ],
      allStatus: [],
      checkeds: true,
      currentIdxs: [],
      currentIdx: false,
      isIndeterminate: false,
      equipmentName: '',
      equipmentCode: '',
      auxiliaryList: [],
      auxiliaryRecordList: []
    }
  },
  computed: {
    tableDialogHeight() {
      return this.$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70
    },
    height() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 100
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
  },
  watch: {
    checkeds(val) {
      if (val) {
        this.currentIdxs = []
        this.isIndeterminate = false
        this.allStatus = this.allStatus.map(item => {
          return {
            ...item,
            checked: false
          }
        })
      } else {
        this.isIndeterminate = true
      }
    }
  },
  async mounted() {
    this.extraParams = [
      {
        id: getUUid(),
        fieldName: 'equipmentId',
        fieldType: 'number',
        fieldValue: this.$route.params.id,
        operator: 'eq'
      }
    ]
    if (this.params.radio === 'FaultRadio') {
      this.extraParams.push({
        id: getUUid(),
        fieldName: 'type',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'in'
      })
      this.allStatus = this._.cloneDeep(this.allStatusCopy)
    }
    await this.getEquipmentDetail()
    this.getInCheckTypeList()
    this.getUserList()
    this.getEmergenceDegree()
    this.getMaintenanceTypeList()
  },
  methods: {
    // 查询关联售后设备
    async getAuxiliaryList() {
      const res = await api.getAfterSaleEquByEqu(this.$route.params.id)
      if (res) {
        console.log('售后设备', res.records)
        this.auxiliaryList = res.records
      }
    },
    // 查询售后设备记录
    async getAuxiliaryRecordList() {
      const res = await api.getAfterSaleEquRecByEqu(this.$route.params.id)
      if (res) {
        console.log('售后设备记录', res.records)
        this.auxiliaryRecordList = res.records
      }
    },
    fixTime(val) {
      if (val) return Math.floor(val / 60) + '分钟'
    },
    async getEquipmentDetail() {
      const res = await api.getEquipmentDetail({ id: this.$route.params.id })
      if (res) {
        this.equipmentDetail = res
        this.equipmentName = res.name
        this.equipmentCode = res.code
        await this.getPoCheckTypeList()
      }
    },
    async getInCheckTypeList() {
      const res = await inApi.getCheckTypeList()
      if (res) {
        this.searchFormList.InspectionRadio[0].children[0].options = res.map(item => ({ id: item, name: item }))
      }
    },
    async getPoCheckTypeList() {
      const res = await poApi.getCheckTypeList({
        equipmentClassIds: this.equipmentDetail.equipmentClassId
      })
      if (res) {
        this.searchFormList.PointRadio[0].children[0].options = res
      }
    },
    async getUserList() {
      const res = await usApi.getUserList()
      if (res) {
        this.searchFormList.FaultRadio[2].children[0].options = res
        this.searchFormList.RepairRadio[2].children[0].options = res
      }
    },
    async getMaintenanceTypeList() {
      const res = await maApi.getCheckTypeList()
      if (res) {
        this.searchFormList.MaintenanceRadio[0].children[0].options = res.map(item => ({ id: item, name: item }))
      }
    },
    // 紧急程度
    async getEmergenceDegree() {
      const res = await eapi.getUrgencyLevelList()
      if (res) {
        this.searchFormList.FaultRadio[3].children[0].options = res
        this.searchFormList.RepairRadio[3].children[0].options = res
      }
    },
    getValue(row) {
      const result = this.equipmentDetail[row.prop]
      if (row.form && row.form.options) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    },
    back() {
      this.$router.push({ name: 'equipmentAccountList' })
    },
    searchTable(data) {
      this.searchFormatData = data
      if (this.$refs.mTable) this.$refs.mTable.setTableData(true)
    },
    tabClick() {
      this.checkeds = true
      this.currentIdxs = []
      this.currentIdx = false
      this.isIndeterminate = false
      if (this.$refs.searchForm) this.$refs.searchForm.form = {}
      this.searchKeyword = ['FaultRadio', 'RepairRadio'].includes(this.params.radio)
      const keyObj = {
        InspectionRadio: 'cycleCheckTaskNumber',
        PointRadio: 'spotCheckNumber',
        MaintenanceRadio: 'maintainTaskNumber'
      }
      this.searchFieldName = keyObj[this.params.radio]
      this.extraParams = [
        {
          id: getUUid(),
          fieldName: 'equipmentId',
          fieldType: 'number',
          fieldValue: this.$route.params.id,
          operator: 'eq'
        }
      ]
      this.searchFormatData = []
      if (this.params.radio === 'FaultRadio') {
        this.extraParams.push({
          id: getUUid(),
          fieldName: 'type',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'in'
        })
        this.allStatus = this._.cloneDeep(this.allStatusCopy)
      }
      if (this.params.radio === 'RepairRadio') {
        this.extraParams.push({
          id: getUUid(),
          fieldName: 'status',
          fieldType: 'number',
          fieldValue: '2^3^4^5^6^7',
          operator: 'in'
        })
        this.allStatus = this._.cloneDeep(this.allStatusCopy).slice(1, 10)
      }
      // 售后设备
      if (this.params.radio === 'auxiliaryMaterialsRadio' && !this.auxiliaryList.length) {
        this.getAuxiliaryList()
      }
      // 售后设备记录
      if (this.params.radio === 'auxiliaryRecordRadio' && !this.auxiliaryRecordList.length) {
        this.getAuxiliaryRecordList()
      }
      setTimeout(() => {
        if (this.params.radio !== 'auxiliaryRecordRadio' && this.params.radio !== 'auxiliaryMaterialsRadio') {
          if (this.$refs.mTable) this.$refs.mTable.setTableData(true)
        }
      }, 0)
    },
    selectCheck() {
      if (this.checkeds) {
        this.currentIdxs = []
        const statusIndex = this.extraParams.findIndex(item => item.fieldName === 'status')
        if (this.params.radio === 'FaultRadio') {
          this.extraParams.splice(statusIndex, 1)
        }
        if (this.params.radio === 'RepairRadio') {
          this.extraParams[statusIndex].fieldValue = '2^3^4^5^6'
        }
        if (this.$refs.mTable) this.$refs.mTable.setTableData(true)
      }
    },
    selectTab(val, index) {
      const isCheck = this.allStatus.filter(item => item.checked)
      const isAll = this.allStatus.filter(item => !item.checked)
      this.allStatus[index].checked = !this.allStatus[index].checked
      if (this.currentIdxs.includes(val.value)) {
        this.currentIdxs = this.currentIdxs.filter(item => item !== val.value)
        this.checkeds = isCheck.length === 1
      } else {
        this.checkeds = isAll.length === 1
        this.currentIdxs.push(val.value)
      }
      const statusIndex = this.extraParams.findIndex(item => item.fieldName === 'status')
      if (statusIndex === -1) {
        if (this.currentIdxs.length) {
          this.extraParams.push({
            id: getUUid(),
            fieldName: 'status',
            fieldType: 'number',
            fieldValue: this.currentIdxs.join('^'),
            operator: 'in'
          })
        } else {
          this.selectCheck()
        }
      } else {
        if (this.currentIdxs.length) {
          this.extraParams[statusIndex].fieldValue = this.currentIdxs.join('^')
        } else {
          this.selectCheck()
        }
      }
      if (this.$refs.mTable) this.$refs.mTable.setTableData(true)
    },
    viewCheckItem(row) {
      this.checkItemList = row.checkItemList || row.maintenanceItemList
      this.checkItemVisible = true
    },
    viewCheckDetail(row) {
      this.currentData = row
      this.resultVisible = true
    },
    viewSpareList(row) {
      this.currentRow = row
      this.spareList = row.sparePartList || []
      this.spareVisible = true
    },
    fixSinImg(img) {
      const imgs = img.split(',')
      return imgs[0]
    },
    fixImg(img) {
      return img.split(',')
    },
    getErrorCount(row) {
      if (row.status === 1 || row.status === 0 || row.status === 4 || !row.checkItemList) {
        return ''
      }
      return row.checkItemList.filter(item => item.result === 0).length
    },
    getUnMaintainCount(row) {
      if (row.status === 1 || row.status === 0 || row.status === 4 || !row.maintenanceItemList) {
        return ''
      }
      return row.maintenanceItemList.filter(item => item.result !== 1).length
    }
  }
}
</script>

<style scoped lang="scss">
.account-detail-wrapper {
  .account-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .account-detail-body {
    padding-top: 10px;

    .card-bot {
      .info {
        padding: 20px;
        border-bottom: 1px solid #DDE3EB;
      }

      ::v-deep .el-tabs__nav {
        position: relative;
        left: 50%;
        transform: translateX(-50%) !important;
      }
    }
  }
}

.faultStatus {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  border-radius: 4px;
  width: 50px;
  text-align: center;
}

.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}

.result-wrapper {
  > div {
    display: flex;
    font-size: 16px;
    padding: 10px 0;

    span {
      &:first-child {
        width: 80px;
        text-align-last: justify;
        text-align: justify;
      }
    }
  }
}

::v-deep {
  .table-row-img {
    > img {
      height: 50px;
    }
  }
}

.all-select {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .checkAll {
    margin-right: 20px;
  }

  .all-li {
    width: 80px;
    height: 26px;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #DDE3EB;
    margin-right: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #A4B3C6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .active {
    color: #607FFF;
    border-color: #607FFF;
    background: #EFF2FF;
  }
}
</style>
