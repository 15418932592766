var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom",
        width: "250",
        trigger: "click",
        "popper-class": "type-popover"
      },
      model: {
        value: _vm.inputVisible,
        callback: function($$v) {
          _vm.inputVisible = $$v
        },
        expression: "inputVisible"
      }
    },
    [
      _vm.type === "common"
        ? _c("div", [
            _vm.list.length > 0
              ? _c(
                  "div",
                  { staticClass: "content-container" },
                  _vm._l(_vm.list, function(item) {
                    return _c(
                      "div",
                      {
                        key: item[_vm.optionsKey.value],
                        staticClass: "selectType",
                        style: {
                          color:
                            _vm.currentIdx === item[_vm.optionsKey.label]
                              ? "#607FFF"
                              : "#606266"
                        },
                        attrs: {
                          label: item[_vm.optionsKey.label],
                          value: item[_vm.optionsKey.value]
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "name-tit",
                            on: {
                              click: function($event) {
                                return _vm.selectName(item, "change")
                              }
                            }
                          },
                          [_vm._v(_vm._s(item[_vm.optionsKey.label]))]
                        )
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "nodata" }, [_vm._v(" 暂无数据 ")])
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "com-ipts",
          attrs: { slot: "reference" },
          on: { focus: _vm.goBack },
          slot: "reference"
        },
        [
          _vm._v(" " + _vm._s(_vm.name ? _vm.name : "请选择") + " "),
          _c("span", { staticClass: "el-icon-arrow-down icons" })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }