<template>
  <div v-loading="loading" class="v-charts-container" :style="{width: tabInfo.width || '100%'}">
    <chart-search-condition
      ref="searchCondition"
      :tab-info="tabInfo"
      v-if="!tabInfo.hiddenSelect"
      @conditionChange="getChartsData"
    />
    <div v-if="tabInfo.chartType === 'barAndLine'">
      <div id="bar-line" />
      <MTable
        v-if="tabInfo.type === 'chartsAndTable'"
        ref="mTable"
        :max-height="200"
        :data="tableData"
        :show-page="false"
        :columns="tableColumns"
      >
        <div slot="workingDays" slot-scope="{ row }">
          <span v-if="row.id !== currentId">
            {{ row.workingDays }}
            <i class="el-icon-edit" @click="modifyWorkingDay(row.id)" />
          </span>
          <span v-else>
            <el-input-number
              v-model="newWorkingDays"
              class="iNum"
              :precision="0"
              size="mini"
              controls-position="right"
              :min="1"
            />
            <i class="el-icon-close" @click="returns(row)" />
            <i class="el-icon-check" @click="confirm(row)" />
          </span>
        </div>
      </MTable>
    </div>
    <div v-else-if="chartData.columns.length" ref="charts">
      <div v-if="tabInfo.chartDataKey === 'hours'" class="dailyNums">UR: {{ urData }}，
        总工时：{{ allHours }}</div>
      <ve-line
        v-if="tabInfo.chartType === 'line'"
        :data="chartData"
        :settings="lineChartSettings"
        :extend="lineChartExtend"
      />
      <ve-histogram
        v-if="tabInfo.chartType === 'bar'"
        :after-config="tabInfo.chartDataKey === 'personalWork' ||  tabInfo.hiddenSelect ? workHoursConfig: afterConfig"
        :data="chartData"
        :settings="barChartSettings"
        :extend="barChartExtend"
      />
      <ve-bar
        v-if="tabInfo.chartType === 'horizontalBar'"
        :data="chartData"
        :settings="barChartSettings"
        :extend="barChartExtend"
      />
      <ve-pie
        v-if="tabInfo.chartType === 'pie' && !tabInfo.hiddenSelect"
        :data="chartData"
        :settings="pieChartSettings"
        :extend="pieChartExtend"

      />
      <ve-pie
        v-if="tabInfo.chartType === 'pie' && tabInfo.hiddenSelect"
        :data="chartData"
        :settings="pieChartSettings"
        :extend="pieChartExtend1"
        width="460px"
        height="230px"

      />
      <MTable
        v-if="tabInfo.type === 'chartsAndTable'"
        ref="mTable"
        :max-height="200"
        :data="tableData"
        :show-page="false"
        :columns="tableColumns"
      />
    </div>
    <MBlank v-else />
  </div>
</template>

<script>
import * as echarts from 'echarts'
import api from '@/api/statistic-statement/index'
import 'echarts/lib/component/legendScroll'
import 'echarts/lib/component/dataZoom'
import { quanlityInfoType } from '@/config/options.sales'
import ChartSearchCondition from './chart-search-condition'
const colorList = [
  '#7760FF',
  '#60E8FF',
  '#38E300',
  '#F7B500',
  '#FA6400',
  '#AF0F0F',
  '#B814D2',
  '#CB843B',
  '#C8CF0B',
  '#13B1C4',
  '#009112',
  '#BCBB95',
  '#FF84A1',
  '#808080',
  '#C89595',
  '#44D7B6',
  '#095C92',
  '#FFE1A5',
  '#0091FF',
  '#90BD61',
  '#95B2C8',
  '#AB6FA7',
  '#8A6161',
  '#EC67FF'
]
export default {
  name: 'Charts',
  components: { ChartSearchCondition },
  props: {
    tabInfo: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      urData: '',
      allHours: '',
      quanlityInfoType,
      lineChartSettings: {},
      barChartSettings: {},
      pieChartSettings: {},
      lineChartExtend: {
        color: colorList,
        legend: {
          type: 'scroll'
        },
        grid: { width: '94%' },
        xAxis: {
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#9597AE'
            }
          }
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#9597AE'
            }
          }
        },
        tooltip: {
          trigger: 'axis'
          // appendToBody: true
        }
      },
      barChartExtend: {
        color: colorList,
        legend: {
          show: false,
          type: 'scroll'
        },
        xAxis: {
          splitLine: {
            show: true,
            interval: (index, value) => index === 0,
            lineStyle: {
              type: 'dashed',
              color: '#9597AE'
            }
          }
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#9597AE'
            }
          }
        },
        tooltip: {
          confine: true
          // appendToBody: true
        },
        series: {
        itemStyle: {
          // color: function(params) {
          //   // var value = params.value; // 获取柱状图的数值
          //   // if (params.seriesName === '工时' && (value > 10 || value < 7)) {
          //   //   return '#FF0000'; // 如果大于 10，返回红色
          //   // } else {
          //     return '#7760FF'; // 否则返回默认颜色
          //   // }
          // }
        }
  }
      },
      pieChartExtend: {
        color: colorList,
        legend: {
          itemWidth: 60,
          itemHeight: 25,
          itemGap: 20,
          top: 'center',
          left: '40%',
          show: true,
          orient: 'vertical',
          textStyle: {
            color: '#000',
            left: 30,
            overflow: 'break',
            rich: {
              a: {
                fontSize: 18,
                width: 120,
                padding: [0, 10]
              }
            }
          }
        },
        series: {
          height: '100%',
          width: '100%',
          radius: ['55%', '85%'],
          center: ['20%', '50%'],
          label: {
            position: 'center',
            formatter: '{a|总数/单}\n{b|2453}',
            rich: {
              a: {
                fontSize: 18,
                lineHeight: 20,
                fontWeight: 'bold',
                color: '#000'
              },
              b: {
                fontSize: 40,
                lineHeight: 50,
                fontWeight: 'bold',
                color: '#000',
                letterSpacing: 2
              }
            },
            top: '45%',
            left: '50%',
            textAlign: 'center'
          }
        },
        tooltip: {
          trigger: 'item'
          // appendToBody: true
        }
      },
      pieChartExtend1: {
            color: colorList,
            legend: {
              itemWidth: 50,
              itemHeight: 20,
              itemGap: 20,
              top: 'center',
              left: '40%',
              show: true,
              orient: 'vertical',
              type: 'scroll',
              textStyle: {
                color: '#000',
                left: 30,
                overflow: 'break',
                rich: {
                  a: {
                    fontSize: 14,
                    width: 100
                  },
                  b: {
                    fontSize: 14,
                    width: 60
                  }
                }
              }
            },
            series: {
              height: '100%',
              width: '100%',
              radius: ['40%', '70%'],
              center: ['20%', '50%'],
              label: {
                position: 'center',
                formatter: '{a|总数/单}\n{b|2453}',
                rich: {
                  a: {
                    fontSize: 18,
                    lineHeight: 20,
                    fontWeight: 'bold',
                    color: '#000'
                  },
                  b: {
                    fontSize: 20,
                    lineHeight: 20,
                    fontWeight: 'bold',
                    color: '#000',
                    letterSpacing: 2
                  }
                },
                top: '45%',
                left: '50%',
                textAlign: 'center'
          }
        },
        tooltip: {
          trigger: 'item'
        }
      },
      pieChartExtendOne: {},
      pieChartExtendTwo: {},
      chartData: {
        columns: [],
        rows: []
      },
      preDataList: [],
      loading: false,
      tableData: [],
      tableColumns: [],
      chartDataOne: {
        columns: [],
        rows: []
      },
      chartDataTwo: {
        columns: [],
        rows: []
      },
      currentId: '',
      newWorkingDays: ''
    }
  },
  mounted() {
    this.getChartsData()
    if (this.tabInfo.columnsType !== 'vertical') {
      this.tableColumns = this.tabInfo.columns
    }
    if (this.tabInfo.lineKey) {
      this.barChartExtend['series.1.type'] = 'line'
      this.barChartExtend['series.1.yAxisIndex'] = 1
    }
  },
  methods: {
    // vue的method中加入如下代码
    afterConfig(options) {
      options.series[0].smooth = false
      // 设置你的左侧y轴
      options.yAxis[0] = {
        type: 'value',
        name: '数量',
        axisLabel: {
          formatter: '{value}' // 设置你的左侧y轴的数值展现形式
        },
        axisLine: {
          show: true // 显示y轴
        },
        splitLine: { // 网格线
          show: true
        }
      }
      // 设置你的右侧y轴
      options.yAxis[1] = {
        type: 'value',
        name: '比例',
        axisLabel: {
          formatter: '{value}%' // 设置你的右侧y轴的数值展现形式
        },
        axisLine: {
          show: true // 显示y轴
        },
        splitLine: {
          show: false // 网格线
        }
      }
      // 当你的鼠标放在折线图上面的折线时候，数据显示的形式。比如要不要加百分号%，都可以通过下面的tooltip设置
      options.tooltip = {
        trigger: 'axis',
        formatter: function(params) {
          let res = '<div><p>时间：' + params[0].axisValueLabel + '</p></div>'
          for (var i = 0; i < params.length; i++) {
            if (params[i].seriesName === '产出比') {
              res += '<p>' + params[i].marker + params[i].seriesName + ' : ' + params[i].data + '%' + '</p>'
            } else {
              res += '<p>' + params[i].marker + params[i].seriesName + ' : ' + params[i].data + '</p>'
            }
          }
          return res
        }
      }
      return options
    },
    workHoursConfig(options) {
      options.series[0].smooth = false
      // 设置你的左侧y轴
      options.yAxis[0] = {
        type: 'value',
        name: '工时/h',
        axisLabel: {
          formatter: '{value}' // 设置你的左侧y轴的数值展现形式
        },
        axisLine: {
          show: true // 显示y轴
        },
        splitLine: { // 网格线
          show: true
        }
      }
      return options
    },
    async getChartsData(condition = {}) {
      const { dataUrl, dataCondition, isAppApi, chartDataKey, method = 'get'} = this.tabInfo
      const res = await api.getData(dataUrl, {
        ...dataCondition,
        ...condition
      }, isAppApi,method)
      if (!res) {
        this.chartData.columns = []
        this.chartData.rows = []
        this.loading = false
        return
      }
      switch (chartDataKey) {
        case 'quantity':
        case 'BUWorkRate':
        case 'workHoursType':
        case 'workOrderWorkHoursTypeRatio':
        case 'projectTaskNodeTypeWorkHoursRatio':
        case 'projectOtherWorkHoursRatio':
        case 'workHoursReasonWorkHoursRatio':
        case 'workOrderOtherWorkHoursRatio':
            this.pieSetData(res, chartDataKey)
          break
        case 'distribution':
        case 'supplierException':
        case 'monthRatio':
        case 'responseAnalyze':
        case 'workHoursRate':
        case 'workOrderWorkHoursType':
        case 'personalWork':
        case 'monthlyStatistics':
          this.barSetData(res, chartDataKey)
          break
        default:
          break
      }
      this.loading = false
    },
    pieSetData(res, chartDataKey) {
      this.chartData.rows = []
      this.pieChartExtend.series.avoidLabelOverlap = false
      this.pieChartExtend.series.radius = ['40%', '70%']
      this.pieChartExtend.series.itemStyle = {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      }
      const dataMap = {}
      switch (chartDataKey) {
        case 'quantity':
          this.pieChartExtend.series.label.formatter = `{a|总数/单}\n{b|${res.totalQuantity}}`
          res.rateList.forEach(({ name, quantity, abnormalTypeId, rate }) => {
            this.chartData.rows.push({ name, quantity })
            dataMap[name] = { name, quantity, abnormalTypeId, rate }
          })
          this.pieChartExtend.legend.formatter = (key) => {
            const { name, quantity, rate } = dataMap[key]
            const arr = [
              '{a|' + name + '}',
              '{a|' + rate + '%}',
              '{a|' + quantity + '单}'
            ]
            return arr.join('')
          }
          this.chartData.columns = ['name', 'quantity']
          break
        case 'BUWorkRate':
          this.pieChartExtend.series.label.formatter = `{a|总工时/h}\n{b|${res.totalWorkHours}}`
          res.rateList.forEach(({ name, workHours, rate }) => {
            this.chartData.rows.push({ name, workHours })
            dataMap[name] = { name, workHours, rate }
          })
          this.pieChartExtend.legend.formatter = (key) => {
            const { name, workHours, rate } = dataMap[key]
            const arr = [
              '{a|' + name + '}',
              '{a|' + rate + '%}',
              '{a|' + workHours + 'h}'
            ]
            return arr.join('')
          }
          this.chartData.columns = ['name', 'workHours']
          break
          case 'projectTaskNodeTypeWorkHoursRatio':
          case 'projectOtherWorkHoursRatio':
          case 'workHoursReasonWorkHoursRatio':
          case 'workOrderOtherWorkHoursRatio':
          case 'workHoursType':
          case 'workOrderWorkHoursTypeRatio':
          const totalWorkHours = chartDataKey === 'workHoursType' ? res.workHoursTypeRatio.totalWorkHours : chartDataKey === 'workOrderWorkHoursTypeRatio' ? res.workOrderWorkHoursTypeRatio.totalWorkHours : res.totalWorkHours
          this.pieChartExtend1.series.label.formatter = `{a|总工时/h}\n{b|${totalWorkHours}}`
          const rateList = chartDataKey === 'workHoursType' ? res.workHoursTypeRatio.rateList : chartDataKey === 'workOrderWorkHoursTypeRatio' ? res.workOrderWorkHoursTypeRatio.rateList : res.rateList
            rateList.forEach(({ name, workHours, rate }) => {
            this.chartData.rows.push({ name, workHours })
            dataMap[name] = { name, workHours, rate }
          })
          this.pieChartExtend1.legend.formatter = (key) => {
            const { name, workHours, rate } = dataMap[key]
            const arr = [
              '{a|' + name + '}',
              '{b|' + rate + '%}',
              '{b|' + workHours + 'h}'
            ]
            return arr.join('')
          }
          this.chartData.columns = ['name', 'workHours']
          break
      }
      this.loading = false
    },

    barSetData(res, chartDataKey) {
      this.chartData.columns = []
      this.chartData.rows = []
      if (res) {
        switch (chartDataKey) {
          case 'distribution':
          case 'supplierException':
            this.chartData.columns = ['name', '数量']
            this.chartData.rows = res.map(({ name, quantity }) => ({ name, 数量: quantity }))
            break
            case 'personalWork':
            this.chartData.columns = ['month', '工时']
            this.chartData.rows = res.map(({ month, projectTotalWorkHours }) => ({ month, 工时: projectTotalWorkHours }))
            break
          case 'monthRatio': {
            this.barChartExtend.legend.show = true
            this.chartData.columns = ['month']
            const dataMap = {}
            res.forEach((item) => {
              const { info } = item
              this.chartData.columns.push(info.name)
              item.totalList.forEach((totalItem) => {
                const month = totalItem.month
                const name = info.name
                const quantity = totalItem.quantity
                if (!dataMap[month]) {
                  dataMap[month] = {}
                }
                dataMap[month][name] = quantity
              })
            })
            this.chartData.rows = Object.keys(dataMap).map((month) => ({
              month,
              ...dataMap[month]
            }))
            break
          }
          case 'responseAnalyze': {
            this.barChartSettings = {
              stack: {
                'xxx': ['判定耗时', '处理耗时', '验收耗时']
              }
            }
            this.barChartExtend.legend.show = true
            this.chartData.columns = ['name', '判定耗时', '处理耗时', '验收耗时']
            res.forEach(({ name, judgeExpendTime, handleExpendTime, checkExpendTime }) => {
              this.chartData.rows.push({ name, '判定耗时': judgeExpendTime, '处理耗时': handleExpendTime, '验收耗时': checkExpendTime })
            })
            break
          }
          case 'workHoursRate': {
            this.barChartSettings = {
              stack: {
                'xxx': ['产出工时', '辅助工时', '浪费工时', '其他工时']
              },
              showLine: ['产出比'],
              axisSite: { right: ['产出比'] }
            }
            this.barChartExtend.legend.show = true
            this.chartData.columns = ['month', '产出工时', '辅助工时', '浪费工时', '其他工时', '产出比']
            res.forEach(({ month, outputWorkHours, assistWorkHours, wasteWorkHours, otherWorkHours, outputRate }) => {
              this.chartData.rows.push({ month, '产出工时': outputWorkHours, '辅助工时': assistWorkHours, '浪费工时': wasteWorkHours, '其他工时': otherWorkHours, '产出比': outputRate })
            })
            break
          }
          case 'workOrderWorkHoursType': {
            this.barChartExtend.legend.show = true
            this.chartData.columns = ['month', '生产', '停工', '返工', '其他']
            res.forEach(({ month, workOrderProductionWorkHours, workOrderStopWorkWorkHours, workOrderReworkWorkHours, workOrderOtherWorkHours }) => {
              this.chartData.rows.push({ month, '生产': workOrderProductionWorkHours, '停工': workOrderStopWorkWorkHours, '返工': workOrderReworkWorkHours, '其他': workOrderOtherWorkHours })
            })
            break
          }
          case 'monthlyStatistics': {
            this.barChartExtend.legend.show = true
            this.chartData.columns = ['month']
            const dataMap = {}

            res.forEach((item) => {
                const month = item.month
                const quantity = item.quantity
                const name = '数量'
                if (!this.chartData.columns.includes(name)) {
                    this.chartData.columns.push(name)
                }
                if (!dataMap[month]) {
                    dataMap[month] = {}
                }
                dataMap[month][name] = quantity
            })

            this.chartData.rows = Object.keys(dataMap).map((month) => ({
                month,
                ...dataMap[month]
            }))
            break
        }
          default:
            break
        }
      }
    },

    initBarAndLine(res) {
      const option = {
        color: colorList,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line'
          }
        },
        grid: {
          right: '20%'
        },
        legend: {},
        xAxis: [
          {
            type: 'category',
            data: res.map((t) => t.time)
          }
        ],
        yAxis: [
          {
            type: 'value',
            position: 'left',
            alignTicks: true,
            axisLine: {
              show: false
            }
          },
          {
            position: 'right',
            alignTicks: true,
            offset: 80,
            axisLine: {
              show: false
            },
            axisLabel: {
              formatter: '{value} %'
            }
          },
          {
            type: 'value',
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: false
            },
            axisLabel: {
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: '产出工时',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: res.map((t) => t.outputHours)
          },
          {
            name: '辅助工时',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: res.map((t) => t.assistHours)
          },
          {
            name: '协调工时',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: res.map((t) => t.coordinateHours)
          },
          {
            name: '休假',
            type: 'bar',
            stack: 'Ad',
            emphasis: {
              focus: 'series'
            },
            data: res.map((t) => t.holidayHours)
          },
          {
            name: 'UR',
            type: 'line',
            yAxisIndex: 2,
            data: res.map((t) => t.urRate)
          },
          {
            name: '产出比',
            type: 'line',
            yAxisIndex: 2,
            data: res.map((t) => t.outputRate)
          }
        ]
      }
      const chartDom = document.getElementById('bar-line')
      const myChart = echarts.init(chartDom)
      option && myChart.setOption(option)
    },

    setTableData(res) {
      if (this.tabInfo.columnsType === 'vertical') {
        if (res[0].totalList) {
          this.hasTotalList(res)
        } else {
          this.noTotalList(res)
        }
      } else {
        this.tableData = res || []
      }
    },
    hasTotalList(res) {
      const { chartXKey, chartLegendKey, chartDataKey } = this.tabInfo
      const tableData = []
      this.tableColumns = this.tabInfo.columns.filter(item => !item.vertical)
      const verticalColumns = res.map(item => item[chartLegendKey])
      res[0].totalList.forEach(item => {
        this.tableColumns.push({
          prop: item[chartXKey],
          label: item[chartXKey],
          sortable: true,
          hiddenSearch: true
        })
      })
      verticalColumns.forEach((vc, vcIndex) => {
        const obj = {}
        this.tableColumns.forEach((tc, index) => {
          if (index === 0) {
            obj[tc.prop] = vc
          } else {
            obj[tc.prop] = +res[vcIndex].totalList.find(item => item[chartXKey] === tc.prop)[chartDataKey]
          }
        })
        tableData.push(obj)
      })
      this.tableData = tableData
    },
    noTotalList(res) {
      const tableData = []
      this.tableColumns = this.tabInfo.columns.filter(item => !item.vertical)
      const verticalColumns = this.tabInfo.columns.filter(item => item.vertical)
      res.forEach(item => {
        this.tableColumns.push({
          prop: item.name,
          label: item.name,
          sortable: true,
          hiddenSearch: true
        })
      })
      verticalColumns.forEach(vc => {
        const obj = {}
        this.tableColumns.forEach((tc, index) => {
          if (index === 0) {
            obj[tc.prop] = vc.label
          } else {
            obj[tc.prop] = res[index - 1][vc.prop]
          }
        })
        tableData.push(obj)
      })
      this.tableData = tableData
    },
    searchConditionRef() {
      return this.$refs.searchCondition
    },
    exportToPdf() {
      this.getPdf(this.$refs.charts, this.tabInfo.name)
    },
    modifyWorkingDay(id) {
      this.currentId = id
    },
    returns() {
      this.currentId = ''
    },
    async confirm(row) {
      const res = await api.modifyWorkingDays({
        id: row.id,
        workingDays: +this.newWorkingDays,
        workingHours: row.workingHours
      })
      if (res) {
        this.currentId = ''
        const condition = this.tabInfo.dataCondition
        await this.getChartsData(condition)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#bar-line {
  height: 400px;
}
.el-icon-edit {
  margin-left: 15px;
}

.el-input-number {
  &.iNum {
    height: 28px;
    line-height: 28px;
  }
}
.el-icon-close {
  font-size: 18px;
  color: #ff0000;
  margin: 0 10px;
}

.el-icon-check {
  font-size: 18px;
  color: #07ef39;
}

.el-input-number--mini {
  width: 100px;
}
.v-charts-container {
  position: relative;
}
.dailyNums {
  font-size: 15px;
  color: #606266;
  margin-bottom: 5px;
}
.pie {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .pie-item {
    width: 40%;
  }
}
</style>
