var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-wrap" },
    [
      _c(
        "Split",
        {
          attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
          on: {
            "update:offset": function($event) {
              return _vm.$set(_vm.split, "offset", $event)
            }
          }
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "title" }, [_vm._v("组织架构")])
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入组织名称" },
                model: {
                  value: _vm.filterText,
                  callback: function($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText"
                }
              }),
              _c("el-tree", {
                ref: "tree",
                staticClass: "filter-tree",
                attrs: {
                  data: _vm.organizationData,
                  props: _vm.defaultProps,
                  "node-key": "id",
                  "default-expanded-keys": _vm.expandTreeData,
                  "expand-on-click-node": false,
                  "filter-node-method": _vm.filterNode
                },
                on: { "node-click": _vm.nodeClick }
              })
            ],
            1
          ),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "bottom common-tab" },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "m-radio-group",
                    on: { change: _vm.radioChange },
                    model: {
                      value: _vm.params.radio,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "radio", $$v)
                      },
                      expression: "params.radio"
                    }
                  },
                  [
                    _vm.permission("UserList")
                      ? _c("el-radio-button", { attrs: { label: "User" } }, [
                          _vm._v("用户")
                        ])
                      : _vm._e(),
                    _vm.permission("UserGroupList")
                      ? _c(
                          "el-radio-button",
                          { attrs: { label: "UserGroup" } },
                          [_vm._v("用户组")]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("FunctionHeader", {
                  attrs: {
                    tags: _vm.functionHeader.tags,
                    "search-title": _vm.searchTitle,
                    radio: _vm.params.radio,
                    "columns-key": _vm.columnsKey,
                    "export-name": _vm.functionHeader.exportName,
                    "export-params": _vm.functionHeader.exportParams,
                    "import-name": _vm.functionHeader.importName
                  },
                  on: {
                    search: function($event) {
                      return _vm.$refs.mTable.setTableData(true)
                    }
                  },
                  model: {
                    value: _vm.functionHeader.searchVal,
                    callback: function($$v) {
                      _vm.$set(_vm.functionHeader, "searchVal", $$v)
                    },
                    expression: "functionHeader.searchVal"
                  }
                }),
                _vm.permission(_vm.params.radio + "List")
                  ? _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        columns: _vm.getColumns,
                        height: _vm.height,
                        "columns-key": _vm.columnsKey,
                        "set-data-method": _vm.getTableData
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "organizationId",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(_vm._s(row.organizationName))
                              ])
                            }
                          },
                          {
                            key: "userType",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(_vm._s(["正式", "外包"][row.userType]))
                              ])
                            }
                          },
                          {
                            key: "isSystemUser",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      +row.isSystemUser === 1 ? "是" : "否"
                                    ) +
                                    " "
                                )
                              ])
                            }
                          },
                          {
                            key: "userIdList",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewUsers(row, 1)
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              )
                            }
                          },
                          {
                            key: "users",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewUsers(row)
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              )
                            }
                          },
                          {
                            key: "station",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewStation(row)
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              )
                            }
                          },
                          {
                            key: "setting",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewSetting(row)
                                    }
                                  }
                                },
                                [_vm._v("查看")]
                              )
                            }
                          },
                          {
                            key: "roleList",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                _vm._l(row.roleList, function(item) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: item.id,
                                      staticClass: "role-tags",
                                      attrs: { color: "#F6F7F9" }
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                1
                              )
                            }
                          },
                          {
                            key: "isEnabled",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                { staticClass: "isEnabled" },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      value: row.isEnabled === 1,
                                      "active-color": "#13ce66",
                                      "inactive-color": "#eee"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.switchStatus(
                                          row,
                                          _vm.params.radio
                                        )
                                      }
                                    }
                                  }),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(row.isEnabled ? "启用" : "停用")
                                    )
                                  ])
                                ],
                                1
                              )
                            }
                          },
                          {
                            key: "action",
                            fn: function(ref) {
                              var row = ref.row
                              return _vm.params.radio === "User"
                                ? _c(
                                    "div",
                                    {},
                                    [
                                      row.isSystem === 0
                                        ? [
                                            _vm.permission(
                                              _vm.params.radio + "SetStation"
                                            )
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setStation(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("设置工位")]
                                                )
                                              : _vm._e(),
                                            _vm.permission(
                                              _vm.params.radio + "SetStation"
                                            )
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission(
                                              _vm.params.radio + "SetEquipment"
                                            )
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setEquipment(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("设置设备")]
                                                )
                                              : _vm._e(),
                                            _vm.permission(
                                              _vm.params.radio + "SetEquipment"
                                            )
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _c(
                                              "el-dropdown",
                                              {
                                                on: {
                                                  command: function(command) {
                                                    _vm.more(row, command)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "el-dropdown-link"
                                                  },
                                                  [
                                                    _vm._v(" 更多 "),
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-arrow-down el-icon--right"
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "el-dropdown-menu",
                                                  {
                                                    staticClass:
                                                      "td-dropdown-menu",
                                                    attrs: { slot: "dropdown" },
                                                    slot: "dropdown"
                                                  },
                                                  [
                                                    _vm.permission(
                                                      _vm.params.radio +
                                                        "Modify"
                                                    )
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command: "modify"
                                                            }
                                                          },
                                                          [_vm._v("修改")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.permission(
                                                      _vm.params.radio + "Del"
                                                    )
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command: "del"
                                                            }
                                                          },
                                                          [_vm._v("删除")]
                                                        )
                                                      : _vm._e(),
                                                    _vm.isSystem
                                                      ? _c(
                                                          "el-dropdown-item",
                                                          {
                                                            attrs: {
                                                              command:
                                                                "resetPassword"
                                                            }
                                                          },
                                                          [_vm._v("重置密码")]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : [
                                            _vm.isSystem
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.resetPassword(
                                                          row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("重置密码")]
                                                )
                                              : _vm._e()
                                          ]
                                    ],
                                    2
                                  )
                                : _c(
                                    "div",
                                    {},
                                    [
                                      row.isSystem === 0
                                        ? [
                                            _vm.permission(
                                              _vm.params.radio + "Modify"
                                            )
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.modifyOrCopy(
                                                          row,
                                                          "modify"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("修改")]
                                                )
                                              : _vm._e(),
                                            _vm.permission(
                                              _vm.params.radio + "Modify"
                                            )
                                              ? _c("el-divider", {
                                                  attrs: {
                                                    direction: "vertical"
                                                  }
                                                })
                                              : _vm._e(),
                                            _vm.permission(
                                              _vm.params.radio + "Del"
                                            )
                                              ? _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.del(row)
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("删除")]
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        2
      ),
      _c(
        "MDialog",
        {
          attrs: {
            title: _vm.messages[_vm.currentType],
            width: "500px",
            customCancel: true
          },
          on: {
            onOk: _vm.submitForm,
            onCancel: function($event) {
              return _vm.cancel()
            }
          },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: {
                "label-position": "right",
                "form-data": _vm.formData,
                "form-list": _vm.formList[_vm.params.radio],
                "custom-rules": _vm.rules
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "picture" }, slot: "picture" },
                [
                  _c(
                    "el-upload",
                    _vm._b(
                      {
                        staticClass: "avatar-uploader",
                        attrs: {
                          accept: ".png,.jpg",
                          "before-upload": _vm.beforeAvatarUpload,
                          "on-success": _vm.handleAvatarSuccess
                        }
                      },
                      "el-upload",
                      _vm.options,
                      false
                    ),
                    [
                      _vm.cardPicture
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.cardPicture }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: "设置工位" },
          on: { onOk: _vm.submitStation },
          model: {
            value: _vm.stationVisible,
            callback: function($$v) {
              _vm.stationVisible = $$v
            },
            expression: "stationVisible"
          }
        },
        [
          _c("MElTransfer", {
            attrs: {
              data: _vm.formOptions.stationList,
              props: { key: "id", label: "name" },
              titles: ["源列表", "目的列表"],
              filterable: true,
              "filter-placeholder": "请输入名称"
            },
            model: {
              value: _vm.selectStationList,
              callback: function($$v) {
                _vm.selectStationList = $$v
              },
              expression: "selectStationList"
            }
          })
        ],
        1
      ),
      _c("TableDialog", {
        attrs: {
          title: "查看用户",
          show: _vm.userVisible,
          columns: _vm.viewTableColumns,
          "table-data": _vm.viewTableData
        },
        on: {
          "update:show": function($event) {
            _vm.userVisible = $event
          }
        }
      }),
      _c("TableDialog", {
        attrs: {
          title: "查看用户组",
          show: _vm.userGroupVisible,
          columns: _vm.viewTableColumns,
          "table-data": _vm.viewTableData
        },
        on: {
          "update:show": function($event) {
            _vm.userGroupVisible = $event
          }
        }
      }),
      _c("select-setting", {
        ref: "selectSetting",
        attrs: {
          visible: _vm.setsVisible,
          "select-materials": _vm.selectEquipment
        },
        on: {
          "update:visible": function($event) {
            _vm.setsVisible = $event
          },
          submitForm: _vm.submitSetting
        }
      }),
      _c(
        "MDialog",
        {
          attrs: { title: "查看设备" },
          on: {
            onOk: function($event) {
              _vm.setVisible = false
            }
          },
          model: {
            value: _vm.setVisible,
            callback: function($$v) {
              _vm.setVisible = $$v
            },
            expression: "setVisible"
          }
        },
        [
          _c("MTable", {
            ref: "mTables",
            attrs: {
              columns: _vm.columns.viewSetColumns,
              height: 320,
              data: _vm.equipmentList,
              "show-page": false
            },
            scopedSlots: _vm._u([
              {
                key: "drawing",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      row.drawing
                        ? _c("el-image", {
                            staticClass: "drawing-img",
                            attrs: {
                              src: row.drawing,
                              "preview-src-list": _vm.fixImg(row.drawing)
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.permission(_vm.params.radio + "SetEquipment") &&
                      _vm.isSystems === 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.resetEquipment(row)
                                }
                              }
                            },
                            [_vm._v("解绑")]
                          )
                        : _c(
                            "el-button",
                            { attrs: { type: "text", disabled: "" } },
                            [_vm._v("解绑")]
                          )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }