<template>
  <big-screen-container ref="container">
    <dv-loading v-if="loading" />
    <div class="top-container">
      <div class="title-wrapper">
        <span class="title">{{ name[$route.params.id] }}</span>
        <img src="https://oss.mubyte.cn/static/%E5%A4%B4%E9%83%A8%402x.png" alt="">
      </div>
      <div class="info">
        <div class="company-logo">
          <img src="https://oss.mubyte.cn/static/%E5%85%AC%E5%8F%B8%402x.png" alt="">
          <span>{{ companyInfo.name }}</span>
        </div>
        <div class="time-container">
          <div style="position: absolute;left: -50px;top: 18px">
            <img
              v-if="!['4', '8', '9'].includes($route.params.id)"
              src="https://oss.mubyte.cn/static/%E8%AE%BE%E7%BD%AE%402x.png"
              alt=""
              style="width: 30px"
              @click.stop="openAreaModal"
            >
            <img
              v-else-if="$route.params.id == '8'"
              src="https://oss.mubyte.cn/static/%E8%AE%BE%E7%BD%AE%402x.png"
              alt=""
              style="width: 30px"
              @click.stop="addEquipement"
            >
            <img
              v-else-if="$route.params.id == '9'"
              src="https://oss.mubyte.cn/static/%E8%AE%BE%E7%BD%AE%402x.png"
              alt=""
              style="width: 30px"
              @click.stop="openTimeSearch"
            >
          </div>
          <img src="https://oss.mubyte.cn/static/date-b.png" alt="">
          <div class="date-time">
            <div class="date">{{ currentDate }}（{{ currentWeek }}）</div>
            <div class="time">{{ currentTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <product v-if="$route.params.id === '1'" ref="board" />
    <work-order-progress v-if="$route.params.id === '2'" ref="board" />
    <quality v-if="$route.params.id === '3'" ref="board" />
    <equipment v-if="$route.params.id === '4'" ref="board" />
    <call v-if="$route.params.id === '5'" ref="board" />
    <quality-new v-if="$route.params.id === '6'" ref="board" />
    <quality-new-fsd v-if="$route.params.id === '66'" ref="board" />
    <warehouse v-if="$route.params.id === '7'" ref="board" />
    <equipment-new v-if="$route.params.id === '8'" ref="board" @equipList="equipDatas" />
    <inventory v-if="$route.params.id === '9'" ref="board" />
    <inventory2 v-if="$route.params.id === '423'" ref="board" />
    <work-order-progress-fsd v-if="$route.params.id === '10'" ref="board" />
    <work-order-progress-new v-if="$route.params.id === '12'" ref="board" />
    <monitor v-if="$route.params.id === '88'" ref="board" />
    <MDialog v-model="areaModalVisible" width="400px" :append-to-body="true" title="选择产线" @onOk="submitAreas">
      <el-select v-model="areaIds" multiple clearable placeholder="请选择" style="width: 95%;">
        <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
    </MDialog>
    <MDialog v-model="timeModalVisible" width="400px" :append-to-body="true" title="筛选" @onOk="submitTime">
      <el-select v-model="warehouseIds" multiple clearable placeholder="请选择" style="width: 95%;margin-bottom: 10px">
        <el-option v-for="item in warehouseList" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
      <el-date-picker
        v-model="createTime"
        type="daterange"
        range-separator="至"
        start-placeholder="创建开始时间"
        end-placeholder="创建结束时间"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        clearable
        style="width: 95%"
      />
    </MDialog>
    <MDialog
      v-model="equipementModalVisible"
      width="1000px"
      :append-to-body="true"
      title="选择展示设备"
      @onOk="submitEquip"
      @fullScreen="fullScreenChange"
    >
      <div>
        <div class="content">
          <Split :offset.sync="split.offset" :height="splitHeight">
            <template slot="left">
              <EquipmentClass ref="equipmentClass" :view="true" @nodeClick="setEquipmentClassId" @refrash="refrash" />
            </template>
            <template slot="right">
              <div class="center">
                <div class="flex-wrapper">

                  <div style="display:flex;width:100%;justify-content: space-between;">
                    <div style="padding: 20px 10px;">
                      <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">全选
                      </el-checkbox>
                    </div>
                    <FunctionHeader
                      v-model="functionHeader.searchVal"
                      search-title="请输入设备编码/名称"
                      :columns-setting="false"
                      @search="searchCard"
                    />
                  </div>

                  <el-checkbox-group v-model="checkEquip">
                    <div v-for="item in equipData" :key="item.id" class="checkCard" :style="{border:checkEquip.includes(item.id) ? '1px solid #607FFF' : '1px solid #DDE3EB'}" @click="showParame(item.id)">
                      <el-checkbox :label="item.id" @change="checked=>handleCheckedEquipsChange(checked, item.id)" />
                      <div style="display:flex">
                        <el-divider direction="vertical" />
                        <div style="word-wrap:break-word;white-space:normal">
                          <div class="title">{{ item.name }}</div>
                          <div class="description">{{ item.code }}</div>
                        </div>
                      </div>
                    </div>

                  </el-checkbox-group>

                </div>
              </div>
            </template>
          </Split>
          <div class="right chooseParame" :style="{ height: splitHeight + 'px' }">
            <div style="font-size: 16px;color: #393D60;margin-top: 10px;">设备参数显示</div>
            <div style="color:#858991;">最多选9个</div>

            <el-checkbox-group v-model="checkedEquipParame" :max="9">
              <div class="paramType">默认</div>
              <el-checkbox v-for="param in parameCustom" v-if="param.isFixed==1" :key="param.id" :label="param.id" style="padding-top:10px;" @change="checked=>changeChecked(checked, param.id)">{{ param.deviceParametersName }}
              </el-checkbox>

              <div class="paramType">自定义</div>
              <el-checkbox v-for="param in parameCustom" v-if="param.isFixed==0" :key="param.id" :label="param.id" style="padding-top:10px;" @change="checked=>changeChecked(checked, param.id)">{{ param.deviceParametersName }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </MDialog>
  </big-screen-container>
</template>

<script>
import Split from '@/components/Split/Split'
import FunctionHeader from '@/components/FunctionHeader/index'
import dayjs from 'dayjs'
import BigScreenContainer from '@/components/BigScreenContainer/BigScreenContainer'
import Product from './components/product'
import { getUUid } from '@/utils'
import processApi from '@/api/information/production/process'
import { Encrypt } from '@/utils/sercet'
import WorkOrderProgress from '@/views/statistics-board/board-detail/components/workOrderProgress'
import WorkOrderProgressFsd from '@/views/statistics-board/board-detail/components/workOrderProgress-fsd'
import WorkOrderProgressNew from '@/views/statistics-board/board-detail/components/workOrderProgress-new'
import Quality from './components/quality'
import Equipment from './components/equipment'
import EquipmentNew from './components/equipment-new'
import Call from './components/call'
import QualityNew from './components/quality-new'
import QualityNewFsd from './components/quality-new-fsd.vue'
import Warehouse from './components/warehouse'
import Inventory from './components/inventory_old.vue'
import Inventory2 from './components/inventory'
import Monitor from './components/monitor'
import EquipmentClass from '@/components/EquipmentClass/EquipmentClass'
import eapi from '@/api/statistics-board/equipment-board'
import { Stomp } from '@/utils/plug/stomp'
import warehouseApi from '@/api/information/warehouse/model'

export default {
  name: 'BoardDetail',
  components: { Monitor, Inventory,Inventory2, Warehouse, QualityNew, Call, WorkOrderProgress, WorkOrderProgressFsd, WorkOrderProgressNew, Product, Split, BigScreenContainer, Quality, Equipment, EquipmentNew, FunctionHeader, EquipmentClass,QualityNewFsd },
  data() {
    return {
      loading: false,
      currentDate: dayjs().format('YYYY-MM-DD'),
      currentTime: dayjs().format('HH:mm:ss'),
      weeks: ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      currentWeek: '',
      companyInfo: sessionStorage.getItem('companyInfo') ? JSON.parse(sessionStorage.getItem('companyInfo')) : {},
      areaModalVisible: false,
      areaList: [],
      areaIds: [],
      name: { 1: '生产看板', 2: '工单进度看板', 3: '质检看板', 4: '设备看板', 5: '制造异常看板', 6: '质检看板', 7: '仓库看板', 8: '设备实时状态看板', 9: '库存看板', 10: '工单进度看板', 11: '安全生产监控大屏',12:'工单进度看板',66:'质检看板',88:'安全生产监控大屏',423:'售后服务' },
      equipementModalVisible: false,
      functionHeader: { searchVal: '' },
      checkAll: false,
      checkEquip: [],
      checkedEquipParame: [],
      equipData: [],
      parameCustom: [],
      isIndeterminate: false,
      split: {
        offset: 20
      },
      fullScreen: false,
      equipmentClassId: '',
      checkedEquip: [],
      equipLists: [],
      newarr: [],
      initData: [],
      timeModalVisible: false,
      warehouseIds: [],
      createTime: [],
      warehouseList: []
    }
  },
  computed: {
    splitHeight() {
      return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 : this.$store.state.app.contentHeight - 289 - 45
    }
  },
  mounted() {
    this.fullAllScreen()
    this.getAreaList()
    this.equipList()
    this.getWarehouseList()
    this.$nextTick(this.$refs.board.getHeight)
    // window.onresize = this.$refs.board.getHeight
    setInterval(this.$refs.board.reloadData, 1000 * 60 * 60)
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.launchFullscreen)
  },
  methods: {
    equipDatas(val) {
      this.equipLists = val
    },
    fullAllScreen() {
      document.body.addEventListener('click', this.launchFullscreen)
      this.currentWeek = this.weeks[dayjs().day()]
      setInterval(() => {
        this.currentTime = dayjs().format('HH:mm:ss')
        this.currentDate = dayjs().format('YYYY-MM-DD')
        this.currentWeek = this.weeks[dayjs().day()]
        if (this.$refs.line) {
          this.$refs.line.resize()
        }
        if (this.$refs.histogram) {
          this.$refs.line.resize()
        }
      }, 1000)
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },

    launchFullscreen() {
      if (this.$refs.board != undefined) {
        this.$refs.board.getHeight()
      }
      const element = document.documentElement
      if (element.requestFullscreencreen) {
        element.requestFullScreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen()
      } else if (element.msRequestFullScreen) {
        element.msRequestFullScreen()
      }
    },
    openAreaModal() {
      this.areaModalVisible = true
    },
    openTimeSearch() {
      this.timeModalVisible = true
    },
    // 仓库
    async getWarehouseList() {
      const res = await warehouseApi.getRepositoryModelList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'type',
              fieldType: 'number',
              fieldValue: 0,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.warehouseList = res
      }
    },
    // 显示所有设备
    equipList() {
      eapi.getEquipmentAllParametersList().then(res => {
        this.equipData = res
        this.initData = res
        const arr = this.equipLists.map(item => item.id)
        this.checkEquip = arr
        this.newarr = arr.map(item => {
          return {
            id: item,
            isShow: 1
          }
        })
        const checkedCount = this.newarr.length
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.initData.length
        this.checkAll = checkedCount === this.initData.length
      })
    },
    // 选择展示设备
    async addEquipement() {
      this.checkedEquipParame = []
      this.parameCustom = []
      this.equipmentClassId = ''
      this.functionHeader.searchVal = ''
      await this.equipList()
      this.equipementModalVisible = true
    },
    searchCard() {
      eapi.getEquipmentAllParametersList({ equipmentClassId: this.equipmentClassId, name: this.functionHeader.searchVal }).then(res => {
        this.equipData = res
      })
    },

    setEquipmentClassId(val) {
      this.equipmentClassId = val
      eapi.getEquipmentAllParametersList({ equipmentClassId: val }).then(res => {
        this.equipData = res
      })
    },
    refrash() {
    },
    handleCheckAllChange(val) {
      this.isIndeterminate = false
      if (val) {
        this.checkEquip = this.equipData.map(item => item.id)
        this.newarr = this.checkEquip.map(item => {
          return {
            isShow: 1,
            id: item
          }
        })
      } else {
        this.checkEquip = []
        this.newarr = this.newarr.map(item => {
          return {
            isShow: 0,
            id: item.id
          }
        })
      }
    },
    // 设备选中事件
    handleCheckedEquipsChange(val, value) {
      const show = val ? 1 : 0
      const findIdx = this.newarr.findIndex(item => +item.id === value)
      const list = this.newarr.map(item => item.id)
      if (list.includes(value)) {
        this.newarr[findIdx].isShow = show
      } else {
        this.newarr.push({ isShow: show, id: value })
      }
    },
    async showParame(value) {
      const res = await eapi.getEquipmentAllParametersList()
      if (res) {
        this.initData = res
        this.checkAll = this.checkEquip.length === this.initData.length
        this.isIndeterminate = this.checkEquip.length > 0 && this.checkEquip.length < this.initData.length
        this.checkedEquipParame = []
        if (this.initData != []) {
          this.initData.map(items => {
            if (value === items.id) {
              this.parameCustom = this._.cloneDeep(items.mappingList)
              if (this.parameCustom != null) {
                this.parameCustom.map(itempar => {
                  if (itempar.isShow === 1) {
                  // 判断默认勾选状态
                    this.checkedEquipParame.push(itempar.id)
                  }
                })
              }
            }
          })
        }
      }
    },
    // 选择设备参数事件
    async changeChecked(val, value) {
      const isShow = val ? 1 : 0
      await eapi.addShowEquip({ isShow: isShow, id: value })
    },
    async getAreaList() {
      const res = await processApi.getAreaList({
        searchVal: Encrypt(JSON.stringify([
          {
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 2,
            operator: 'eq'
          },
          {
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }
        ]))
      })
      if (res) {
        this.areaList = res
      }
    },
    submitAreas(callback) {
      callback()
      this.$refs.board.reloadData(this.areaIds.join('^'))
      this.areaModalVisible = false
    },
    submitTime(callback) {
      callback()
      this.$refs.board.reloadData(this.warehouseIds.join('^'), this.createTime)
      this.timeModalVisible = false
    },
    async submitEquip(callback) {
      if (this.checkEquip.length < 1) {
        callback()
        return this.$message.error('请至少选择一条展示设备')
      }
      console.log('888', this.newarr)
      const res = await eapi.updateShowEquip(this.newarr)
      if (res) {
        this.equipementModalVisible = false
        this.$refs.board.reloadData()
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.top-container {
  color: #FFFFFF;

  .title-wrapper {
    text-align: center;
    position: relative;

    .title {
      font-size: 33px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      width: 80%;
      margin: 0 auto;
    }
  }

  .info {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5%;
    position: relative;
    top: -20px;

    .time-container {
      position: relative;
      display: inline-block;
      width: 16%;
      top: 2px;

      img {
        width: 100%;
      }

      .date-time {
        position: absolute;
        top: 55%;
        left: 60%;
        transform: translate(-50%, -50%);
        color: #00B7EF;

        .date {
          font-size: 18px;
          word-wrap: normal;
          white-space: nowrap;
        }

        .time {
          font-size: 27px;
          font-weight: 700;
        }
      }
    }

    .company-logo {
      display: inline-flex;
      align-items: center;

      img {
        width: 28px;
        margin-right: 10px;
      }

      span {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 700;
        color: #CED8FF;
        line-height: 70px;
        background: linear-gradient(180deg, #B8CCFF 0%, #4465EC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.status-board {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 44px;
}

.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .center {
    .checkCard {
      width: 30%;
      height:70px;
      border-radius: 4px;
      border: 1px solid #DDE3EB;
      margin-right: 0px;
      margin-left:3%;
      margin-top: 10px;
      padding: 6px 4px 6px 10px;
      background: #eceef2;
      display:inline-flex;
      cursor: pointer;
      ::v-deep .el-checkbox__input {
        top: 20px;
      }
      ::v-deep .el-checkbox__label {
        display:none;
      }
      ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label{
        color:#606266
      }
      .el-divider--vertical {
        background: #DDE3EB;
        height: 58px;
      }
    }

    .flex-wrapper {
      padding-right: 15px;
      padding-bottom: 15px;
      .title{
       overflow: hidden;
       text-overflow: ellipsis;
       display: -webkit-box;
       -webkit-line-clamp: 2;
       -webkit-box-orient: vertical;
       font-size: 14px;
      }
      .description {
        font-size: 12px;
        color: #9FA1B7;
        margin-top: 5px;
      }
    }
  }

  .right {
    width: 200px;
    background-color: #DDE3EB;
    padding-left: 15px;
    margin-left:10px;
    overflow:auto;
    .paramType {
      font-size: 14px;
      margin-top: 10px;
      color: #858991;
    }
    }
    .chooseParame{
     .el-checkbox:last-of-type{
      margin-right: 30px;
      margin-bottom: 10px;
    }
  }
}
</style>
