<template>
  <div class="plan-detail-wrapper">
    <el-button v-if="permission('plansModifyMaterial') && baseFormData.status !== 2" size="small" type="primary" style="margin-bottom: 10px;" @click="modifyMaterial()">编辑</el-button>
    <MTable v-if="activeName === 'plans'" ref="mTableM" :height="350" :set-data-method="planMaterials" :columns="noplans" :columns-setting="false">
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
    </MTable>
    <MDialog v-model="plansVisible" title="编辑计划用料" width="80%" @onOk="saveMaterials">
      <div style="margin-bottom: 10px;">
        <el-button size="small" type="primary" @click="slectMaterial">添加物料</el-button>
        <el-button size="small" type="primary" @click="delMaterial">删除</el-button>
      </div>
      <MTable
        ref="planTable"
        :show-page="false"
        :highlight-current-row="true"
        :height="heights"
        :columns="plans"
        :data="planMaterialsList"
        style="margin-bottom: 5px;"
        @selection-change="selectionChanges"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="procedureName" slot-scope="{ row, $index }" style="display: flex;align-items: center;justify-content: flex-start;">
          <span style="color: #f00;width: 20px;">*</span>
          <el-select
            v-model="row.procedureId"
            style="width:170px;"
            placeholder="请选择"
            filterable
            @change="selectProceduce(row, $index)"
          >
            <el-option
              v-for="item in procedureList"
              :key="item.procedureId"
              :label="item.procedureName"
              :value="item.procedureId"
            />
          </el-select>
        </div>
        <div slot="materialsQuantity" slot-scope="{ row, $index }">
          <el-input-number v-model="row.materialsQuantity" :min="0" controls-position="right" :step="1" style="width:98%" />
        </div>
      </MTable>
    </MDialog>
    <select-materials :visible.sync="showMaterials" @submitForm="submitMaterials" />
  </div>
</template>

<script>
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
import { noplans, plans } from './columns'
import planApi from '@/api/production/plans'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import api from '@/api/production/operation-task'

export default {
  name: 'PlanMaterials',
  components: { SelectMaterials },
  props: {
    baseFormData: {
      type: Object,
      default: {}
    },
    activeName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      plans,
      noplans,
      showMaterials: false,
      procedureList: [],
      plansVisible: false,
      selectionMaterialList: [],
      materialsList: [],
      planMaterialsList: []
    }
  },
  computed: {
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    heights() {
      return this.$store.state.app.contentHeight - 289 - 45 - 100
    }
  },
  watch: {
    // activeName(val) {
    //   if (val === 'plans') {
    //     this.$refs.mTableM.setTableData(true)
    //   }
    // },
  },
  methods: {
    selectProceduce(row, idx) {
      const list = this.planMaterialsList.filter((item, index) => index !== idx)
      const isHave = list.filter(item => item.materialsId == row.materialsId)
      if (isHave.length > 0) {
        if (isHave[0].procedureId == row.procedureId) {
          this.$message.error('该工序已有相同计划用料')
          row.procedureId = undefined
        }
      }
    },
    async getAllProceduce() {
      const search = [{
        id: getUUid(),
        fieldName: 'produceWorkOrderId',
        fieldType: 'number',
        fieldValue: this.baseFormData.id,
        operator: 'eq'
      }]
      const res = await api.getOrderTaskpageno({
        searchVal: Encrypt(JSON.stringify([...search]))
      })
      if (res) {
        if (res && res.length > 0) {
          this.procedureList = res.filter(item => +item.status !== 2)
          return false
        }
        this.procedureList = []
      }
    },
    async planMaterials(condition, callback) {
      const { page, search, order } = condition
      search.push({
        id: getUUid(),
        fieldName: 'producePlanId',
        fieldType: 'number',
        fieldValue: this.baseFormData.producePlanId,
        operator: 'eq'
      })
      const res = await planApi.getPlanStockingListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify([...search]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    async modifyMaterial() {
      await this.getAllProceduce()
      await this.getPlanMaterialsList()
      this.plansVisible = true
    },
    selectionChanges(data) {
      this.selectionMaterialList = data
      //  console.log('选中数据', this.selectionMaterialList)
    },
    submitMaterials(val) {
      if (val.length > 0) {
        const vals = val.map(item => {
          return {
            ...item,
            materialsName: item.name,
            materialsCode: item.code,
            materialsId: item.id,
            materialsQuantity: 0,
            planId: '',
            procedureId: ''
          }
        })
        this.planMaterialsList = [...vals, ...this.planMaterialsList]
      }
      this.showMaterials = false
    },
    slectMaterial() {
      this.showMaterials = true
    },
    async delMaterial() {
      if (this.selectionMaterialList.length > 0) {
        // 先过滤工序为空的数据
        const list = this.selectionMaterialList.filter(item => item.procedureId)
        const isEmpty = this.selectionMaterialList.some(item => item.procedureId === '')
        // 是否包含工序为空的数据
        if (!isEmpty) {
          const ids = this.selectionMaterialList.map(item => item.procedureId)
          const search = [{
            id: getUUid(),
            fieldName: 'produceWorkOrderId',
            fieldType: 'number',
            fieldValue: this.baseFormData.id,
            operator: 'eq'
          },
          {
            id: getUUid(),
            fieldName: 'procedureId',
            fieldType: 'number',
            fieldValue: ids.join('^'),
            operator: 'in'
          }]
          const res = await api.getOrderTaskpageno({
            searchVal: Encrypt(JSON.stringify([...search]))
          })
          if (res) {
            if (res && res.length > 0) {
              const arr = res.some(item => +item.status === 2)
              if (arr) {
                this.$message.error('已完成的投入工序不能删除')
                return false
              }
              this.planMaterialsList = this.getNoEmpty(list)
            }
          }
        } else {
          // 都是工序为空
          if (list.length === 0) {
            this.planMaterialsList = this.getEmpty(this.selectionMaterialList)
            return false
          }
          // 工序有空和非空-存在物料相同的情况
          // 先过滤空的数据
          this.planMaterialsList = this.getEmpty(this.selectionMaterialList)
          // 不为空
          const isEmptys = this.selectionMaterialList.filter(item => item.procedureId)
          this.planMaterialsList = this.getNoEmpty(isEmptys)
        }
      } else {
        this.$message.error('请选择要删除的数据')
      }
    },
    getEmpty(data) {
      const arrs = []
      const emptys = data.filter(item => item.procedureId === '')
      const materials = emptys.map(item => item.materialsId)
      this.planMaterialsList.map(item => {
        if (materials.includes(item.materialsId) && item.procedureId === '') {
          return
        } else {
          arrs.push(item)
        }
      })
      return arrs
    },
    getNoEmpty(list) {
      const materialsIds = list.map(item => item.materialsId)
      const procedureIds = list.map(item => item.procedureId)
      const datas = []
      this.planMaterialsList.map(item => {
        if (materialsIds.includes(item.materialsId) && procedureIds.includes(item.procedureId)) {
          return
        } else {
          datas.push(item)
        }
      })
      return datas
    },
    async saveMaterials(done) {
      if (this.planMaterialsList.length === 0) {
        this.$message.error('计划用料列表不能为空')
        done()
        return false
      }
      const val = this.planMaterialsList.every(item => item.procedureId)
      if (!val) {
        this.$message.error('请选择投入工序')
        done()
        return false
      }
      const obj = this.planMaterialsList.map(item => {
        return {
          id: item.planId,
          materialsName: item.materialsName,
          materialsCode: item.materialsCode,
          materialsId: item.materialsId,
          mainUnitName: item.mainUnitName,
          picture: item.picture,
          materialsQuantity: item.materialsQuantity,
          procedureId: item.procedureId,
          procedureName: item.procedureName,
          produceWorkOrderNumber: item.produceWorkOrderNumber ? item.produceWorkOrderNumber : this.baseFormData.produceWorkOrderNumber,
          workOrderQuantity: item.workOrderQuantity ? item.workOrderQuantity : this.baseFormData.quantity,
          processFlowId: item.processFlowId ? item.processFlowId : this.baseFormData.processFlowId,
          producePlanId: item.producePlanId ? item.producePlanId : this.baseFormData.producePlanId,
          produceWorkOrderNumber: item.produceWorkOrderNumber ? item.produceWorkOrderNumber : this.baseFormData.produceWorkOrderNumber,
          processFlowVersionId: item.processFlowVersionId ? item.processFlowVersionId : this.baseFormData.processFlowVersionId,
          workOrderId: item.workOrderId ? item.workOrderId : this.baseFormData.id
        }
      })

      const res = await planApi.modifyPlanStockingList(obj)
      if (res) {
        this.plansVisible = false
        this.$refs.mTableM.setTableData(true)
      }
      done()
    },
    async getPlanMaterialsList() {
      const search = [{
        id: getUUid(),
        fieldName: 'producePlanId',
        fieldType: 'number',
        fieldValue: this.baseFormData.producePlanId,
        operator: 'eq'
      }]
      const res = await planApi.getPlanStockingList({
        searchVal: Encrypt(JSON.stringify([...search]))
      })
      if (res) {
        this.planMaterialsList = res && res.length > 0 ? res.map(item => {
          return {
            ...item,
            planId: item.id
          }
        }) : []
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
