<template>
  <div class="container">
    <div class="table-box">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        :tags="functionHeader.tags"
        search-title="请输入物料编码/名称"
        style="padding: 10px 0 10px 0;"
        class="func-header"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :columns="columns"
        :height="height"
        :set-data-method="getTableData"
        @selection-change="data=>selectData = data"
      >
        <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
        <NewPageOpen slot="materialsCode" slot-scope="{ row }" :path="{ name: 'materialsDetail', query: {id: row.materialsId}}" :text="row.materialsCode" />
        <NewPageOpen slot="procedureCode" slot-scope="{ row }" :path="{ name: 'procedureDetail', query: {id: row.procedureId}}" :text="row.procedureCode" />
        <div slot="action" slot-scope="{ row }">
          <el-button
            v-if="permission('Edit')"
            type="text"
            @click="onHandle('edit',row)"
          >修改</el-button>
          <el-button v-if="permission('Del')" type="text" @click="onHandle('del',row)">删除</el-button>
        </div>
      </MTable>
      <!-- 修改 -->
      <PieceRateHandle ref="editPop" :handle-type="handleType" :edit-raw="editRaw" />
      <!--<Import-->
        <!--:visible.sync="showImport"-->
        <!--import-name="单件工资导入模版"-->
        <!--template-url="/static/salary-temp.xlsx"-->
        <!--@checkExcelData="checkExcelData"-->
      <!--/>-->
    </div>
  </div>
</template>
<script>
import { columns } from './data'
import api from '@/api/production/prodStatistic'
import FunctionHeader from '@/components/FunctionHeader'
import PieceRateHandle from './pieceRateHandle.vue'
import Import from '@/components/MCustom/Import/Import.vue'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
export default {
  name: 'PieceRate',
  components: { FunctionHeader, PieceRateHandle, Import, NewPageOpen },
  data() {
    return {
      api,
      showImport: false,
      handleType: 'add',
      editRaw: null,
      functionHeader: {
        searchVal: '',
        time: '',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            hidden: !this.permission('Add'),
            innerText: '新增',
            on: {
              click: () => this.onHandle('add')
            }
          },
          // {
          //   tagName: 'el-button',
          //   props: { type: 'primary', size: 'small' },
          //   hidden: !this.permission('Import'),
          //   innerText: '导入',
          //   on: {
          //     click: this.onImport
          //   }
          // }
        ]
      },
      columns,
      selectData: []
    }
  },

  computed: {
    height() {
      return this.$store.state.app.contentHeight - 220
    }
  },
  methods: {
    // 文件选择回调
    async checkExcelData(data) {
      //  console.log(data)
    },
    async downloadFile(url, name) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.download = name
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          // 在内存中移除URL 对象
          // window.URL.revokeObjectURL(href)
        })
    },
    onImport() {
      this.showImport = true
      // this.downloadFile('/static/salary-temp.xlsx', '单件工资导入模版')
    },
    round(number, precision) {
      return this._.round(number, precision)
    },
    onHandle(handleType, raw = {}) {
      this.handleType = handleType
      this.editRaw = raw
      this.$nextTick(() => { this.$refs.editPop.showPop() })
    },
    // 列表
    async getTableData(condition, callback) {
      const { page } = condition
      const res = await api.getPriceList({
        page: page.currentPage,
        size: page.pageSize,
        searchVal: this.functionHeader.searchVal
      })
      if (res) {
        this.showTable = res.total
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    }
  }

}
</script>

<style lang="scss" scoped>
.btn-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
}
::v-deep .custom-sel {
  div {
    min-width: 100%;
  }
}
.container {
  height: 100%;
  padding: 0 12px 0 0;
  .table-box {
    width: 100%;
    height: calc(100% - 300px);
    background: #fff;
    border-radius: 4px;
    margin-top: 12px;
    padding: 10px;
  }

  ::v-deep .function-header-wrap {
    .function-left {
      width: 100%;
      justify-content: space-between;
      padding-right: 45px;
    }
  }
}
</style>
