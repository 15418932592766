<template>
  <div class="inspection-plan-detail-wrapper">
    <div class="inspection-plan-detail-header flex-sbc">
      <div class="left" @click="$router.push({ name: 'spotPlanList' })">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">{{ messages[params.type + 'O'] }}</span>
      </div>
      <div class="right">
        <el-button v-if="!saveButton" type="primary" @click="inspectionDialogVisible = true">
          点检记录
        </el-button>
        <el-button v-if="permission('Modify')" type="primary" :loading="buttonLoading" @click="switchOrSubmit">
          {{ saveButton ? '保存' : '编辑' }}
        </el-button>
      </div>
    </div>

    <div class="inspection-plan-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <MFormBuilder v-if="saveButton" ref="formBuild" :form-list="baseFormList" :form-data="baseFormData">
            <!-- <div slot="checkType">
              <RSelect :default-value="baseFormData.checkType" :default-value-index="0" api-name="EquipmentSpotCheckClass" @change="selectName" />
            </div> -->
          </MFormBuilder>
          <el-row v-else class="view-list" :gutter="24">
            <!--            暂时这么处理距离问题-->
            <template v-for="(item, index) in baseFormList">
              <el-col
                v-if="item.label && (item.key !== 'cycleTime' || baseFormData.cycleTime)"
                :key="item.prop"
                :span="6"
                :style="{marginBottom: index !== 4 ? '20px' : 0 }"
              >
                <span class="bot-tis">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">点检项</div>
        <div class="card-bot">
          <el-button v-if="saveButton" type="primary" style="margin-bottom: 10px" @click="pushCheckItem">添加</el-button>
          <MTable
            ref="pointCheckTable"
            :height="$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 192"
            :columns="pointCheckItemView"
            :columns-setting="false"
            :show-page="false"
            :data="selectCheckCheckItem"
          >
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="needUpload" slot-scope="{ row }" class="is-enabled">
              <div :style="{background: row.needUpload ? '#24CAD7' : '#FFCC78'}" />
              <div>{{ row.needUpload ? '是' : '否' }}</div>
            </div>
            <div slot="sketchMap" slot-scope="{ row }">
              <el-image
                v-for="item in formatArr(row.sketchMap)"
                :key="item.id"
                style="padding-left: 10px"
                :src="item.url"
                class="table-row-img"
                :preview-src-list="[item.url]"
              />
            </div>
            <div slot="action" slot-scope="{ row, $index }">
              <el-button type="text" @click="delRow(row, $index)">删除</el-button>
            </div>
          </MTable>
        </div>
      </div>
    </div>
    <MDialog v-model="selectCheckItemVisible" title="添加点检项" @onOk="submitSelectCheckItem">
      <FunctionHeader
        v-model="functionHeaderS.searchVal"
        :search-title="'请输入检查项'"
        :tags="functionHeaderS.tags"
        :radio="params.radio"
        :hidden-search="true"
        :columns-setting="false"
        @search="$refs.pointCheckTableSelect.setTableData(true)"
      />
      <MTable
        ref="pointCheckTableSelect"
        :height="$store.state.app.contentHeight - 54 - 100 - 50 - 60 - 70 - 60"
        :columns="pointCheckTableSelectColumns"
        :columns-setting="false"
        :show-page="false"
        :set-data-method="getCheckItemTableData"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="needUpload" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.needUpload ? '#24CAD7' : '#FFCC78'}" />
          <div>{{ row.needUpload ? '是' : '否' }}</div>
        </div>
      </MTable>
    </MDialog>
    <MDialog v-model="dialogAddVisible" title="新增点检项" @onOk="submitDialogAddForm">
      <MFormBuilder ref="formBuildAdd" :form-list="formList.addInspectionItemFormList">
        <upload-pic slot="sketchMap" ref="uploadPic" :upload-file-list="uploadFileList" />
      </MFormBuilder>
    </MDialog>
    <MDialog v-model="inspectionDialogVisible" title="点检记录" width="80%" @onOk="views" @fullScreen="fullScreenChange">
      <spot-bills :full-screen="fullScreen" :plan-id="+params.id" />
    </MDialog>

  </div>
</template>

<script>
import RSelect from '@/components/RSelect/RSelect'
import formList from '../form-list'
import settingMinix from '@/views/equipment/project-setting/setting.minix'
import conventionMixin from '@/mixin/conventional-page'
import columns from '@/views/equipment/project-setting/columns'
import api from '@/api/equipment/spot-plan'
import FunctionHeader from '@/components/FunctionHeader/index'
import equipmentClassApi from '@/api/information/equipment-class'
import { getUUid, setTreeData } from '@/utils'
import UploadPic from '@/views/equipment/project-setting/upload-pic'
import { getFunctionList } from '../columns'
import { getFunctionList as getFunctionList2 } from '../form-list'
import { Encrypt } from '@/utils/sercet'
import { dateList } from '@/config/options.config'
import dayjs from 'dayjs'
import spotBills from './spot-bills'
import curdApi from '@/api/CURDSelect'

export default {
  name: 'SpotPlanDetail',
  components: { RSelect, spotBills, UploadPic, FunctionHeader },
  mixins: [conventionMixin, settingMinix],
  data() {
    return {
      api,
      fullScreen: false,
      inspectionDialogVisible: false,
      params: {},
      columns,
      saveButton: false,
      baseFormList: this._.cloneDeep(formList.formList),
      formList: this._.cloneDeep(formList),
      baseFormData: {},
      functionHeaderS: {
        searchVal: '',
        tags: [
          {
            key: 'equipmentClassIds',
            tagName: 'el-cascader',
            colSpan: 6,
            props: {
              placeholder: '请选择设备类',
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              },
              options: [],
              value: [],
              collapseTags: true,
              filterable: true
            },
            on: {
              change: (val) => { this.equipmentClassIdsChange(val) }
            }
          },
          // {

          //   tagName: 'el-button',
          //   props: { type: 'primary', size: 'small' },
          //   style: { width: 'auto', background: '#607FFF', marginLeft: '15px' },
          //   innerText: '新增',
          //   on: {
          //     click: this.dialogAdd
          //   }
          // }
        ]
      },
      settingGeyKey: 'CheckItemEquipmentClass',
      dialogAddKey: 'CheckItemEquipmentClass',
      buttonLoading: false,
      formOptions: {
        equipmentClassList: []
      },
      pointCheckTableSelectColumns: [
        {
          type: 'selection',
          fixed: 'left',
          width: 80,
          reserveSelection: true
        },
        ...columns.PointCheckItemView('point').slice(1, 5)
      ],
      equipmentClassId: '',
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '点检计划详情',
        addO: '新增点检计划',
        modifyO: '修改点检计划',
        copyO: '复制点检计划'
      },
      restaurants: []
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'spotPlanList') {
      this.$store.dispatch('tagsView/delCachedView', to)
    }
    next()
  },
  computed: {
    pointCheckItemView() {
      const newColumns = columns.PointCheckItemView('point')
      if (this.params.type !== 'view') {
        newColumns.push({
          slotName: 'action',
          label: '操作',
          fixed: 'right',
          sortable: false,
          hiddenSearch: true,
          width: 60
        })
      }
      return this.params.type === 'view' ? columns.PointCheckItemView('point') : newColumns
    }
  },
  beforeMount() {
    getFunctionList({
      equipmentClassChange: this.equipmentClassChange,
      querySearch: this.querySearch,
      selectName:this.selectName
    })
    getFunctionList2({
      cycleTypeChange: this.cycleTypeChange
    })
  },
  mounted() {
    this.params = this.$route.query
    this.saveButton = this.params.type !== 'view'
    if (this.params.type !== 'add') {
      this.getSpotPlanDetail(this.params.id)
    } else {
      this.getPlanCode()
      this.getEquipmentSpotCheckClassList()
    }
    this.getEquipmentClassList()
  },
  methods: {
    // 获取点检类别
    async getEquipmentSpotCheckClassList() {
      const res = await curdApi.getEquipmentSpotCheckClassList()
      if (res) {
        this.baseFormList[2].children[0].options = res.map(item => {
          return {
            ...item,
            name: item.spotCheckType,
            id: item.spotCheckType
          }
        })

        await this.$refs.formBuild.setForm({
          // checkTypeId: res.length > 0 ? res[0].id : '',
          checkType: res.length > 0 ? res[0].spotCheckType : '',
          equipmentClassId: res.length > 0 ? res[0].equipmentClassId : ''
        })
        this.selectCheckCheckItem = res.length > 0 ? res[0].checkItemList ? res[0].checkItemList: []: []
      }
    },
    async selectName(row) {
      const targetItem = this.baseFormList[2].children[0].options.find(item => item.id == row)
      if(targetItem){
        this.equipmentClassId = targetItem.equipmentClassId
        await this.$refs.formBuild.setForm({
        equipmentClassId: targetItem.equipmentClassId
      })
      this.selectCheckCheckItem = targetItem.checkItemList ? targetItem.checkItemList: []
      }else{
        this.equipmentClassId = ''
        await this.$refs.formBuild.setForm({
        equipmentClassId: ''
      })
      this.selectCheckCheckItem = []
      }
    },
    views(done) {
      done()
      this.inspectionDialogVisible = false
    },
    fullScreenChange(val) {
      this.fullScreen = val
    },
    // 获取设备类
    async getEquipmentClassList() {
      const res = await equipmentClassApi.getEquipmentClassList()
      if (res) {
        const equipmentClassList = setTreeData(res)
        this.functionHeaderS.tags[0].props.options = equipmentClassList
        this.baseFormList[3].props.options = equipmentClassList
        this.formOptions.equipmentClassList = res
      }
    },
    async getCheckItemTableData(condition, callback) {
      const { page, order } = condition
      const res = await this.api[`get${this.settingGeyKey}ListPage`]({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        equipmentClassIds: this.equipmentClassId
      })
      if (res) {
        callback({
          total: res.records[0] && res.records[0].checkItemList ? res.records[0].checkItemList.length : 0,
          content: res.records[0] && res.records[0].checkItemList ? res.records[0].checkItemList : []
        })
      }
      callback()
    },
    async querySearch(qs, cb) {
      const res = await api.getCheckTypeList()
      if (res) {
        this.restaurants = qs ? res.filter(this.createFilter(qs)).map(item => ({ value: item })) : res.map(item => ({ value: item }))
      }
      cb(this.restaurants)
    },
    async getPlanCode() {
      const res = await api.getPlanCode()
      if (res) {
        this.$refs.formBuild.setForm({
          planNumber: res
        })
      }
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    formatArr(arr) {
      try {
        return JSON.parse(arr)
      } catch (err) {
        return []
      }
    },
    equipmentClassIdsChange(val) {
      this.functionHeaderS.tags[0].props.value = val
      this.equipmentClassId = this._.last(val)
      this.$refs.pointCheckTableSelect.setTableData(true)
    },
    cycleTypeChange(val) {
      if (this.$refs.formBuild) {
        this.$refs.formBuild.setRuleRequired([{
          key: 'cycleTime',
          required: val !== 0
        }])
        this.$refs.formBuild.setForm({
          cycleType: val,
          cycleTime: 1
        })
      }
      this.baseFormList[8].hidden = !val
    },
    async equipmentClassChange(val) {
      const res = await this.api[`get${this.settingGeyKey}ListPage`]({
        page: 1,
        limit: 10,
        equipmentClassIds: this._.last(val)
      })
      if (res) {
        this.selectCheckCheckItem = res.records[0] && res.records[0].checkItemList ? res.records[0].checkItemList : []
      }
    },
    pushCheckItem() {
      const { form } = this.$refs.formBuild
      this.equipmentClassId = Array.isArray(form.equipmentClassId) ? this._.last(form.equipmentClassId) : form.equipmentClassId
      this.functionHeaderS.tags[0].props.value = form.equipmentClassId
      if (!this.equipmentClassId) return this.$message.info('请先选择设备类')
      this.selectCheckItemVisible = true
      this.$nextTick(() => {
        this.selectCheckCheckItem.forEach(item => {
          this.$refs.pointCheckTableSelect.tableRefs().toggleRowSelection(item, true)
        })
      })
    },
    async getSpotPlanDetail(id) {
      const res = await api.getSpotPlanList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'id',
            fieldType: 'number',
            fieldValue: id,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        this.baseFormData = res[0]
        this.selectCheckCheckItem = res[0].checkItemList || []
        this.cycleTypeChange(res[0].cycleType)
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.key]
      if (row.key === 'equipmentClassId') {
        return this.baseFormData.equipmentClassName
      }
      if (row.children && row.children[0] && row.children[0].options) {
        const current = row.children[0].options.find(item => item.id === result)
        return current && current.name
      }
      if (row.key === 'cycleTime' && this.baseFormData.cycleTime !== 0 && this.baseFormData.remindTime !== undefined) {
        return this.baseFormData.cycleTime + dateList('year')[this.baseFormData.cycleTimeType].name
      }
      if (row.key === 'remindTime' && this.baseFormData.remindTime !== undefined) {
        return this.baseFormData.remindTime + dateList('hour')[this.baseFormData.remindTimeType].name
      }
      return result
    },
    switchOrSubmit() {
      if (this.saveButton) {
        this.buttonLoading = true
        this.submitForm()
      } else {
        this.params.type = 'modify'
        this.saveButton = true
      }
    },
    submitDialogAddForm(callback) {
      const { form } = this.$refs.formBuildAdd
      this.$refs.formBuildAdd.formRefs().validate(async(valid) => {
        if (valid) {
          const newCheckItemList = [{
            id: form.id,
            name: form.name,
            standard: form.standard,
            needUpload: form.needUpload || 0,
            sketchMap: JSON.stringify(this.$refs.uploadPic.fileList)
          }]
          const res = await this.api.addCheckItemAndBindEquipmentClass({
            equipmentClassId: this.equipmentClassId,
            checkItemList: newCheckItemList.map(item => ({
              id: item.id,
              name: item.name,
              standard: item.standard,
              needUpload: item.needUpload || 0,
              sketchMap: item.sketchMap || ''
            }))
          })
          if (res) {
            this.$message.success('新增成功')
            this.dialogAddVisible = false
            this.$refs.pointCheckTableSelect.setTableData()
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    submitForm() {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          if (this.selectCheckCheckItem.length === 0) {
            this.buttonLoading = false
            return this.$message.info('请配置点检项')
          }
          let res
          if (['add', 'copy'].indexOf(this.params.type) !== -1) {
            res = await api.addSpotPlan(this.createSetData(form))
          }
          if (this.params.type === 'modify') {
            res = await api.modifySpotPlan(this.createResetData(form))
          }
          if (res) {
            this.saveButton = false
            this.$message.success(this.messages[this.params.type])
            if (this.params.type !== 'modify') {
              this.$router.push({ query: { type: 'view', id: res }})
              this.params = { type: 'view', id: res }
            }
            this.params.type = 'view'
            await this.getSpotPlanDetail(this.params.id)
          }
          this.buttonLoading = false
        } else {
          this.buttonLoading = false
        }
      })
    },
    createSetData(raw) {
      return {
        planNumber: raw.planNumber,
        planName: raw.planName,
        cycleType: raw.cycleType,
        checkType: raw.checkType,
        checkTypeId: raw.checkTypeId,
        equipmentClassId: Array.isArray(raw.equipmentClassId) ? this._.last(raw.equipmentClassId) : raw.equipmentClassId,
        planTime: dayjs(raw.planTime).format('YYYY-MM-DD'),
        remindTime: raw.remindTime,
        remindTimeType: raw.remindTimeType,
        cycleTime: raw.cycleType === 1 ? raw.cycleTime : 0,
        cycleTimeType: raw.cycleTimeType,
        isPass: raw.isPass,
        isExist: raw.isExist,
        isEnabled: raw.isEnabled,
        remark: raw.remark,
        checkItemList: this.selectCheckCheckItem.map(item => ({
          id: item.id,
          name: item.name,
          standard: item.standard,
          needUpload: item.needUpload,
          sketchMap: item.sketchMap
        }))
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.inspection-plan-detail-wrapper {
  height: 100%;

  .inspection-plan-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .inspection-plan-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
  ::v-deep {
    .el-col.el-col-6 >.el-form-item {
      min-height: 91px;
    }
    .el-col.el-col-12 >.el-form-item {
      min-height: 91px;
    }
    .date-number {
      width: 100%;
      display: flex;

      .el-form-item:first-child {
        width: 138% !important;

        input {
          border-radius: 4px 0 0 4px;
        }
      }

      .el-form-item:last-child {
        width: 62% !important;

        input {
          border-radius: 0 4px 4px 0;
          border-left: 0;
        }
      }
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
  ::v-deep {
    .table-row-img {
      >img {
        width: 50px;
      }
    }
  }
}
</style>
