<template>
  <MElTransfer
    v-model="selectIds"
    filter-placeholder="请输入用户"
    filterable
    :titles="['源列表', '目的列表']"
    :data="data"
    :props="{ key: 'key', label: 'name' }"
    :filter-method="filterMethod"
    :source-data-methods="sourceDataMethods"
    :set-label-r-method="setLabelRMethod"
  >
    <!--<div slot="leftBodyHeader" class="left-body-header">-->
      <!--<el-radio-group v-model="params.radio" class="m-radio-group">-->
        <!--<el-radio-button label="user">用户</el-radio-button>-->
      <!--</el-radio-group>-->
    <!--</div>-->
  </MElTransfer>
</template>

<script>
/** write by luomingzhi */
import MElTransfer from '@/components/EleComponents/transfer/main'
import userApi from '@/api/sets/user/user'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'

export default {
  name: 'SelectUser',
  components: { MElTransfer },
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    userOptions: {
      type: [Array, Boolean],
      default: false
    },
    userGroupOptions: {
      type: [Array, Boolean],
      default: false
    },
    isAssign: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      params: {
        radio: 'user'
      },
      transferProps: {
        user: { key: 'userName', label: 'name' },
        userGroup: { key: 'id', label: 'name' }
      },
      selectIds: [],
      data: [],
      formOptions: {
        userOptions: [],
        userGroupOptions: []
      },
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      }
    }
  },
  watch: {
    value(val) {
      console.log('val=', val)
      this.selectIds = val
    },
    selectIds(ids) {
      this.$emit('input', ids)
    }
  },
  mounted() {
    this.selectIds = this.value
    this.getUserAndUserGroupList()
  },
  methods: {
    // 供父组件调用获取ids
    getFormatData() {
      const userIds = this.formOptions.userOptions
        .filter(item => this.selectIds.indexOf(item.key) !== -1)
        .map(item => item.id)
      const userGroupIds = this.formOptions.userGroupOptions
        .filter(item => this.selectIds.indexOf(item.key) !== -1)
        .map(item => item.id)
      return {
        user: userIds,
        userGroup: userGroupIds
      }
    },
    async getUserAndUserGroupList() {
      let user = this.userOptions ? this.userOptions : []
      let userGroup = this.userGroupOptions ? this.userGroupOptions : []
      if (!this.userOptions && this.isAssign) {
        user = await userApi.getUserList(this.defaultSearch)
      }
      if (!this.userGroupOptions && this.isAssign) {
        userGroup = await userApi.getUserGroupList(this.defaultSearch)
      }
      this.formOptions.userOptions = user
      this.formOptions.userGroupOptions = userGroup
      this.data = [...user, ...userGroup]
    },
    filterMethod(query, item, title) {
      const flag = this.params.radio === 'user' ? item.userName : !item.userName
      return (flag || title === '目的列表') && item.name.toLowerCase().indexOf(query.toLowerCase()) > -1
    },
    sourceDataMethods(value) {
      return this.data.filter(item => value.indexOf(item.key) !== -1)
    },
    setLabelRMethod(item) {
      return item.key
    }
  }
}
</script>

<style scoped lang="scss">
.left-body-header {
  padding: 8px 0;
  border-bottom: 1px solid #DDE3EB;
  display: inline-flex;
  justify-content: center;
  width: 100%;

  ::v-deep .el-radio-button {
    min-width: 129px;
  }

  .m-radio-group.el-radio-group {
    height: 32px;
  }

  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    width: 119px;
    padding: 4px 15px !important;
  }
}
::v-deep .el-transfer-panel .el-transfer-panel__body {
  height: 398px;
}
::v-deep .el-transfer-panel .el-transfer-panel__list.is-filterable {
  height: 351px;
}
</style>
