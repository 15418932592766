<template>
  <div class="scrap-wrap">
    <div class="top">
      <SearchForm ref="searchForm" :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" />
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入工位编码/名称"
        :tags="!exportSelectionIds.length ? functionHeader.tags : []"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :import-name="!exportSelectionIds.length ? functionHeader.importName :''"
        :show-export="!exportSelectionIds.length ? true : false"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all" v-show="exportSelectionIds.length">
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable ref="mTable" :columns="getColumns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <el-button slot="userAndUserGroup" slot-scope="{ row }" type="text" @click="viewUserAndUserGroups(row)">查看</el-button>
        <el-button v-if="row.procedureIdList" slot="procedureList" slot-scope="{ row }" type="text" @click="viewProcedureList(row)">查看</el-button>
        <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
          <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
        </div>
        <div slot="lockStatus" slot-scope="{ row }">
          <div>{{ row.lockStatus ? '已占用' : '未占用' }}</div>
        </div>
        <div slot="action" slot-scope="{ row }">
          <el-button v-if="permission('SetUser')" type="text" @click="setUserAndUserGroup(row)">设置人员</el-button>
          <el-divider v-if="permission('SetUser')" direction="vertical" />
          <el-button v-if="permission('SetProcedure')" type="text" @click="setProcedures(row)">设置工序</el-button>
          <el-divider v-if="permission('SetProcedure')" direction="vertical" />
          <el-dropdown @command="(command) => { more(row, command) }">
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
              <el-dropdown-item v-if="permission('Status')" command="status">{{ row.isEnabled ? '停用' : '启用' }}</el-dropdown-item>
              <el-dropdown-item v-if="permission('Modify')" command="modify">修改</el-dropdown-item>
              <el-dropdown-item v-if="permission('Del')" command="del">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </MTable>

      <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
      </MDialog>

      <MDialog v-model="setPersonVisible" title="选择包含用户" @onOk="submitUserAndUserGroup">
        <SelectUserAndUserGroup ref="userAndUserGroup" v-model="transferSelectIds" />
      </MDialog>

      <MDialog v-model="setProcedureVisible" width="900px" title="选择工序" @onOk="submitProcedure">
        <el-transfer
          v-model="procedureSelectIds"
          filterable
          :filter-method="filterMethod"
          :titles="['源列表', '目的列表']"
          filter-placeholder="请输入..."
          :data="transferData"
          :props="defaultProps"
        />
      </MDialog>

      <TableDialog title="查看人员" :show.sync="userVisible" :columns="userColumns" :table-data="userAndGroupList" />
      <TableDialog title="查看工序" :show.sync="procedureVisible" :columns="procedureColumns" :table-data="procedureList" />
    </div>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { searchFormList, formList } from './form-list'
import { columns, userColumns, procedureColumns } from './columns'
import { getUUid } from '@/utils'
import areaApi from '@/api/information/production/factory-mode'
import procedureApi from '@/api/information/production/procedure'
import userApi from '@/api/sets/user/user'
import equipmentApi from '@/api/equipment/equipment'
import equipmentClassApi from '@/api/information/equipment-class'
import api from '@/api/information/production/station'
import { Encrypt } from '@/utils/sercet'
import conventionMixin from '@/mixin/conventional-page'
import batchExportFront from '@/mixin/batch-export-front'
import SelectUserAndUserGroup from '@/components/SelectUserAndUserGroup/SelectUserAndUserGroup'
import TableDialog from '@/components/TableDialog/TableDialog'

export default {
  name: 'Station',
  components: { TableDialog, SelectUserAndUserGroup, SearchForm, FunctionHeader },
  mixins: [conventionMixin, batchExportFront],
  data() {
    return {
      api,
      params: {
        radio: 'Station'
      },
      transferData: [],
      defaultProps: {
        key: 'id',
        label: 'name'
      },
      columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addCus
            }
          }
        ],
        exportName: 'exportStation',
        exportParams: {},
        importName: 'station'
      },
      formList,
      formData: {},
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      currentType: 'add',
      visible: false,
      formOptions: {
        equipmentList: [],
        userList: [],
        userGroupList: [],
        areaList: []
      },
      setPersonVisible: false,
      transferSelectIds: [],
      userVisible: false,
      userColumns,
      userAndGroupList: [],
      searchKeyword: true,
      procedureVisible: false,
      procedureColumns,
      procedureList: [],
      procedureSelectIds: [],
      setProcedureVisible: false,
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 20
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.exportSelectionList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getColumns() {
      if (this.memberCode != 'mom') {
        return this.columns
      } else {
        return this.columns.filter(item => {
          return item.prop !== 'userAndUserGroup' && item.prop !== 'procedureList'
        })
      }
    }
  },
  mounted() {
    this.getSiteWarehouseList()
    this.getUserAndUserGroupList()
    // this.getEquipmentList()
  },
  methods: {
    resetSelectData() {
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    async getSiteWarehouseList() {
      const res = await areaApi.getAreaList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }, {
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 2,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        this.searchFormList[0].children[0].options = res
        this.formList[2].children[0].options = res
        this.formOptions.areaList = res
      }
    },
    async getUserAndUserGroupList() {
      const user = await userApi.getUserList(this.defaultSearch)
      const userGroup = await userApi.getUserGroupList(this.defaultSearch)
      if (user && userGroup) {
        this.formOptions.userList = user
        this.formOptions.userGroupList = userGroup
        this.searchFormList[2].props.options = [{
          key: 'users',
          name: '用户',
          children: user
        }, {
          key: 'userGroups',
          name: '用户组',
          children: userGroup
        }]
      }
    },
    async getEquipmentList() {
      const equipment = await equipmentApi.getEquipmentList(this.defaultSearch)
      const equipmentClass = await equipmentClassApi.getEquipmentClassList(this.defaultSearch)
      if (equipment && equipmentClass) {
        const tree = equipmentClass.map(item => ({
          ...item,
          children: equipment.filter(e => e.equipmentClassId === item.id)
        }))
        this.searchFormList[3].props.options = tree
        this.formList[3].props.options = tree
        this.formOptions.equipmentList = equipment
      }
    },
    addCus() {
      this.add()
      this.createStationCode()
    },
    filterMethod(query, item) {
      return item.name.indexOf(query) > -1
    },
    async createStationCode() {
      if (sessionStorage.getItem('memberCode') !== 'mubai') return false
      const res = await api.createCode({
        count: 1
      })
      if (res) {
        this.$refs.formBuild.setForm({
          code: res[0]
        })
      }
    },
    searchTable() {
      const form = this._.cloneDeep(this.$refs.searchForm.form)
      this.searchFormatData = []
      Object.keys(form).forEach(item => {
        if (form[item] || form[item] === 0) {
          if (item === 'userId' || item === 'equipmentId') {
            this.searchFormatData.push({
              id: getUUid(),
              fieldName: item === 'userId' ? form[item][0] : item,
              fieldType: item === 'equipmentId' ? 'number' : 'string',
              fieldValue: item === 'userId' ? `,${form[item].pop().split('_').pop()},` : form[item].pop(),
              operator: item === 'equipmentId' ? 'eq' : 'like'
            })
          } else {
            this.searchFormatData.push({
              id: getUUid(),
              fieldName: item,
              fieldType: typeof form[item],
              fieldValue: form[item],
              operator: typeof form[item] === 'number' ? 'eq' : 'like'
            })
          }
        }
      })
      this.$refs.mTable.setTableData(true)
    },
    // 更多操作
    more(row, command) {
      const functions = {
        modify: () => {
          this.modifyOrCopy(row, command)
          if (command === 'copy') {
            this.createStationCode()
          }
        },
        del: () => {
          this.del(row)
        },
        status: () => {
          this.switchStatus(row, this.params.radio)
        }
      }
      functions[command]()
    },
    async setProcedures(row) {
      this.formData = row
      if (this.procedureList.length) {
        this.procedureSelectIds = row.procedureIdList || []
        this.setProcedureVisible = true
        return false
      }
      const res = await procedureApi.getProcedureList()
      if (res) {
        this.procedureSelectIds = row.procedureIdList || []
        this.transferData = res
        this.setProcedureVisible = true
      }
    },
    async setUserAndUserGroup(row) {
      const res = await api.getStationDetail(row.id)
      if (res) {
        this.formData = row
        const user = res.userList ? res.userList.map(item => item.key) : []
        const userGroup = res.groupList ? res.groupList.map(item => item.key) : []
        this.transferSelectIds = [...userGroup, ...user]
        this.setPersonVisible = true
      }
    },
    async viewUserAndUserGroups(row) {
      const res = await api.getStationDetail(row.id)
      if (res) {
        const user = res.userList || []
        const userGroup = res.groupList || []
        this.userAndGroupList = [...userGroup, ...user]
        if (this.userAndGroupList.length) {
          this.userVisible = true
        } else {
          this.$message.info('暂未设置人员！')
        }
      }
    },
    async viewProcedureList(row) {
      const res = await api.getProcedureListByStationId(row.id)
      if (res) {
        this.procedureList = res
        this.procedureVisible = true
      }
    },
    async submitProcedure(callback) {
      const res = await api.setProcedure({
        id: this.formData.id,
        code: this.formData.code,
        procedureIdList: this.procedureSelectIds
      })
      if (res) {
        callback()
        this.setProcedureVisible = false
        this.$refs.mTable.setTableData()
        this.$message.success('设置成功')
      } else {
        callback()
      }
    },
    async submitUserAndUserGroup(callback) {
      const formatIds = this.$refs.userAndUserGroup.getFormatData()
      const res = await api.modifyStation({
        id: this.formData.id,
        code: this.formData.code,
        groupIdList: formatIds.userGroup,
        userIdList: formatIds.user
      })
      if (res) {
        callback()
        this.setPersonVisible = false
        this.$message.success('设置成功')
      } else {
        callback()
      }
    },
    createSetData(raw) {
      const area = this.formOptions.areaList.find(item => item.id === raw.areaId)
      const equipmentId = Array.isArray(raw.equipmentId) ? raw.equipmentId[1] : raw.equipmentId
      // const equipment = this.formOptions.equipmentList.find(item => item.id === equipmentId)
      return {
        code: raw.code,
        name: raw.name,
        areaId: raw.areaId,
        areaCode: area.code,
        areaName: area.name,
        // equipmentId: (equipment && equipment.id) || 0,
        // equipmentCode: equipment && equipment.code,
        // equipmentName: equipment && equipment.name,
        isEnabled: raw.isEnabled,
        remark: raw.remark
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;

  .bottom {
    padding: 10px;
  }
}
::v-deep .el-transfer-panel {
  width: 290px;
}
::v-deep .el-transfer-panel__body {
  height: 300px;
}
::v-deep .el-transfer-panel__list {
  height: 358px;
}
::v-deep .el-transfer-panel__list.is-filterable {
  height: 253px;
}
</style>
