<template>
  <div v-loading="loading" class="custom-table-container">
    <column-setting v-if="!tabInfo.searches" ref="columnSetting" :columns="tabInfo.columns" @updateColumns="updateColumns" />
    <chart-search-condition v-else ref="searchCondition" :tab-info="tabInfo" @conditionChange="searchConditionChange" />
    <el-button v-if="tabInfo.isExport" style="margin-bottom:10px" size="small" type="primary" @click="exportReport(tabInfo)">导出</el-button>
    <MTable
      v-if="showTable"
      ref="mTable"
      :columns="getBaseColumns"
      :max-height="getBaseColumns.length > 0 && getBaseColumns[0].label === '适用产线' ? 680 : getBaseColumns.length > 0 && getBaseColumns[0].label === '班组/用户组' ? 550 : 330"
      :show-page="!!tabInfo.page"
      :custom-page-size="10"
      :set-data-method="getTableData"
    >
      <template v-for="item in hasHeaderSlot">
        <date-range
          v-if="item.filterType === 'dateRange'"
          :slot="item.headerSlotName"
          :key="item.prop"
          class="slot-header"
          @dateRangeChange="dateRangeChange"
        />
        <cascader
          v-else-if="item.filterType === 'cascader'"
          :slot="item.headerSlotName"
          :key="item.prop"
          :tab-info="item"
          class="slot-header"
          @conditionChange="cascaderChange"
        />
        <el-button
          v-else
          :slot="item.headerSlotName"
          :key="item.prop"
          v-popover:popover
          class="slot-header"
          type="text"
          style="width: auto"
          @click="openFilterModal(item)"
        >
          <img src="@/assets/statistics-board/高级筛选@2x.png" width="14">
        </el-button>
      </template>

      <template v-for="item in hasSlot" :slot="item.slotName" slot-scope="{ row, column }">
        <table-slot-list
          v-if="item.slotName"
          :key="item.slotName"
          :slot-name="item.slotName"
          :row="row"
          :to-day="toDay"
          :column="column"
          :config="item"
        />
      </template>
    </MTable>
    <!--    穿梭框筛选-->
    <template v-if="filterType === 'transfer'">
      <MDialog v-model="show" width="900px" title="筛选" @onOk="submitTransfer">
        <el-transfer
          v-model="selectObj[currentKey]"
          filterable
          :filter-method="filterMethod"
          :titles="['源列表', '目的列表']"
          filter-placeholder="请输入..."
          :data="transferData"
          :render-content="(h, option) => renderFunc(h, option)"
          :props="defaultProps"
        />
      </MDialog>
    </template>
    <!-- 物料筛选-->
    <template v-if="filterType === 'materials'">
      <select-materials
        :visible.sync="show"
        :select-materials="selectObj[currentKey] || []"
        @submitForm="submitMaterials"
      />
    </template>
  </div>
</template>

<script>

import HeaderFilter from './header-filter'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials'
import ColumnSetting from '@/views/statistic-statement/statement-manage/components/column-setting/column-setting'
import api from '@/api/statistic-statement/index'
import TableSlotList from './table-slot-list'
import DateRange from './date-range.vue'
import cascader from './cascader'
import ChartSearchCondition from '@/views/statistic-statement/statement-manage/components/charts/chart-search-condition.vue'
import dayjs from 'dayjs'
export default {
  name: 'CustomTable',
  components: { ChartSearchCondition, TableSlotList, ColumnSetting, SelectMaterials, DateRange, cascader },
  mixins: [HeaderFilter],
  props: {
    tabInfo: {
      required: true,
      type: Object,
      default: () => ({})
    },
    toDay: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasHeaderSlot: [],
      selectObj: {},
      loading: false,
      newColumns: [],
      showTable: true,
      hasSlot: [],
      dateRange: [],
      searchCondition: {}
    }
  },
  computed: {
  mode() {
      return this.$store.state.app.mode
    },
    getBaseColumns(){
      if (this.mode) {
      return this.newColumns.map(item => {
        const newLabel = item.label ? item.label.replace(/项目/g, '订单') :''
        return { ...item, label: newLabel }
      })
    } else {
      return this.newColumns
    }
  }
  },
  beforeMount() {
    this.hasHeaderSlot = this.tabInfo.columns.filter(item => item.headerSlotName)
    this.hasSlot = this.tabInfo.columns.filter(item => item.slotName)
    if (this.tabInfo.searches) {
      this.newColumns = this._.clone(this.tabInfo.columns)
    }
  },
  methods: {
    async getTableData(condition, callback) {
      const { preDataUrl, preDataCondition, preDataKey, dataCondition, size, dataUrl, method = 'get' } = this.tabInfo
      if (!dataUrl) {
        return callback()
      }
      const { search = [], page, order: sort } = condition
      const formatSearch = {}
      search.forEach(item => {
        formatSearch[item.fieldName] = item.fieldValue
      })
      let preData
      const newCondition = {}
      if (preDataUrl && !Object.keys(this.searchCondition).length) {
        preData = await api.getTableData(preDataUrl, preDataCondition, method)
        if (preData) {
          const data = Array.isArray(preData) ? preData : preData.records
          newCondition[preDataKey] = data.map(item => item.id).join('^')
        }
      }
      if (!preData || newCondition[preDataKey]) {
        let res = await api.getTableData(dataUrl, { page: page.currentPage, limit: size || page.pageSize || 10, sort, ...dataCondition, ...this.searchCondition, ...newCondition, ...formatSearch }, method)
        if (res) {
          const length = Array.isArray(res) ? res.length : res.total
          if (!Array.isArray(res)) res = res.records
          if (res[0] && res[0].maintenanceItemInfoList) {
            const columns = []
            res.forEach(item => {
              item.maintenanceItemInfoList.forEach(col => {
                if (!columns.find(a => a.prop === col.name)) {
                  columns.push({ prop: col.name, label: col.name, hiddenSearch: true, sortable: true })
                }
                Object.assign(item, {
                  [col.name]: col.count
                })
              })
            })
            this.newColumns = [...this.tabInfo.columns, ...columns]
          }
          if (res[0] && res[0].totalList) {
            const columns = []
            const newRes = []
            res.forEach(col => {
              columns.push({ prop: col.info.name, label: col.info.name, hiddenSearch: true, sortable: true })
            })
            res[0].totalList.forEach((item, tIndex) => {
              const obj = { month: item.month }
              columns.forEach((col, index) => {
                obj[col.prop] = res[index].totalList[tIndex].quantity
              })
              newRes.push(obj)
            })
            columns.unshift({
              prop: 'month',
              label: '月份',
              sortable: false,
              hiddenSearch: true
            })
            this.newColumns = columns
            res = newRes
          }
          callback({
            total: length,
            content: res
          })
        }
      }
      callback()
    },
    updateColumns(columns) {
      this.newColumns = this._.clone(columns)
      this.reloadTable()
    },
    //   导出报表
    async exportReport(tableInfo){
       const res = await api.exportDatas(tableInfo.exportUrl,this.searchCondition)
        if (res && res.data.byteLength) {
          const urlP = window.URL.createObjectURL(new Blob([res.data], { type: 'application/octet-stream;charset=ISO8859-1' }))
          const fileName = tableInfo.name+`${dayjs().format('YYYYMMDDHHmmss')}.xls`
          this.downloadFile(urlP,fileName)
        } else {
          this.$message.error('导出失败，内容为空')
        }
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    renderFunc(h, option) {
      const children = []
      let title = ''
      if (Array.isArray(this.defaultProps.label)) {
        for (let i = 0; i < this.defaultProps.label.length; i++) {
          const item = this.defaultProps.label[i]
          if (i !== this.defaultProps.label.length - 1) {
            title += option[item] + ' - '
            children.push(h('span', [h('span', option[item]), h('span', ' - ')]))
          } else {
            title += option[item]
            children.push(h('span', option[item]))
          }
        }
        return h('div', {
          attrs: { title }
        }, children)
      } else {
        return h('span', {
          attrs: { title: option[this.defaultProps.label] }
        }, option[this.defaultProps.label])
      }
    },
    dateRangeChange(dateRange) {
      const [beginTime, endTime] = dateRange
      Object.assign(this.searchCondition, {
        beginTime,
        endTime
      })
      this.$refs.mTable.setTableData()
    },
    cascaderChange(val) {
      Object.assign(this.searchCondition, val)
      this.$refs.mTable.setTableData()
    },
    refreshTable() {
      const condition = {}
      Object.keys(this.selectObj).forEach(key => {
        if (key === 'materialsIds' || key === 'materials') {
          condition[key] = this.selectObj[key].map(item => item.id).join('^')
        } else {
          condition[key] = this.selectObj[key].join('^')
        }
      })
      Object.assign(this.searchCondition, condition)
      this.$refs.mTable.setTableData()
    },
    searchConditionChange(condition) {
      Object.assign(this.searchCondition, condition)
      this.$refs.mTable.setTableData()
    },
    reloadTable() {
      this.showTable = false
      this.$nextTick(() => { this.showTable = true })
    },
    columnSettingRefs() {
      return this.$refs.columnSetting
    },
    tableRefs() {
      return this.$refs.mTable
    },
    searchConditionRef() {
      return this.$refs.searchCondition
    }
  }
}
</script>

<style scoped lang="scss">
.custom-table-container {
  position: relative;
  .slot-header {
    position: absolute;
    right: 10px;
    top: -8px;
  }
}
::v-deep .el-transfer-panel {
  width: 290px;
}
::v-deep .el-transfer-panel__body {
  height: 300px;
}
::v-deep .el-transfer-panel__list {
  height: 358px;
}
::v-deep .el-transfer-panel__list.is-filterable {
  height: 253px;
}
::v-deep .el-table .cell {
  line-height: 20px;
}
</style>
