
export const columnsAll = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'materialPreparationNumber',
    label: '备料单号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    slotName: 'materialPreparationNumber'
  },
  {
    prop: 'materialPreparationStatus',
    label: '备料状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialPreparationStatus',
    minWidth: 180
  },
  {
    prop: 'quantityPrepared',
    label: '待备数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'quantityAvailable',
    label: '已备数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'progression',
    label: '备料进度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'produceWorkOrderNumber',
    label: '关联工单',
    hiddenSearch: true,
    sortable: false,
    minWidth: 220
  },
  {
    prop: 'deliveryTime',
    label: '工单交期',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'areaName',
    label: '产线',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'produceOrderNumber',
    label: '所属订单',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'producePlanPriorityId',
    label: '计划优先级',
    slotName: 'producePlanPriorityId',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialPreparation',
    label: '备料类型',
    slotName: 'materialPreparation',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'quantity',
    label: '工单数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'createTime',
    label: '创建日期',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  }
]

export const columnsDetail = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'specifications',
    label: '规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsTypeName',
    label: '类型',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsAttributeName',
    label: '属性',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 220
  },
  {
    prop: 'quantityPrepared',
    label: '待备数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'quantityAvailable',
    label: '已备数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'completionRate',
    label: '完成率',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  }
]
export const columnsApply = [
  // {
  //   type: 'selection',
  //   width: 50,
  //   fixed: 'left',
  //   reserveSelection: true
  // },
    {
      prop: 'index',
      label: '序号',
      sortable: true,
      minWidth: 80,
      hiddenSearch: true,
      slotName: 'index'
    },
    {
      prop: 'taskCode',
      label: '任务单号',
      sortable: false,
      hiddenSearch: true,
      slotName: 'code',
      minWidth: 160
    },
    {
      prop: 'code',
      label: '领料单号',
      sortable: false,
      hiddenSearch: true,
      minWidth: 160
    },
    {
      prop: 'name',
      label: '领料单名称',
      sortable: false,
      hiddenSearch: true,
      minWidth: 160
    },
    {
      prop: 'receiptType',
      label: '单据类型',
      sortable: false,
      slotName: 'receiptType',
      hiddenSearch: true
    },
    {
      prop: 'receiptStatus',
      label: '状态',
      sortable: false,
      slotName: 'receiptStatus',
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'applyQuantity',
      label: '申请数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 100
    },
    {
      prop: 'receivedQuantity',
      label: '完成数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 100
    },
    {
      prop: 'notReceiveQuantity',
      label: '未完数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 100
    },
    {
      prop: 'requireDate',
      label: '时间',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
        prop: 'receiptNumber',
        label: '工单编号',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'receiptName',
        label: '工单名称',
        sortable: false,
        hiddenSearch: true
      },
    {
      prop: 'projectNumber',
      label: '项目编号',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'projectName',
      label: '项目名称',
      hiddenSearch: true,
      sortable: false
    },
    // {
    //   prop: 'handoverStatus',
    //   label: '交接状态',
    //   sortable: false,
    //   hiddenSearch: true,
    //   slotName: 'handoverStatus'
    // },
    // {
    //   prop: 'handoverPerson',
    //   label: '交接人',
    //   hiddenSearch: true,
    //   sortable: false
    // },
    // {
    //   prop: 'handoverTime',
    //   label: '交接时间',
    //   sortable: false,
    //   hiddenSearch: true
    // },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true
    },
    // {
    //   slotName: 'action',
    //   label: '操作',
    //   fixed: 'right',
    //   sortable: false,
    //   hiddenSearch: true,
    //   width: 120
    // }
  ]
  export const columnsReturn = [
      {
        prop: 'index',
        label: '序号',
        sortable: true,
        minWidth: 80,
        hiddenSearch: true,
        slotName: 'index'
      },
      {
        prop: 'taskCode',
        label: '任务单号',
        sortable: false,
        hiddenSearch: true,
        slotName: 'code',
        minWidth: 160
      },
      {
        prop: 'code',
        label: '退料单号',
        sortable: false,
        hiddenSearch: true,
        minWidth: 160
      },
      {
        prop: 'name',
        label: '退料单名称',
        sortable: false,
        hiddenSearch: true,
        minWidth: 160
      },
      {
        prop: 'receiptType',
        label: '单据类型',
        sortable: false,
        slotName: 'receiptType',
        hiddenSearch: true
      },
      {
        prop: 'receiptStatus',
        label: '状态',
        sortable: false,
        slotName: 'receiptStatus',
        hiddenSearch: true,
        minWidth: 120
      },
      {
        prop: 'applyQuantity',
        label: '申请数量',
        sortable: false,
        hiddenSearch: true,
        minWidth: 100
      },
      {
        prop: 'receivedQuantity',
        label: '完成数量',
        sortable: false,
        hiddenSearch: true,
        minWidth: 100
      },
      {
        prop: 'notReceiveQuantity',
        label: '未完数量',
        sortable: false,
        hiddenSearch: true,
        minWidth: 100
      },
      {
        prop: 'requireDate',
        label: '时间',
        sortable: false,
        hiddenSearch: true,
        minWidth: 120
      },
      {
          prop: 'receiptNumber',
          label: '工单编号',
          sortable: false,
          hiddenSearch: true
        },
        {
          prop: 'receiptName',
          label: '工单名称',
          sortable: false,
          hiddenSearch: true
        },
      {
        prop: 'projectNumber',
        label: '项目编号',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'projectName',
        label: '项目名称',
        hiddenSearch: true,
        sortable: false
      },
      // {
      //   prop: 'handoverStatus',
      //   label: '交接状态',
      //   sortable: false,
      //   hiddenSearch: true,
      //   slotName: 'handoverStatus'
      // },
      // {
      //   prop: 'handoverPerson',
      //   label: '交接人',
      //   hiddenSearch: true,
      //   sortable: false
      // },
      // {
      //   prop: 'handoverTime',
      //   label: '交接时间',
      //   sortable: false,
      //   hiddenSearch: true
      // },
      {
        prop: 'remark',
        label: '备注',
        sortable: false,
        hiddenSearch: true
      },
      // {
      //   slotName: 'action',
      //   label: '操作',
      //   fixed: 'right',
      //   sortable: false,
      //   hiddenSearch: true,
      //   width: 120
      // }
    ]
    // 详情基本信息字段
export const detailBaseFields = [
  {
    key: 'taskCode',
    name: '任务单号：'
  },
  {
    key: 'code',
    name: '领料单号：'
  },
  {
    key: 'name',
    name: '领料单名称：'
  },
  {
    key: 'receiptType',
    name: '单据类型：'
  },
  {
    key: 'receiptStatus',
    name: '领料单状态：'
  },
  {
    key: 'receiptNumber',
    name: '工单编号：'
  },
  {
    key: 'receiptName',
    name: '工单名称：'
  },
  {
    key: 'projectNumber',
    name: '项目编号：'
  },
  {
    key: 'projectName',
    name: '项目名称：'
  },
  {
    key: 'requireDate',
    name: '需求时间：'
  },
  // {
  //   key: 'handoverStatus',
  //   name: '交接状态：'
  // },
  // {
  //   key: 'handoverPerson',
  //   name: '交接人：'
  // },
  // {
  //   key: 'handoverTime',
  //   name: '交接时间：'
  // },
  {
    key: 'remark',
    name: '备注：'
  }
]
// 详情物料字段
export const applyColumn = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'procedureLocationCode',
      label: '工序定位码',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      label: '工序名称',
      sortable: false,
      prop: 'procedureName',
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'materialsType',
      label: '物料类型',
      sortable: false,
      slotName: 'materialsType',
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'materialsCode',
      label: '物料编号',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'specifications',
      label: '物料规格',
      sortable: false,
      hiddenSearch: true,
      minWidth: 140
    },
    {
      prop: 'mainUnitName',
      label: '主单位',
      hiddenSearch: true,
      sortable: false,
      minWidth: 120
    },
    {
      prop: 'applyQuantity',
      label: '申请数量',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100
    },
    {
        prop: 'receivedQuantity',
        label: '已发数量',
        hiddenSearch: true,
        sortable: false,
        minWidth: 100
      },
    {
      prop: 'notReceiveQuantity',
      label: '未发数量',
      sortable: false,
      hiddenSearch: true,
      minWidth:100
    }
  ]
  // 退料单详情基本信息字段
export const detailReturnBaseFields = [
  {
    key: 'taskCode',
    name: '任务单号：'
  },
  {
    key: 'code',
    name: '退料单号：'
  },
  {
    key: 'name',
    name: '退料单名称：'
  },
  {
    key: 'receiptType',
    name: '单据类型：'
  },
  {
    key: 'receiptStatus',
    name: '退料单状态：'
  },
  {
    key: 'receiptNumber',
    name: '工单编号：'
  },
  {
    key: 'receiptName',
    name: '工单名称：'
  },
  {
    key: 'projectNumber',
    name: '项目编号：'
  },
  {
    key: 'projectName',
    name: '项目名称：'
  },
  {
    key: 'requireDate',
    name: '退料时间：'
  },
  // {
  //   key: 'handoverStatus',
  //   name: '交接状态：'
  // },
  // {
  //   key: 'handoverPerson',
  //   name: '交接人：'
  // },
  // {
  //   key: 'handoverTime',
  //   name: '交接时间：'
  // },
  {
    key: 'remark',
    name: '备注：'
  }
]
// 退料单详情物料字段
export const applyReturnColumn = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    label: '工序名称',
    sortable: false,
    prop: 'procedureName',
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    sortable: false,
    slotName: 'materialsType',
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '物料规格',
    sortable: false,
    hiddenSearch: true,
    minWidth: 140
  },
  {
    prop: 'mainUnitName',
    label: '主单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'applyQuantity',
    label: '申请数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
      prop: 'receivedQuantity',
      label: '已退数量',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100
    },
  {
    prop: 'notReceiveQuantity',
    label: '未退数量',
    sortable: false,
    hiddenSearch: true,
    minWidth:100
  }
]

