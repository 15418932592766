<template>
  <div class="stationSchedulingBox">
    <div class="printBtnBoxWrapper">
      <el-select
        v-model="stationId"
        placeholder="请选择"
        @change="selectChange"
      >
        <el-option
          v-for="item in stationList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <div class="printBtnBox">
        <div v-if="!reportWorkClick">
          <el-button v-if="!isShowCheckBox" type="primary" @click="printBtn()"
            >打印</el-button
          >
          <el-button v-if="isShowCheckBox" @click="cancelBtn()"
            >取消打印</el-button
          >
          <el-button v-print="printObj" v-if="isShowCheckBox" type="primary">
            <div @click="confirmPrintBtn($event)">确认打印</div>
          </el-button>
        </div>
        <div v-if="!printClick">
          <el-button
            class="reportWorkBtn"
            v-if="!isShowCheckBox"
            type="primary"
            @click="reportWorkBtn()"
            >报工</el-button
          >
          <el-button v-if="isShowCheckBox" @click="cancelBtn()"
            >取消报工</el-button
          >
          <el-button
            v-if="isShowCheckBox"
            type="primary"
            @click="confirmReportWorkBtn()"
            >确认报工</el-button
          >
        </div>
      </div>
    </div>

    <!-- <div @click="readExcelTemplate" class="printBox">获取打印内容</div> -->
    <!-- <div v-print="printObj" class="printBox">打印</div> -->
    <div class="printContent">
      <div id="printTest">
        <zhongChaoTemplate
          :taskList="printList"
          :stationNm="stationNm"
          :fieldSet = "fieldSet"
        ></zhongChaoTemplate>
      </div>
    </div>

    <div class="contentBox" v-loading="loading">
      <div class="leftBox">
        <div class="title">待排程列表</div>
        <div class="content">
          <draggable
            :disabled="dragDisabled"
            v-model="leftArr"
            group="taskList"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
          >
            <transition-group :style="style">
              <div class="itemBox" v-for="item in leftArr" :key="item.id">
                <scheduleListItemVue
                  :itemInfo="item"
                  @editSuccess="editSuccess"
                  :isShowCheckBox="isShowCheckBox"
                ></scheduleListItemVue>
                <div class="blankBox"></div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
      <div class="leftBox">
        <div class="title">排程列表</div>
        <div class="content">
          <draggable
            :disabled="dragDisabled"
            v-model="rightArr"
            group="taskList"
            dragClass="dragClass"
            ghostClass="ghostClass"
            chosenClass="chosenClass"
            @add="add"
            @remove="remove"
            @update="update"
          >
            <transition-group :style="style">
              <div
                class="itemBox"
                v-for="(item, index) in rightArr"
                :key="item.id"
              >
                <div
                  class="checkBoxWrapper"
                  :class="[
                    getBorderBlueType(index) === 'itemBorderTop'
                      ? 'itemBorderTop'
                      : getBorderBlueType(index) === 'itemBorderMiddle'
                      ? 'itemBorderMiddle'
                      : getBorderBlueType(index) === 'itemBorderBottom'
                      ? 'itemBorderBottom'
                      : '',
                  ]"
                >
                  <div
                    @click.shift.capture.prevent="
                      shiftEvent($event, item, index)
                    "
                  >
                    <el-checkbox
                      v-if="isShowCheckBox"
                      class="checkBox"
                      :value="printIdList.has(item.id)"
                      @change="printIdListChange(item, index)"
                    ></el-checkbox>
                  </div>

                  <scheduleListItemVue
                    :itemInfo="item"
                    @editSuccess="editSuccess"
                    :isShowCheckBox="isShowCheckBox"
                  ></scheduleListItemVue>
                </div>

                <div
                  v-if="index + 1 < rightArr.length"
                  class="mergeBox"
                  @click="merge(item)"
                  :class="[getClass(item)]"
                >
                  {{ getText(item) }}
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    <MDialog
      v-model="reportWorkVisible"
      title="报工"
      @onOk="confirmReportWorkEvent"
    >
      <MFormBuilder
        ref="dialogFormBuilds"
        style=""
        label-position="left"
        :form-data="dialogFormDatas"
        :form-list="dialogFormLists"
      />
    </MDialog>
  </div>
</template>
<script>
import stationApi from "@/api/information/production/station";
import stationScheduleApi from "@/api/station-schedule/station-schedule";
import draggable from "vuedraggable";
import log from "@/api/log";
import scheduleListItemVue from "./components/scheduleListItem.vue";
import * as XLSX from "xlsx";
import zhongChaoTemplate from "./printTemplate/zhongChaoTemplate.vue";
import print from "vue-print-nb";
import { transformColumnsToForm } from "@/utils";
import equipmentApi from "@/api/equipment";
import { getUUid } from "@/utils";

export default {
  name: "stationScheduling",
  components: { draggable, scheduleListItemVue, zhongChaoTemplate },
  directives: {
    print,
  },
  data() {
    return {
      fieldSet: new Set(),

      stationId: "",
      stationNm: "",
      stationList: [],
      leftArr: [],
      rightArr: [],
      style:
        "min-height:300px; paddingBottom:300px;display: block;overflowY: auto;",
      loading: false,
      htmlResult: null,
      showContent: false,
      printObj: {
        id: "printTest",
        popTitle: "工位排程任务详情",
        extraCss: "",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        previewBeforeOpenCallback() {
          // console.log("正在加载预览窗口！");
          return false;
        },
        beforeOpenCallback(vue) {
          console.log("before");
          return false;
        },
        openCallback(vue) {},
        closeCallback(vue) {},
        url: "",
      },
      printIdList: new Set(),
      dragDisabled: false,
      isShowCheckBox: false,
      selectedIndexList: null,
      printList: [],
      printClick: false,
      reportWorkClick: false,
      reportWorkList: [],
      reportWorkVisible: false,
      columns: [
        {
          prop: "frequencyId",
          label: "班次",
          hiddenSearch: true,
          sortable: false,
          form: {
            index: 0,
            colSpan: 14,
            tagName: "el-select",
            required: true,
            props: {},
            attrs: { style: { "margin-bottom": "20px" } },
            key: "frequencyId",
            children: [
              {
                tagName: "el-option",
                props: {
                  label: "name",
                  value: "id",
                },
              },
            ],
          },
        },
        {
          prop: "reportWorkerId",
          label: "报工人",
          form: {
            index: 1,
            colSpan: 14,
            tagName: "el-select",
            required: true,
            props: {},
            attrs: {},
            key: "reportWorkerId",
            children: [
              {
                tagName: "el-option",
                props: {
                  label: "name",
                  value: "id",
                },
              },
            ],
          },
        },
      ],

      dialogFormLists: null,

      dialogFormDatas: {},
      firstShiftIndex: -1,
      secondShiftIndex: -1,
    };
  },
  mounted() {
    this.selectedIndexList = new Set();
    this.printIdList = new Set();
    this.getStationList();
    this.dialogFormLists = transformColumnsToForm(this.columns);
    console.log("dialogFormLists", this.dialogFormLists);
    this.getFrequencyList();
  },
  methods: {
    // 获取工位数据
    async getStationList() {
      this.loading = true;
      const res = await stationApi.getStationList();
      if (res) {
        this.loading = false;
        this.stationList = res;
        this.leftArr = [];
        this.rightArr = [];
        if (this.stationList.length > 0) {
          this.stationId = this.stationList[0].id;
          this.selectChange(this.stationId);
        }
      }
    },
    async selectChange(val) {
      // this.loading = true;
      this.stationList.forEach((item) => {
        if (item.id === val) {
          this.stationNm = item.name;
        }
      });
      this.stationId = val;
      // const params = { stationId: val };
      // const res =
      //   await stationScheduleApi.getProduceWorkOrderTaskLisWithoutLockStation(
      //     params
      //   );
      // this.loading = false;

      // console.log(res);
      this.leftArr = [];
      this.rightArr = [];
      this.dragDisabled = false;
      this.isShowCheckBox = false;
      (this.reportWorkClick = false), (this.printClick = false);

      this.printIdList = new Set();
      this.printList = [];
      this.selectedIndexList = new Set();
      this.fieldSet= new Set();
      this.getProduceWorkOrderTaskLisWithoutLockStation();
      this.getProduceWorkOrderTaskListByLockStation();
      this.getUserListByStationId();
    },
    //添加到右侧
    async add(e) {
      console.log(e);
      console.log(this.rightArr);
      this.loading = true;
      const params = {
        produceWorkOrderTaskId: this.rightArr[e.newIndex].id, //工序任务id
        stationId: this.stationId, //工位id
        produceWorkOrderId: this.rightArr[e.newIndex].produceWorkOrderId,
        endMappingId: this.rightArr[e.newIndex + 1]
          ? this.rightArr[e.newIndex + 1].produceWorkOrderTaskLockStationMapping
              .id
          : null,
      };
      const res =
        await stationScheduleApi.addProduceWorkOrderTaskLockStationMapping(
          params
        );
      this.loading = false;
      console.log(res);
      this.getProduceWorkOrderTaskListByLockStation();
    },
    //获取左侧列表
    async getProduceWorkOrderTaskLisWithoutLockStation() {
      this.loading = true;

      const params = { stationId: this.stationId };
      const res =
        await stationScheduleApi.getProduceWorkOrderTaskLisWithoutLockStation(
          params
        );
      if (res) {
        console.log('res:',res)
        this.leftArr = res;
        if(this.leftArr.length>0){
        this.getFieldSet(this.leftArr[0].produceWorkOrderTaskFieldMappingList)
      }
        this.loading = false;
      }
    },
    //获取右侧列表
    async getProduceWorkOrderTaskListByLockStation() {
      this.loading = true;
      const params = {
        stationId: this.stationId,
      };
      const res =
        await stationScheduleApi.getProduceWorkOrderTaskListByLockStation(
          params
        );

      console.log(res);
      this.rightArr = res;
      if(this.rightArr.length>0){
        this.getFieldSet(this.rightArr[0].produceWorkOrderTaskFieldMappingList)
      }
      this.loading = false;
    },
    //删除右侧数据
    async remove(e) {
      console.log(e);
      console.log(this.leftArr);
      this.loading = true;
      const params = {
        id: this.leftArr[e.newIndex].produceWorkOrderTaskLockStationMapping.id,
      };
      const res =
        await stationScheduleApi.deleteProduceWorkOrderTaskLockStationMappingById(
          params
        );
      this.loading = false;
      this.printIdList.delete(this.leftArr[e.newIndex].id);
      this.getProduceWorkOrderTaskListByLockStation();

      console.log(res);
    },

    //
    getBorderBlueType(index) {
      if (
        this.rightArr[index].produceWorkOrderTaskLockStationMapping
          ?.isContinuous === 1
      ) {
        if (
          index - 1 >= 0 &&
          this.rightArr[index - 1].produceWorkOrderTaskLockStationMapping
            ?.isContinuous === 1
        ) {
          return "itemBorderMiddle";
        } else if (
          index - 1 < 0 ||
          this.rightArr[index - 1].produceWorkOrderTaskLockStationMapping
            ?.isContinuous === 0
        ) {
          return "itemBorderTop";
        }
      } else {
        if (
          index - 1 >= 0 &&
          this.rightArr[index - 1].produceWorkOrderTaskLockStationMapping
            ?.isContinuous === 1
        ) {
          return "itemBorderBottom";
        } else if (
          index - 1 < 0 ||
          this.rightArr[index - 1].produceWorkOrderTaskLockStationMapping
            ?.isContinuous === 0
        ) {
          return "";
        }
      }
    },
    async merge(item) {
      // this.loading = true
      if (this.isShowCheckBox) {
        this.$message.error("请先取消打印或合并");

        return;
      }
      const params = {
        id: item.produceWorkOrderTaskLockStationMapping.id,
      };
      const res =
        await stationScheduleApi.updateProduceWorkOrderTaskLockStationMappingIsContinuousById(
          params
        );
      // this.loading = false
      this.getProduceWorkOrderTaskListByLockStation();
    },
    //右侧上下移动
    async update(val) {
      console.log(val);
      console.log(this.rightArr);
      let params;
      this.loading = true;
      if (val.newIndex > val.oldIndex) {
        params = {
          beginId:
            this.rightArr[val.newIndex].produceWorkOrderTaskLockStationMapping
              .id,
          endId:
            this.rightArr[val.newIndex - 1]
              .produceWorkOrderTaskLockStationMapping.id,
        };
      } else {
        params = {
          beginId:
            this.rightArr[val.newIndex].produceWorkOrderTaskLockStationMapping
              .id,
          endId:
            this.rightArr[val.newIndex + 1]
              .produceWorkOrderTaskLockStationMapping.id,
        };
      }

      const res =
        await stationScheduleApi.updateProduceWorkOrderTaskLockStationMapping(
          params
        );
      this.loading = false;
      this.getProduceWorkOrderTaskListByLockStation();
    },
    //获取class
    getClass(item) {
      if (item.produceWorkOrderTaskLockStationMapping) {
        if (item.produceWorkOrderTaskLockStationMapping.isContinuous === 1) {
          return "isMerge";
        } else {
          return "";
        }
      }
    },
    // 获取text
    getText(item) {
      if (item.produceWorkOrderTaskLockStationMapping) {
        if (item.produceWorkOrderTaskLockStationMapping.isContinuous === 1) {
          return "取消合并";
        } else {
          return "合并";
        }
      }
    },
    readExcelTemplate() {
      // 模板文件的相对路径，假设在 public 文件夹下
      const templateFilePath = "/excelTemplate/zhongChao.xlsx";

      // 发起请求获取模板文件
      fetch(templateFilePath)
        .then((response) => response.arrayBuffer())
        .then((data) => {
          // 使用 SheetJS 处理 Excel 文件
          const workbook = XLSX.read(data, { type: "array" });

          // 处理 Excel 文件，可以在这里进行其他操作

          // 例如，获取 HTML 结构并存储到组件数据中
          const html = XLSX.utils.sheet_to_html(
            workbook.Sheets[workbook.SheetNames[0]]
          );
          this.htmlResult = html;
        })
        .catch((error) => {
          console.error("Error reading Excel template:", error);
        });
    },
    printIdListChange(item, index) {
      if (this.selectedIndexList.has(index)) {
        this.selectedIndexList.delete(index);
      } else {
        this.selectedIndexList.add(index);
      }
      if (this.printIdList.has(item.id)) {
        this.printIdList.delete(item.id);
      } else {
        this.printIdList.add(item.id);
      }
      console.log(this.printIdList);
      let idList = [...this.printIdList];
      let printDetailList = this.rightArr.filter((item) =>
        idList.includes(item.id)
      );
      this.printList = [];
      this.convertPrintList(printDetailList);
    },
    isChecked(item) {
      if (this.printIdList.has(item.id)) {
        return true;
      } else {
        return false;
      }
    },
    // toPrint() {
    //   console.log("print");
    //   this.printIframe();
    // },
    // printIframe() {
    //   setTimeout(() => {
    //     const printHtml = document.getElementById(`zhongchaoPrint`).innerHTML;
    //     const new_iframe = document.createElement("IFRAME");
    //     let doc = null;
    //     new_iframe.setAttribute(
    //       "style",
    //       "width:0px;height:0px;position:absolute;left:-2000px;top:-2000px;"
    //     );
    //     new_iframe.setAttribute("align", "center");
    //     document.body.appendChild(new_iframe);
    //     doc = new_iframe.contentWindow.document;
    //     doc.write(
    //       '<div style="width:100%;height:auto;margin:0 auto;"align="center">' +
    //         printHtml +
    //         "</div>"
    //     );
    //     // doc.body.style.zoom = '0.8'
    //     doc.close();
    //     new_iframe.contentWindow.focus();
    //     new_iframe.contentWindow.print();
    //   }, 0);
    // },
    printBtn() {
      this.dragDisabled = true;
      this.isShowCheckBox = true;
      (this.reportWorkClick = false), (this.printClick = true);
    },
    cancelBtn() {
      this.dragDisabled = false;
      this.isShowCheckBox = false;
      (this.reportWorkClick = false), (this.printClick = false);
      this.selectedIndexList = new Set();
      this.printIdList = new Set();
      this.printList = [];
    },
    confirmPrintBtn(ev) {
      if (this.printList.length <= 0) {
        ev.stopPropagation();

        this.$message.error("请先进行选择");
        return;
      }
      let res = this.isConsecutive(this.selectedIndexList);
      if (res) {
        console.log("连续");
      } else {
        console.log("不连续");
        this.$message.error("打印时请选择连续的任务");
        ev.stopPropagation();
      }
      console.log(this.rightArr);
    },
    //判断是否连续选择
    isConsecutive(setData) {
      console.log(setData);
      // 将 Set 转换为数组，并对数组进行排序
      const sortedArray = Array.from(setData).sort((a, b) => a - b);
      console.log(sortedArray);
      // 检查数组中的每个元素是否连续
      for (let i = 0; i < sortedArray.length - 1; i++) {
        if (sortedArray[i] + 1 !== sortedArray[i + 1]) {
          return false;
        }
      }

      // 如果所有元素都连续，则返回 true
      return true;
    },
    // 将要打印的数据转换为一定格式
    convertPrintList(arr) {
      let printList = [];
      let currentGroup = [];

      arr.forEach((item, index) => {
        // 将第一条记录直接作为一个子数组的开始
        if (index === 0) {
          currentGroup.push(item);
        } else {
          // 如果当前记录的 isContinus 为 0，且上一条记录的 isContinus 为 1，单独成为一个子数组
          if (
            item.produceWorkOrderTaskLockStationMapping.isContinuous === 0 &&
            arr[index - 1].produceWorkOrderTaskLockStationMapping
              .isContinuous === 1
          ) {
            currentGroup.push(item);
            printList.push([...currentGroup]);
            currentGroup = [];
          } else if (
            item.produceWorkOrderTaskLockStationMapping.isContinuous === 1 &&
            arr[index - 1].produceWorkOrderTaskLockStationMapping
              .isContinuous === 1
          ) {
            currentGroup.push(item);
          } else if (
            item.produceWorkOrderTaskLockStationMapping.isContinuous === 1 &&
            arr[index - 1].produceWorkOrderTaskLockStationMapping
              .isContinuous === 0
          ) {
            printList.push([...currentGroup]);
            currentGroup = [];
            currentGroup.push(item);
          } else if (
            item.produceWorkOrderTaskLockStationMapping.isContinuous === 0 &&
            arr[index - 1].produceWorkOrderTaskLockStationMapping
              .isContinuous === 0
          ) {
            printList.push([...currentGroup]);
            currentGroup = [];
            currentGroup.push(item);
          }
        }
      });
      if (arr.length === 1) {
        printList.push([...currentGroup]);
      } else {
        if (currentGroup.length > 0) {
          printList.push([...currentGroup]);
        }
      }
      console.log(printList);

      this.printList = printList;
      // return printList
    },
    editSuccess() {
      this.getProduceWorkOrderTaskListByLockStation();
      this.getProduceWorkOrderTaskLisWithoutLockStation();

      console.log("editSuccess");
    },
    // 报工
    reportWorkBtn() {
      this.dragDisabled = true;
      this.isShowCheckBox = true;
      this.reportWorkClick = true;
      this.printClick = false;
    },
    //确认报工
    confirmReportWorkBtn() {
      if (this.printList.length <= 0) {
        this.$message.error("请先进行选择");
        return;
      }
      this.reportWorkList = [];
      console.log(this.printList);
      this.printList.forEach((itemList) => {
        itemList.forEach((item) => {
          this.reportWorkList.push(item);
        });
      });
      console.log(this.reportWorkList);
      // this.dialogFormDatas = {
      //   frequencyId: 19,
      //   reportWorkerId:1
      // };
      this.reportWorkVisible = true;
    },
    confirmReportWorkEvent() {
      console.log("确认报工");
      const { form } = this.$refs.dialogFormBuilds;
      this.$refs.dialogFormBuilds.formRefs().validate(async (valid) => {
        if (valid) {
          console.log(form);
          console.log(this.reportWorkList);
          let params = [];
          this.reportWorkList.forEach((item) => {
            console.log(item);
            let paramsItem = {
              checkInParamBean: {
                checkInList: [],
                id: item.id,
                stationId: this.stationId,
              },
              checkOutParamBean: {
                checkInList: [],
                id: item.id,
                stationId: this.stationId,
                isClose: false,
              },
              reportParamBean: {
                moldInstanceIds: [],
                reportWorkRecords: [
                  {
                    reportWorkQuantity: item.workOrderQuantity,
                    outputReportWorkQuantity:
                      item.produceWorkOrderTaskOutputMaterials
                        ? item.produceWorkOrderTaskOutputMaterials.quantity
                        : "",
                    batchNumber: item.batchNumber,
                    procedureDetails: "[]",
                    produceWorkOrderTaskId: item.id,
                    stationId: this.stationId,
                    operateType: 0,
                    frequencyId: form.frequencyId,
                    materialsUnit: item.mainUnitName,
                    uuid: getUUid(),
                    width: 0,
                    thickness: 0,
                    coilNo: "",
                    steelGrade: "",
                    equipmentId: "",
                    isPartialOutbound: item.isPartialOutbound,
                    operatorId: form.reportWorkerId,
                  },
                ],
              },
            };
            params.push(paramsItem);
          });
          console.log(params);
          const res = await stationScheduleApi.batchFinishProduceWorkOrderTask(
            params
          );
          if (res) {
            this.$message.success("批量报工成功");
            this.selectChange(this.stationId);
          }
          this.reportWorkVisible = false;
        }
      });
    },
    // 获取班次列表
    async getFrequencyList() {
      const res = await equipmentApi.getFrequencyList();
      if (res) {
        console.log(res);
        this.dialogFormLists[0].children[0].options = res;
      }
    },
    //获取报工人列表
    async getUserListByStationId() {
      const params = {
        stationId: this.stationId,
      };
      const res = await stationScheduleApi.getUserListByStationId(params);
      if (res) {
        this.dialogFormLists[1].children[0].options = res;

        console.log("报工人", res);
      }
    },
    //
    isFilter(ev) {
      console.log("filter");
      ev.stopPropagation();
    },
    generateOrderedArray(a, b) {
      if (a > b) {
        let c = b;
        b = a;
        a = c;
      }
      // return this.rightArr.slice(a,b)
      // 使用 Array 构造函数创建一个数组，长度为 b - a + 1
      return Array.from({ length: b - a + 1 }, (_, index) => a + index);
    },
    shiftEvent(e, item, index) {
      if (this.firstShiftIndex < 0) {
        this.firstShiftIndex = index;
        if (this.selectedIndexList.has(index)) {
          return;
        } else {
          this.selectedIndexList.add(index);
        }
        if (this.printIdList.has(item.id)) {
          return;
        } else {
          this.printIdList.add(item.id);
        }
        console.log(this.printIdList);
        let idList = [...this.printIdList];
        let printDetailList = this.rightArr.filter((item) =>
          idList.includes(item.id)
        );
        this.printList = [];
        this.convertPrintList(printDetailList);
      } else {
        this.secondShiftIndex = index;
        let orderArr = this.generateOrderedArray(
          this.firstShiftIndex,
          this.secondShiftIndex
        );
        console.log(orderArr);
        if (orderArr.length > 1) {
          this.rightArr.forEach((selectItem,index) => {
            if(index>=orderArr[0] && index<=orderArr[orderArr.length-1]){
              if (this.selectedIndexList.has(index)) {
              return;
            } else {
              this.selectedIndexList.add(index);
            }
            if (this.printIdList.has(selectItem.id)) {
              return;
            } else {
              this.printIdList.add(selectItem.id);
            }
            }

          });
          console.log(this.printIdList);
          let idList = [...this.printIdList];
          let printDetailList = this.rightArr.filter((item) =>
            idList.includes(item.id)
          );
          this.printList = [];
          this.convertPrintList(printDetailList);
        }
        this.firstShiftIndex = -1;
        this.secondShiftIndex = -1;
      }
      console.log(index);
    },
    getFieldSet(array) {
      array.forEach((item) => {
        // 检查条件
        if (item.type >= 1 && item.type <= 4 && item.isShow) {
          // 将 fieldCode 添加到 Set 中
          if(!this.fieldSet.has(item.fieldCode)){
            this.fieldSet.add(item.fieldCode);

          }
        }
      });
    },
    judgeField(code){
      if(this.fieldSet.has(code)){
        return true
      }else{
        return false
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 20px !important;
}
.stationSchedulingBox {
  width: 100%;
  background: #f0f2f5;
  height: 100%;
  .printBtnBoxWrapper {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .printBtnBox {
      display: flex;
      align-items: center;
    }
  }
}
.contentBox {
  margin-top: 10px;
  display: flex;
  height: calc(100% - 60px);
  .title {
    width: 100%;
    height: 37px;
    background-color: #d3d6dd;
    font-size: 14px;
    font-weight: 600;
    color: #393d60;
    line-height: 37px;
    text-indent: 14px;
  }
  .content {
    background: #e7e9ed;
    height: calc(100% - 37px);
    padding: 10px 11px 60px 11px;
    overflow-y: auto;
  }
  .checkBoxWrapper {
    display: flex;
    align-items: center;
    .checkBox {
      margin-left: 4px;
      margin-right: 6px;
    }
  }
  .leftBox {
    margin-right: 20px;
    width: 45%;
    /*定义要拖拽元素的样式*/
    .ghostClass {
      background-color: #eee !important;
    }

    .chosenClass {
      background-color: #eee !important;
      opacity: 1 !important;
    }

    .dragClass {
      background-color: #eee !important;
      opacity: 1 !important;
      box-shadow: none !important;
      outline: none !important;
      background-image: none !important;
    }
  }

  .itemBorderTop {
    margin-top: 4px;

    border-top: 2px solid #ff0000;
    border-left: 2px solid #ff0000;
    border-right: 2px solid #ff0000;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .itemBorderMiddle {
    border-left: 2px solid #ff0000;
    border-right: 2px solid #ff0000;
  }
  .itemBorderBottom {
    border-bottom: 2px solid #ff0000;
    border-left: 2px solid #ff0000;
    border-right: 2px solid #ff0000;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .itemBox {
    .mergeBox {
      padding-right: 4px;
      height: 20px;
      width: 100%;
      font-size: 12px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #ff0000;
      line-height: 17px;
      text-align: right;
      cursor: pointer;
    }
    .blankBox {
      height: 10px;
      width: 100%;
    }
    .isMerge {
      border-left: 2px solid #ff0000;
      border-right: 2px solid #ff0000;
    }
  }
}
.printBox {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  background-color: #fff;
  margin-right: 20px;
}
.printContent {
  position: fixed;
  top: -2000px;
  left: -3000px;
}
.reportWorkBtn {
  margin-left: 10px;
}
</style>
