<template>
  <div class="chart-search-condition">
    <el-button class="search-setting" type="text" @click="showCondition">
      <img :src="isShowCondition ? filterActive : filter" alt="" width="14">
    </el-button>
    <div v-if="isShowCondition" class="condition-wrapper">
      <template v-for="(search, index) in tabInfo.searches">
        <el-select
          v-if="search.type === 'select'"
          :key="index"
          v-model="searchForm[search.asKey || search.key]"
          size="small"
          filterable
          clearable
          collapse-tags
          style="width: 200px"
          :placeholder="search.placeholder"
          :loading="selectLoading"
          v-bind="search.props || {}"
          @change="() => { selectChange(false, search) }"
          @visible-change="(visible) => { selectVisibleChange(visible, search)}"
          @clear="clearSelect(search)"
        >
          <el-option
            v-for="item in search.options || formOptions[search.dataKey]"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <el-button v-if="search.type === 'materials'" :key="index" size="small" type="primary" @click="showMaterials(search)">选择物料</el-button>
        <el-button v-if="search.type === 'workOrder'" :key="index" size="small" type="primary" @click="showWorkOrder(search)">选择工单</el-button>
        <date-range
          v-if="search.type === 'dateRange'"
          :key="index"
          :icon-show="false"
          :tab-info="!tabInfo.hiddenInit && tabInfo"
          :format-date-type="search.formatDateType"
          :can-no-select="search.canNoSelect"
          :placeholder="search.placeholder"
          :class="search.className"
          :date-type="search.dateType"
          @dateRangeChange="(val)=> dateRangeChange(val, search)"
        />
        <el-cascader
          v-if="search.type === 'cascader'"
          ref="cascader"
          :key="index"
          v-model="searchForm[search.asKey || search.key]"
          size="mini"
          collapse-tags
          filterable
          clearable
          :placeholder="search.placeholder"
          :options="search.options || formOptions[search.dataKey]"
          :loading="selectLoading"
          v-bind="search.props"
          @visible-change="(visible) => { selectVisibleChange(visible, search)}"
          @change="() => { cascaderChange(false, search) }"
        />
        <div v-if="search.type === 'dailys'" :key="index" class="dailyNums">
          <el-input-number v-model="inputValue" controls-position="right" :min="1" :step="1" :step-strictly="true" placeholder="工作日天数" style="width: 140px" />
          <div class="num">应出勤总工时: {{ getValue }} h</div>
          <el-button size="small" type="primary" @click="save(search)">确定</el-button>
        </div>
        <div v-if="search.type === 'input'" :key="index">
          <el-input
            v-model="searchForm[search.asKey || search.key]"
            clearable
            :placeholder="search.placeholder"
            @input="(val) => { inputChange(val, search) }"
          />
        </div>
      </template>
    </div>
    <select-materials
      v-if="materialsLoad"
      :visible.sync="materialsShow"
      :select-materials="searchForm[current.key]"
      @submitForm="materialsChange"
    />
    <select-work-order
      v-if="workOrderLoad"
      :visible.sync="workOrderShow"
      :single="true"
      @submitForm="submitWorkOrder"
    />
  </div>
</template>

<script>
import DateRange from '../custom-table/date-range.vue'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials'
import api from '@/api/statistics-board/all-statistics'
import { setTreeData } from '@/utils'
import filter from '@/assets/statistics-board/高级筛选@2x.png'
import filterActive from '@/assets/statistics-board/高级筛选Active@2x.png'
import SelectWorkOrder from '@/components/SelectWorkOrder/SelectWorkOrder'
export default {
  name: 'ChartSearchCondition',
  components: { SelectWorkOrder, SelectMaterials, DateRange },
  props: {
    tabInfo: {
      type: Object,
      default: () => ({
        searches: []
      })
    },
    ssflag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      filter,
      filterActive,
      searchForm: {},
      searchCondition: {},
      formOptions: {},
      materialsShow: false,
      current: {},
      isShowCondition: true,
      workOrderShow: false,
      selectLoading: false,
      materialsLoad: false,
      workOrderLoad: false,
      loaded: {},
      inputValue: undefined
    }
  },
  computed: {
    getValue() {
      return this.inputValue ? this.inputValue * 8 : 0
    }
  },
  beforeMount() {
    this.isShowCondition = this.ssflag
    this.getOptions()
    if (this.tabInfo.dataConditionOrigin) {
      this.searchForm = this._.cloneDeep(this.tabInfo.dataConditionOrigin)
      if (this.tabInfo.formOptionOrigin) {
        this.formOptions = this._.cloneDeep(this.tabInfo.formOptionOrigin)
      }
    }
  },
  methods: {
    save() {
      Object.assign(this.searchCondition, {
        days: this.inputValue
      })
      this.$emit('conditionChange', this.searchCondition)
    },
    getOptions() {
      if (!this.tabInfo.searches) return false
      this.tabInfo.searches.forEach(item => {
        // if (item.dataKey) {
        //   this.setOptions(item)
        // }
        if (item.defaultValue || item.defaultValue === 0) {
          this.searchForm[item.asKey || item.key] = item.defaultValue
        }
      })
    },
    selectVisibleChange(visible, search) {
      if (visible && search.dataKey && (!this.loaded[search.dataKey])) {
        this.selectLoading = true
        this.setOptions(search)
      }
    },
    async setOptions(condition) {
      const res = await api.getData(condition.dataUrl, condition.dataCondition)
      if (res) {
        if (condition.type === 'cascader') {
          if (condition.key === 'customerSiteId') {
            const data = res.map(item => {
              return {
                ...item,
                name: +item.isCustomer === 0 ? item.customerName : item.customerSiteName,
                id: +item.isCustomer === 0 ? item.customerId : item.customerSiteId
              }
            })
            this.$set(this.formOptions, condition.dataKey, setTreeData(data))
          } else {
            this.$set(this.formOptions, condition.dataKey, setTreeData(res))
          }
        } else if (condition.key === 'facilityIdStr') {
          const data = res.map(item => {
            return {
              ...item,
              name: item.code,
              id: item.id
            }
          })
          this.$set(this.formOptions, condition.dataKey, data)
        } else {
          this.$set(this.formOptions, condition.dataKey, res)
        }
        this.loaded[condition.dataKey] = true
        this.selectLoading = false
        this.$forceUpdate()
      }
    },
    async getEquipmentListByEquipmentClassIds(equipmentClassIds) {
      const res = await api.getData('equipment/private/equipment/getEquipmentList', {
        equipmentClassIdList: equipmentClassIds
      })
      if (res) {
        this.formOptions.equipmentList = res
        this.$forceUpdate()
      }
    },
    showMaterials(search) {
      this.current = search
      this.materialsLoad = true
      this.materialsShow = true
    },
    showWorkOrder(search) {
      this.current = search
      this.workOrderLoad = true
      this.workOrderShow = true
    },
    showCondition() {
      this.isShowCondition = !this.isShowCondition
    },
    dateRangeChange(dateRange, search) {
      const [beginTime, endTime] = dateRange
      Object.assign(this.searchCondition, {
        [search.keys ? search.keys[0] : 'beginTime']: beginTime,
        [search.keys ? search.keys[1] : 'endTime']: endTime
      })
      this.$emit('conditionChange', this.searchCondition)
    },
    selectChange(bol, search) {
      this.$forceUpdate()
      if (bol) return
      if ('statisticsType' in search) {
        this.searchCondition.statisticsType = this.searchForm[search.asKey || search.key] && this.searchForm[search.asKey || search.key].length ? search.statisticsType : 1
      }
      const ids = search.props && search.props.multiple ? this.searchForm[search.asKey || search.key].join('^') : this.searchForm[search.asKey || search.key]
      const ecSearch = this.tabInfo.searches.find(item => item.asKey === 'equipmentClassIds')
      if (!ids && ecSearch && search.key === 'equipmentIds') {
        return this.cascaderChange(false, ecSearch)
      }
      Object.assign(this.searchCondition, {
        [search.key]: ids
      })
      this.$emit('conditionChange', this.searchCondition)
    },
    clearSelect(search) {
      delete this.searchCondition[search.key]
      if ('statisticsType' in search) {
        this.searchCondition.statisticsType = 1
      }
      const ecSearch = this.tabInfo.searches.find(item => item.asKey === 'equipmentClassIds')
      if (ecSearch) {
        return this.cascaderChange(false, ecSearch)
      }
      this.$emit('conditionChange', this.searchCondition)
    },
    cascaderChange(bol, search) {
      if (bol) return
      // 对故障卡片来源特殊处理
      if (search.options && search.options[0].key) {
        return this.faultOriginCascaderChange(search)
      }
      const data = this.searchForm[search.asKey || search.key]
      const ids = search.props && search.props.props && search.props.props.multiple ? Array.from(new Set(data.reduce((a, b) => a.concat([this._.last(b)]), []))).join('^') : this._.last(data)
      if ('statisticsType' in search) {
        this.searchCondition.statisticsType = data && data.length ? search.statisticsType : 1
      }
      if (search.asKey) {
        this.getEquipmentListByEquipmentClassIds(ids)
        this.searchForm[search.key] = search.props && search.props.props && search.props.props.multiple ? [] : null
      }
      if (search.key === 'customerSiteId') {
        Object.assign(this.searchCondition, {
          customerId: this._.first(data),
          [search.key]: data && data.length > 1 ? ids : ''
        })
      } else {
        Object.assign(this.searchCondition, {
          [search.key]: ids
        })
      }
      this.$emit('conditionChange', this.searchCondition)
    },
    inputChange(val, search) {
      Object.assign(this.searchCondition, {
        [search.key]: val
      })
      this.$emit('conditionChange', this.searchCondition)
    },
    faultOriginCascaderChange(search) {
      const value = this.searchForm[search.asKey || search.key]
      const f1 = value.filter(item => item.includes('f-1'))
      const f2 = value.filter(item => item.includes('f-2'))
      if (f1.length && f2.length) {
        Object.assign(this.searchCondition, {
          [search.key]: 0,
          faultStatuses: f1.filter(item => item[1]).map(item => item[1]).join('^'),
          quickStatuses: f2.filter(item => item[1]).map(item => item[1]).join('^')
        })
      } else if (f1.length) {
        Object.assign(this.searchCondition, {
          [search.key]: 1,
          faultStatuses: f1.filter(item => item[1]).map(item => item[1]).join('^'),
          quickStatuses: undefined
        })
      } else {
        Object.assign(this.searchCondition, {
          [search.key]: 2,
          faultStatuses: f2.filter(item => item[1]).map(item => item[1]).join('^'),
          quickStatuses: undefined
        })
      }
      if (!value.length) {
        Object.assign(this.searchCondition, {
          [search.key]: undefined,
          faultStatuses: undefined,
          quickStatuses: undefined
        })
      }
      this.$emit('conditionChange', this.searchCondition)
    },
    materialsChange(val) {
      this.searchForm[this.current.key] = val
      Object.assign(this.searchCondition, {
        [this.current.key]: val.map(item => item.id).join('^')
      })
      this.$emit('conditionChange', this.searchCondition)
      this.materialsShow = false
    },
    submitWorkOrder(val) {
      this.searchForm[this.current.key] = val
      Object.assign(this.searchCondition, {
        [this.current.key]: val.map(item => item.id).join('^')
      })
      this.$emit('conditionChange', this.searchCondition)
      this.workOrderShow = false
    }
  }
}
</script>

<style scoped lang="scss">

.chart-search-condition {
  .search-setting {
    position: absolute;
    right: 0;
    top: -63px;
  }
  .condition-wrapper {
    padding-bottom: 15px;
    display: inline-flex;
    span, div, button {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
  .dailyNums {
    display: flex;
    justify-content: flex-start;
    font-size: 15px;
    color: #606266;
    align-items: center;
  }

  .date-right {
    // position: absolute;
    // left: calc(50% - 50px);

    ::v-deep .el-button {
      min-width: 100px
    }
  }
}
</style>
