var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "equipment-board-container" }, [
    _c("div", { staticClass: "top" }, [
      _c(
        "div",
        { staticClass: "fault-sort" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("月度工时统计")]
          ),
          _c("charts", { ref: "customTable", attrs: { "tab-info": _vm.child } })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "defects" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("客户设备数量")]
          ),
          _c("charts", {
            ref: "customTable",
            attrs: { "tab-info": _vm.child2 }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "defects" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("设备质保状态")]
          ),
          _c("charts", {
            ref: "customTable",
            attrs: { "tab-info": _vm.child3 }
          })
        ],
        1
      )
    ]),
    _c("div", { staticClass: "top", staticStyle: { "margin-top": "30px" } }, [
      _c(
        "div",
        { staticClass: "fault-sort" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("用量排行")]
          ),
          _c("charts", {
            ref: "customTable",
            attrs: { "tab-info": _vm.child4 }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "defects" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("存量分析")]
          ),
          _c("charts", {
            ref: "customTable",
            attrs: { "tab-info": _vm.child5 }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "defects" },
        [
          _c(
            "div",
            {
              staticClass: "gradient-title",
              staticStyle: {
                background:
                  "linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%)"
              }
            },
            [_vm._v("新增工单(默认昨日)")]
          ),
          _c("charts", {
            ref: "customTable",
            attrs: { "tab-info": _vm.child6 }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }