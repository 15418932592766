<template>
  <div class="materials-wrap">
    <div class="bottom">
      <Split :offset.sync="split.offset" :height="splitHeight">
        <template slot="left">
          <FileClass @nodeClick="setMaterialsClassId"/>
        </template>
        <template slot="right">
          <div class="top">
            <SearchForm :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable"/>
          </div>
          <div class="materials-info">
            <FunctionHeader
              ref="functionHeader"
              v-model="functionHeader.searchVal"
              search-title="请输入文件名称"
              :tags="functionHeader.tags"
              :import-name="functionHeader.importName"
              :export-name="functionHeader.exportName"
              :export-params="functionHeader.exportParams"
              @search="$refs.mTable.setTableData(true)"
            >

              <div slot="all" v-if="selectionData.length"
                   style="display: flex;justify-content: flex-start;align-items: center;margin-left: 10px">
                <el-button size="small" type="primary" @click="downLoadFile">批量下载</el-button>
                <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;line-height:32px;">
                已选
                <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                / {{ total }}项
              </span>
                <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
              </div>

              <!-- <div slot="all" style="display: flex;justify-content: flex-start;align-items: center;margin-left: 10px">
                <el-dropdown size="small" split-button class="all-operator" @command="(command) => { allMore(command) }">
                  <span class="el-dropdown-link">
                    批量操作
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="permission('Export')" command="batchExport" :disabled="!exportSelectionIds.length">导出</el-dropdown-item>
                    <el-dropdown-item v-if="permission('Del')" command="batchDel" :disabled="!exportSelectionIds.length">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div> -->
            </FunctionHeader>
            <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData"
                    @selection-change="selectionChange">
              <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
              <div slot="name" slot-scope="{ row }">
                <a :href="row.ossAddress" :download="row.name" target="_blank" style="color: #607FFF">{{ row.name }}</a>
              </div>
              <div slot="action" slot-scope="{ row }">
                <el-button v-if="permission('Modify')" type="text" @click="modifys(row)">修改</el-button>
                <el-divider v-if="permission('Modify')" direction="vertical"/>
                <el-button v-if="permission('Download')" type="text" @click="download(row)">下载</el-button>
                <el-divider v-if="permission('Download')" direction="vertical"/>
                <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
              </div>
            </MTable>
          </div>
        </template>
      </Split>
    </div>
    <MDialog v-model="uploadVisible" :append-to-body="true" title="上传文件" width="500px" @onOk="submitFormPlan"
             @onCancel="cancel()" :customCancel="true">
      <MFormBuilder ref="baseForm" :form-list="formList" label-position="right" :form-data="baseFormData">
        <div slot="drawing">
          <el-upload
            ref="upload"
            class="upload-demo"
            :file-list="fileList"
            v-bind="uploadProp"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .jpg..</div>
          </el-upload>
        </div>
      </MFormBuilder>
      <!-- <div>

        <el-upload
          drag
          multiple
          ref="upload"
          class="upload-demo"
          :file-list="fileList"
          v-bind="uploadProp"
          >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .jpg..</div>
        </el-upload>
      </div> -->
    </MDialog>
    <MDialog v-model="resetVisible" :append-to-body="true" title="修改" width="500px" @onOk="submitFormReset">
      <MFormBuilder ref="resetForm" :form-list="modifyFormList" label-position="right" :form-data="fileFormData"/>
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import {columns} from './columns'
import api from '@/api/xiewei/knowledge-base/enterprise-files'
import classApi from '@/api/xiewei/knowledge-base/enterprise-files-class'
import {modifyFormList,modifyFormListBbelc, searchFormList,searchFormListBbelc, formList,formListBbelc} from './form-list'
import {getUUid} from '@/utils'
import {Encrypt} from '@/utils/sercet'
import Split from '@/components/Split/Split'
import FileClass from '@/components/FileClass/FileClass'
import conventionMixin from '@/mixin/conventional-page'
import uApi from '@/api/sets/user/user'
import {getToken} from '@/utils/auth'
import {saveAs} from 'file-saver'
import JSZip from 'jszip'
import axios from 'axios'

export default {
  name: 'EnterpriseFilesList',
  components: {FileClass, Split, FunctionHeader, SearchForm},
  mixins: [conventionMixin],
  data() {
    return {
      api,
      modifyFormList,
      formList,
      baseFormData: {},
      params: {
        radio: 'EnterpriseDocument'
      },
      fileFormData: {},
      resetVisible: false,
      uploadVisible: false,
      fileList: [],
      attachmentsList: [],
      uploadProp: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: {token: getToken()},
        showFileList: true,
        onSuccess: this.fileUploadChange,
        onRemove: this.fileRemove,
        onError: this.fileUploadChange
      },
      selectionData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            tagName: 'el-button',
            props: {type: 'primary', size: 'small'},
            style: {width: 'auto', background: '#607FFF'},
            innerText: '上传',
            on: {
              click: this.addFile
            }
          },
          // {
          //   tagName: 'el-button',
          //   props: { type: 'primary', size: 'small' },
          //   style: { width: 'auto', background: '#607FFF' },
          //   innerText: '批量下载',
          //   on: {
          //     click: this.downLoadFile
          //   }
          // }
        ],
        importName: '',
        exportName: '',
        exportParams: {}
      },
      columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      split: {
        offset: 15
      },
      materialsClassId: '',
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      enterpriseDocumentClassId: undefined,
      list: [],
      getTableDataParams: {},
      customersList: []
    }
  },
  computed: {
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionData.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    },
    height() {
      return this.$store.state.app.contentHeight - 235 - 40
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 75
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'refresh') {
      this.$store.dispatch('tagsView/addCachedView', from)
    }
    next()
  },
  async mounted() {
    if (this.memberCode === 'bbelc') {
      this.formList = formListBbelc
      this.searchFormList = searchFormListBbelc
      this.modifyFormList = modifyFormListBbelc
    }
    await this.getUser()
    await this.getCustomers()
  },
  methods: {
    resetSelectData() {
      this.selectionData.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionData = this.selectionData.filter((item) => item.selectable)
    },
    downloads(href) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url: href,
          responseType: 'arraybuffer'
        }).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error.toString())
        })
      })
    },
    downLoadFile() {
      if (this.selectionData.length === 0) {
        return this.$message.error('请选择要下载的文件')
      }
      const zip = new JSZip()
      const cache = {}
      const promises = []
      this.selectionData.forEach(item => {
        const promise = this.downloads(item.ossAddress).then(data => {
          zip.file(item.name, data, {binary: true}) // 逐个添加文件
          cache[item.name] = data
        })
        promises.push(promise) // 加到promises队列里
      })
      Promise.all(promises).then(() => { // 异步队列全部完成时 执行下面代码
        zip.generateAsync({type: 'blob'}).then(content => { // 生成二进制流
          saveAs(content, '企业文件打包下载.zip') // 利用file-saver保存文件
        })
      })
    },
    download(file) {
      saveAs(file.ossAddress, file.name)
    },
    fileRemove(file, fileList) {
      this.list = fileList.length > 0 ? fileList.map(file => {
        return {
          uid: file.uid,
          name: file.name,
          url: file.response.result
        }
      }) : []
    },
    // 上传附件
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.list.push({
          uid: file.uid,
          name: file.name,
          url: res.result
        })
        return this.$message.success('上传成功')
      }
      if (res.errorCode !== 0) {
        return this.$message.error(res.result)
      }
    },
    async submitFormPlan(callback) {
      if (this.list.length === 0) {
        callback(true)
        return this.$message.error('上传文件不能为空')
      }
      const {form} = this.$refs.baseForm
      const ssd = this.customersList.find(item => item.id === form.customerId)
      const data = this.list.map(item => {
        return {
          enterpriseDocumentClassId: form.enterpriseDocumentClassId,
          name: item.name,
          ossAddress: item.url,
          customerId:form.customerId,
          customerName:ssd.name,
          factoryNumber:form.factoryNumber,
          time:form.time
        }
      })
      this.$refs.baseForm.formRefs().validate(async (valid) => {
        if (valid) {
          const res = await api.addBatchEnterpriseDocument(data)
          if (res) {
            this.list = []
            this.$refs.mTable.setTableData(true)
            this.uploadVisible = false
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    cancel() {
      console.log('cancel')
      this.list = []
      this.uploadVisible = false

    },
    async submitFormReset(callback) {
      const {form} = this.$refs.resetForm
      const ssd = this.customersList.find(item => item.id === form.customerId)
      this.$refs.resetForm.formRefs().validate(async (valid) => {
        if (valid) {
          const res = await api.modifyEnterpriseDocument({
            id: form.id,
            name: form.name,
            enterpriseDocumentClassId: form.enterpriseDocumentClassId,
            ossAddress: form.ossAddress,
            customerId:form.customerId,
            customerName:ssd.name,
            factoryNumber:form.factoryNumber,
            time:form.time
          })
          if (res) {
            this.resetVisible = false
            this.$message.success('修改成功')
            this.$refs.mTable.setTableData(true)
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    // 获取系统用户
    async getUser() {
      const res = await uApi.getUserList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            },
            {
              id: getUUid(),
              fieldName: 'isSystemUser',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.searchFormList[0].children[0].options = res
      }
    },
    // 获取目录
    async getClassList(val) {
      const res = await classApi.getEnterpriseDocumentClassList()
      if (res) {
        if (val === 1) {
          this.modifyFormList[1].children[0].options = res
        } else {
          this.formList[0].children[0].options = res
        }
      }
    },
    selectionChange(data) {
      this.selectionData = data
    },
    setMaterialsClassId(id) {
      this.enterpriseDocumentClassId = id
      this.getTableDataParams.classId = id
      this.$refs.mTable.setTableData(true)
    },
    // 查询物料
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },
    async addFile() {
      await this.getClassList()
      this.uploadVisible = true
    },
    async getCustomers() {
      const res = await classApi.nbgGetCustomers()
      if (res) {
        this.customersList = res.records.map(item => {
          return {
            id: item.id,
            name: item.name
          }
        })
        if (this.memberCode === 'bbelc') {
          this.formList[2].children[0].options = this.customersList
          this.searchFormList[2].children[0].options = this.customersList
          this.modifyFormList[2].children[0].options = this.customersList
        }
      }
    },
    async modifys(row) {
      await this.getClassList(1)
      this.resetVisible = true
      this.fileFormData = this._.cloneDeep(row)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.materials-wrap {
  width: 100%;

  .bottom {
    width: 100%;
    height: 100%;
    padding: 10px;

    .materials-info {
      padding: 10px;
    }
  }
}

.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}

::v-deep {
  .params-card {
    .body {
      padding: 0 !important;
    }

    .params {
      width: 100% !important;
    }

    .el-form-item__label {
      padding: 0 12px 0 0 !important;
    }
  }
}
</style>
