<template>
  <div class="scrap-wrap">
    <h2 style="margin-left: 20px">设备看板数据生成</h2>
    <div class="top">
      <el-row style="padding: 10px 10px 10px 10px;">
        <el-col :span="8">
          <el-date-picker
            @change="timeChange"
            style="width: 95%"
            v-model="valueTimeRange"
            type="daterange"
            align="right"
            unlink-panels
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-col>

        <el-col :span="4">
          <el-button type="primary" size="small" style="background:#607FFF" @click="topSearch">生成数据</el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

import api from '@/api/toolManagement/toolLeader/index.js'
import dayjs from "dayjs";
import axios from "axios";

export default {
  name: 'WeldingProcessSpecification2',
  data() {
    return {
      valueTimeRange: [
        dayjs().format('YYYY-MM-DD'),
        dayjs().add(7, 'day').format('YYYY-MM-DD')
      ]
    }
  },
  methods: {
    async topSearch() {
      console.log(this.valueTimeRange)
      if (!this.valueTimeRange || !this.valueTimeRange.length) {
        this.$message.error('请选择时间范围')
        return
      }
      console.log(process.env.NODE_ENV)

      const devOrProd = process.env.NODE_ENV
      const memberCode = sessionStorage.getItem("memberCode")
      if (memberCode === 'fsd') {
        let url = '/api/web/v1/updateInterface/private/simulate?startTime=' + this.valueTimeRange[0]
          + '&endTime=' + this.valueTimeRange[1]
        if (devOrProd === "development") {
          console.log('开发环境')
          url = 'http://10.100.100.182:4407' + url
        } else {
          console.log('生产环境')
          url = 'http://10.100.100.78:4407' + url
        }

        const loading = this.$loading({
          lock: true,
          text: '数据处理中，请稍等~(时间会有点久哦，不要着急~)',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        axios.get(url).then(res => {
          loading.close();
          if (res.status === 200) {
            if (res.data.errorCode === 0) {
              this.$message.success('数据生成成功')
              return
            } else {
              this.$message.error('接口调用失败：' + res.data.errorMsg)
              return
            }
          } else {
            this.$message.error('接口调用失败')
            return
          }
        })
      } else {
        this.$message.error('租户信息有误')
        return
      }
      // const res = await api.addWeldLayingOff({...this.dataForAdd})
      // if (res) {
      //   this.$message.success('新增成功')
      //   this.visiable = false
      //   this.$refs.mTable.setTableData(true)
      // }
    },
    timeChange(e) {
      if (e && e.length) {
        if (new Date(e[1]).getTime() - new Date(e[0]).getTime() > 86400000 * 7) {
          this.$message.error('时间范围不能超过7天')
          this.valueTimeRange = []
          return
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.ttt {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .ttt-label {
    width: 100px;
    text-align: right;
    padding-right: 10px;
    line-height: 32px;
    font-size: 14px;
  }
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;

  .bottom {
    padding: 10px;
  }

  .m-radio-group {
    margin-left: 10px;
  }
}

::v-deep {
  .e-divider {
    margin: 0px 0 0 0;
  }

  .el-form-item__content {
    height: 32px;
  }

  .avatar-uploader {
    .el-upload {
      border: 1px dashed #d9d9d9 !important;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 38px;
        height: 38px;
        line-height: 38px;
        text-align: center;
      }
    }
  }
}
</style>
