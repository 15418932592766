
export const columnsAll = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode',
    minWidth: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 220
  },
  {
    prop: 'materialsQuality',
    label: '材质',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    slotName: 'unitName',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'currentQuantity',
    label: '当前库存',
    slotName: 'currentQuantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'lockStock',
    label: '锁定库存',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'availableQuantity',
    label: '可用库存',
    slotName: 'availableQuantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'upperLimit',
    label: '库存上限',
    slotName: 'upperLimit',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'lowerLimit',
    label: '库存下限',
    slotName: 'lowerLimit',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  }
  // {
  //   prop: 'materialsType',
  //   label: '物料类型',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  // {
  //   prop: 'stayInWarehouseQuantity',
  //   label: '待入库数',
  //   hiddenSearch: true,
  //   sortable: 'custom',
  //   minWidth: 180
  // },
  // {
  //   prop: 'stayOutWarehouseQuantity',
  //   label: '待出库数',
  //   hiddenSearch: true,
  //   sortable: 'custom',
  //   minWidth: 180
  // },
  // {
  //   prop: 'availableQuantity',
  //   label: '可用库存',
  //   slotName: 'availableQuantity',
  //   hiddenSearch: true,
  //   sortable: 'custom',
  //   minWidth: 180
  // },
  // {
  //   prop: 'containerQuantity',
  //   label: '箱数',
  //   minWidth: 100,
  //   fixed: 'right',
  //   slotName: 'packageNumber',
  //   hiddenSearch: true,
  //   sortable: false
  // },
  // {
  //   prop: 'scatterQuantity',
  //   label: '散数',
  //   minWidth: 100,
  //   fixed: 'right',
  //   slotName: 'otherNumber',
  //   hiddenSearch: true,
  //   sortable: false
  // }
]

export const columnsDetail = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode',
    minWidth: 180,
    index: 1
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180,
    index: 2
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    slotName: 'unitName',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200
  },
  {
    prop: 'warehouseName',
    label: '仓库',
    hiddenSearch: true,
    sortable: false,
    slotName: 'warehouseName',
    minWidth: 180
  },
  {
    prop: 'warehouseLocationName',
    label: '库位',
    hiddenSearch: true,
    sortable: false,
    slotName: 'warehouseLocation',
    minWidth: 240
  },
  {
    prop: 'currentQuantity',
    label: '当前库存',
    slotName: 'currentQuantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'width',
    label: '宽度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'thickness',
    label: '厚度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'convertValue',
    label: '米数',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'steelGrade',
    label: '钢卷号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'coilNo',
    label: '牌号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'operateTime',
    label: '操作时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'lockStock',
    label: '锁定库存',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'availableQuantity',
    label: '可用库存',
    slotName: 'availableQuantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  // {
  //   slotName: 'action',
  //   label: '操作',
  //   fixed: 'right',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 100
  // }
]

export const columnsPackage = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'containerCode',
    label: '包装箱码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'name',
    label: '包装容器',
    hiddenSearch: true,
    sortable: false,
    slotName: 'container'
  },
  {
    prop: 'quantity',
    label: '数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'warehouseLocationName',
    label: '库区 - 库位',
    hiddenSearch: true,
    sortable: false,
    slotName: 'warehouseLocation'
  },
  {
    prop: 'batchNumberList',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    slotName: 'batchNumberList'
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 220
  }
]

export const columnsOthers = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'quantity',
    label: '数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'warehouseLocationName',
    label: '库区 - 库位',
    hiddenSearch: true,
    sortable: false,
    slotName: 'warehouseLocation'
  }
]

export const columnsInventoryHistory = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'recordType',
    label: '出/入库',
    hiddenSearch: true,
    sortable: false,
    slotName: 'recordType'
  },
  {
    prop: 'type',
    label: '类型',
    hiddenSearch: true,
    sortable: false,
    slotName: 'historyType'
  },
  {
    prop: 'confirmTime',
    label: '出/入库日期',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'warehouseLocationName',
    label: '库区 - 库位',
    hiddenSearch: true,
    sortable: false,
    slotName: 'warehouseLocation'
  },
  {
    prop: 'palletCode',
    label: '托盘',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'operateTime',
    label: '制单时间',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'operator',
    label: '制单人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'quantity',
    label: '数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'billNumber',
    label: '出/入库单号',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'sourceBillNumber',
    label: '源单单据',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  }
]

export const columnsMaterial = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode',
    minWidth: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 220
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    slotName: 'unitName',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'currentQuantity',
    label: '当前库存',
    slotName: 'currentQuantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'lockStock',
    label: '锁定库存',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'availableQuantity',
    label: '可用库存',
    slotName: 'availableQuantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  }
]

export const columnsBatch = [
  {
    type: 'selection',
    minWidth: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    slotName: 'materialsCode',
    minWidth: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'materialsSpecifications',
    label: '规格型号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 220
  },
  {
    prop: 'materialsUnit',
    label: '单位',
    slotName: 'unitName',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'currentQuantity',
    label: '当前库存',
    slotName: 'currentQuantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'lockStock',
    label: '锁定库存',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  },
  {
    prop: 'availableQuantity',
    label: '可用库存',
    slotName: 'availableQuantity',
    hiddenSearch: true,
    sortable: false,
    minWidth: 180
  }
]
export const spareColumns = [
  {
    prop: 'containerNo',
    label: '容器编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'containerName',
    label: '容器名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'capacity',
    label: '容量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'containerQuantity',
    label: '整件数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'containerLock',
    label: '整件锁定数量',
    sortable: false,
    hiddenSearch: true
  }
]

