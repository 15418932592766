<template>
  <div class="equipment-board-container">
    <div class="top">
      <div class="fault-sort">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">月度工时统计</div>
        <charts ref="customTable" :tab-info="child" />
      </div>
      <div class="defects">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">客户设备数量</div>
        <charts ref="customTable" :tab-info="child2" />
      </div>
      <div class="defects">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">设备质保状态</div>
        <charts ref="customTable" :tab-info="child3" />
      </div>
    </div>
    <div class="top" style="margin-top: 30px">
      <div class="fault-sort">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">用量排行</div>
        <charts ref="customTable" :tab-info="child4" />
      </div>
      <div class="defects">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">存量分析</div>
        <charts ref="customTable" :tab-info="child5" />
      </div>
      <div class="defects">
        <div class="gradient-title" style="background: linear-gradient(90deg, #1053FF 0%, rgba(96, 127, 255, 0) 100%);">新增工单(默认昨日)</div>
        <charts ref="customTable" :tab-info="child6" />
      </div>
    </div>
  </div>
</template>

<script>
import mix from '@/views/statistics-board/board-detail/components/mixin'
import scrollBoard from '@/components/DataVComponents/ScrollBoard/ScrollBoard.vue'
import api from '@/api/statistics-board/all-statistics'
import { baseUrl, colorList } from '@/views/statistics-board/board-detail/components/base-setting'
import dayjs from 'dayjs'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { warehouseRecordTypeIn, warehouseRecordTypeOut } from '@/config/options.sales'
import Charts from "@/views/statistics-board/board-manage/components/charts/charts-xw.vue";
import {getDateRange} from "@/views/statistics-board/board-manage/all-statistics/sale-statistics-card";

const scrollBoardSettingDefault = {
  carousel: 'page',
  headerHeight: 32,
  hoverPause: false,
  waitTime: 10000,
  headerBGC: '#222222',
  oddRowBGC: '#222222',
  evenRowBGC: 'transparent'
}
export default {
  name: 'Inventory2',
  components: {Charts, scrollBoard },
  mixins: [mix],
  data() {
    return {
      child:{
        id: '41-1',
        name: '月度工时统计',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getMonthManHourResultList',
        chartDataKey: 'outputHours',
        chartType: 'barAndLine',
        dataCondition: {},
        chartLegendKey: 'time',
        chartXKey: 'time',
        size: 999,
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM',
            canNoSelect: true,
            isStartName: true
          },
          {
            type: 'select',
            key: 'userGroupId',
            dataUrl: 'basicData/settings/private/userGroup/getUserGroupList',
            dataKey: 'userGroupList',
            placeholder: '请选择用户组'
          }
        ]
      },
      child2:{
        id: 43,
        name: '客户设备数量',
        type: 'charts',
        dataUrl: 'displayBoard/private/displayBoard/getCustomerByParams',
        chartDataKey: 'quantity',
        chartColumns: ['客户', '次数'],
        chartType: 'bar',
        dataCondition: {},
        chartLegendKey: 'name',
        chartXKey: 'name',
        dataZoom: true,
        searches: [
          {
            type: 'cascader',
            key: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true,
                multiple: true
              }
            },
            placeholder: '设备分类'
          }
        ],
        columnsType: 'vertical'
      },
      child3:{
        id: '46-3',
        name: '设备质保状态',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getFacilityWarrantyStatusStatisticList',
        chartType: 'pie',
        chartDataKey: 'count',
        chartLegendKey: 'sign',
        chartXKey: 'sign',
        optionKey: 'quanlityInfoType',
        dataCondition: { beginTime: dayjs().format('YYYY-MM-DD'), endTime: dayjs().format('YYYY-MM-DD'), rightBeginTime: dayjs().format('YYYY-MM-DD'), rightEndTime: dayjs().format('YYYY-MM-DD') },
        hiddenInit: true,
        searches: [
          {
            type: 'cascader',
            key: 'equipmentClassId',
            asKey: 'equipmentClassIds',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          },
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            placeholder: '选择日期/默认当天'
          },
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true,
            placeholder: '选择对比日期/默认当天',
            keys: ['rightBeginTime', 'rightEndTime'],
            className: 'date-right'
          }
        ]
      },
      child4:{
        id: 54,
        name: '用量排行',
        type: 'charts',
        dataUrl: 'field/facility/invoices/private/facilityUpkeepWorkOrder/getUseAnalysis',
        chartDataKey: 'quantity',
        chartColumns: ['物料', '数量'],
        chartType: 'bar',
        dataCondition: { beginTime: getDateRange(0, 'YYYY-MM-DD')[0], endTime: dayjs().format('YYYY-MM-DD') },
        chartLegendKey: 'name',
        chartXKey: 'name',
        dataZoom: true,
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          }
        ],
        columnsType: 'vertical'
      },
      child5:{
        id: 55,
        name: '存量分析',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getMaterialsInventoryAnalysisList',
        chartDataKey: 'quantity',
        chartType: 'pie',
        chartLegendKey: 'materialsCode',
        chartXlabels: 'materialsName',
        chartOverlap: true,
        dataCondition: { beginTime: getDateRange(0, 'YYYY-MM-DD')[0], endTime: dayjs().format('YYYY-MM-DD') },
        searches: [
          {
            type: 'select',
            key: 'warehouseIdStr',
            dataUrl: 'warehouse/private/warehouse/getWarehouseList',
            dataKey: 'warehouseIdStr',
            placeholder: '请选择仓库',
            props: {
              multiple: true
            }
          }
        ]
      },
      child6:{
        id: 57,
        name: '新增工单（默认昨日）',
        type: 'charts',
        dataUrl: 'statistics/private/facilityStatistics/getAddWorkOrderCount',
        chartDataKey: 'count',
        chartColumns: ['类型', '数量'],
        chartType: 'bar',
        chartLegendKey: 'sign',
        chartXKey: 'sign',
        dataCondition: { beginTime: getDateRange(7, 'YYYY-MM-DD')[0], endTime: getDateRange(7, 'YYYY-MM-DD')[1] },
        searches: [
          {
            type: 'dateRange',
            formatDateType: 'YYYY-MM-DD',
            canNoSelect: true
          },
          {
            type: 'cascader',
            key: 'equipmentClassId',
            dataUrl: 'basicData/private/equipmentClass/getEquipmentClassList',
            dataKey: 'equipmentClassList',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
                // multiple: true
              }
            },
            placeholder: '设备分类'
          },
          {
            type: 'cascader',
            key: 'customerSiteId',
            asKey: 'childrenIdList',
            dataUrl: 'basicData/private/customer/getAllCustomer',
            dataKey: 'allCustomer',
            props: {
              props: {
                label: 'name',
                value: 'id',
                checkStrictly: true
              }
            },
            placeholder: '客户/现场'
          }
        ],
        columnsType: 'vertical'
      },
      scrollBoardSettingDefault,
      digital: {
        equipmentQuantity: 0,
        maintenanceQuantity: 0,
        faultQuantity: 0
      },
      inspectionTaskData: [],
      repairTaskData: [],
      maintenanceTaskData: [],
      maintenanceChartData: {
        columns: ['物料', '数量'],
        rows: []
      },
      barChartExtend: {
        legend: {
          show: false,
          x: 'center',
          y: 'bottom',
          orient: 'horizontal'
        },
        xAxis: {
          name: '', // 坐标轴名称。
          nameLocation: 'end', // 坐标轴名称显示位置。
          axisLabel: { // 坐标轴刻度标签的相关设置。
            interval: 0,
            rotate: 0,
            formatter: function(value) {
              const val = value.split('^')
              return val[0] + '\n' + val[1]
            }
          },
          axisLine: { // 设置轴线的属性
            show: false,
            lineStyle: {
              color: '#000',
              width: 1
            }
          }

        },
        yAxis: {
          nameLocation: 'end', // 坐标轴名称显示位置。
          axisLine: { // 设置轴线的属性
            show: false,
            lineStyle: {
              color: '#000',
              width: 1
            }
          }
        }
      },
      scrollBoardConfig1: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 120, 150, 150, 150, 150, 100, 130],
        header: ['序号', '物料编号', '物料名称', '仓库', '流水方向', '库存操作', '单位', '数量'],
        data: []
      },
      scrollBoardConfig2: {
        ...scrollBoardSettingDefault,
        columnWidth: [60, 200, 200, 200, 200],
        header: ['序号', '物料编号', '物料名称', '仓库', '数量'],
        data: []
      },
      tableHeight: 100,
      inventoryDataTotal: 0,
      inventoryAimTotal: 0,
      pieChartExtend1: {
        color: colorList,
        legend: {
          top: 15,
          show: true,
          orient: 'vertical',
          right: 10,
          textStyle: {
            width: 10,
            color: '#fff',
            fontSize: 14,
            left: 20,
            overflow: 'break'
          }
        },
        series: {
          top: 0,
          avoidLabelOverlap: false,
          radius: ['40%', '70%'],
          center: ['30%', '50%'],
          label: {
            formatter: '\n\n {b} ',
            padding: [0, -25],
            alignTo: 'labelLine',
            color: '#fff'
          },
          labelLine: {
          },
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          }

        },
        tooltip: {
          trigger: 'item'
        }
      },
      pieChartExtend2: {
        color: colorList,
        legend: {
          top: 15,
          show: true,
          orient: 'vertical',
          right: 10,
          textStyle: {
            width: 10,
            color: '#fff',
            fontSize: 14,
            left: 20,
            overflow: 'break'
          }
        },
        series: {
          top: 0,
          avoidLabelOverlap: false,
          radius: ['40%', '70%'],
          center: ['30%', '50%'],
          label: {
            formatter: '\n\n {b} ',
            padding: [0, -25],
            alignTo: 'labelLine',
            color: '#fff'
          },
          labelLine: {
          },
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          }

        },
        tooltip: {
          trigger: 'item'
        }
      },
      defectsChartData: {
        columns: ['分类', '数量'],
        rows: []
      },
      typeChartData: {
        columns: ['属性', '数量'],
        rows: []
      },
      warehouseIds: '',
      createTime: [],
      maintenanceData: 0
    }
  },
  beforeMount() {

  },
  mounted() {
    this.reloadData()
  },
  methods: {
    getType(type, val) {
      let current
      if (val === 0) {
        current = warehouseRecordTypeIn.find(item => type === item.id)
      } else {
        current = warehouseRecordTypeOut.find(item => type === item.id)
      }
      return current && current.name
    },
    reloadData(warehouseIds = '', createTime = []) {
      this.warehouseIds = warehouseIds
      this.createTime = createTime || []
      if (this.createTime.length === 0) {
        this.createTime = [dayjs().subtract(2, 'month').format('YYYY-MM-DD') ,dayjs().format('YYYY-MM-DD')]
      }

      this.getDefectsChartData()
      this.getTypesChartData()
      this.getMaintenanceChartData()
      const search = []
      if (this.warehouseIds) {
        search.push({
          id: getUUid(),
          fieldName: 'warehouseId',
          fieldType: 'number',
          fieldValue: warehouseIds,
          operator: 'in'
        })
      }
      this.getTableData(baseUrl + 'getOutInSitWarehouseRecordList', {
        beginTime: this.createTime.length > 0 ? this.createTime[0] : '',
        endTime: this.createTime.length > 0 ? this.createTime[1] : '',
        warehouseIds: this.warehouseIds
      }, {
        repeat: true,
        key: 'scrollBoardConfig1',
        key2: 'inventoryData',
        columns: this.scrollBoardConfig1.header,
        columnWidth: [60, 120, 150, 150, 150, 150, 100, 130],
        rowNum: 10,
        columnsKey: ['index', 'materialsCode', 'materialsName', 'warehouseName', 'slot:recordType', 'slot:type', 'materialsUnit', 'quantity']
      })
      this.getTableData(baseUrl + 'getWarehouseSoftStockList', {
        // beginTime: this.createTime.length ? this.createTime[0] : '',
        // endTime: this.createTime.length ? this.createTime[1] : '',
        warehouseIds: this.warehouseIds
      }, {
        repeat: true,
        key: 'scrollBoardConfig2',
        key2: 'inventoryAimData',
        columns: this.scrollBoardConfig2.header,
        columnWidth: [60, 200, 200, 200, 200],
        rowNum: 10,
        columnsKey: ['index', 'materialsCode', 'materialsName', 'warehouseName', 'slot:sumQuantity']
      })
    },
    async getDefectsChartData() {
      const res = await api.getTableData(baseUrl + 'getOutInSitWarehouseRecordByClass', {
        // beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        // endTime: dayjs().format('YYYY-MM-DD'),
        warehouseIds: this.warehouseIds
      })
      if (res) {
        this.pieChartExtend1.legend.formatter = function(name) {
          for (var i = 0; i < res.length; i++) {
            var dpercent = res[i].sumQuantity
            var ratio = res[i].rate
            if (res[i].name === name) {
              return `${name} | ${ratio}%  ${dpercent} `
            }
          }
        }
        this.defectsChartData.rows = res.map(item => ({
          '分类': item.name,
          '数量': Number(item.sumQuantity)
        }))
      }
    },
    async getTypesChartData() {
      const res = await api.getTableData(baseUrl + 'getOutInSitWarehouseRecordByType', {
        // beginTime: dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        // endTime: dayjs().format('YYYY-MM-DD'),
        warehouseIds: this.warehouseIds
      })
      if (res) {
        this.pieChartExtend2.legend.formatter = function(name) {
          for (var i = 0; i < res.length; i++) {
            var dpercent = res[i].sumQuantity
            var ratio = res[i].rate
            if (res[i].name === name) {
              return `${name} | ${ratio}%  ${dpercent} `
            }
          }
        }
        this.typeChartData.rows = res.map(item => ({
          '属性': item.name,
          '数量': Number(item.sumQuantity)
        }))
      }
    },
    async getMaintenanceChartData() {
      const res = await api.getTableData(baseUrl + 'getMaterialsUsageStatistics', {
        beginTime: this.createTime.length > 0 ? this.createTime[0] : dayjs().subtract(6, 'day').format('YYYY-MM-DD'),
        endTime: this.createTime.length > 0 ? this.createTime[1] : dayjs().format('YYYY-MM-DD'),
        warehouseIds: this.warehouseIds
      })
      if (res) {
        this.maintenanceChartData.rows = res.map(item => ({
          '物料': `${item.materialsCode}^${item.materialsName}`,
          '数量': Number(item.sumQuantity)
        }))
        this.maintenanceData = res.reduce((sum, item) => { return sum + Number(item.sumQuantity) }, 0)
      }
    },
    getHeight() {
      const ww = window.innerWidth / 1920
      const wh = window.innerHeight / 1080
      const scale = ww < wh ? ww : wh
      this.tableHeight = (window.innerHeight * 0.48) / scale
    }
  }
}
</script>

<style scoped lang="scss">
@import "./common";

.equipment-board-container {
  padding: 0 20px 20px 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  .icon-lowerLimit,
  .icon-upperLimit {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  .icon-upperLimit {
    background: url("../../../../assets/inventory/icon-arrow.png");
    background-size: contain;
  }
  .icon-lowerLimit {
    transform: scaleY(-1);
    background: url("../../../../assets/inventory/icon-arrow.png");
    background-size: contain;
  }
  .top, .center {
    display: flex;
    justify-content: space-between;

    > div {
      width: 596px;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    >div {
      width: 878px;
      .gradient-title {
        margin-bottom: 10px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .top {
    .equipment-info {
      display: flex;
      justify-content: space-between;

      .card-info {
        position: relative;
        width: 162px;

        img {
          width: 162px;
        }

        > div {
          color: #FFFFFF;
          font-size: 23px;
          text-align: center;
          padding: 20px 0;
        }

        ::v-deep {
          .dv-digital-flop {
            position: absolute;
            top: 10px;
          }
        }
      }
    }
  }
}
::v-deep .dv-scroll-board .ceil {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  >span {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.status-board {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 44px;
}
.status-board-r {
  display: inline-block;
  width: calc(100% + 10px);
  height: 100%;
  text-align: center;
  line-height: 44px;
}
</style>
