import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/authority/private/role/'
const baseUrl1 = 'api/web/v1/authority/private/dataPermission/'

export default {
  // 获取用户-不分页
  getRoleList(data) {
    return request.get(baseUrl + 'getRoleList', data)
  },
  // 获取用户-分页
  getRoleListPage(data) {
    return request.get(baseUrl + 'getRoleListPage', data)
  },
  // 新增用户
  addRole(data) {
    return request.post(baseUrl + 'addRole', data)
  },
  // 新增用户
  copyRole(data) {
    return request.post(baseUrl + 'addRole', data)
  },
  // 修改用户
  modifyRole(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除用户
  delRole(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  getRoleListByUserId(userId) {
    return request.get(baseUrl + 'getRoleListByUserId', { userId })
  },
  createCode(params) {
    return request.get(baseUrl + 'getMaxRoleCode', params)
  },
  // 取得数据权限列表(不分页)
  getDataPermissionList(data) {
    return request.get(baseUrl1 + 'getDataPermissionList', data)
  },
  updateDataPermission(data) {
    return request.put(baseUrl1 + 'updateDataPermission', data)
  },
  // 获取自定义数据规则组相关信息
  getCustomDataRuleInfo(data) {
    return request.get(baseUrl1 + 'getCustomDataRuleInfo', data)
  },
  // 新增自定义数据规则组
  addCustomDataRuleGroup(data) {
    return request.post(baseUrl1 + 'addCustomDataRuleGroup', data)
  },
  // 修改自定义数据规则组
  updateCustomDataRuleGroup(data) {
    return request.put(baseUrl1 + 'updateCustomDataRuleGroup', data)
  },
// 删除自定义数据规则组
deleteCustomDataRuleGroup(data) {
    return request.del(baseUrl1 + 'deleteOne', data)
  },
  getDataRuleGroupDetail(data) {
    return request.get(baseUrl1 + 'getDataRuleGroupDetail', data)
  },
  // 取消关联
  cancelRoleMappingUser(data) {
    return request.del(baseUrl + 'cancelRoleMappingUser', data)
  },
  // 关联用户
  roleMappingUser(data) {
    return request.post(baseUrl + 'roleMappingUser', data)
  },
  // 获取角色详情
  getRoleDetail(data) {
    return request.get(baseUrl + 'getRoleDetail', data)
  },
}
