<template>
  <div class="orderAdd">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>来料检详情</span>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-col :span="6">
          <span class="bot-ti">来料检单号：{{ detailData.code }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">检验批次：{{ detailData.qualifiedBatchNumber }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">批次数量：{{ detailData.batchQuantity }}</span>
        </el-col>
        <el-col :span="6" v-if="detailData.isEnableSequenceNumber">
          <span class="bot-ti">序列号：{{ detailData.sequenceNumber }}</span>
        </el-col>
        <!-- <el-col :span="6">
            <span class="bot-ti">质检状态：{{ detailData.status || detailData.status==0 ? qualityTaskStatus[detailData.status].name:'' }}</span>
          </el-col> -->
        <el-col :span="6">
          <span class="bot-ti">物料编码：
          <NewPageOpen :path="{ name: 'materialsDetail', query: { id: detailData.materialsId, type: 'view' }}" :text="detailData.materialsCode" />
          </span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">物料名称：{{ detailData.materialsName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">物料规格：{{ detailData.materialsSpecifications || '-' }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">是否关键件：{{ detailData.isKeyMaterials ? '是' : '否' }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">是否免检：{{ detailData.isExemption ? '是' : '否' }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">收料单号：{{ detailData.materialsReceivedOrderCode }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">采购单号：{{ detailData.purchaseOrderCode }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">供应商：{{ detailData.supplierName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">检验方案：{{ detailData.inspectionSchemeName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">计划检验人：{{ detailData.executeUserName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">附件：
            <el-link v-for="item in detailData.attachment" :key="item.id" :href="item.url" target="_blank"
                     style="padding-left:10px;">{{ item.name }}</el-link>
         </span>
        </el-col>
        <el-col :span="6">
          <span v-if="type === 'view'" class="bot-ti">备注：{{ detailData.remark || '-' }}</span>
          <div v-else style="display: flex;align-items: center;">
            <span class="bot-ti" style="margin-bottom: 0px;">备注：</span>
            <el-input v-model="detailData.remark" placeholder="" style="width:80%" />
          </div>
        </el-col>
        <el-col :span="6" v-if="detailData.result == 0">
          <span class="bot-ti">处理意见：{{ detailData.suggestion }}</span>
        </el-col>
      </div>
    </div>

    <div class="detail-card">
      <div class="card-tit">检验统计</div>
      <div class="card-bot">

        <el-col :span="6">
          <span v-if="type === 'view' || detailData.isEnableSequenceNumber" class="bot-ti">合格数量：{{
            detailData.qualifiedQuantity != null ? detailData.qualifiedQuantity : '-' }}</span>

          <div v-else style="display: flex;align-items: center;">
            <span class="bot-ti" style="margin-bottom: 0px;">合格数量：</span>
            <el-input v-model="detailData.qualifiedQuantity" placeholder="" style="width:65%" />
          </div>
        </el-col>
        <el-col :span="6" v-if="detailData.isEnableSequenceNumber">
          <span class="bot-ti">合格序列号：{{ detailData.qualifiedSequenceNumber || '-' }}</span>
        </el-col>
        <el-col :span="6">
          <span v-if="type === 'view' || detailData.isEnableSequenceNumber" class="bot-ti">不良数量：{{
            detailData.defectQuantity != null ? detailData.defectQuantity : '-' }}</span>
          <div v-else style="display: flex;align-items: center;">
            <span class="bot-ti" style="margin-bottom: 0px;">不良数量：</span>
            <el-input v-model="detailData.defectQuantity" placeholder="" style="width:65%" />
          </div>
        </el-col>
        <el-col :span="6" v-if="detailData.isEnableSequenceNumber">
          <span class="bot-ti">不良序列号：{{ detailData.defectSequenceNumber || '-' }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">不良批次：{{ detailData.defectBatchNumber || '-' }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">检验数量：{{ detailData.inspectionQuantity || '-' }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">检验人：{{ detailData.inspectionUserName || '-' }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">开始检验时间：{{ detailData.startTime || '-' }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">完成检验时间：{{ detailData.endTime || '-' }}</span>
        </el-col>
      </div>
    </div>

    <div class="detail-card">
      <div class="tables" style="padding: 10px;margin-bottom: 50px;">
        <div v-if="projectLength > 0">
          <div style="font-size:14px;padding-bottom:10px;">
            <i class="el-icon-arrow-left"
              :style="{ color: currentPage == 1 ? '#C0C4CC' : '#303133', cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }"
              @click="beforePage()"></i>

            <div v-for="(item, index) in detailData.sequenceNumberItemList" :key="item.sequenceNumber"
              :style="{ color: currentPage == index + 1 ? '#303133' : '#C0C4CC' }"
              style="display:inline-block;min-width:35px;text-align:center;padding:0 4px">{{ item.sequenceNumber }}</div>

            <i class="el-icon-arrow-right"
              :style="{ color: currentPage == projectLength ? '#C0C4CC' : '#303133', cursor: currentPage == projectLength ? 'not-allowed' : 'pointer' }"
              @click="nextPage()"></i>
          </div>
        </div>
        <el-tabs id="sortTabs" ref="sortTab" type="card" v-model="currentTabs">
          <el-tab-pane v-for="(item, index) in projectList" :key="index" :label="item.inspectionGroupName || '请选择'"
            :name="'tab' + index" />
        </el-tabs>
        <el-card v-for="(card, cardIndex) in newprojectList[currentPage - 1]" v-show="('tab' + cardIndex) === currentTabs"
          :key="cardIndex" class="card-custom">
          <MTable ref="mTable" :show-page="false" :height="400" :columns="columnsDetail" :data="card.inspectionItemList">
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="attachments" slot-scope="{ row,$index }">
              <div v-if="type === 'view'">
                <div v-if="row.attachments" style="color:#6B8AFF;cursor: pointer;" @click="viewPicture(row)">查看</div>
                <div v-else>-</div>
              </div>
              <div v-else>
                <el-upload ref="upload" :file-list="row.attachments" accept=".png,.jpg" v-bind="uploadProps" :on-remove="(file)=> fileRemove(file, cardIndex, $index)">
                  <div style="color:#6B8AFF;cursor: pointer;" @click="getRowIndex(cardIndex, $index)">上传</div>
                </el-upload>
              </div>
            </div>
            <div slot="measuredValue" slot-scope="{ row }">
              <span v-if="type === 'view'" :style="{ color: fixData(row) ? '#E02020' : '' }">{{ row | fixValue }}</span>
              <div v-else>

                <el-input-number v-if="row.parameterType === 0" v-model="row.measuredValue" controls-position="right"
                  style="width:100%;"
                  :class="(row.standardValue != null) ? row.measuredValue > (row.upperLimit + row.standardValue) || row.measuredValue < (row.standardValue - row.lowerLimit) ? 'red-group' : '' : ''"
                  @change="(val) => { handleChange(val, row) }" />
                <el-input v-if="row.parameterType === 1" v-model="row.measuredValue" />
                <el-select v-if="row.parameterType === 2" v-model="row.measuredValue">
                  <el-option v-for="val in row.possibleValue" :key="val" :label="val" :value="val" />
                </el-select>
                <el-select v-model="row.measuredValue" multiple v-if="row.parameterType === 3">
                  <el-option v-for="val in row.possibleValue" :key="val" :label="val" :value="val" />
                </el-select>
                <el-select v-if="row.parameterType === 4" v-model="row.measuredValue" clearable>
                  <el-option v-for="val in resultList" :key="val.id" :label="val.name" :value="val.id" />
                </el-select>
              </div>

            </div>

            <div slot="remark" slot-scope="{ row }">
              <span v-if="type === 'view'">{{ row.remark }}</span>
              <el-input v-else v-model="row.remark" />
            </div>
            <!-- <div slot="defectItemName" slot-scope="{ row }">
              <span>{{row.defectItemName}}</span>
            </div>
            <div slot="defectReasonName" slot-scope="{ row }">
              <span >{{row.defectReasonName}}</span>
            </div> -->
          </MTable>
        </el-card>

        <div v-for="(item, index) in detailData.sequenceNumberItemList" :key="item.sequenceNumber">
          <div class="card-bot" style="align-items: center;" v-show="index + 1 === currentPage">
            <span class="bot-ti" style="margin-bottom: 0px;" v-if="type === 'view'">合格状态：{{ item.sequenceNumberResult === 0
              ? '不合格' : item.sequenceNumberResult === 1 ? '合格' : '-' }}</span>
            <div v-else>
              <span class="bot-ti" style="margin-bottom: 0px;">合格状态：</span>
              <el-select v-model="item.sequenceNumberResult">
                <el-option v-for="val in resultList" :key="val.name" :label="val.name" :value="val.id" />
              </el-select>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="returnList">{{ type !== 'view' ? '取 消' : '返 回' }}</el-button>
        <el-button v-if="type !== 'view'" type="primary" @click="save(0)" :loading="loading">暂 存</el-button>
        <el-button v-if="type !== 'view'" type="primary" @click="save(1)" :loading="loading">完成检验</el-button>
      </div>
    </div>
    <div class="lines" />
    <!-- 现场图片 -->
    <MDialog v-model="pictureVisible" title="现场图片" width="600px" height="500px" :show-footer="false"
      @onOk="pictureVisible = false">
      <el-image v-for="item in formatArr(imgList)" :key="item" style="padding-left: 10px" :src="item"
        class="table-row-img" :preview-src-list="formatArr(imgList)" />
    </MDialog>
  </div>
</template>
<script>
import { columnsDetail } from './columns'
import { planStatus, orderSource, productionStatus, qualityTaskStatus } from '@/config/options.config'
import workApi from '@/api/production/work-order'
import SelectWorkOrder from '@/components/SelectWorkOrder/SelectWorkOrder'
import saveAs from 'file-saver'
import api from '@/api/qualityManagement/quality-task'
import { getToken } from '@/utils/auth'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'

export default {
  name: 'QualityIncomingTaskDetail',
  components: { SelectWorkOrder, NewPageOpen },
  filters: {
    fixValue(val) {
      if (val) {
        if (val.parameterType === 4) {
          return val.measuredValue === 1 ? '合格' : val.measuredValue === 0 ? '不合格' : '-'
        } else {
          return val.measuredValue ? val.measuredValue : '-'
        }
      }
    },
  },
  data() {
    return {
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: true,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onChange: this.fileUploadChange,
        beforeUpload: this.beforeUpload,
        onPreview: this.handlePreview
      },
      fileList: [],
      currentTabs: 'tab0',
      columnsDetail,
      planStatus,
      orderSource,
      productionStatus,
      allWorkTask: [],
      userList: [],
      customTag: [],
      qualityTaskStatus,
      showPlanOrder: false,
      showWorkOrder: false,
      show: false,
      orderList: [],
      detailData: {
        qualifiedQuantity: '',
        sequenceNumberItemList: []
      },
      imgList: [],
      resultList: [
        { id: 1, name: '合格' },
        { id: 0, name: '不合格' }
      ],
      pictureVisible: false,
      activeName: 'first',
      relationType: 0,
      id: undefined,
      processVisible: false,
      saveButton: false,
      currentProcedureIds: [],
      currentProcedureName: '',
      currentPage: 1,
      projectLength: 0,
      activeTabName: '按检验对象',
      tableData: [],
      tableHeader: {},
      loading: false,
      type: '',
      projectList: [],
      status: '',
      startTime: '',
      chooseList: [],
      transferTableDatass: [],
      newprojectList: [],
      isShowWarn: false,
      rowIndex: -1,
      columIdx: -1
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 40 - 54 + 29 - 62 - 22
    },
    computedObj() {
      return JSON.parse(JSON.stringify(this.detailData.sequenceNumberItemList))
    }
  },
  watch: {
    'computedObj': {
      handler(newVal, oldVal) {
        this.detailData.defectQuantity = newVal.filter(item => item.sequenceNumberResult === 0).length
        this.detailData.qualifiedQuantity = this.detailData.batchQuantity - this.detailData.defectQuantity
      },
      deep: true
    }
  },
  mounted() {
    this.type = this.$route.query.type
    this.startTime = this.$route.query.startTime
    this.getOrderDetail(this.$route.query.id)
  },
  methods: {
    getRowIndex(index, idx) {
      this.columIdx = index
      this.rowIndex = idx
    },
    handleChange() {
      const flatArray = this.newprojectList[this.currentPage - 1].flatMap(item => item.inspectionItemList).flat()
      const inValidate = flatArray.some(items =>items.standardValue != null && ((items.measuredValue > (items.upperLimit + items.standardValue)) || (items.measuredValue < (items.standardValue - items.lowerLimit))))
      if (inValidate) {
        this.detailData.sequenceNumberItemList[this.currentPage - 1].sequenceNumberResult = 0
      } else {
        this.detailData.sequenceNumberItemList[this.currentPage - 1].sequenceNumberResult = 1
      }
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileRemove(file,columIdx,rowIndex) {
      const datas = this.newprojectList[this.currentPage - 1][columIdx].inspectionItemList[rowIndex].attachments
      this.newprojectList[this.currentPage - 1][columIdx].inspectionItemList[rowIndex].attachments = datas.filter(item => item.uid !== file.uid)

    },
    // 上传附件
    fileUploadChange(res, file) {
      console.log('ceshiimg', res)
      if (res.errorCode === 0) {
        const list = this.newprojectList[this.currentPage - 1][this.columIdx].inspectionItemList[this.rowIndex].attachments ? this.newprojectList[this.currentPage - 1][this.columIdx].inspectionItemList[this.rowIndex].attachments : []
        list.push({
          uid: file.uid,
          id: file.uid,
          name: file.name,
          url: res.result
        })
        this.newprojectList[this.currentPage - 1][this.columIdx].inspectionItemList[this.rowIndex].attachments = list

        return this.$message.success('上传成功')
      }
    },
    async save(type) {
      if (type && ((+this.detailData.qualifiedQuantity) + (+this.detailData.defectQuantity) != this.detailData.batchQuantity)) {
        return this.$message.info('检验数量不等于批次数量！')
      }
      if (!type && ((+this.detailData.qualifiedQuantity) + (+this.detailData.defectQuantity) > this.detailData.batchQuantity)) {
        return this.$message.info('检验数量大于批次数量！')
      }

      const inValidate = this.detailData.sequenceNumberItemList.some(items =>items.sequenceNumberResult !=0 && items.sequenceNumberResult !=1)
      // type 1是完成，0是暂存
      if (type && inValidate) {
        return this.$message.info('合格状态不能为空！')
      }
      this.loading = true
      let obj = {
        inspectionTaskId: this.$route.query.id,
        remark: this.detailData.remark,
        startTime: this.startTime,
        sequenceNumberItemList: this.detailData.sequenceNumberItemList.map((item, index) => {
          return {
            ...item,
            itemList: this.newprojectList[index] ? this.flatData(this.newprojectList[index], 1) : this.flatData(item.itemList, 0)
          }
        }),
        qualifiedQuantity: this.detailData.qualifiedQuantity,
        defectQuantity: this.detailData.defectQuantity
      }
      let apis
      apis = !type ? api.tempSaveIncomingInspection: api.saveIncomingInspection
      const res = await apis(obj)
      if (res) {
        this.loading = false
        this.$router.back()
      }
      this.loading = false
    },
    flatData(data, type) {
      let flatArray
      if (type) {
        flatArray = data.flatMap(item => item.inspectionItemList).flat();
      } else {
        flatArray = Object.values(data).flatMap(obj => Object.values(obj)).flat();
      }
      const arr = flatArray.map(item => {
        return {
          inspectionTaskItemId: item.id,
          measuredValue: item.parameterType === 3 && item.measuredValue != null ? item.measuredValue.join(',') : item.measuredValue,
          remark: item.remark,
          attachments: item.attachments && item.attachments.length > 0 ? JSON.stringify(item.attachments) : null,
          // "defectItemId": null,
          // "defectReasonId": null
        }
      })
      return arr
    },
    returnList() {
      this.$router.back()
    },
    fixData(row) {
      if (row.parameterType === 4) {
        return row.measuredValue == 0 ? true : false
      } else if (row.parameterType === 0) {
        return (row.standardValue != null && row.measuredValue) ? row.measuredValue > (row.upperLimit + row.standardValue) || row.measuredValue < (row.standardValue - row.lowerLimit) ? true : '' : ''
      } else {
        return false
      }
    },
    formatArr(arr) {
      try {
        return JSON.parse(arr).map(item => {
          return item.url
        })
      } catch (err) {
        return []
      }
    },
    beforePage() {
      if (this.currentPage > 1) {
        this.currentPage--
        this.fixTableData(this.detailData.sequenceNumberItemList)
      }
    },
    nextPage() {
      if (this.currentPage < this.projectLength) {
        this.currentPage++
        this.fixTableData(this.detailData.sequenceNumberItemList)
      }
    },
    viewPicture(row) {
      this.imgList = row.attachments
      this.pictureVisible = true
    },
    async getProduceProcessList(row) {
      const res = await workApi.getProduceProcessList({
        id: row.id
      })
      if (res) {
        this.allWorkTask = res
      }
    },
    handlePreview(file) {
      if (file.url.indexOf('.txt') > 0) {
        saveAs(file.url, '文件')
        return
      }
      window.open(file.url)
    },
    // 任务详情
    async getOrderDetail(id) {
      const res = await api.getQualityIncomingTaskDetail({ inspectionTaskId: id })
      if (res) {
        this.projectLength = res.sequenceNumberItemList.length
        this.detailData = {
          ...res,
          attachment: res.attachments ? JSON.parse(res.attachments) : []
        }
        this.fixTableData(this.detailData.sequenceNumberItemList)
      }
    },
    fixTableData(val) {
      const inspectionEntryMap = val[this.currentPage - 1].itemList
      const inspectionEntryMaps = Object.values(inspectionEntryMap["0"]).sort((a, b) => a[0].sort - b[0].sort)
      this.projectList = inspectionEntryMaps.map((item) => ({
        currentPage: this.currentPage,
        inspectionGroupName: item[0].inspectionGroupName,
        inspectionItemList: item.map(items => {
          return {
            ...items,
            measuredValue: items.parameterType === 3 && items.measuredValue ? items.measuredValue.split(',') : items.parameterType === 4 && items.measuredValue ? +items.measuredValue : items.measuredValue,
            possibleValue: [2, 3].includes(items.parameterType) ? JSON.parse(items.possibleValue) : [],
            attachments: this.type === 'view' ? items.attachments : items.attachments ? JSON.parse(items.attachments) : []
          }
        })
      }))
      let index = this.newprojectList.findIndex(item => item[0].currentPage === this.currentPage)
      if (index === -1) {
        this.newprojectList.push(this.projectList);
      }
    },
    // 递归函数将包含children子数组的数组对象展开为一维数组，并去掉数组形式
    flattenArrayWithChildren(arr) {
      let result = [];
      arr.forEach(item => {
        if (item.children && Array.isArray(item.children)) {
          result = result.concat(this.flattenArrayWithChildren(item.children));
        } else {
          result.push(item);
        }
      });

      return result;
    }



  }
}
</script>

<style scoped lang="scss">
.orderAdd {
  height: 100%;
  width: 100%;
}

::v-deep {
  .el-button--text {
    padding: 0;
  }
}

.red-group {
  ::v-deep {
    .el-input__inner {
      color: #e02020 !important;
    }
  }
}

.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}

::v-deep {
  .table-row-img {
    >img {
      width: 100px;
    }
  }
}

.el-tags {
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.icon-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 19px;
  height: 19px;
  display: block;
}

.all-ipt {
  margin-top: 4px;
}

.procedure-detail-header {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456b;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  img {
    width: 8px;
    height: 12px;
    display: block;
    margin-right: 10px;
  }
}

.detail-card {
  margin-bottom: 20px;
}

.card-bot {
  display: flex;
  flex-flow: row wrap;

  .bot-ti {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    display: inline-block;
    margin-bottom: 16px;
    word-break: break-all;
  }
}

.order-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background: #fff;
  border-top: 1px solid #dde3eb;

  .footer-le {
    padding-left: 30px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;

    span {
      margin-right: 45px;
      display: inline-block;
    }
  }

  .footer-ri {
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
