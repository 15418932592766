<template>
  <div />
</template>

<script>
import { getToken, setToken,setVersionMsg } from '@/utils/auth'
import baseSetting from '@/api/sets/base-setting'
import server from '@/api/user'

import Hapi from '@/api/home'
export default {
  name: 'ReHome',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const queryCode = to.query ? to.query.code : ''
      const queryState = to.query ? to.query.state : ''
      if (queryCode && queryState) {
        vm.getUserInfos(queryCode,queryState)
      } else {
        sessionStorage.clear()
        vm.$router.push({ name: 'login' })
      }
    })
  },
  methods: {
    // 根据token获取用户信息
    async getUserInfos(queryCode,queryState) {
      // 使用code  走洋哥的接口获取 token  memberCode  账号 信息  ————————Start————————
      const tokenMsg = await server.getTokenForOther({ code: queryCode,state:queryState })
      if(tokenMsg){
        setToken(tokenMsg.token)
        const allUserInfo = await Hapi.getLoginInfoForYYHL()
        if(allUserInfo){
          // 处理缓存信息 tenantType  区分产线还是装备云 ————————Start————————
          const tenantType = allUserInfo?.tenantInfo?.tenantType;
          localStorage.setItem('memberType', ['mom','line'].includes(tenantType) ? tenantType : '-租户类型异常—');
          // 处理缓存信息 tenantType  区分产线还是装备云 ————————End————————

          // 登录成功需要处理一下版本信息 存缓存 ————————Start————————
          const versionMsg = await server.getVersionMsg();
          if(versionMsg){
            setVersionMsg(versionMsg.value);
          }else {
            setVersionMsg('版本信息-error');
          }
          // 登录成功需要处理一下版本信息 存缓存 ————————End————————

          await this.$store.dispatch('user/getInfo')
          await this.$store.dispatch('user/priorityStatusList')
          await this.$store.dispatch('app/changeMode')
          // 跳转到工单列表页面
          this.$router.push({ name: 'prodPlan' })
        }else {
          sessionStorage.clear()
          this.$router.push({ name: 'login' })
        }
      }else {
        sessionStorage.clear()
        this.$router.push({ name: 'login' })
        return false;
      }
      // 使用code  走洋哥的接口获取 token  memberCode  账号 信息  ————————End————————
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
