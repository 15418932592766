/** write by luomingzhi */
import { status } from '@/config/options.config'
import { formatColumns } from '@/utils'

export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'code',
    label: '角色编码',
    sortable: false,
    hiddenSearch: true
    // form: { tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: '角色名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'authority',
    label: '权限',
    sortable: false,
    hiddenSearch: true,
    slotName: 'authority'
  },
  {
    prop: 'isEnabled',
    label: '角色状态',
    sortable: false,
    slotName: 'isEnabled',
    filters: formatColumns(status)
    // form: {
    //   tagName: 'el-select',
    //   options: status,
    //   defaultValue: 1
    // }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
    // form: {
    //   tagName: 'el-input',
    //   required: false,
    //   props: { type: 'textarea' }
    // }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]

export const appMenu_old = [
  {
    class: 'production',
    name: '生产',
    children: [
      {
        name: '工序任务'
      },
      {
        name: '工单任务'
      },
      {
        name: '拌料'
      },
      {
        name: '领料'
      },
      {
        name: '全场呼叫'
      },
      {
        name: '异常呼叫'
      },
      {
        name: '代报工'
      },
      {
        class: 'workOrderTask',
        name: '工序任务-任务页',
        children: [
          {
            name: '完工'
          },
          {
            name: '待开始'
          },
          {
            name: '进行中'
          },
          {
            name: '快速扫码'
          },
          {
            name: '撤回报工'
          }
        ]
      },
    ]
  },
  {
    class: 'warehouse',
    name: '仓库',
    children: [
      {
        name: '入库'
      },
      {
        name: '出库'
      },
      {
        name: '包装'
      }, {
        name: '托盘'
      },
      {
        name: '拆包'
      },
      {
        name: '更换包装'
      },
      {
        name: '转移库位'
      },
      {
        class: 'prepareMaterialTask',
        name: '备料任务',
        children: [
          {
            name: '待备料'
          },
          {
            name: '备料中'
          }
        ]
      }
    ]
  },
  {
    class: 'stock',
    name: '库存',
    children: [
      {
        name: '入库'
      },
      {
        name: '出库'
      },
      {
        name: '调拨'
      },
      {
        name: '仓库盘点'
      },
      {
        name: '物料盘点'
      },
      {
        name: '库存查询'
      },
      {
        name: '打包'
      }
    ]
  },
  {
    class: 'plan',
    name: '计划',
    children: [
      {
        name: '领料申请',
        url: ''
      }
    ]
  },
  {
    class: 'library',
    name: '线边仓',
    children: [
      {
        name: '线边仓收料',
        url: ''
      }
    ]
  },
  {
    class: 'equipment',
    name: '设备',
    children: [
      {
        name: '故障报修-应用页'
      },
      {
        name: '维修单'
      },
      {
        name: '巡检单'
      },
      {
        name: '点检单'
      },
      {
        name: '保养单'
      },
      {
        name: '备件入库'
      },
      {
        name: '备件出库'
      },
      {
        class: 'faultReport',
        name: '故障报修-任务页',
        children: [{
          name: '故障报修'
        }, {
          name: '待审核'
        },
          {
            name: '待验证'
          }]
      },
      {
        class: 'equipmentReport',
        name: '设备维修',
        children: [{
          name: '待接单'
        }, {
          name: '待维修'
        },
          {
            name: '维修中'
          },
          {
            name: '快速维修'
          }]
      },
      {
        class: 'xunjian',
        name: '巡检任务',
        children: [{
          name: '待巡检'
        },
          {
            name: '巡检中'
          },
          {
            name: '快速巡检'
          }]
      },
      {
        class: 'dianjian',
        name: '点检任务',
        children: [
          {
            name: '待点检'
          },
          {
            name: '点检中'
          },
          {
            name: '快速点检'
          }
        ]
      },
      {
        class: 'baoyang',
        name: '保养任务',
        children: [{
          name: '待保养'
        }, {
          name: '保养中'
        },
          {
            name: '快速保养'
          }]
      },
      {
        class: 'equipmentAssing',
        name: '备件任务',
        children: [{
          name: '待出库单'
        }]
      }
    ]
  },
  {
    class: 'quality',
    name: '质量',
    children: [
      {
        name: '过程检'
      }, {
        name: '来料检'
      }, {
        name: '出货检'
      },
      {
        class: 'qualityTask',
        name: '质检任务',
        children: [
          {
            name: '过程检待检'
          }, {
            name: '来料检待检'
          },
          {
            name: '出货检待检'
          }
        ]
      }
    ]
  },
  {
    class: 'service',
    name: '服务',
    children: [
      {
        name: '待处理需求'
      },
      {
        name: '售后设备'
      },
      {
        class: 'demandTask',
        name: '需求任务',
        children: [
          {
            name: '待处理'
          },
          {
            name: '已处理'
          }
        ]
      }
    ]
  },
  {
    class: 'count',
    name: '统计',
    children: [
      {
        name: '工资绩效'
      },
      {
        name: '工作日报'
      },
      {
        name: '个人工时'
      }
    ]
  },
  {
    class: 'prepareMaterialTask',
    name: '备料任务',
    children: [
      {
        name: '待备料'
      },
      {
        name: '备料中'
      }
    ]
  },
  {
    class: 'query',
    name: '查询',
    children: [
      {
        name: '设备列表'
      },
      {
        name: '辅材列表'
      },
    ]
  },
  {
    class: 'personalCenter',
    name: '个人中心',
    children: [
      {
        name: '模式切换'
      }
    ]
  },
]
export const viewStations = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '工位编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工位名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '工位状态',
    slotName: 'isEnabled',
    sortable: false,
    hiddenSearch: true
  }
]

export const viewSetColumns = [
  {
    prop: 'code',
    label: '设备编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'drawing',
    label: '设备图片',
    slotName: 'drawing',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentClassName',
    label: '设备类',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 200
  }
]
export const User = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'jobNumber',
    label: '工号',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 0, tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: '姓名',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 1, tagName: 'el-input' }
  },
  {
    prop: 'userName',
    label: '用户名',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 6, tagName: 'el-input' }
  },
  {
    prop: 'phone',
    label: '手机号码',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 3, tagName: 'el-input' }
  },
  {
    prop: 'organizationId',
    label: '组织',
    hiddenSearch: true,
    sortable: false,
    slotName: 'organizationId',
    form: { colSpan: 24, index: 2, tagName: 'el-select', options: [], required: false }
  },
  {
    prop: 'email',
    label: '邮箱',
    hiddenSearch: true,
    sortable: false,
    form: { colSpan: 24, index: 4, tagName: 'el-input', required: false }
  },
  {
    prop: 'isSystemUser',
    label: '是否系统用户',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isSystemUser',
    width: 120,
    form: {
      index: 5,
      colSpan: 24,
      tagName: 'el-switch',
      defaultValue: 1,
      props: {
        activeText: '是',
        inactiveText: '否',
        activeValue: 1,
        inactiveValue: 0
      },
      on: {
        change: (val) => {
        }
      }
      // on: { change: (val) => { functionList.paramsTypeChange(val) } }
    }
  },
  // {
  //   prop: 'station',
  //   label: '可执行工位',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'station'
  // },
  // {
  //   prop: 'setting',
  //   label: '我的设备',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'setting'
  // },
  // {
  //   prop: 'roleIdList',
  //   label: '角色',
  //   hiddenSearch: true,
  //   sortable: false,
  //   slotName: 'roleList',
  //   width: 180,
  //   form: { colSpan: 24, index: 8, tagName: 'el-select', options: [], props: { multiple: true, collapseTags: true } }
  // },
  // {
  //   prop: 'isEnabled',
  //   label: '用户状态',
  //   slotName: 'isEnabled',
  //   sortable: false
  // },
  // {
  //   prop: 'remark',
  //   label: '备注',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  // {
  //   prop: 'loginTime',
  //   label: '最近登录',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 100
  }
]

export const appMenu = [
  {
    class: 'ApplicationForApp',
    name: '应用',
    children: [
      {
        class: 'production',
        name: '生产',
        children: [
          {
            name: '工序任务'
          },
          {
            name: '工单任务'
          },
          // {
          //   name: '拌料'
          // },
          // {
          //   name: '领料'
          // },
          {
            name: '全场呼叫'
          },
          {
            name: '异常呼叫'
          },
          // {
          //   name: '代报工'
          // },
          {
            name: '异常列表'
          }
        ]
      },
      // {
      //   class: 'warehouse',
      //   name: '仓库',
      //   children: [
      //     {
      //       name: '入库'
      //     },
      //     {
      //       name: '出库'
      //     },
      //     {
      //       name: '包装'
      //     }, {
      //       name: '托盘'
      //     },
      //     {
      //       name: '拆包'
      //     },
      //     {
      //       name: '更换包装'
      //     },
      //     {
      //       name: '转移库位'
      //     }
      //   ]
      // },
      {
        class: 'stock',
        name: '库存',
        children: [
          {
            name: '入库'
          },
          {
            name: '出库'
          },
          {
            name: '调拨'
          },
          {
            name: '仓库盘点'
          },
          {
            name: '物料盘点'
          },
          {
            name: '库存查询'
          },
          {
            name: '打包'
          }
        ]
      },
      // {
      //   class: 'plan',
      //   name: '计划',
      //   children: [
      //     {
      //       name: '领料申请',
      //       url: ''
      //     }
      //   ]
      // },
      // {
      //   class: 'library',
      //   name: '线边仓',
      //   children: [
      //     {
      //       name: '线边仓收料',
      //       url: ''
      //     }
      //   ]
      // },
      {
        class: 'equipment',
        name: '设备',
        children: [
          {
            name: '故障报修-应用页'
          },
          {
            name: '维修单'
          },
          {
            name: '巡检单'
          },
          {
            name: '点检单'
          },
          {
            name: '保养单'
          },
          {
            name: '备件入库'
          },
          {
            name: '备件出库'
          }
        ]
      },
      {
        class: 'quality',
        name: '质量',
        children: [
          {
            name: '过程检-装备云'
          },
          {
            name: '过程检-数字产线'
          }, {
            name: '来料检'
          }, {
            name: '出货检'
          }, {
            name: '待检物料'
          }
        ]
      },
      {
        class: 'service',
        name: '服务',
        children: [
          {
            name: '待处理需求'
          },
          {
            name: '售后设备'
          }
        ]
      },
      {
        class: 'count',
        name: '统计',
        children: [
          {
            name: '工资绩效'
          },
          {
            name: '工作日报'
          },
          {
            name: '个人工时'
          }
        ]
      },
      {
        class: 'incomingBarodeQuery',
        name: '条码查询',
        children: [
          {
            name: '来料批次码'
          },
          {
            name: '来料序列号'
          }
        ]
      },
      // {
      //   class: 'query',
      //   name: '查询',
      //   children: [
      //     {
      //       name: '设备列表'
      //     },
      //     {
      //       name: '辅材列表'
      //     }
      //   ]
      // }
    ]
  },
  {
    class: 'TaskForApp',
    name: '任务',
    children: [
      {
        class: 'allCallTask',
        name: '全场呼叫任务',
        children: [
          {
            name: '待我处理-全场呼叫'
          },
          {
            name: '正在处理-全场呼叫'
          },
          {
            name: '我已处理-全场呼叫'
          },
          {
            name: '我发起的-全场呼叫'
          }
        ]
      },
      {
        class: 'abnormalCall',
        name: '异常呼叫任务',
        children: [
          {
            name: '待我处理-异常呼叫'
          },
          {
            name: '我已处理-异常呼叫'
          }
        ]
      },
      {
        class: 'projectTask',
        name: '项目任务',
        children: [
          {
            name: '待我处理-项目任务'
          },
          {
            name: '我已处理-项目任务'
          }
        ]
      },
      {
        class: 'workOrderTaskForCloud',
        name: '工序任务-装备云',
        children: [
          {
            name: '待开始-工序任务-装备云'
          },
          {
            name: '进行中-工序任务-装备云'
          },
          {
            name: '已完成-工序任务-装备云'
          },
          {
            name: '全部任务-工序任务-装备云'
          },
          {
            name: '工序任务-重启'
          },
        ]
      },
      {
        class: 'workOrderTask',
        name: '工序任务-数字产线',
        children: [
          // {
          //   name: '完工-工序任务-数字产线'
          // },
          {
            name: '待开始-工序任务-数字产线'
          },
          {
            name: '进行中-工序任务-数字产线'
          },
          {
            name: '快速扫码-工序任务-数字产线'
          },
          // {
          //   name: '撤回报工-工序任务-数字产线'
          // }
        ]
      },
      {
        class: 'qualityTaskForCloud',
        name: '质检任务-装备云',
        children: [
          {
            name: '待开始-过程检'
          },
          {
            name: '进行中-过程检'
          },
          {
            name: '已完成-过程检'
          },
        ]
      },
      {
        class: 'flawInspectionTask',
        name: '探伤任务',
        children: [
          {
            name: '探伤待检'
          }
        ]
      },
      {
        class: 'qualityTask',
        name: '质检任务-数字产线',
        children: [
          {
            name: '过程检待检-数字产线'
          },
          {
            name: '来料检待检-数字产线'
          },
          {
            name: '出货检待检-数字产线'
          }
        ]
      },
      {
        class: 'prepareMaterialTask',
        name: '备料任务',
        children: [
          {
            name: '待备料'
          },
          {
            name: '备料中'
          }
        ]
      },
      {
        class: 'receiveMaterialTask',
        name: '收料任务',
        children: [
          {
            name: '开始收料'
          },
          {
            name: '进行中-收料任务'
          },
          {
            name: '已完成-收料任务'
          }
        ]
      },
      {
        class: 'pickMaterialTask',
        name: '领料任务',
        children: [
          {
            name: '待开始-领料任务'
          },
          {
            name: '已完成-领料任务'
          }
        ]
      },
      {
        class: 'qualityIncomingTask',
        name: '来料检任务',
        children: [
          {
            name: '创建检验任务'
          }
        ]
      },
      {
        class: 'inventoryTask',
        name: '库存任务',
        children: [
          {
            name: '扫码入库'
          },
          {
            name: '领料待出库'
          },
          {
            name: '领料待交接'
          },
          {
            name: '领料已出库'
          }
        ]
      },
      {
        class: 'faultReport',
        name: '故障报修-任务页',
        children: [{
          name: '故障报修'
        }, {
          name: '待审核'
        },
          {
            name: '待验证'
          }]
      },
      {
        class: 'equipmentReport',
        name: '设备维修',
        children: [{
          name: '待接单'
        }, {
          name: '待维修'
        },
          {
            name: '维修中'
          },
          {
            name: '快速维修'
          }]
      },
      {
        class: 'xunjian',
        name: '巡检任务',
        children: [{
          name: '待巡检'
        },
          {
            name: '巡检中'
          },
          {
            name: '快速巡检'
          }]
      },
      {
        class: 'dianjian',
        name: '点检任务',
        children: [
          {
            name: '待点检'
          },
          {
            name: '点检中'
          },
          {
            name: '快速点检'
          }
        ]
      },
      {
        class: 'baoyang',
        name: '保养任务',
        children: [{
          name: '待保养'
        }, {
          name: '保养中'
        },
          {
            name: '快速保养'
          }]
      },
      {
        class: 'equipmentAssing',
        name: '备件任务',
        children: [{
          name: '待出库单'
        }]
      },
      {
        class: 'demandTask',
        name: '需求任务',
        children: [
          {
            name: '待处理-需求任务'
          },
          {
            name: '已处理-需求任务'
          }
        ]
      }
    ]
  },
  {
    class: 'SelfCenterForApp',
    name: '个人中心',
    children: [
      {
        class: 'personalCenter',
        name: '模式切换',
      }
    ]
  }
]
