export default {
  receiveMaterialBtn: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'ReceivedMaterialsOrderItemExport',
      title: '导出'
    },
    {
      name: name + 'EditVariable',
      title: '编辑设变件标识'
    },
    {
      name: name + 'EditSupplier',
      title: '编辑供应商标识'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Print',
      title: '打印'
    }
  ],
  inventory: (name) => [
    {
      name: name + 'AInventoryList',
      title: '库存总表',
      children: [
        {
          name: name + 'InventoryList',
          title: '列表'
        },
        {
          name: name + 'InventoryInventoryHistory',
          title: '查看库存流水'
        },
        {
          name: name + 'InventoryExport',
          title: '导出'
        }
      ]
    },
    {
      name: name + 'BInventoryDetailList',
      title: '库存明细表',
      children: [
        {
          name: name + 'InventoryDetailList',
          title: '列表'
        },
        {
          name: name + 'InventoryDetailInventoryHistory',
          title: '查看库存流水'
        },
        {
          name: name + 'InventoryDetailExport',
          title: '导出'
        },
        {
          name: name + 'InventoryDetailDelivery',
          title: '出库'
        }
      ]
    },
    {
      name: name + 'CMaterialsList',
      title: '物料汇总表',
      children: [
        {
          name: name + 'MaterialsList',
          title: '列表'
        },
        {
          name: name + 'MaterialsInventoryHistory',
          title: '查看库存流水'
        },
        {
          name: name + 'MaterialsExport',
          title: '导出'
        },
        {
          name: name + 'MaterialsDelivery',
          title: '出库'
        }
      ]
    },
    {
      name: name + 'DBatchList',
      title: '批次汇总表',
      children: [
        {
          name: name + 'BatchList',
          title: '列表'
        },
        {
          name: name + 'BatchInventoryHistory',
          title: '查看库存流水'
        },
        {
          name: name + 'BatchExport',
          title: '导出'
        },
        {
          name: name + 'BatchDelivery',
          title: '出库'
        }
      ]
    }
  ],
  purchaseWarehouseTask: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'BatchInWarehouse',
      title: '批量入库'
    },
    {
      name: name + 'Cancel',
      title: '撤销'
    }
  ],
  warehouseAssignList: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Finish',
      title: '批量完成'
    },
    {
      name: name + 'MaterialPreparationTaskForTab',
      title: '备料任务'
    },
    {
      name: name + 'MaterialRequisitionTaskForTab',
      title: '领料任务'
    },
    {
      name: name + 'MaterialReturnTaskForTab',
      title: '退料任务'
    }
  ],

  warehouseAssignBtn: (name) => {
    //备料PreparationTaskList    领料PickingTaskList    退料ScrapingTaskList
    if (name === 'PreparationTaskList') {
      return [
        {
          name: name + 'List',
          title: '列表'
        },
        {
          name: name + 'Finish',
          title: '批量完成'
        },
        {
          name: name + 'MaterialPreparationTaskForTab',
          title: '备料任务'
        }
      ]
    } else if (name === 'PickingTaskList') {
      return [
        {
          name: name + 'List',
          title: '列表'
        },
        {
          name: name + 'MaterialRequisitionTaskForTab',
          title: '领料任务'
        },
        // {
        //   name: name + 'FinishHandover',
        //   title: '完成交接'
        // }
      ]
    }else if (name === 'ScrapingTaskList') {
      return [
        {
          name: name + 'List',
          title: '列表'
        },
        {
          name: name + 'MaterialReturnTaskForTab',
          title: '退料任务'
        },
        // {
        //   name: name + 'FinishHandover',
        //   title: '完成交接'
        // }
      ]
    }
  },

  warehouseAssignDetail: (name) => [
    {
      name: name + 'List',
      title: '查看详情'
    },
    {
      name: name + 'Finish',
      title: '完成'
    }
  ],
  applyAssignDetail: (name) => [
    {
      name: name + 'List',
      title: '查看详情'
    },
    {
      name: name + 'OutWarehouse',
      title: '出库'
    }
  ],
  returnAssignDetail: (name) => [
    {
      name: name + 'List',
      title: '查看详情'
    },
    {
      name: name + 'InWarehouse',
      title: '入库'
    }
  ],
  inventoryAccount: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'BatchExport',
      title: '批量导出'
    }
  ],
  purchase: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'BatchOperate',
      title: '批量操作'
    },
    {
      name: name + 'Add',
      title: '新建申请'
    },
    {
      name: name + 'Modify',
      title: '修改申请'
    },
    {
      name: name + 'Del',
      title: '删除申请'
    },
    {
      name: name + 'ConfirmOrUnConfirm',
      title: '确认/反确认申请'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  scrap: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Export',
      title: '导出'
    }
  ],
  warehouseRecord: (name) => [
    {
      name: name + 'List',
      title: '列表'
    },
    {
      name: name + 'Modify',
      title: '修改'
    },
    {
      name: name + 'Del',
      title: '删除'
    },
    {
      name: name + 'Export',
      title: '导出'
    },
    {
      name: name + 'InWarehouse',
      title: '入库'
    },
    {
      name: name + 'OutWarehouse',
      title: '出库'
    },
    {
      name: name + 'Allocation',
      title: '调拨'
    },
    {
      name: name + 'WarehouseInventory',
      title: '仓库盘点'
    },
    {
      name: name + 'MaterialInventory',
      title: '物料盘点'
    },
    {
      name: name + 'TransferWarehouse',
      title: '移库'
    }
  ]
}
