<template>
    <div class="inventory-wrap common-tab">
      <div class="bottom">

            <div class="materials-info">
              <div v-show="searchFormList">
                <SearchForm
                  ref="searchForm"
                  :form-list-extend="searchFormList"
                  :form-data="searchFormData"
                  :searchType='1'
                  @update:formData="data=>searchFormData=data"
                  @search="searchTable"
                />
              </div>
              <div class="bottom">
                <FunctionHeader
                  ref="functionHeader"
                  v-model="functionHeader.searchVal"
                  :search-title="functionHeader.searchTitle"
                  :radio="params.radio"
                  :export-name="functionHeader.exportName"
                  :export-params="functionHeader.exportParams"
                  :columns="materialsColumns"
                  @search="$refs.mTable.setTableData(true)"
                >
                </FunctionHeader>
                <MTable
                  ref="mTable"
                  :columns="materialsColumns"
                  :height="height"
                  :set-data-method="getTableData"
                >

                <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                <div slot="isKeyMaterials" slot-scope="{ row }">{{ row.isKeyMaterials ? '是' : '否' }}</div>
                <div slot="isExemption" slot-scope="{ row }">{{ row.isExemption ? '是' : '否' }}</div>
                <div slot="isStrengthenInspection" slot-scope="{ row }">{{ row.isStrengthenInspection ? '是' : '否' }}</div>
                <div slot="settingVariable" slot-scope="{ row }">{{ row.settingVariable ? '是' : '否' }}</div>
                <div slot="sequenceNumber" slot-scope="{row }">
                    <el-tooltip popper-class="sequence-tooltip" effect="dark" :content="row.sequenceNumber" placement="top-start">
                      <span class="mr-10">
                        {{ row.sequenceNumber }}
                    </span>
                    </el-tooltip>
                  </div>
                  <div slot="action" slot-scope="{ row }">
                    <el-button v-if="permission('NotCheck')&&[0].includes(row.checkStatus)" type="text" @click="notCheck(row)">免检</el-button>
                  </div>
                </MTable>
              </div>
            </div>
      </div>

    </div>
  </template>

  <script>
  import SearchForm from '@/components/SearchForm/SearchForm'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import {
    materialsColumns
  } from './data'
  import api from '@/api/qualityManagement/quality-task'

  export default {
    name: 'receiveMaterialList',
    components: {
      FunctionHeader,
      SearchForm
    },
    data() {
      return {
        api,
        total:0,
        params: {
          radio: 'ReceivedMaterialsOrderItem'
        },
        searchFormList : [
    {
      colSpan: 5,
      key: 'materials',
      tagName: 'el-input',
      defaultValue: 1,
      attrs: {
        placeholder: '请输入物料编码/名称/规格'
      },
      children: []
    },
    {
        colSpan:5,
        key: 'batchNumber',
        tagName: 'el-input',
        defaultValue: 1,
        attrs: {
          placeholder: '请输入批次码'
        },
        children: []
      },
    {
      colSpan: 5,
      key: 'sequenceNumber',
      tagName: 'el-input',
      defaultValue: 1,
      attrs: {
        placeholder: '请输入序列号'
      },
      children: []
    }
  ],
        functionHeader: {
          searchVal: '',
          exportName: '',
          searchTitle:  '请输入收料单号/采购单号/供应商',
          exportParams: {}
        },
        materialsColumns,
        searchFormData: {
        },
        searchFormatData: [],
        selectTableData: []
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 44
      }
    },
    watch: {
    },
    mounted() {
    },
    methods: {
      async getTableData(condition, callback) {
        const { page, search, order } = condition
        const data = Object.assign({}, ...this.searchFormatData )
        const searchCondition = {
          ...data,
          searchKeyword: this.functionHeader.searchVal
        }
        this.functionHeader.exportParams = searchCondition
        const res = await api.getWaitingInspectionMaterialsListPage({
          page: page.currentPage,
          limit: page.pageSize,
          checkStatus : 0,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      },
      // 查询
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      },
      // 免检入库
      async notCheck(row) {
        this.$confirm('您确定要免检吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.notCheckMaterialsInWarehouse(row.id)
          if (res) {
            this.$message.success('操作成功')
            this.$refs.mTable.setTableData()
          }
        })
      },
    }
  }
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .inventory-wrap {
    width: 100%;
    padding-top: 10px;

    .m-radio-group {
      margin-left: 10px;
    }

    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;
    }
  }
  </style>
