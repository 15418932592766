export default [
  {
    type: 'selection',
    fixed: 'left',
    minWidth: 80,
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'warehouseName',
    label: '仓库名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'recordType',
    label: '流水方向',
    sortable: false,
    hiddenSearch: true,
    slotName: 'recordType'
  },
  {
    prop: 'outInWarehouseTypeName',
    label: '出入库类型',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'typeName',
    label: '库存操作类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'type',
    minWidth: 180
  },
  {
    prop: 'billNumber',
    label: '单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 260,
    slotName: 'billNumber'
  },
  {
    prop: 'sourceBillNumber',
    label: '关联单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsSpecifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    minWidth: 220
  },
  {
    prop: 'materialsUnit',
    label: '主单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'warehouseBatchNumber',
    label: '批次',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    slotName:'sequenceNumber',
    minWidth: 180
  },
  {
    prop: 'quantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'width',
    label: '宽度',
    sortable: false,
    hiddenSearch: true,
    minWidth: 140
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'operateTime',
    label: '创建时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'operator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  }
]
