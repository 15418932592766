/** write by luomingzhi */
const baseUrl = 'api/web/v1/'
export const importUrls = {
  XLQDImport: baseUrl + 'basicData/weld/private/weldLayingOff/import',
  GYHCImport: baseUrl + 'basicData/weld/private/weldLayer/import',
  GYGCImport: baseUrl + 'basicData/weld/private/weldProcessRegulation/import',
  GYQDImport: baseUrl + 'basicData/weld/private/weldProcess/import',
  HGZGImport: baseUrl + 'basicData/weld/private/welderQualification/import',
  HGXMImport: baseUrl + 'basicData/weld/private/welderProject/import',
  HGBHImport: baseUrl + 'basicData/weld/private/welder/import',
  materialsUnit: baseUrl + 'basicData/materials/private/materialsUnit/import',
  materials: baseUrl + 'basicData/materials/private/materials/import',
  supplier: baseUrl + 'basicData/private/supplier/import',
  customer: baseUrl + 'basicData/private/customer/import',
  package: baseUrl + 'warehouse/private/containerClass/import',
  procedure: baseUrl + 'basicData/production/private/procedure/import',
  user: baseUrl + 'authority/private/user/import',
  station: baseUrl + 'basicData/private/station/import',
  orderList: baseUrl + 'production/kiki/private/produceOrder/import',
  account: baseUrl + 'equipment/private/equipment/import',
  procedureGroup: baseUrl + 'basicData/production/private/procedureGroup/import',
  pointCheckItem: baseUrl + 'equipment/setting/private/checkItem/import',
  maintenanceProject: baseUrl + 'equipment/setting/private/maintenanceItem/import',
  sparePart: baseUrl + 'equipment/sparePart/private/sparePart/import',
  bom: baseUrl + 'basicData/materials/private/materialsBom/import',
  inspectionMethods: baseUrl + 'inspection/private/inspectionMethod/import',
  inspectionStandard: baseUrl + 'inspection/private/inspectionStandard/import',
  inspectionItems: baseUrl + 'inspection/private/inspectionEntry/import',
  inspectionProject: baseUrl + 'inspection/private/inspectionProject/import',
  defectsItem: baseUrl + 'inspection/private/defectItem/import',
  defectsReason: baseUrl + 'inspection/private/defectReason/import',
  quality: baseUrl + 'basicData/production/private/processFlow/importInspectEntity',
  // xiewei
  facility: baseUrl + 'field/facility/private/facility/import',
  moldDefineImport: baseUrl + 'mold/clazz/private/upload', // 模具定义导入
  moldStockImport: baseUrl + 'mold/instance/private/upload', // 模具库存导入
  materialsBomImport: baseUrl + 'bom/private/materialsBom/import',
  planOrderImport: baseUrl + 'production/private/planOrder/import',
  incomingImport: baseUrl + 'inspection/private/inspectionScheme/importForYyhlIncoming',
  inspectionSchemeImport: baseUrl + 'inspection/private/inspectionScheme/import'
}
export const checkImportUrls = {
  XLQDImport: baseUrl + 'basicData/weld/private/weldLayingOff/importCheck',
  GYHCImport: baseUrl + 'basicData/weld/private/weldLayer/importCheck',
  GYGCImport: baseUrl + 'basicData/weld/private/weldProcessRegulation/importCheck',
  GYQDImport: baseUrl + 'basicData/weld/private/weldProcess/importCheck',
  HGZGImport: baseUrl + 'basicData/weld/private/welderQualification/importCheck',
  HGXMImport: baseUrl + 'basicData/weld/private/welderProject/importCheck',
  HGBHImport: baseUrl + 'basicData/weld/private/welder/importCheck',
  materialsUnit: baseUrl + 'basicData/materials/private/materialsUnit/importCheck',
  materials: baseUrl + 'basicData/materials/private/materials/importCheck',
  supplier: baseUrl + 'basicData/private/supplier/importCheck',
  customer: baseUrl + 'basicData/private/customer/importCheck',
  package: baseUrl + 'warehouse/private/containerClass/importCheck',
  procedure: baseUrl + 'basicData/production/private/procedure/importCheck',
  user: baseUrl + 'authority/private/user/importCheck',
  station: baseUrl + 'basicData/private/station/importCheck',
  orderList: baseUrl + 'production/kiki/private/produceOrder/importCheck',
  account: baseUrl + 'equipment/private/equipment/importCheck',
  procedureGroup: baseUrl + 'basicData/production/private/procedureGroup/importCheck',
  pointCheckItem: baseUrl + 'equipment/setting/private/checkItem/importCheck',
  maintenanceProject: baseUrl + 'equipment/setting/private/maintenanceItem/importCheck',
  sparePart: baseUrl + 'equipment/sparePart/private/sparePart/importCheck',
  bom: baseUrl + 'basicData/materials/private/materialsBom/importCheck',
  inspectionMethods: baseUrl + 'inspection/private/inspectionMethod/importCheck',
  inspectionStandard: baseUrl + 'inspection/private/inspectionStandard/importCheck',
  inspectionItems: baseUrl + 'inspection/private/inspectionEntry/importCheck',
  inspectionProject: baseUrl + 'inspection/private/inspectionProject/importCheck',
  defectsItem: baseUrl + 'inspection/private/defectItem/importCheck',
  defectsReason: baseUrl + 'inspection/private/defectReason/importCheck',
  quality: baseUrl + 'basicData/production/private/processFlow/importInspectEntityCheck',
  // xiewei
  facility: baseUrl + 'field/facility/private/facility/importCheck',
  moldDefineImport: baseUrl + 'mold/clazz/private/check', // 模具定义导入校验
  moldStockImport: baseUrl + 'mold/instance/private/check', // 模具库存导入校验
  materialsBomImport: baseUrl + 'bom/private/materialsBom/importCheck',
  planOrderImport: baseUrl + 'production/private/planOrder/importCheck',
  incomingImport: baseUrl + 'inspection/private/inspectionScheme/importCheckForYyhlIncoming',
  inspectionSchemeImport: baseUrl + 'inspection/private/inspectionScheme/importCheck'
}
