var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scrap-wrap" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("SearchForm", {
          ref: "searchForm",
          attrs: {
            "form-list-extend": _vm.searchFormList,
            "form-data": _vm.searchFormData
          },
          on: { search: _vm.searchTable }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "FunctionHeader",
          {
            ref: "functionHeader",
            attrs: {
              "search-title": "请输入工位编码/名称",
              tags: !_vm.exportSelectionIds.length
                ? _vm.functionHeader.tags
                : [],
              "export-name": _vm.functionHeader.exportName,
              "export-params": _vm.functionHeader.exportParams,
              "import-name": !_vm.exportSelectionIds.length
                ? _vm.functionHeader.importName
                : "",
              "show-export": !_vm.exportSelectionIds.length ? true : false
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.exportSelectionIds.length,
                    expression: "exportSelectionIds.length"
                  }
                ],
                attrs: { slot: "all" },
                slot: "all"
              },
              [
                _vm.permission("Export")
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.allMore("batchExport")
                          }
                        }
                      },
                      [_vm._v("批量导出")]
                    )
                  : _vm._e(),
                _c(
                  "span",
                  {
                    staticStyle: {
                      margin: "0 10px",
                      display: "inline-block",
                      "font-size": "12px",
                      color: "#393D60"
                    }
                  },
                  [
                    _vm._v(" 已选 "),
                    _c("span", { staticStyle: { color: "#607FFF" } }, [
                      _vm._v(_vm._s(_vm.getSelectionDataLength))
                    ]),
                    _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: { click: _vm.resetSelectData }
                  },
                  [_vm._v("清空")]
                )
              ],
              1
            )
          ]
        ),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.getColumns,
            height: _vm.height,
            "set-data-method": _vm.getTableData
          },
          on: { "selection-change": _vm.selectionChange },
          scopedSlots: _vm._u(
            [
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "userAndUserGroup",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.viewUserAndUserGroups(row)
                        }
                      }
                    },
                    [_vm._v("查看")]
                  )
                }
              },
              {
                key: "procedureList",
                fn: function(ref) {
                  var row = ref.row
                  return row.procedureIdList
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.viewProcedureList(row)
                            }
                          }
                        },
                        [_vm._v("查看")]
                      )
                    : _vm._e()
                }
              },
              {
                key: "isEnabled",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", { staticClass: "is-enabled" }, [
                    _c("div", {
                      style: {
                        background: row.isEnabled ? "#24CAD7" : "#FFCC78"
                      }
                    }),
                    _c("div", [_vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))])
                  ])
                }
              },
              {
                key: "lockStatus",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _c("div", [
                      _vm._v(_vm._s(row.lockStatus ? "已占用" : "未占用"))
                    ])
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _vm.permission("SetUser")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.setUserAndUserGroup(row)
                                }
                              }
                            },
                            [_vm._v("设置人员")]
                          )
                        : _vm._e(),
                      _vm.permission("SetUser")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _vm.permission("SetProcedure")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.setProcedures(row)
                                }
                              }
                            },
                            [_vm._v("设置工序")]
                          )
                        : _vm._e(),
                      _vm.permission("SetProcedure")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _c(
                        "el-dropdown",
                        {
                          on: {
                            command: function(command) {
                              _vm.more(row, command)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _vm._v(" 更多"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right"
                            })
                          ]),
                          _c(
                            "el-dropdown-menu",
                            {
                              staticClass: "td-dropdown-menu",
                              attrs: { slot: "dropdown" },
                              slot: "dropdown"
                            },
                            [
                              _vm.permission("Status")
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "status" } },
                                    [
                                      _vm._v(
                                        _vm._s(row.isEnabled ? "停用" : "启用")
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.permission("Modify")
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "modify" } },
                                    [_vm._v("修改")]
                                  )
                                : _vm._e(),
                              _vm.permission("Del")
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "del" } },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              }
            ],
            null,
            true
          )
        }),
        _c(
          "MDialog",
          {
            attrs: { title: _vm.messages[_vm.currentType] },
            on: { onOk: _vm.submitForm },
            model: {
              value: _vm.visible,
              callback: function($$v) {
                _vm.visible = $$v
              },
              expression: "visible"
            }
          },
          [
            _c("MFormBuilder", {
              ref: "formBuild",
              attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
            })
          ],
          1
        ),
        _c(
          "MDialog",
          {
            attrs: { title: "选择包含用户" },
            on: { onOk: _vm.submitUserAndUserGroup },
            model: {
              value: _vm.setPersonVisible,
              callback: function($$v) {
                _vm.setPersonVisible = $$v
              },
              expression: "setPersonVisible"
            }
          },
          [
            _c("SelectUserAndUserGroup", {
              ref: "userAndUserGroup",
              model: {
                value: _vm.transferSelectIds,
                callback: function($$v) {
                  _vm.transferSelectIds = $$v
                },
                expression: "transferSelectIds"
              }
            })
          ],
          1
        ),
        _c(
          "MDialog",
          {
            attrs: { width: "900px", title: "选择工序" },
            on: { onOk: _vm.submitProcedure },
            model: {
              value: _vm.setProcedureVisible,
              callback: function($$v) {
                _vm.setProcedureVisible = $$v
              },
              expression: "setProcedureVisible"
            }
          },
          [
            _c("el-transfer", {
              attrs: {
                filterable: "",
                "filter-method": _vm.filterMethod,
                titles: ["源列表", "目的列表"],
                "filter-placeholder": "请输入...",
                data: _vm.transferData,
                props: _vm.defaultProps
              },
              model: {
                value: _vm.procedureSelectIds,
                callback: function($$v) {
                  _vm.procedureSelectIds = $$v
                },
                expression: "procedureSelectIds"
              }
            })
          ],
          1
        ),
        _c("TableDialog", {
          attrs: {
            title: "查看人员",
            show: _vm.userVisible,
            columns: _vm.userColumns,
            "table-data": _vm.userAndGroupList
          },
          on: {
            "update:show": function($event) {
              _vm.userVisible = $event
            }
          }
        }),
        _c("TableDialog", {
          attrs: {
            title: "查看工序",
            show: _vm.procedureVisible,
            columns: _vm.procedureColumns,
            "table-data": _vm.procedureList
          },
          on: {
            "update:show": function($event) {
              _vm.procedureVisible = $event
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }