import { docType, materialsRequisitionStatus,receiptStatus } from '@/config/options.config'
export const searchFormListAll = [
  {
    key: 'materialPreparationStatus',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '备料状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'value'
        },
        options: [
          {
            name: '待备料',
            value: 1
          },
          {
            name: '备料中',
            value: 2
          },
          {
            name: '已备料',
            value: 3
          }
        ]
      }
    ]
  },
  {
    key: 'materialPreparation',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '备料类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'value'
        },
        options: [{
          name: '按工单备料',
          value: 0
        },
        {
          name: '按工序备料',
          value: 1
        }]
      }
    ]
  },
  {
    key: 'areaName',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择产线'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'name'
        },
        options: []
      }
    ]
  },
  {
    key: 'producePlanPriorityId',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择优先级'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'deliveryTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    dateType: 'date',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '工单交期开始日期',
      endPlaceholder: '工单交期开始日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'createTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建开始日期',
      endPlaceholder: '创建开始日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  }
]
export const searchFormListRequisition = [
  {
    colSpan: 6,
    key: 'receiptNumber',
    tagName: 'el-input',
    attrs: {
      placeholder: '工单编号'
    }
  },
  {
    colSpan: 6,
    key: 'receiptName',
    tagName: 'el-input',
    attrs: {
      placeholder: '工单名称'
    }
  },
  {
    colSpan: 6,
    key: 'receiptType',
    tagName: 'el-select',
    props: {
      placeholder: '请选择单据类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: docType
      }
    ]
  },
  // {
  //   colSpan: 4,
  //   key: 'receiptStatus',
  //   tagName: 'el-select',
  //   props: {
  //     placeholder: '状态'
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'name',
  //         value: 'id'
  //       },
  //       options: materialsRequisitionStatus
  //     }
  //   ]
  // },
  {
    colSpan: 6,
    key: 'projectNumber',
    tagName: 'el-input',
    attrs: {
      placeholder: '项目编号'
    }
  },
  {
    colSpan: 6,
    key: 'projectName',
    tagName: 'el-input',
    attrs: {
      placeholder: '项目名称'
    }
  }
]
