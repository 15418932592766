var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-container" },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _vm.fullScreen
            ? _c(
                "div",
                {
                  staticClass: "clear",
                  on: {
                    click: function($event) {
                      return _vm.closedFullScreen()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/l_c_H_images/全屏打开.png")
                    }
                  })
                ]
              )
            : _vm._e(),
          _c(
            "el-breadcrumb",
            { staticClass: "app-breadcrumb", attrs: { separator: "/" } },
            [
              _c(
                "transition-group",
                { key: "app-breadcrumb-group", attrs: { name: "breadcrumb" } },
                _vm._l(_vm.levelList, function(item, index) {
                  return _c("el-breadcrumb-item", { key: item.path }, [
                    item.redirect === "noRedirect" ||
                    index === _vm.levelList.length - 1
                      ? _c("span", { staticClass: "no-redirect" }, [
                          _vm._v(_vm._s(item.title))
                        ])
                      : _c("span", [_vm._v(_vm._s(item.title))])
                  ])
                }),
                1
              )
            ],
            1
          ),
          _c("MTagView")
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center" },
        [
          _c(
            "el-popover",
            {
              ref: "popover1",
              attrs: {
                "popper-class": "popover-class",
                placement: "bottom",
                title: "",
                trigger: "hover"
              }
            },
            [
              _c("VueQr", {
                attrs: {
                  "correct-level": 3,
                  "auto-color": false,
                  "color-dark": "#000000",
                  text: _vm.version,
                  size: 100,
                  margin: 0
                }
              }),
              _c("span", { staticStyle: { "font-size": "14px" } }, [
                _vm._v("扫码下载APP")
              ]),
              _c("span", { staticStyle: { "font-size": "14px" } }, [
                _vm._v("安卓" + _vm._s(_vm.versionNumber))
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "popover",
                  rawName: "v-popover:popover1",
                  arg: "popover1"
                }
              ],
              staticClass: "icon"
            },
            [
              _vm.version
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.version,
                        download: "",
                        target: "_blank"
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/l_c_H_images/二维码.png")
                        }
                      })
                    ]
                  )
                : _vm._e()
            ]
          ),
          _c(
            "div",
            { staticClass: "icon", on: { click: _vm.goNewlink } },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "帮助中心",
                    placement: "bottom",
                    effect: "light"
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/l_c_H_images/帮助.png")
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "icon", on: { click: _vm.goJump } },
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: "意见反馈",
                    placement: "bottom",
                    effect: "light"
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/l_c_H_images/意见反馈.png")
                    }
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "news" }, [_c("news")], 1),
        _vm.memberCode !== "demo"
          ? _c("div", { staticClass: "lock", on: { click: _vm.lockScreen } }, [
              _c("img", {
                attrs: { src: require("../../../assets/l_c_H_images/锁屏.png") }
              }),
              _vm._m(0)
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "user" },
          [
            _c(
              "el-dropdown",
              {
                on: {
                  command: function($event) {
                    return _vm.dropdownClick($event)
                  }
                }
              },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _c("span", { staticStyle: { color: "#41456B" } }, [
                    _vm._v(_vm._s(_vm.userInfos.name))
                  ]),
                  _c("img", {
                    attrs: {
                      src: require("../../../assets/l_c_H_images/下拉.png")
                    }
                  })
                ]),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#41456B",
                          padding: "0 10px",
                          "line-height": "30px"
                        }
                      },
                      [_vm._v(_vm._s(_vm.userInfos.companyName))]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        staticClass: "dropdown-item",
                        attrs: { command: "userInfo" }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../../assets/l_c_H_images/info.png"),
                            alt: ""
                          }
                        }),
                        _c("span", [_vm._v("用户信息")]),
                        _c("img", { attrs: { src: "", alt: "" } })
                      ]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        staticClass: "dropdown-item",
                        attrs: { command: "shift" }
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../../assets/l_c_H_images/shift.png"),
                            alt: ""
                          }
                        }),
                        _c("div", { staticClass: "dropdown-item" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                (_vm.userInfos &&
                                  _vm.userInfos.frequencyName) ||
                                  "请选择班次"
                              )
                            )
                          ])
                        ]),
                        _vm.userInfos && _vm.userInfos.frequencyName
                          ? _c("img", {
                              attrs: {
                                src: require("../../../assets/l_c_H_images/switch.png"),
                                alt: ""
                              }
                            })
                          : _c("img")
                      ]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        staticClass: "dropdown-item",
                        attrs: { command: "station" }
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "14px", height: "12px" },
                          attrs: {
                            src: require("../../../assets/l_c_H_images/station.png"),
                            alt: ""
                          }
                        }),
                        _c("div", { staticClass: "dropdown-item" }, [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                (_vm.userInfos && _vm.userInfos.stationName) ||
                                  "请选择工位"
                              )
                            )
                          ])
                        ]),
                        _vm.userInfos && _vm.userInfos.stationName
                          ? _c("img", {
                              attrs: {
                                src: require("../../../assets/l_c_H_images/switch.png"),
                                alt: ""
                              }
                            })
                          : _c("img")
                      ]
                    ),
                    _vm.memberCode !== "demo"
                      ? _c(
                          "el-dropdown-item",
                          {
                            staticClass: "dropdown-item",
                            attrs: { command: "clearCache" }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/l_c_M_images/刷新.png"),
                                alt: ""
                              }
                            }),
                            _c("span", [_vm._v("清空缓存")]),
                            _c("img", { attrs: { src: "", alt: "" } })
                          ]
                        )
                      : _vm._e(),
                    _vm.memberCode !== "demo"
                      ? _c(
                          "el-dropdown-item",
                          {
                            staticClass: "dropdown-item",
                            attrs: { command: "logout" }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/l_c_H_images/logout.png"),
                                alt: ""
                              }
                            }),
                            _c("span", [_vm._v("退出登录")]),
                            _c("img", { attrs: { src: "", alt: "" } })
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "MDialog",
        {
          attrs: { width: "400px", title: "切换班次" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: {
              "label-position": "left",
              "form-data": _vm.formData,
              "form-list": _vm.formList
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { width: "400px", title: "切换工位" },
          on: { onOk: _vm.stationSubmitForm },
          model: {
            value: _vm.stationVisible,
            callback: function($$v) {
              _vm.stationVisible = $$v
            },
            expression: "stationVisible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuilds",
            attrs: {
              "label-position": "left",
              "form-data": _vm.formDatas,
              "form-list": _vm.formLists
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { "font-size": "14px" } }, [
      _c("a", [_vm._v("锁屏")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }