var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inventory-wrap common-tab" }, [
    _c("div", { staticClass: "bottom" }, [
      _c("div", { staticClass: "materials-info" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchFormList,
                expression: "searchFormList"
              }
            ]
          },
          [
            _c("SearchForm", {
              ref: "searchForm",
              attrs: {
                "form-list-extend": _vm.searchFormList,
                "form-data": _vm.searchFormData,
                searchType: 1
              },
              on: {
                "update:formData": function(data) {
                  return (_vm.searchFormData = data)
                },
                search: _vm.searchTable
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bottom" },
          [
            _c("FunctionHeader", {
              ref: "functionHeader",
              attrs: {
                "search-title": _vm.functionHeader.searchTitle,
                radio: _vm.params.radio,
                "export-name": _vm.functionHeader.exportName,
                "export-params": _vm.functionHeader.exportParams,
                columns: _vm.materialsColumns
              },
              on: {
                search: function($event) {
                  return _vm.$refs.mTable.setTableData(true)
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            }),
            _c("MTable", {
              ref: "mTable",
              attrs: {
                columns: _vm.materialsColumns,
                height: _vm.height,
                "set-data-method": _vm.getTableData
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "isKeyMaterials",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm._v(_vm._s(row.isKeyMaterials ? "是" : "否"))
                    ])
                  }
                },
                {
                  key: "isExemption",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm._v(_vm._s(row.isExemption ? "是" : "否"))
                    ])
                  }
                },
                {
                  key: "isStrengthenInspection",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm._v(_vm._s(row.isStrengthenInspection ? "是" : "否"))
                    ])
                  }
                },
                {
                  key: "settingVariable",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _vm._v(_vm._s(row.settingVariable ? "是" : "否"))
                    ])
                  }
                },
                {
                  key: "sequenceNumber",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              "popper-class": "sequence-tooltip",
                              effect: "dark",
                              content: row.sequenceNumber,
                              placement: "top-start"
                            }
                          },
                          [
                            _c("span", { staticClass: "mr-10" }, [
                              _vm._v(" " + _vm._s(row.sequenceNumber) + " ")
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _vm.permission("NotCheck") &&
                        [0].includes(row.checkStatus)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.notCheck(row)
                                  }
                                }
                              },
                              [_vm._v("免检")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }