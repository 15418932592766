
export const columns = [
  {
    prop: 'spotCheckNumber',
    label: '点检单号',
    sortable: 'custom',
    hiddenSearch: true,
    slotName: 'spotCheckNumber',
    minWidth: 180
  },
  {
    prop: 'spotCheckType',
    label: '点检类别',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentCode',
    label: '设备编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentName',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'equipmentClassName',
    label: '设备类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'checkPlanNumber',
    label: '计划单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'checkPlanTime',
    label: '计划日期',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'checkBeginTime',
    label: '实际开始',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'checkEndTime',
    label: '实际结束',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'checkContinueTime',
    label: '消耗时间（min）',
    sortable: false,
    hiddenSearch: true,
    slotName: 'checkContinueTime',
    minWidth: 160
  },
  {
    prop: 'checker',
    label: '执行人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status'
  },
  {
    prop: 'checkItemList',
    label: '点检项',
    sortable: false,
    hiddenSearch: true,
    slotName: 'checkItemList'
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  }
]

