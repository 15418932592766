var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "big-screen-container",
    { ref: "container" },
    [
      _vm.loading ? _c("dv-loading") : _vm._e(),
      _c("div", { staticClass: "top-container" }, [
        _c("div", { staticClass: "title-wrapper" }, [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.name[_vm.$route.params.id]))
          ]),
          _c("img", {
            attrs: {
              src: "https://oss.mubyte.cn/static/%E5%A4%B4%E9%83%A8%402x.png",
              alt: ""
            }
          })
        ]),
        _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "company-logo" }, [
            _c("img", {
              attrs: {
                src: "https://oss.mubyte.cn/static/%E5%85%AC%E5%8F%B8%402x.png",
                alt: ""
              }
            }),
            _c("span", [_vm._v(_vm._s(_vm.companyInfo.name))])
          ]),
          _c("div", { staticClass: "time-container" }, [
            _c(
              "div",
              {
                staticStyle: {
                  position: "absolute",
                  left: "-50px",
                  top: "18px"
                }
              },
              [
                !["4", "8", "9"].includes(_vm.$route.params.id)
                  ? _c("img", {
                      staticStyle: { width: "30px" },
                      attrs: {
                        src:
                          "https://oss.mubyte.cn/static/%E8%AE%BE%E7%BD%AE%402x.png",
                        alt: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.openAreaModal($event)
                        }
                      }
                    })
                  : _vm.$route.params.id == "8"
                  ? _c("img", {
                      staticStyle: { width: "30px" },
                      attrs: {
                        src:
                          "https://oss.mubyte.cn/static/%E8%AE%BE%E7%BD%AE%402x.png",
                        alt: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.addEquipement($event)
                        }
                      }
                    })
                  : _vm.$route.params.id == "9"
                  ? _c("img", {
                      staticStyle: { width: "30px" },
                      attrs: {
                        src:
                          "https://oss.mubyte.cn/static/%E8%AE%BE%E7%BD%AE%402x.png",
                        alt: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.openTimeSearch($event)
                        }
                      }
                    })
                  : _vm._e()
              ]
            ),
            _c("img", {
              attrs: { src: "https://oss.mubyte.cn/static/date-b.png", alt: "" }
            }),
            _c("div", { staticClass: "date-time" }, [
              _c("div", { staticClass: "date" }, [
                _vm._v(
                  _vm._s(_vm.currentDate) +
                    "（" +
                    _vm._s(_vm.currentWeek) +
                    "）"
                )
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.currentTime))
              ])
            ])
          ])
        ])
      ]),
      _vm.$route.params.id === "1" ? _c("product", { ref: "board" }) : _vm._e(),
      _vm.$route.params.id === "2"
        ? _c("work-order-progress", { ref: "board" })
        : _vm._e(),
      _vm.$route.params.id === "3" ? _c("quality", { ref: "board" }) : _vm._e(),
      _vm.$route.params.id === "4"
        ? _c("equipment", { ref: "board" })
        : _vm._e(),
      _vm.$route.params.id === "5" ? _c("call", { ref: "board" }) : _vm._e(),
      _vm.$route.params.id === "6"
        ? _c("quality-new", { ref: "board" })
        : _vm._e(),
      _vm.$route.params.id === "66"
        ? _c("quality-new-fsd", { ref: "board" })
        : _vm._e(),
      _vm.$route.params.id === "7"
        ? _c("warehouse", { ref: "board" })
        : _vm._e(),
      _vm.$route.params.id === "8"
        ? _c("equipment-new", {
            ref: "board",
            on: { equipList: _vm.equipDatas }
          })
        : _vm._e(),
      _vm.$route.params.id === "9"
        ? _c("inventory", { ref: "board" })
        : _vm._e(),
      _vm.$route.params.id === "423"
        ? _c("inventory2", { ref: "board" })
        : _vm._e(),
      _vm.$route.params.id === "10"
        ? _c("work-order-progress-fsd", { ref: "board" })
        : _vm._e(),
      _vm.$route.params.id === "12"
        ? _c("work-order-progress-new", { ref: "board" })
        : _vm._e(),
      _vm.$route.params.id === "88"
        ? _c("monitor", { ref: "board" })
        : _vm._e(),
      _c(
        "MDialog",
        {
          attrs: { width: "400px", "append-to-body": true, title: "选择产线" },
          on: { onOk: _vm.submitAreas },
          model: {
            value: _vm.areaModalVisible,
            callback: function($$v) {
              _vm.areaModalVisible = $$v
            },
            expression: "areaModalVisible"
          }
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "95%" },
              attrs: { multiple: "", clearable: "", placeholder: "请选择" },
              model: {
                value: _vm.areaIds,
                callback: function($$v) {
                  _vm.areaIds = $$v
                },
                expression: "areaIds"
              }
            },
            _vm._l(_vm.areaList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { width: "400px", "append-to-body": true, title: "筛选" },
          on: { onOk: _vm.submitTime },
          model: {
            value: _vm.timeModalVisible,
            callback: function($$v) {
              _vm.timeModalVisible = $$v
            },
            expression: "timeModalVisible"
          }
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "95%", "margin-bottom": "10px" },
              attrs: { multiple: "", clearable: "", placeholder: "请选择" },
              model: {
                value: _vm.warehouseIds,
                callback: function($$v) {
                  _vm.warehouseIds = $$v
                },
                expression: "warehouseIds"
              }
            },
            _vm._l(_vm.warehouseList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _c("el-date-picker", {
            staticStyle: { width: "95%" },
            attrs: {
              type: "daterange",
              "range-separator": "至",
              "start-placeholder": "创建开始时间",
              "end-placeholder": "创建结束时间",
              "value-format": "yyyy-MM-dd",
              format: "yyyy-MM-dd",
              clearable: ""
            },
            model: {
              value: _vm.createTime,
              callback: function($$v) {
                _vm.createTime = $$v
              },
              expression: "createTime"
            }
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: {
            width: "1000px",
            "append-to-body": true,
            title: "选择展示设备"
          },
          on: { onOk: _vm.submitEquip, fullScreen: _vm.fullScreenChange },
          model: {
            value: _vm.equipementModalVisible,
            callback: function($$v) {
              _vm.equipementModalVisible = $$v
            },
            expression: "equipementModalVisible"
          }
        },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "Split",
                  {
                    attrs: {
                      offset: _vm.split.offset,
                      height: _vm.splitHeight
                    },
                    on: {
                      "update:offset": function($event) {
                        return _vm.$set(_vm.split, "offset", $event)
                      }
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "left" },
                      [
                        _c("EquipmentClass", {
                          ref: "equipmentClass",
                          attrs: { view: true },
                          on: {
                            nodeClick: _vm.setEquipmentClassId,
                            refrash: _vm.refrash
                          }
                        })
                      ],
                      1
                    ),
                    _c("template", { slot: "right" }, [
                      _c("div", { staticClass: "center" }, [
                        _c(
                          "div",
                          { staticClass: "flex-wrapper" },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  width: "100%",
                                  "justify-content": "space-between"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { padding: "20px 10px" } },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          indeterminate: _vm.isIndeterminate
                                        },
                                        on: {
                                          change: _vm.handleCheckAllChange
                                        },
                                        model: {
                                          value: _vm.checkAll,
                                          callback: function($$v) {
                                            _vm.checkAll = $$v
                                          },
                                          expression: "checkAll"
                                        }
                                      },
                                      [_vm._v("全选 ")]
                                    )
                                  ],
                                  1
                                ),
                                _c("FunctionHeader", {
                                  attrs: {
                                    "search-title": "请输入设备编码/名称",
                                    "columns-setting": false
                                  },
                                  on: { search: _vm.searchCard },
                                  model: {
                                    value: _vm.functionHeader.searchVal,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.functionHeader,
                                        "searchVal",
                                        $$v
                                      )
                                    },
                                    expression: "functionHeader.searchVal"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "el-checkbox-group",
                              {
                                model: {
                                  value: _vm.checkEquip,
                                  callback: function($$v) {
                                    _vm.checkEquip = $$v
                                  },
                                  expression: "checkEquip"
                                }
                              },
                              _vm._l(_vm.equipData, function(item) {
                                return _c(
                                  "div",
                                  {
                                    key: item.id,
                                    staticClass: "checkCard",
                                    style: {
                                      border: _vm.checkEquip.includes(item.id)
                                        ? "1px solid #607FFF"
                                        : "1px solid #DDE3EB"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.showParame(item.id)
                                      }
                                    }
                                  },
                                  [
                                    _c("el-checkbox", {
                                      attrs: { label: item.id },
                                      on: {
                                        change: function(checked) {
                                          return _vm.handleCheckedEquipsChange(
                                            checked,
                                            item.id
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      [
                                        _c("el-divider", {
                                          attrs: { direction: "vertical" }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "word-wrap": "break-word",
                                              "white-space": "normal"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "description" },
                                              [_vm._v(_vm._s(item.code))]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass: "right chooseParame",
                    style: { height: _vm.splitHeight + "px" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          color: "#393D60",
                          "margin-top": "10px"
                        }
                      },
                      [_vm._v("设备参数显示")]
                    ),
                    _c("div", { staticStyle: { color: "#858991" } }, [
                      _vm._v("最多选9个")
                    ]),
                    _c(
                      "el-checkbox-group",
                      {
                        attrs: { max: 9 },
                        model: {
                          value: _vm.checkedEquipParame,
                          callback: function($$v) {
                            _vm.checkedEquipParame = $$v
                          },
                          expression: "checkedEquipParame"
                        }
                      },
                      [
                        _c("div", { staticClass: "paramType" }, [
                          _vm._v("默认")
                        ]),
                        _vm._l(_vm.parameCustom, function(param) {
                          return param.isFixed == 1
                            ? _c(
                                "el-checkbox",
                                {
                                  key: param.id,
                                  staticStyle: { "padding-top": "10px" },
                                  attrs: { label: param.id },
                                  on: {
                                    change: function(checked) {
                                      return _vm.changeChecked(
                                        checked,
                                        param.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(param.deviceParametersName) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        }),
                        _c("div", { staticClass: "paramType" }, [
                          _vm._v("自定义")
                        ]),
                        _vm._l(_vm.parameCustom, function(param) {
                          return param.isFixed == 0
                            ? _c(
                                "el-checkbox",
                                {
                                  key: param.id,
                                  staticStyle: { "padding-top": "10px" },
                                  attrs: { label: param.id },
                                  on: {
                                    change: function(checked) {
                                      return _vm.changeChecked(
                                        checked,
                                        param.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(param.deviceParametersName) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }