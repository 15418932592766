<template>
  <div class="user-wrap">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <div class="top">
          <div class="title">组织架构</div>
        </div>
        <el-input v-model="filterText" placeholder="请输入组织名称" />
        <el-tree
          ref="tree"
          class="filter-tree"
          :data="organizationData"
          :props="defaultProps"
          node-key="id"
          :default-expanded-keys="expandTreeData"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          @node-click="nodeClick"
        />
      </template>
      <template slot="right">
        <div class="bottom common-tab">
          <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
            <el-radio-button v-if="permission('UserList')" label="User">用户</el-radio-button>
            <el-radio-button v-if="permission('UserGroupList')" label="UserGroup">用户组</el-radio-button>
          </el-radio-group>
          <FunctionHeader
            v-model="functionHeader.searchVal"
            :tags="functionHeader.tags"
            :search-title="searchTitle"
            :radio="params.radio"
            :columns-key="columnsKey"
            :export-name="functionHeader.exportName"
            :export-params="functionHeader.exportParams"
            :import-name="functionHeader.importName"
            @search="$refs.mTable.setTableData(true)"
          />
          <MTable
            v-if="permission(params.radio + 'List')"
            ref="mTable"
            :columns="getColumns"
            :height="height"
            :columns-key="columnsKey"
            :set-data-method="getTableData"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="organizationId" slot-scope="{ row}">{{ row.organizationName }}</div>
            <div slot="userType" slot-scope="{ row}">{{ ['正式','外包'][row.userType] }}</div>
            <div slot="isSystemUser" slot-scope="{ row }">
              {{ +row.isSystemUser === 1 ? '是' : '否' }}
            </div>
            <!-- 查看用户组 -->
            <el-button slot="userIdList" slot-scope="{ row }" type="text" @click="viewUsers(row, 1)">查看</el-button>
            <!--      查看用户-->
            <el-button slot="users" slot-scope="{ row }" type="text" @click="viewUsers(row)">查看</el-button>
            <!--      查看可执行工位-->
            <el-button slot="station" slot-scope="{ row }" type="text" @click="viewStation(row)">查看</el-button>
            <!-- 查看设备 -->
            <el-button slot="setting" slot-scope="{ row }" type="text" @click="viewSetting(row)">查看</el-button>
            <!--      角色列表-->
            <div slot="roleList" slot-scope="{ row }">
              <el-tag v-for="item in row.roleList" :key="item.id" color="#F6F7F9" class="role-tags">{{ item.name }}</el-tag>
            </div>
            <div slot="isEnabled" slot-scope="{ row }" class="isEnabled">
              <el-switch :value="row.isEnabled === 1" active-color="#13ce66" inactive-color="#eee" @change="switchStatus(row, params.radio)" />
              <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
            </div>
            <div slot="action" slot-scope="{ row }" v-if="params.radio === 'User'">
              <template v-if="row.isSystem === 0">
                <el-button v-if="permission(params.radio + 'SetStation')" type="text" @click="setStation(row)">设置工位</el-button>
                <el-divider v-if="permission(params.radio + 'SetStation')" direction="vertical" />
                <el-button v-if="permission(params.radio + 'SetEquipment')" type="text" @click="setEquipment(row)">设置设备</el-button>
                <el-divider v-if="permission(params.radio + 'SetEquipment')" direction="vertical" />
                <el-dropdown

                  @command="
                    command => {
                      more(row, command)
                    }
                  "
                >
                  <span class="el-dropdown-link">
                    更多
                    <i class="el-icon-arrow-down el-icon--right" />
                  </span>
                  <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                    <el-dropdown-item v-if="permission(params.radio + 'Modify')" command="modify">修改</el-dropdown-item>
                    <el-dropdown-item v-if="permission(params.radio + 'Del')" command="del">删除</el-dropdown-item>
                    <el-dropdown-item v-if="isSystem" command="resetPassword">重置密码</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <template v-else>
                <el-button v-if="isSystem" type="text" @click="resetPassword(row)">重置密码</el-button>
              </template>
            </div>
            <div slot="action" slot-scope="{ row }" v-else>
              <template v-if="row.isSystem === 0">
                    <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
                    <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
                    <el-button v-if="permission(params.radio + 'Del')" type="text" @click="del(row)">删除</el-button>
              </template>
            </div>
          </MTable>
        </div>
      </template>
    </Split>

    <MDialog v-model="visible" :title="messages[currentType]" width="500px" @onOk="submitForm" @onCancel="cancel()" :customCancel="true">
      <MFormBuilder ref="formBuild" label-position="right" :form-data="formData" :form-list="formList[params.radio]" :custom-rules="rules" >
      <div slot="picture">
              <el-upload
                class="avatar-uploader"
                v-bind="options"
                accept=".png,.jpg"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
              >
                <img v-if="cardPicture" :src="cardPicture" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </div>
          </MFormBuilder>
    </MDialog>
    <!--    设置工位-->
    <MDialog v-model="stationVisible" title="设置工位" @onOk="submitStation">
      <MElTransfer
        v-model="selectStationList"
        :data="formOptions.stationList"
        :props="{ key: 'id', label: 'name' }"
        :titles="['源列表', '目的列表']"
        :filterable="true"
        filter-placeholder="请输入名称"
      />
    </MDialog>
    <TableDialog title="查看用户" :show.sync="userVisible" :columns="viewTableColumns" :table-data="viewTableData" />
    <TableDialog title="查看用户组" :show.sync="userGroupVisible" :columns="viewTableColumns" :table-data="viewTableData" />
    <!-- 关联设备 -->
    <select-setting ref="selectSetting" :visible.sync="setsVisible" :select-materials="selectEquipment" @submitForm="submitSetting" />
    <MDialog v-model="setVisible" title="查看设备" @onOk="setVisible = false">
      <MTable ref="mTables" :columns="columns.viewSetColumns" :height="320" :data="equipmentList" :show-page="false">
        <div slot="drawing" slot-scope="{ row }">
          <el-image v-if="row.drawing" :src="row.drawing" class="drawing-img" :preview-src-list="fixImg(row.drawing)" />
        </div>
        <div slot="action" slot-scope="{ row }">
          <el-button v-if="permission(params.radio + 'SetEquipment') && isSystems === 0" type="text" @click="resetEquipment(row)">解绑</el-button>
          <el-button v-else type="text" disabled>解绑</el-button>
        </div>
      </MTable>
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import columns, { getFunctionList } from './columns'
import formList from './form-list'
import api from '@/api/sets/user/user'
import uapi from '@/api/user'
import role from '@/api/sets/role/role'
import stationApi from '@/api/information/production/station'
import conventionMixin from '@/mixin/conventional-page'
import { Encrypt } from '@/utils/sercet'
import TableDialog from '@/components/TableDialog/TableDialog'
import DelPopover from '@/components/DelPopover/index'
import MElTransfer from '@/components/EleComponents/transfer/main'
import SelectSetting from '@/components/SelectSetting/SelectSetting'
import Split from '@/components/Split/Split'
import { setTreeData } from '@/utils'
import log from '@/api/log'
import { getToken } from '@/utils/auth'

export default {
  name: 'User',
  components: {
    SelectSetting,
    MElTransfer,
    TableDialog,
    FunctionHeader,
    DelPopover,
    Split
  },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      userGroupVisible: false,
      params: {
        radio: 'User'
      },
      searchKeyword: true,
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('UserAdd'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ],
        exportName: 'exportUser',
        importName: 'user',
        exportParams: {}
      },
      columns,
      viewTableColumns: [],
      viewTableData: [],
      visible: false,
      formList: formList,
      formData: {},
      formOptions: {
        userOptions: [],
        roleOptions: [],
        stationList: []
      },
      selectStationList: [],
      currentType: 'add',
      searchTitle: '请输入用户姓名/工号',
      userVisible: false,
      userGroupUsers: [],
      isSystem: false,
      stationVisible: false,
      setVisible: false,
      setsVisible: false,
      selectEquipment: [],
      equipmentList: [],
      selectUserId: undefined,
      isSystems: 0,
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3567894]\d{9}))$/,
            message: '请输入正确的手机号/电话号',
            trigger: 'blur'
          }
        ]
      },
      organizationList: [],
      split: {
        offset: 15
      },
      filterText: '',
      organizationData: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      organizationId: '',
      expandTreeData: [],
      options: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false
      },
      cardPicture: ''
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 15
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 97
    },
    getTableDataParams() {
      return {
        organizationId: this.organizationId,
        isRole: 1
      }
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getColumns() {
      if(this.params.radio === 'UserGroup'){
        return this.columns[this.params.radio]
      }else{
        if (this.memberCode != 'mom') {
        return this.columns[this.params.radio]
      } else {
        return this.columns[this.params.radio].filter(item => {
          return item.prop !== 'station' && item.prop !== 'setting'
        })
      }
      }
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
  beforeMount() {
    getFunctionList({
      paramsTypeChange: this.paramsTypeChange
    })
  },
  mounted() {
    this.getOrganizationList()
    this.getRoleList()
    this.getStationList()
    this.currentUserIsSystem()
    this.getUserGroupList()
  },
  methods: {
    paramsTypeChange(val) {
      if (val === 0) {
        this.formList['User'][8].hidden = true
        this.formList['User'][9].hidden = true
        this.formList['User'][10].hidden = true
        this.$refs.formBuild.setForm({
          password: '',
          userName: '',
          roleIdList: []
        })
      } else {
        this.formList['User'][8].hidden = false
        this.formList['User'][9].hidden = false
        this.formList['User'][10].hidden = false
      }
    },
    async resetEquipment(row) {
      const res = await uapi.delUserEquipment(this.selectUserId, row.id)
      if (res) {
        this.getUserEquipments(this.selectUserId)
      }
    },
    async getOrganizationList() {
      const res = await api.getOrganizationList()
      if (res) {
        this.formList['User'][3].props.options = setTreeData(res)
        this.organizationList = res
        const treeData = setTreeData(res)
        this.expandTreeData = [treeData[0].id]
        this.organizationData = [...treeData]
      }
    },
    async viewSetting(row) {
      this.selectUserId = row.id
      await this.getUserEquipments(row.id)
      this.setVisible = true
      this.isSystems = row.isSystem
    },
    async getUserEquipments(id) {
      const res = await uapi.getUserEquipmentLists(id)
      if (res) {
        this.equipmentList = res
      }
    },
    async submitSetting(data) {
      const list = data.map(item => item.id)
      const res = await uapi.modifyUserEquipment(this.selectUserId, list)
      if (res) {
        this.setsVisible = false
        this.$refs.mTable.setTableData()
      }
    },
    async setEquipment(row) {
      this.selectUserId = row.id
      await this.getUserEquipments(row.id)
      this.selectEquipment = this.equipmentList
      this.setsVisible = true
    },
    fixImg(img) {
      return [img]
    },
    async getRoleList() {
      const res = await role.getRoleList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.formOptions.roleOptions = res
        this.formList.User[10].children[0].options = res
      }
    },
    async getUserGroupList() {
      const res = await api.getUserGroupList()
      if (res) {
        // console.log('用户组==', res)
        this.formOptions.userGroupOptions = res
        this.formList.User[6].children[0].options = res
      }
    },
    async getStationList() {
      const res = await stationApi.getStationList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.formOptions.stationList = res
      }
    },
    async getUserList() {
      const res = await api.getUserList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.formOptions.userOptions = res
        this.formList.UserGroup[1].children[0].options = res
      }
    },
    async currentUserIsSystem() {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      const res = await api.getUserDetail(userInfo.id)
      if (res) {
        this.isSystem = !!res.isSystem
      }
    },
    radioChange(label) {
      const flag = label === 'User'
      Object.assign(this.functionHeader, {
        exportName: flag ? 'exportUser' : 'exportUserGroup',
        importName: flag ? 'user' : ''
      })
      switch (this.params.radio) {
        case 'User':
          this.searchTitle = '请输入用户姓名/工号'
          break
        case 'UserGroup':
          this.searchTitle = '请输入用户组名称'
          this.getUserList()
          break
        default:
          break
      }
      this.$refs.mTable.setTableData(true)
    },
    more(row, command) {
      const functions = {
        del: () => {
          this.del(row)
        },
        modify: () => {
          this.modifyOrCopy(row, command)
        },
        resetPassword: () => {
          this.resetPassword(row)
        },
        setEquipment: () => {
          this.setEquipment(row)
        }
      }
      functions[command]()
    },
    async add() {
      this.formData = {}
      this.currentType = 'add'
      this.visible = true
      this.$nextTick(() => {
        document.querySelector('.el-dialog .el-switch')?.parentElement.insertAdjacentHTML('beforeend', '<span>(系统用户即可以登录系统进行操作的用户)</span>')
      })
    },
    async modifyOrCopy(row, command) {
      let currentKey = 'roleIdList'
      let res
      let res1
      switch (this.params.radio) {
        case 'User':
          currentKey = 'roleIdList'
          res = await role.getRoleListByUserId(row.id)
          if(row.organizationId){
            res1 = await api.getOrganizationtParentPath({id:row.organizationId})
          }
          break
        case 'UserGroup':
          currentKey = 'userIdList'
          res = await api.getUserGroupDetail(row.id)
          res = res.userList
          break
        default:
          break
      }
      if (res) {
        this.formData = {
          ...row,
          [currentKey]: res.map(item => item.id)
        }
        this.cardPicture=row.cardPicture
        this.currentType = command
        this.visible = true
        if (this.params.radio === 'User') {
          if(res1){
            this.formData.organizationId = (res1 || "").split("^").map(item => +item);
          }
          
          if (row.isSystemUser === 0) {
            this.formList['User'][8].hidden = true
            this.formList['User'][9].hidden = true
            this.formList['User'][10].hidden = true
          } else {
            this.formList['User'][8].hidden = false
            this.formList['User'][9].hidden = false
            this.formList['User'][10].hidden = false
          }
        }
      }
    },
    // 重置密码
    resetPassword(row) {
      this.currentType = 'del'
      this.$confirm(`您确定要重置用户（${row.name}）的密码吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.resetPassword(row.id)
        if (res) {
          this.$message.success('密码已重置为123')
          this.$refs.mTable.setTableData()
        }
      })
    },
    // 查看用户组--用户
    async viewUsers(row, val) {
      if (+val === 1) {
        const res = await api.getUserGroupIdList(row.id)
        if (res) {
          this.viewTableData = res
          this.viewTableColumns = columns.viewUserGroup
          this.userGroupVisible = true
        }
      } else {
        const res = await api.getUserGroupDetail(row.id)
        if (res) {
          this.viewTableData = res.userList
          this.viewTableColumns = columns.viewUser
          this.userVisible = true
        }
      }
    },
    async viewStation(row) {
      const res = await stationApi.getStationListByUserId(row.id)
      if (res && res.length) {
        this.viewTableData = res
        this.viewTableColumns = columns.viewStation
        this.userVisible = true
      } else {
        this.$message.info('暂无可执行工位')
      }
    },
    async setStation(row) {
      const res = await stationApi.getStationListByUserId(row.id)
      if (res) {
        this.formData = row
        this.selectStationList = res.map(item => item.id)
        this.stationVisible = true
      }
    },
    async submitStation(callback) {
      const res = await api.modifyUserStation({
        id: this.formData.id,
        userName: this.formData.userName,
        stationIdList: this.selectStationList
      })
      if (res) {
        this.$message.success('设置成功')
        this.stationVisible = false
        callback(false)
      }
    },
    createSetData(raw) {
      let oName = ''
      if (this.params.radio === 'User') {
        oName = this.organizationList.find((item) => item.id === (raw.organizationId || []).at(-1))
      }
      const setData = {
        User: {
          name: raw.name,
          phone: raw.phone,
          roleIdList: raw.roleIdList,
          userName: raw.userName,
          isEnabled: 1,
          isSystemUser: raw.isSystemUser,
          jobNumber: raw.jobNumber,
          organizationId: raw.organizationId,
          userGroupIdList: raw.userGroupIdList,
          organizationName: (oName || {}).name,
          organizationId: (raw.organizationId || []).at(-1),
          email: raw.email,
          password: raw.password,
          cardPicture:this.cardPicture,
          userType: raw.userType
          // remark: raw.remark
        },
        UserGroup: {
          name: raw.name,
          userIdList: raw.userIdList,
          isEnabled: raw.isEnabled,
          remark: raw.remark
        }
      }
      return setData[this.params.radio]
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },

    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },

    async nodeClick(data) {
      this.organizationId = data.id
      this.$refs.mTable.setTableData()
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.$refs.formBuild.setForm({
          cardPicture: res.result
        })
        console.log('formData:',this.$refs.formBuild)
        this.cardPicture = res.result
      } else {
        this.$message.error(res.result)
      }
    },
    cancel(){
      console.log('cancel')
      this.cardPicture = ''
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.user-wrap {
  .left {
    .top {
      padding: 10px;
      .title {
        font-size: 16px;
      }
    }
    .el-input {
      padding: 0 10px;
    }

    .el-tree {
      padding: 10px;
    }
  }

  .bottom {
    padding: 10px;
  }
}
::v-deep {
  .el-form-item {
    margin-bottom: 18px !important;
  }
}
.isEnabled {
  display: flex;
  .el-switch {
    margin-right: 10px;
  }
}
.role-tags {
  margin: 5px;
  border-color: #dde3eb;
  color: #9597ae;
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 150px;
  display: block;
}
</style>
