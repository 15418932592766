let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columns = [
  {
    prop: 'planNumber',
    label: '计划单号',
    sortable: 'custom',
    hiddenSearch: true,
    slotName: 'planCode',
    minWidth: 160,
    form: { index: 0, tagName: 'el-input', props: { disabled: true }}
  },
  {
    prop: 'planName',
    label: '计划名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160,
    form: { index: 1, tagName: 'el-input' }
  },
  {
    prop: 'checkType',
    label: '点检类别',
    sortable: false,
    hiddenSearch: true,
    form: { 
    index: 2,
     tagName: 'el-select', 
    options: [],
    on: { change: (val) => { functionList.selectName(val) } }
  }
  },
  {
    prop: 'equipmentClassName',
    label: '应用设备类',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      index: 3,
      tagName: 'el-cascader',
      key: 'equipmentClassId',
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true,
        disabled: true
      },
      on: {
        change: (val) => { functionList.equipmentClassChange(val) }
      }
    }
  },
  {
    prop: 'pointCheckItem',
    label: '点检项',
    sortable: false,
    hiddenSearch: true,
    slotName: 'pointCheckItem'
  },
  {
    prop: 'cycleTime',
    label: '循环周期',
    sortable: false,
    hiddenSearch: true,
    slotName: 'cycleTime'
  },
  {
    prop: 'execuTotal',
    label: '执行次数',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 150
  },
  {
    prop: 'lastExecuTime',
    label: '上次执行时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'nextExecuTime',
    label: '下次执行时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'isEnabled',
    label: '是否启用',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled'
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 100
  }
]
