import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/private/materialsReceivedOrder/'

export default {
  // 获取收料单列表
  getReceivedMaterialsOrderItemListPage(data) {
    return request.get(baseUrl + 'getMaterialsReceivedOrderItemListPage', data)
  },
  //编辑设变件标识
  updateMaterialsItemSettingVariable(data){
    return request.put(baseUrl + 'updateMaterialsItemSettingVariable', data)
  },
  updateReceivedOrderSupplier(data){
    return request.put(baseUrl + 'updateReceivedOrderSupplier', data)
  },
  // 获取采购单-不分页
  getPurchaseOrderList(data){
    return request.get('api/web/v1/purchase/private/purchaseOrder/getPurchaseOrderList', data)
  },
  // 删除收料物料行
  deleteMaterialsReceivedOrderMaterials(id){
    return request.del(baseUrl + `deleteMaterialsReceivedOrderMaterials?id=${id}`)
  }
}
