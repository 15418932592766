import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/equipment/check/private/spotCheckPlan/'
const baseUrl2 = 'api/web/v1/equipment/check/private/equipmentSpotCheckClass/'
const settingUrl = 'api/web/v1/equipment/setting/private/'

export default {
  addCheckItemAndBindEquipmentClass(data) {
    return request.post(settingUrl + 'checkItemEquipmentClassMapping/addEquipmentClassCheckItem', data)
  },
  // 获取设备点检配置
  getCheckItemEquipmentClassListPage(data) {
    return request.get(settingUrl + 'spotCheckItemEquipmentClassMapping/getSpotCheckItemEquipmentClassMappingListPage', data)
  },
  // 获取设备类列表
  getEquipmentClassList() {
    return request.get(`/api/web/v1/basicData/private/equipmentClass/getEquipmentClassList`)
  },
  // 获取点检计划-不分页
  getSpotPlanList(data) {
    return request.get(baseUrl + 'getEquipmentSpotCheckPlanList', data)
  },
  // 获取点检计划-分页
  getSpotPlanListPage(data) {
    return request.get(baseUrl + 'getEquipmentSpotCheckPlanListPage', data)
  },
  // 新增点检计划
  addSpotPlan(data) {
    return request.post(baseUrl + 'addEquipmentSpotCheckPlan', data)
  },
  // 新增点检计划
  copySpotPlan(data) {
    return request.post(baseUrl + 'addEquipmentSpotCheckPlan', data)
  },
  // 修改点检计划
  modifySpotPlan(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除点检计划
  delSpotPlan(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 获取点检计划详情
  getSpotPlanDetail(data) {
    return request.get(baseUrl + 'getEquipmentSpotCheckPlanDetail', data)
  },
  // 获取单号
  getPlanCode() {
    return request.get(baseUrl + 'getMaxSpotCheckNumber')
  },
  // 获取是点检类别
  getEquipmentSpotCheckClassList() {
    return request.get(settingUrl + 'spotCheckItemEquipmentClassMapping/getSpotCheckItemEquipmentClassMappingList')
  }
}
