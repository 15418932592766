import request from '@/utils/requestForAsync'
import api from '@/api/production/completeSet'
import processApi from '@/api/information/production/process'
const baseUrl = 'api/web/v1/production/private/productionPlan/'

export default {
  ...processApi,
  ...api,
  // 计划列表
  getPlanList(data) {
    return request.post(baseUrl + 'list', data)
  },
  // 获取生产计划编号
  getProductionPlanNumber(data) {
    return request.get(baseUrl + 'getProductionPlanNumber', data)
  },
  // 批量删除计划
  deletePlan(data) {
    return request.put(baseUrl + 'delete', data)
  },
  // 获取计划详情
  getPlanDetail(id) {
    return request.get(baseUrl + `detail?id=${id}`)
  },
  // 创建计划
  createPlan(data) {
    return request.post(baseUrl + 'create', data)
  },
  // 修改计划
  modifyPlan(data) {
    return request.put(baseUrl + 'update', data)
  },
  // 列表导出
  exportList(data) {
    return request.exportPost(baseUrl + 'export', data)
  },
  // 获取产线
  getAreaListDetail(id) {
    return request.get(baseUrl + `getAreaList?id=${id}`)
  },
  // 工艺和产线
  getFlowers(data) {
    return request.get('api/web/v1/basicData/production/private/processFlow/getProcessFlowList', data)
  },
  // 获取工单详情里的生产任务
  getTaskList(data) {
    return request.get('api/web/v1/production/private/productionTask/productionPlanTaskList', data)
  },
  // 工单下任务的报工记录
  productionTaskReportWorkList(data) {
    return request.get('api/web/v1/production/private/productionTask/productionTaskReportWorkList', data)
  },
  // 生产工单下的报工记录
  productionPlanReportWorkList(data) {
    return request.get('api/web/v1/production/private/productionTask/productionPlanReportWorkList', data)
  },

  //生产工单下的质检项
  productionPlanInspectEntity(data){
    return request.get('api/web/v1/production/private/productionPlan/inspectDetail', data)
  },
    // 导出工单质检项目
  exportProductionPlanInspectionEntity(data){
    return request.exportGet('api/web/v1/production/private/productionPlan/inspectExport', data)
  },
  // 生产进度详情
  productionTempoDetail(data) {
    return request.get('api/web/v1/production/private/productionPlan/productionTempoDetail', data)
  },
  // 完结工单
  overPlan(data) {
    return request.post('api/web/v1/production/private/productionPlan/complete', data)
  },
  // 重启工单
  restart(data) {
    return request.post('api/web/v1/production/private/productionPlan/restart', data)
  },
  preOrderList(data) {
    return request.get('api/web/v1/production/private/productionPlan/preList', data)
  },
  addOrderParams(data) {
    return request.post('api/web/v1/production/private/productionPlan/repairPre', data)
  },
  // 装配完工
  assemblyPlan(data) {
    return request.post('api/web/v1/production/private/productionPlan/assemblyCompletion', data)
  },
  //开工
  startDo(data){
    return request.post('api/web/v1/production/private/productionPlan/startWork', data)
  },
  // 批量编辑生产任务
  batchUpdatePoductionTask(data){
    return request.put('api/web/v1/production/private/productionTask/batchUpdate', data)
  },
  // 获取工单齐套详情
  getCompleteRateListPage(data){
    return request.post('api/web/v1/basicData/materials/private/materialsCompleteCalculate/listProductionMaterialsCompleteDetails', data)
  },
  // 获取结案配置项状态
  getCompleteConfigList(data){
    return request.get(baseUrl + 'getCompleteConfigList', data)
  },
  // 获取工单标准工时
  getWorkOrderStandardHoursListPage(data){
    return request.get(baseUrl + 'getStandardHoursListPage', data)
  },
   // 批量编辑工单标准工时
  batchUpdateStandardHours(data){
    return request.put(baseUrl + 'batchUpdateStandardHours', data)
  },
  // 撤销投料记录
  cancelInputMaterialsRecord(data){
    return request.put(baseUrl + `cancelInputMaterialsRecord?id=${data}`)
  },
  // 获取投料记录列表
  getInputMaterialsRecordListPage(data){
    return request.get(baseUrl + 'getInputMaterialsRecordListPage', data)
  },
  // 获取产线-工位列表
  getAreaStationList(data){
    return request.get(baseUrl + 'getAreaStationList', data)
  },
  // 工单工位位置调整
  adjustStationLocation(data){
    return request.post(baseUrl + 'stationLocationAdjust', data)
  }
}
