import settingButtons from './page-buttons/setting.button'
export default {
  path: 'setting',
  name: 'setting',
  component: () => import('@/layout/blank.vue'),
  meta: { title: '设置', icon: require('@/assets/l_c_S_images/设置.svg'), activeMenu: 'setting' },
  redirect: '/setting/user-info/user',
  children: [
    // {
    //   path: 'user-info',
    //   name: 'userInfo',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '用户管理', activeMenu: 'userInfo' },
    //   redirect: '/setting/user-info/user',
    //   children: [
    //     {
    //       path: 'user',
    //       name: 'user',
    //       component: () => import('@/views/setting/user/user.vue'),
    //       meta: { title: '用户管理', icon: 'user', activeMenu: 'user', noCache: true, buttons: settingButtons.user }
    //     },
    //     // {
    //     //   path: 'framework',
    //     //   name: 'framework',
    //     //   component: () => import('@/views/setting/framework/framework.vue'),
    //     //   meta: { title: '组织架构', icon: '', activeMenu: 'framework', buttons: settingButtons.framework('framework') }
    //     // },
    //     // {
    //     //   path: 'role',
    //     //   name: 'role',
    //     //   component: () => import('@/views/setting/role/role.vue'),
    //     //   meta: { title: '角色权限', icon: 'role', activeMenu: 'role', noCache: true, buttons: settingButtons.role('role') }
    //     // }
    //   ]
    // },
    {
      path: 'base-setting-info',
      name: 'baseSettingInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '基本设置', activeMenu: 'baseSettingInfo' },
      redirect: '/setting/base-setting-info/base-setting',
      children: [
        {
          path: 'user',
          name: 'user',
          component: () => import('@/views/setting/user/user.vue'),
          meta: { title: '用户管理', icon: 'user', activeMenu: 'user', noCache: true, buttons: settingButtons.user }
        },
        {
          path: 'framework',
          name: 'framework',
          component: () => import('@/views/setting/framework/framework.vue'),
          meta: { title: '组织架构', icon: '', activeMenu: 'framework', buttons: settingButtons.framework('framework') }
        },
        {
          path: 'role',
          name: 'role',
          component: () => import('@/views/setting/role/role.vue'),
          meta: { title: '角色权限', icon: 'role', activeMenu: 'role', noCache: true, buttons: settingButtons.role('role') }
        },
        {
          path: 'system-params',
          name: 'systemParams',
          component: () => import('@/views/setting/function/system-params/system-params'),
          meta: { title: '系统参数', icon: 'flight-managment', activeMenu: 'system-params', nb: true }
        },
        {
          path: 'base-setting',
          name: 'baseSetting',
          component: () => import('@/views/setting/base-setting/base-setting'),
          meta: { title: '基本设置', icon: 'base-setting', activeMenu: 'base-setting', nb: true, buttons: settingButtons.log }
        },
      ]
    },
    // {
    //   path: 'manageOrg',
    //   name: 'manageOrg',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '组织管理', activeMenu: 'manageOrg' },
    //   redirect: '/setting/manageOrg/framework',
    //   children: [
    //     {
    //       path: 'framework',
    //       name: 'framework',
    //       component: () => import('@/views/setting/framework/framework.vue'),
    //       meta: { title: '组织架构', icon: '', activeMenu: 'framework', buttons: settingButtons.framework('framework') }
    //     },
    //   ]
    // },
    // {
    //   path: 'shift-info',
    //   name: 'shiftInfo',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '班次管理', activeMenu: 'shiftInfo' },
    //   redirect: '/setting/shift-info/shift',
    //   children: [
    //     {
    //       path: 'shift',
    //       name: 'shift',
    //       component: () => import('@/views/setting/shift/shift.vue'),
    //       meta: { title: '班次管理', icon: 'shift', activeMenu: 'shift', buttons: settingButtons.shift('shift') }
    //     }
    //   ]
    // },
    // {
    //   path: 'function',
    //   name: 'function',
    //   component: () => import('@/layout/blank.vue'),
    //   meta: { title: '参数设置', icon: require('@/assets/l_c_S_images/质量.png'), activeMenu: 'function' },
    //   redirect: '/setting/function/system-params',
    //   children: [
    //     {
    //       path: 'system-params',
    //       name: 'systemParams',
    //       component: () => import('@/views/setting/function/system-params/system-params'),
    //       meta: { title: '系统参数', icon: 'flight-managment', activeMenu: 'system-params', nb: true }
    //     }
    //   ]
    // },

    {
      path: 'system-setting-info',
      name: 'systemSettingInfo',
      component: () => import('@/layout/blank.vue'),
      meta: { title: '系统设置', activeMenu: 'systemSettingInfo' },
      redirect: '/setting/system-setting-info/field-setting',
      children: [
        {
          path: 'field-setting',
          name: 'fieldSetting',
          component: () => import('@/views/setting/system/field-setting/field-setting'),
          meta: { title: '字段设置', noCache: true, icon: 'field-setting', activeMenu: 'field-setting' }
        },
        {
          path: 'code-setting',
          name: 'codeSetting',
          component: () => import('@/views/setting/system/code-setting/code-setting'),
          meta: { title: '编号设置', noCache: true, icon: 'code-setting', activeMenu: 'code-setting' }
        },
        {
          path: 'message-notificate',
          name: 'messageNotificate',
          component: () => import('@/layout/blank.vue'),
          meta: { title: '消息通知', activeMenu: 'message-notificate', canLink: true },
          redirect: '/setting/system-setting-info/message-notificate/list',
          children: [
            {
              path: 'list',
              name: 'messageNotificateList',
              component: () => import('@/views/setting/message-notificate/message-notificate'),
              meta: { roleTitle: '列表', title: '', noCache: true, activeMenu: 'message-notificate', buttons: settingButtons.MessageNotificate('messageNotificate') },
              hidden: true
            },
            {
              path: 'add',
              name: 'addMessageNotificate',
              component: () => import('@/views/setting/message-notificate/add-message'),
              meta: { roleTitle: '新增消息通知', title: '', noCache: true, activeMenu: 'message-notificate', nb: true },
              hidden: true
            }
          ]
        },
        {
          path: 'msg-handle',
          name: 'MsgHandleForFSD',
          component: () => import('@/views/information/production/fsdDataHandle/index.vue'),
          meta: { title: '看板数据生成', activeMenu: 'msg-handle', canLink: true }
        }
      ]
    }
  ]
}
